/* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */ /* stylelint-disable max-line-length */
.igx-display-container {
  display: inherit;
  flex-flow: inherit;
  position: relative;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}

.igx-display-container--inactive {
  width: 100%;
}

.igx-drag {
  touch-action: none;
}

.igx-drag--select-disabled {
  user-select: none;
}

.igx-drag__handle {
  user-select: none;
}

.igx-vhelper--vertical, .igx-vhelper--horizontal {
  display: block;
  overflow: auto;
  z-index: 10001;
}

.igx-vhelper--vertical {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
}

.igx-vhelper--horizontal {
  width: 100%;
}

.igx-vhelper--vertical .igx-vhelper__placeholder-content {
  width: 1px;
}

.igx-vhelper--horizontal .igx-vhelper__placeholder-content {
  height: 1px;
}

/* stylelint-disable keyframes-name-pattern */
/**
 * @sass-export-section="material"
 */
/* stylelint-disable max-line-length */
/**
 * @sass-export-section="bootstrap"
 */
/**
 * @sass-export-section="material"
 */
/**
 * @sass-export-section="fluent"
 */
/**
 * @sass-export-section="indigo"
 */
/**
 * @sass-export-section="light-material"
 */
/**
 * @sass-export-section="light-bootstrap"
 */
/**
 * @sass-export-section="light-fluent"
 */
/**
 * @sass-export-section="light-indigo"
 */
/**
 * @sass-export-section="light"
 */
/**
 * @sass-export-section="dark-material"
 */
/**
 * @sass-export-section="dark-bootstrap"
 */
/**
 * @sass-export-section="dark-fluent"
 */
/**
 * @sass-export-section="dark-indigo"
 */
/**
 * @sass-export-section="dark"
 */
[hidden] {
  display: none !important;
}

[class^=igx-],
[class^=igx-] *,
[class^=igx-] *::before,
[class^=igx-] *::after,
[class^=ig-],
[class^=ig-] *,
[class^=ig-] *::before,
[class^=ig-] *::after {
  box-sizing: border-box;
}

.igx-svg-container {
  visibility: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  overflow: hidden;
}

.igx-input-group {
  --is-large: clamp(0, (var(--component-size, 1) + 1) - var(--ig-size-large, 3), 1);
  --is-medium: min(
      clamp(0, (var(--component-size, 1) + 1) - var(--ig-size-medium, 2), 1),
      clamp(0, var(--ig-size-large, 3) - var(--component-size, 1), 1)
  );
  --is-small: clamp(0, var(--ig-size-medium) - var(--component-size, 1), 1);
}
@media print {
  html,
  body,
  app-root {
    min-height: 100vh;
    min-width: 100vw;
    margin: 0;
  }
  app-root {
    display: block;
  }
  * {
    -webkit-print-color-adjust: exact;
    -webkit-filter: opacity(1);
    filter: opacity(1);
    print-color-adjust: exact;
    text-shadow: none !important;
  }
  a[href^=http]::after {
    content: "[" attr(href) "]";
    color: blue;
  }
  .igx-no-print {
    display: none !important;
  }
  .igx-bw-print {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); /* future-proof */
  }
  igx-circular-bar,
  igx-linear-bar,
  .igx-carousel__indicators,
  .igx-carousel__arrow--prev,
  .igx-carousel__arrow--next,
  .igx-ripple,
  .igx-grid__tbody-scrollbar,
  igx-switch__ripple,
  igx-virtual-helper {
    display: none !important;
  }
  igx-grid .igx-grid-th__title,
  igx-grid .igx-grid__td-text {
    white-space: unset !important;
    text-overflow: initial !important;
    overflow: visible !important;
  }
}
.ig-typography {
  font-family: var(--ig-font-family);
}
.ig-typography igx-category-chart {
  --title-text-style: var(--category-chart-title-text-style, 600 1.25rem var(--ig-font-family));
  --subtitle-text-style: var(--category-chart-subtitle-text-style, 400 1rem var(--ig-font-family));
  --x-axis-label-text-style: var(--category-chart-x-axis-label-text-style, 400 0.875rem var(--ig-font-family));
  --x-axis-title-text-style: var(--category-chart-x-axis-title-text-style, 400 0.875rem var(--ig-font-family));
  --y-axis-label-text-style: var(--category-chart-y-axis-label-text-style, 400 0.875rem var(--ig-font-family));
  --y-axis-title-text-style: var(--category-chart-y-axis-title-text-style, 400 0.875rem var(--ig-font-family));
}
.ig-typography igx-data-chart {
  --title-text-style: var(--data-chart-title-text-style, 600 1.25rem var(--ig-font-family));
  --subtitle-text-style: var(--data-chart-subtitle-text-style, 400 1rem var(--ig-font-family));
}
.ig-typography igx-financial-chart {
  --title-text-style: var(--financial-chart-title-text-style, 600 1.25rem var(--ig-font-family));
  --subtitle-text-style: var(--financial-chart-subtitle-text-style, 400 1rem var(--ig-font-family));
  --x-axis-label-text-style: var(--financial-chart-x-axis-label-text-style, 400 0.875rem var(--ig-font-family));
  --x-axis-title-text-style: var(--financial-chart-x-axis-title-text-style, 400 0.875rem var(--ig-font-family));
  --y-axis-label-text-style: var(--financial-chart-y-axis-label-text-style, 400 0.875rem var(--ig-font-family));
  --y-axis-title-text-style: var(--financial-chart-y-axis-title-text-style, 400 0.875rem var(--ig-font-family));
}
.ig-typography igx-funnel-chart {
  --outer-label-text-style: var(--funnel-chart-outer-label-text-style, 300 6rem var(--ig-font-family));
  --text-style: var(--funnel-chart-text-style, 400 1rem var(--ig-font-family));
}
.ig-typography igx-linear-gauge {
  --linear-gauge-font: var(--ig-font-family, inherit);
}
.ig-typography igx-radial-gauge {
  --radial-gauge-font: var(--ig-font-family, inherit);
}
.ig-typography igx-bullet-graph {
  --bullet-graph-font: var(--ig-font-family, inherit);
}
.ig-typography igx-pie-chart {
  --text-style: var(--pie-chart-text-style, 400 0.875rem var(--ig-font-family));
}
.ig-typography igx-shape-chart {
  --title-text-style: var(--shape-chart-title-text-style, 600 1.25rem var(--ig-font-family));
  --subtitle-text-style: var(--shape-chart-subtitle-text-style, 400 1rem var(--ig-font-family));
  --x-axis-title-text-style: var(--shape-chart-x-axis-title-text-style, 400 3rem var(--ig-font-family));
  --x-axis-label-text-style: var(--shape-chart-x-axis-label-text-style, 400 2.125rem var(--ig-font-family));
  --y-axis-title-text-style: var(--shape-chart-y-axis-title-text-style, 400 3rem var(--ig-font-family));
  --y-axis-label-text-style: var(--shape-chart-y-axis-label-text-style, 400 2.125rem var(--ig-font-family));
}
.ig-typography .igx-banner__text {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
  margin-block-start: 0;
  margin-block-end: 0;
}
.ig-typography .igx-bottom-nav__menu-item [igxBottomNavHeaderLabel], .igx-bottom-nav__menu-item .ig-typography [igxBottomNavHeaderLabel], .ig-typography .igx-bottom-nav__menu-item--selected [igxBottomNavHeaderLabel], .igx-bottom-nav__menu-item--selected .ig-typography [igxBottomNavHeaderLabel], .ig-typography .igx-bottom-nav__menu-item--disabled [igxBottomNavHeaderLabel], .igx-bottom-nav__menu-item--disabled .ig-typography [igxBottomNavHeaderLabel] {
  font-family: var(--ig-caption-font-family, var(--ig-font-family));
  font-size: var(--ig-caption-font-size);
  font-weight: var(--ig-caption-font-weight);
  font-style: var(--ig-caption-font-style);
  line-height: var(--ig-caption-line-height);
  letter-spacing: var(--ig-caption-letter-spacing);
  text-transform: var(--ig-caption-text-transform);
  margin-top: var(--ig-caption-margin-top);
  margin-bottom: var(--ig-caption-margin-bottom);
  margin: 0;
}
.ig-typography .igx-button {
  font-family: var(--ig-button-font-family, var(--ig-font-family));
  font-size: var(--ig-button-font-size);
  font-weight: var(--ig-button-font-weight);
  font-style: var(--ig-button-font-style);
  line-height: var(--ig-button-line-height);
  letter-spacing: var(--ig-button-letter-spacing);
  text-transform: var(--ig-button-text-transform);
  margin-top: var(--ig-button-margin-top);
  margin-bottom: var(--ig-button-margin-bottom);
  text-align: center;
}
.ig-typography .igx-button--fab {
  font-family: var(--ig-button-font-family, var(--ig-font-family));
  font-size: var(--ig-button-font-size);
  font-weight: var(--ig-button-font-weight);
  font-style: var(--ig-button-font-style);
  line-height: var(--ig-button-line-height);
  letter-spacing: var(--ig-button-letter-spacing);
  text-transform: var(--ig-button-text-transform);
  margin-top: var(--ig-button-margin-top);
  margin-bottom: var(--ig-button-margin-bottom);
  text-align: center;
  margin: 0;
}
.ig-typography .igx-calendar__header-year {
  font-family: var(--ig-overline-font-family, var(--ig-font-family));
  font-size: var(--ig-overline-font-size);
  font-weight: var(--ig-overline-font-weight);
  font-style: var(--ig-overline-font-style);
  line-height: var(--ig-overline-line-height);
  letter-spacing: var(--ig-overline-letter-spacing);
  text-transform: var(--ig-overline-text-transform);
  margin-top: var(--ig-overline-margin-top);
  margin-bottom: var(--ig-overline-margin-bottom);
  margin: 0;
}
.ig-typography .igx-calendar__header-date {
  font-family: var(--ig-h4-font-family, var(--ig-font-family));
  font-size: var(--ig-h4-font-size);
  font-weight: var(--ig-h4-font-weight);
  font-style: var(--ig-h4-font-style);
  line-height: var(--ig-h4-line-height);
  letter-spacing: var(--ig-h4-letter-spacing);
  text-transform: var(--ig-h4-text-transform);
  margin-top: var(--ig-h4-margin-top);
  margin-bottom: var(--ig-h4-margin-bottom);
  margin: 0;
}
.ig-typography .igx-calendar-picker__date {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
  margin: 0;
}
.ig-typography .igx-calendar__date-content {
  font-family: var(--ig-body-1-font-family, var(--ig-font-family));
  font-size: var(--ig-body-1-font-size);
  font-weight: var(--ig-body-1-font-weight);
  font-style: var(--ig-body-1-font-style);
  line-height: var(--ig-body-1-line-height);
  letter-spacing: var(--ig-body-1-letter-spacing);
  text-transform: var(--ig-body-1-text-transform);
  margin-top: var(--ig-body-1-margin-top);
  margin-bottom: var(--ig-body-1-margin-bottom);
  margin: 0;
}
.ig-typography .igx-calendar__label {
  font-family: var(--ig-body-1-font-family, var(--ig-font-family));
  font-size: var(--ig-body-1-font-size);
  font-weight: var(--ig-body-1-font-weight);
  font-style: var(--ig-body-1-font-style);
  line-height: var(--ig-body-1-line-height);
  letter-spacing: var(--ig-body-1-letter-spacing);
  text-transform: var(--ig-body-1-text-transform);
  margin-top: var(--ig-body-1-margin-top);
  margin-bottom: var(--ig-body-1-margin-bottom);
  margin: 0;
}
.ig-typography .igx-calendar__year--current {
  font-family: var(--ig-body-1-font-family, var(--ig-font-family));
  font-size: var(--ig-body-1-font-size);
  font-weight: var(--ig-body-1-font-weight);
  font-style: var(--ig-body-1-font-style);
  line-height: var(--ig-body-1-line-height);
  letter-spacing: var(--ig-body-1-letter-spacing);
  text-transform: var(--ig-body-1-text-transform);
  margin-top: var(--ig-body-1-margin-top);
  margin-bottom: var(--ig-body-1-margin-bottom);
  font-size: 1.5rem;
  line-height: 3.25rem;
  margin: 0;
}
.ig-typography .igx-card-header__title, .ig-typography .igx-card-header__title--small {
  font-family: var(--ig-h6-font-family, var(--ig-font-family));
  font-size: var(--ig-h6-font-size);
  font-weight: var(--ig-h6-font-weight);
  font-style: var(--ig-h6-font-style);
  line-height: var(--ig-h6-line-height);
  letter-spacing: var(--ig-h6-letter-spacing);
  text-transform: var(--ig-h6-text-transform);
  margin-top: var(--ig-h6-margin-top);
  margin-bottom: var(--ig-h6-margin-bottom);
  margin: 0;
}
.ig-typography .igx-card-header__title--small {
  font-family: var(--ig-subtitle-2-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-2-font-size);
  font-weight: var(--ig-subtitle-2-font-weight);
  font-style: var(--ig-subtitle-2-font-style);
  line-height: var(--ig-subtitle-2-line-height);
  letter-spacing: var(--ig-subtitle-2-letter-spacing);
  text-transform: var(--ig-subtitle-2-text-transform);
  margin-top: var(--ig-subtitle-2-margin-top);
  margin-bottom: var(--ig-subtitle-2-margin-bottom);
  margin: 0;
}
.ig-typography .igx-card-header__subtitle {
  font-family: var(--ig-subtitle-2-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-2-font-size);
  font-weight: var(--ig-subtitle-2-font-weight);
  font-style: var(--ig-subtitle-2-font-style);
  line-height: var(--ig-subtitle-2-line-height);
  letter-spacing: var(--ig-subtitle-2-letter-spacing);
  text-transform: var(--ig-subtitle-2-text-transform);
  margin-top: var(--ig-subtitle-2-margin-top);
  margin-bottom: var(--ig-subtitle-2-margin-bottom);
  margin: 0;
}
.ig-typography .igx-card-content > * {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
  margin: 0;
}
.ig-typography .igx-checkbox__label, .ig-typography .igx-checkbox__label--before {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
  margin-top: 0;
  margin-bottom: 0;
}
.ig-typography .igx-chip {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
  font-weight: 600;
}
.ig-typography .igx-chip--compact .igx-chip__content,
.ig-typography .igx-chip__ghost--compact .igx-chip__content {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
  font-size: 0.75rem;
  font-weight: 600;
}
.ig-typography .igx-column-actions__header-title {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
  margin: 0;
}
.ig-typography .igx-dialog__window-title {
  font-family: var(--ig-h6-font-family, var(--ig-font-family));
  font-size: var(--ig-h6-font-size);
  font-weight: var(--ig-h6-font-weight);
  font-style: var(--ig-h6-font-style);
  line-height: var(--ig-h6-line-height);
  letter-spacing: var(--ig-h6-letter-spacing);
  text-transform: var(--ig-h6-text-transform);
  margin-top: var(--ig-h6-margin-top);
  margin-bottom: var(--ig-h6-margin-bottom);
  margin: 0;
}
.ig-typography .igx-dialog__window-content {
  font-family: var(--ig-body-1-font-family, var(--ig-font-family));
  font-size: var(--ig-body-1-font-size);
  font-weight: var(--ig-body-1-font-weight);
  font-style: var(--ig-body-1-font-style);
  line-height: var(--ig-body-1-line-height);
  letter-spacing: var(--ig-body-1-letter-spacing);
  text-transform: var(--ig-body-1-text-transform);
  margin-top: var(--ig-body-1-margin-top);
  margin-bottom: var(--ig-body-1-margin-bottom);
  margin: 0;
}
.ig-typography .igx-dialog__window-content > * {
  letter-spacing: normal;
}
.ig-typography igx-dock-manager {
  --igc-font-family: var(--ig-font-family, inherit);
}
.ig-typography .igx-drop-down__group label, .igx-drop-down__group .ig-typography label, .ig-typography .igx-drop-down__header {
  font-family: var(--ig-overline-font-family, var(--ig-font-family));
  font-size: var(--ig-overline-font-size);
  font-weight: var(--ig-overline-font-weight);
  font-style: var(--ig-overline-font-style);
  line-height: var(--ig-overline-line-height);
  letter-spacing: var(--ig-overline-letter-spacing);
  text-transform: var(--ig-overline-text-transform);
  margin-top: var(--ig-overline-margin-top);
  margin-bottom: var(--ig-overline-margin-bottom);
  margin: 0;
}
.ig-typography .igx-drop-down__item {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
  margin: 0;
}
.ig-typography .igx-expansion-panel__header-title {
  font-family: var(--ig-h5-font-family, var(--ig-font-family));
  font-size: var(--ig-h5-font-size);
  font-weight: var(--ig-h5-font-weight);
  font-style: var(--ig-h5-font-style);
  line-height: var(--ig-h5-line-height);
  letter-spacing: var(--ig-h5-letter-spacing);
  text-transform: var(--ig-h5-text-transform);
  margin-top: var(--ig-h5-margin-top);
  margin-bottom: var(--ig-h5-margin-bottom);
  margin: 0;
}
.ig-typography .igx-expansion-panel__header-description {
  font-family: var(--ig-subtitle-2-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-2-font-size);
  font-weight: var(--ig-subtitle-2-font-weight);
  font-style: var(--ig-subtitle-2-font-style);
  line-height: var(--ig-subtitle-2-line-height);
  letter-spacing: var(--ig-subtitle-2-letter-spacing);
  text-transform: var(--ig-subtitle-2-text-transform);
  margin-top: var(--ig-subtitle-2-margin-top);
  margin-bottom: var(--ig-subtitle-2-margin-bottom);
  margin: 0;
}
.ig-typography .igx-expansion-panel__body {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
  margin: 0;
}
.ig-typography .igx-excel-filter__menu .igx-excel-filter__menu-header > h4, .ig-typography .igx-excel-filter__menu .igx-excel-filter__secondary-header > h4 {
  font-family: var(--ig-h6-font-family, var(--ig-font-family));
  font-size: var(--ig-h6-font-size);
  font-weight: var(--ig-h6-font-weight);
  font-style: var(--ig-h6-font-style);
  line-height: var(--ig-h6-line-height);
  letter-spacing: var(--ig-h6-letter-spacing);
  text-transform: var(--ig-h6-text-transform);
  margin-top: var(--ig-h6-margin-top);
  margin-bottom: var(--ig-h6-margin-bottom);
}
.ig-typography .igx-excel-filter__secondary .igx-excel-filter__menu-header > h4, .ig-typography .igx-excel-filter__secondary .igx-excel-filter__secondary-header > h4 {
  font-family: var(--ig-h6-font-family, var(--ig-font-family));
  font-size: var(--ig-h6-font-size);
  font-weight: var(--ig-h6-font-weight);
  font-style: var(--ig-h6-font-style);
  line-height: var(--ig-h6-line-height);
  letter-spacing: var(--ig-h6-letter-spacing);
  text-transform: var(--ig-h6-text-transform);
  margin-top: var(--ig-h6-margin-top);
  margin-bottom: var(--ig-h6-margin-bottom);
}
.ig-typography .igx-excel-filter__sort header,
.ig-typography .igx-excel-filter__move header {
  font-family: var(--ig-overline-font-family, var(--ig-font-family));
  font-size: var(--ig-overline-font-size);
  font-weight: var(--ig-overline-font-weight);
  font-style: var(--ig-overline-font-style);
  line-height: var(--ig-overline-line-height);
  letter-spacing: var(--ig-overline-letter-spacing);
  text-transform: var(--ig-overline-text-transform);
  margin-top: var(--ig-overline-margin-top);
  margin-bottom: var(--ig-overline-margin-bottom);
}
.ig-typography .igx-excel-filter__menu--cosy .igx-excel-filter__menu-header > h4, .ig-typography .igx-excel-filter__menu--cosy .igx-excel-filter__secondary-header > h4 {
  font-family: var(--ig-h6-font-family, var(--ig-font-family));
  font-size: var(--ig-h6-font-size);
  font-weight: var(--ig-h6-font-weight);
  font-style: var(--ig-h6-font-style);
  line-height: var(--ig-h6-line-height);
  letter-spacing: var(--ig-h6-letter-spacing);
  text-transform: var(--ig-h6-text-transform);
  margin-top: var(--ig-h6-margin-top);
  margin-bottom: var(--ig-h6-margin-bottom);
}
.ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__menu-header > h4, .ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__secondary-header > h4 {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
}
.ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__sort header,
.ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__move header {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
  text-transform: capitalize;
}
.ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__actions-pin span, .ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__actions-pin--disabled span, .ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__actions-unpin span, .ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__actions-hide span, .ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__actions-select span, .ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__actions-selected span, .ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__actions-filter span, .ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__actions-clear span, .ig-typography .igx-excel-filter__menu--compact .igx-excel-filter__actions-clear--disabled span {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
}
.ig-typography .igx-excel-filter__menu--compact .igx-checkbox__label, .ig-typography .igx-excel-filter__menu--compact .igx-checkbox__label--before {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
}
.ig-typography .igx-excel-filter__secondary--cosy .igx-excel-filter__menu-header > h4, .ig-typography .igx-excel-filter__secondary--cosy .igx-excel-filter__secondary-header > h4 {
  font-family: var(--ig-h6-font-family, var(--ig-font-family));
  font-size: var(--ig-h6-font-size);
  font-weight: var(--ig-h6-font-weight);
  font-style: var(--ig-h6-font-style);
  line-height: var(--ig-h6-line-height);
  letter-spacing: var(--ig-h6-letter-spacing);
  text-transform: var(--ig-h6-text-transform);
  margin-top: var(--ig-h6-margin-top);
  margin-bottom: var(--ig-h6-margin-bottom);
}
.ig-typography .igx-excel-filter__secondary--compact .igx-excel-filter__menu-header > h4, .ig-typography .igx-excel-filter__secondary--compact .igx-excel-filter__secondary-header > h4 {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
}
.ig-typography .igx-input-group__input, .ig-typography .igx-input-group__textarea {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
  margin: 0;
}
.ig-typography .igx-input-group__hint {
  font-family: var(--ig-caption-font-family, var(--ig-font-family));
  font-size: var(--ig-caption-font-size);
  font-weight: var(--ig-caption-font-weight);
  font-style: var(--ig-caption-font-style);
  line-height: var(--ig-caption-line-height);
  letter-spacing: var(--ig-caption-letter-spacing);
  text-transform: var(--ig-caption-text-transform);
  margin-top: var(--ig-caption-margin-top);
  margin-bottom: var(--ig-caption-margin-bottom);
  margin: 0;
}
.ig-typography .igx-input-group igx-prefix, .igx-input-group .ig-typography igx-prefix, .ig-typography .igx-input-group--focused igx-prefix, .igx-input-group--focused .ig-typography igx-prefix,
.ig-typography .igx-input-group [igxPrefix],
.igx-input-group .ig-typography [igxPrefix],
.ig-typography .igx-input-group--focused [igxPrefix],
.igx-input-group--focused .ig-typography [igxPrefix],
.ig-typography .igx-input-group igx-suffix,
.igx-input-group .ig-typography igx-suffix,
.ig-typography .igx-input-group--focused igx-suffix,
.igx-input-group--focused .ig-typography igx-suffix,
.ig-typography .igx-input-group [igxSuffix],
.igx-input-group .ig-typography [igxSuffix],
.ig-typography .igx-input-group--focused [igxSuffix],
.igx-input-group--focused .ig-typography [igxSuffix] {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
  margin: 0;
}
.ig-typography .igx-list__header {
  font-family: var(--ig-overline-font-family, var(--ig-font-family));
  font-size: var(--ig-overline-font-size);
  font-weight: var(--ig-overline-font-weight);
  font-style: var(--ig-overline-font-style);
  line-height: var(--ig-overline-line-height);
  letter-spacing: var(--ig-overline-letter-spacing);
  text-transform: var(--ig-overline-text-transform);
  margin-top: var(--ig-overline-margin-top);
  margin-bottom: var(--ig-overline-margin-bottom);
  margin: 0;
}
.ig-typography .igx-list__item-lines,
.ig-typography .igx-list__item-line-title {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
  margin: 0;
}
.ig-typography .igx-list__item-line-subtitle {
  font-family: var(--ig-caption-font-family, var(--ig-font-family));
  font-size: var(--ig-caption-font-size);
  font-weight: var(--ig-caption-font-weight);
  font-style: var(--ig-caption-font-style);
  line-height: var(--ig-caption-line-height);
  letter-spacing: var(--ig-caption-letter-spacing);
  text-transform: var(--ig-caption-text-transform);
  margin-top: var(--ig-caption-margin-top);
  margin-bottom: var(--ig-caption-margin-bottom);
  margin: 0;
}
.ig-typography igx-navbar-title,
.ig-typography [igxNavbarTitle], .ig-typography .igx-navbar__title {
  font-family: var(--ig-h6-font-family, var(--ig-font-family));
  font-size: var(--ig-h6-font-size);
  font-weight: var(--ig-h6-font-weight);
  font-style: var(--ig-h6-font-style);
  line-height: var(--ig-h6-line-height);
  letter-spacing: var(--ig-h6-letter-spacing);
  text-transform: var(--ig-h6-text-transform);
  margin-top: var(--ig-h6-margin-top);
  margin-bottom: var(--ig-h6-margin-bottom);
  margin-bottom: 0;
}
.ig-typography .igx-nav-drawer__item, .ig-typography .igx-nav-drawer__item--active {
  font-family: var(--ig-subtitle-2-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-2-font-size);
  font-weight: var(--ig-subtitle-2-font-weight);
  font-style: var(--ig-subtitle-2-font-style);
  line-height: var(--ig-subtitle-2-line-height);
  letter-spacing: var(--ig-subtitle-2-letter-spacing);
  text-transform: var(--ig-subtitle-2-text-transform);
  margin-top: var(--ig-subtitle-2-margin-top);
  margin-bottom: var(--ig-subtitle-2-margin-bottom);
}
.ig-typography .igx-nav-drawer__item--header {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
  margin: 0;
}
.ig-typography .igx-radio__label, .ig-typography .igx-radio__label--before {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
  margin-top: 0;
  margin-bottom: 0;
}
.ig-typography .igx-slider-thumb-label-from__container, .ig-typography .igx-slider-thumb-label-to__container {
  font-family: var(--ig-caption-font-family, var(--ig-font-family));
  font-size: var(--ig-caption-font-size);
  font-weight: var(--ig-caption-font-weight);
  font-style: var(--ig-caption-font-style);
  line-height: var(--ig-caption-line-height);
  letter-spacing: var(--ig-caption-letter-spacing);
  text-transform: var(--ig-caption-text-transform);
  margin-top: var(--ig-caption-margin-top);
  margin-bottom: var(--ig-caption-margin-bottom);
}
.ig-typography .igx-slider__ticks-label {
  font-family: var(--ig-caption-font-family, var(--ig-font-family));
  font-size: var(--ig-caption-font-size);
  font-weight: var(--ig-caption-font-weight);
  font-style: var(--ig-caption-font-style);
  line-height: var(--ig-caption-line-height);
  letter-spacing: var(--ig-caption-letter-spacing);
  text-transform: var(--ig-caption-text-transform);
  margin-top: var(--ig-caption-margin-top);
  margin-bottom: var(--ig-caption-margin-bottom);
}
.ig-typography .igx-snackbar__message {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
}
.ig-typography .igx-switch__label, .ig-typography .igx-switch__label--before {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
  margin-top: 0;
  margin-bottom: 0;
}
.ig-typography .igx-tabs__header-item-inner > [igxtabheaderlabel] {
  font-family: var(--ig-button-font-family, var(--ig-font-family));
  font-size: var(--ig-button-font-size);
  font-weight: var(--ig-button-font-weight);
  font-style: var(--ig-button-font-style);
  line-height: var(--ig-button-line-height);
  letter-spacing: var(--ig-button-letter-spacing);
  text-transform: var(--ig-button-text-transform);
  margin-top: var(--ig-button-margin-top);
  margin-bottom: var(--ig-button-margin-bottom);
  margin-top: 0;
  margin-bottom: 0;
}
.ig-typography .igx-time-picker__header-ampm {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
}
.ig-typography .igx-time-picker__header-hour {
  font-family: var(--ig-h4-font-family, var(--ig-font-family));
  font-size: var(--ig-h4-font-size);
  font-weight: var(--ig-h4-font-weight);
  font-style: var(--ig-h4-font-style);
  line-height: var(--ig-h4-line-height);
  letter-spacing: var(--ig-h4-letter-spacing);
  text-transform: var(--ig-h4-text-transform);
  margin-top: var(--ig-h4-margin-top);
  margin-bottom: var(--ig-h4-margin-bottom);
  margin-top: 0;
}
.ig-typography .igx-time-picker__column {
  font-family: var(--ig-body-1-font-family, var(--ig-font-family));
  font-size: var(--ig-body-1-font-size);
  font-weight: var(--ig-body-1-font-weight);
  font-style: var(--ig-body-1-font-style);
  line-height: var(--ig-body-1-line-height);
  letter-spacing: var(--ig-body-1-letter-spacing);
  text-transform: var(--ig-body-1-text-transform);
  margin-top: var(--ig-body-1-margin-top);
  margin-bottom: var(--ig-body-1-margin-bottom);
  margin-top: 0;
  margin-bottom: 0;
}
.ig-typography .igx-stepper__step-title {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
  margin-top: 0;
  margin-bottom: 0;
}
.ig-typography .igx-stepper__step-subtitle {
  font-family: var(--ig-caption-font-family, var(--ig-font-family));
  font-size: var(--ig-caption-font-size);
  font-weight: var(--ig-caption-font-weight);
  font-style: var(--ig-caption-font-style);
  line-height: var(--ig-caption-line-height);
  letter-spacing: var(--ig-caption-letter-spacing);
  text-transform: var(--ig-caption-text-transform);
  margin-top: var(--ig-caption-margin-top);
  margin-bottom: var(--ig-caption-margin-bottom);
  margin-top: 0;
  margin-bottom: 0;
}
.ig-typography .igx-stepper__step-header--current .igx-stepper__step-title {
  font-weight: 600;
}
.ig-typography .igx-toast,
.ig-typography .igx-toast > * {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
  margin: 0;
}
.ig-typography .igx-tooltip--mobile {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
}
.ig-typography .igx-tooltip--desktop {
  font-size: 0.625rem;
  font-weight: 600;
}
.ig-typography .igx-tree-node__content {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
}

:root {
  --ig-font-family: 'Titillium Web', sans-serif;
  --ig-h1-font-size: 6rem;
  --ig-h1-font-weight: 300;
  --ig-h1-font-style: normal;
  --ig-h1-line-height: 7rem;
  --ig-h1-letter-spacing: -0.09375rem;
  --ig-h1-text-transform: none;
  --ig-h1-margin-top: 1.75rem;
  --ig-h1-margin-bottom: 3.5rem;
  --ig-h2-font-size: 3.75rem;
  --ig-h2-font-weight: 300;
  --ig-h2-font-style: normal;
  --ig-h2-line-height: 4.4375rem;
  --ig-h2-letter-spacing: -0.03125rem;
  --ig-h2-text-transform: none;
  --ig-h2-margin-top: 1.75rem;
  --ig-h2-margin-bottom: 1.75rem;
  --ig-h3-font-size: 3rem;
  --ig-h3-font-weight: 400;
  --ig-h3-font-style: normal;
  --ig-h3-line-height: 3.5625rem;
  --ig-h3-letter-spacing: 0;
  --ig-h3-text-transform: none;
  --ig-h3-margin-top: 1.75rem;
  --ig-h3-margin-bottom: 0;
  --ig-h4-font-size: 2.125rem;
  --ig-h4-font-weight: 400;
  --ig-h4-font-style: normal;
  --ig-h4-line-height: 2.5rem;
  --ig-h4-letter-spacing: 0.015625rem;
  --ig-h4-text-transform: none;
  --ig-h4-margin-top: 1.75rem;
  --ig-h4-margin-bottom: 0;
  --ig-h5-font-size: 1.5rem;
  --ig-h5-font-weight: 400;
  --ig-h5-font-style: normal;
  --ig-h5-line-height: 1.75rem;
  --ig-h5-letter-spacing: 0;
  --ig-h5-text-transform: none;
  --ig-h5-margin-top: 1.75rem;
  --ig-h5-margin-bottom: 0;
  --ig-h6-font-size: 1.25rem;
  --ig-h6-font-weight: 600;
  --ig-h6-font-style: normal;
  --ig-h6-line-height: 1.5rem;
  --ig-h6-letter-spacing: 0.009375rem;
  --ig-h6-text-transform: none;
  --ig-h6-margin-top: 0;
  --ig-h6-margin-bottom: 0;
  --ig-subtitle-1-font-size: 1rem;
  --ig-subtitle-1-font-weight: 400;
  --ig-subtitle-1-font-style: normal;
  --ig-subtitle-1-line-height: 1.5rem;
  --ig-subtitle-1-letter-spacing: 0.009375rem;
  --ig-subtitle-1-text-transform: none;
  --ig-subtitle-1-margin-top: 0;
  --ig-subtitle-1-margin-bottom: 0;
  --ig-subtitle-2-font-size: 0.875rem;
  --ig-subtitle-2-font-weight: 600;
  --ig-subtitle-2-font-style: normal;
  --ig-subtitle-2-line-height: 1.5rem;
  --ig-subtitle-2-letter-spacing: 0.00625rem;
  --ig-subtitle-2-text-transform: none;
  --ig-subtitle-2-margin-top: 0;
  --ig-subtitle-2-margin-bottom: 0;
  --ig-body-1-font-size: 1rem;
  --ig-body-1-font-weight: 400;
  --ig-body-1-font-style: normal;
  --ig-body-1-line-height: 1.75rem;
  --ig-body-1-letter-spacing: 0.03125rem;
  --ig-body-1-text-transform: none;
  --ig-body-1-margin-top: 1.75rem;
  --ig-body-1-margin-bottom: 1rem;
  --ig-body-2-font-size: 0.875rem;
  --ig-body-2-font-weight: 400;
  --ig-body-2-font-style: normal;
  --ig-body-2-line-height: 1.25rem;
  --ig-body-2-letter-spacing: 0.015625rem;
  --ig-body-2-text-transform: none;
  --ig-body-2-margin-top: 0;
  --ig-body-2-margin-bottom: 0;
  --ig-button-font-size: 0.875rem;
  --ig-button-font-weight: 600;
  --ig-button-font-style: normal;
  --ig-button-line-height: 1rem;
  --ig-button-letter-spacing: 0.046875rem;
  --ig-button-text-transform: uppercase;
  --ig-button-margin-top: 0;
  --ig-button-margin-bottom: 0;
  --ig-caption-font-size: 0.75rem;
  --ig-caption-font-weight: 400;
  --ig-caption-font-style: normal;
  --ig-caption-line-height: 1rem;
  --ig-caption-letter-spacing: 0.025rem;
  --ig-caption-text-transform: none;
  --ig-caption-margin-top: 0;
  --ig-caption-margin-bottom: 0;
  --ig-overline-font-size: 0.625rem;
  --ig-overline-font-weight: 400;
  --ig-overline-font-style: normal;
  --ig-overline-line-height: 1rem;
  --ig-overline-letter-spacing: 0.09375rem;
  --ig-overline-text-transform: uppercase;
  --ig-overline-margin-top: 0;
  --ig-overline-margin-bottom: 0;
}

.ig-typography h1 {
  font-family: var(--ig-h1-font-family, var(--ig-font-family));
  font-size: var(--ig-h1-font-size);
  font-weight: var(--ig-h1-font-weight);
  font-style: var(--ig-h1-font-style);
  line-height: var(--ig-h1-line-height);
  letter-spacing: var(--ig-h1-letter-spacing);
  text-transform: var(--ig-h1-text-transform);
  margin-top: var(--ig-h1-margin-top);
  margin-bottom: var(--ig-h1-margin-bottom);
}
.ig-typography h2 {
  font-family: var(--ig-h2-font-family, var(--ig-font-family));
  font-size: var(--ig-h2-font-size);
  font-weight: var(--ig-h2-font-weight);
  font-style: var(--ig-h2-font-style);
  line-height: var(--ig-h2-line-height);
  letter-spacing: var(--ig-h2-letter-spacing);
  text-transform: var(--ig-h2-text-transform);
  margin-top: var(--ig-h2-margin-top);
  margin-bottom: var(--ig-h2-margin-bottom);
}
.ig-typography h3 {
  font-family: var(--ig-h3-font-family, var(--ig-font-family));
  font-size: var(--ig-h3-font-size);
  font-weight: var(--ig-h3-font-weight);
  font-style: var(--ig-h3-font-style);
  line-height: var(--ig-h3-line-height);
  letter-spacing: var(--ig-h3-letter-spacing);
  text-transform: var(--ig-h3-text-transform);
  margin-top: var(--ig-h3-margin-top);
  margin-bottom: var(--ig-h3-margin-bottom);
}
.ig-typography h4 {
  font-family: var(--ig-h4-font-family, var(--ig-font-family));
  font-size: var(--ig-h4-font-size);
  font-weight: var(--ig-h4-font-weight);
  font-style: var(--ig-h4-font-style);
  line-height: var(--ig-h4-line-height);
  letter-spacing: var(--ig-h4-letter-spacing);
  text-transform: var(--ig-h4-text-transform);
  margin-top: var(--ig-h4-margin-top);
  margin-bottom: var(--ig-h4-margin-bottom);
}
.ig-typography h5 {
  font-family: var(--ig-h5-font-family, var(--ig-font-family));
  font-size: var(--ig-h5-font-size);
  font-weight: var(--ig-h5-font-weight);
  font-style: var(--ig-h5-font-style);
  line-height: var(--ig-h5-line-height);
  letter-spacing: var(--ig-h5-letter-spacing);
  text-transform: var(--ig-h5-text-transform);
  margin-top: var(--ig-h5-margin-top);
  margin-bottom: var(--ig-h5-margin-bottom);
}
.ig-typography h6 {
  font-family: var(--ig-h6-font-family, var(--ig-font-family));
  font-size: var(--ig-h6-font-size);
  font-weight: var(--ig-h6-font-weight);
  font-style: var(--ig-h6-font-style);
  line-height: var(--ig-h6-line-height);
  letter-spacing: var(--ig-h6-letter-spacing);
  text-transform: var(--ig-h6-text-transform);
  margin-top: var(--ig-h6-margin-top);
  margin-bottom: var(--ig-h6-margin-bottom);
}
.ig-typography p {
  font-family: var(--ig-body-1-font-family, var(--ig-font-family));
  font-size: var(--ig-body-1-font-size);
  font-weight: var(--ig-body-1-font-weight);
  font-style: var(--ig-body-1-font-style);
  line-height: var(--ig-body-1-line-height);
  letter-spacing: var(--ig-body-1-letter-spacing);
  text-transform: var(--ig-body-1-text-transform);
  margin-top: var(--ig-body-1-margin-top);
  margin-bottom: var(--ig-body-1-margin-bottom);
}
.ig-typography .ig-typography__h1 {
  font-family: var(--ig-h1-font-family, var(--ig-font-family));
  font-size: var(--ig-h1-font-size);
  font-weight: var(--ig-h1-font-weight);
  font-style: var(--ig-h1-font-style);
  line-height: var(--ig-h1-line-height);
  letter-spacing: var(--ig-h1-letter-spacing);
  text-transform: var(--ig-h1-text-transform);
  margin-top: var(--ig-h1-margin-top);
  margin-bottom: var(--ig-h1-margin-bottom);
}
.ig-typography .ig-typography__h2 {
  font-family: var(--ig-h2-font-family, var(--ig-font-family));
  font-size: var(--ig-h2-font-size);
  font-weight: var(--ig-h2-font-weight);
  font-style: var(--ig-h2-font-style);
  line-height: var(--ig-h2-line-height);
  letter-spacing: var(--ig-h2-letter-spacing);
  text-transform: var(--ig-h2-text-transform);
  margin-top: var(--ig-h2-margin-top);
  margin-bottom: var(--ig-h2-margin-bottom);
}
.ig-typography .ig-typography__h3 {
  font-family: var(--ig-h3-font-family, var(--ig-font-family));
  font-size: var(--ig-h3-font-size);
  font-weight: var(--ig-h3-font-weight);
  font-style: var(--ig-h3-font-style);
  line-height: var(--ig-h3-line-height);
  letter-spacing: var(--ig-h3-letter-spacing);
  text-transform: var(--ig-h3-text-transform);
  margin-top: var(--ig-h3-margin-top);
  margin-bottom: var(--ig-h3-margin-bottom);
}
.ig-typography .ig-typography__h4 {
  font-family: var(--ig-h4-font-family, var(--ig-font-family));
  font-size: var(--ig-h4-font-size);
  font-weight: var(--ig-h4-font-weight);
  font-style: var(--ig-h4-font-style);
  line-height: var(--ig-h4-line-height);
  letter-spacing: var(--ig-h4-letter-spacing);
  text-transform: var(--ig-h4-text-transform);
  margin-top: var(--ig-h4-margin-top);
  margin-bottom: var(--ig-h4-margin-bottom);
}
.ig-typography .ig-typography__h5 {
  font-family: var(--ig-h5-font-family, var(--ig-font-family));
  font-size: var(--ig-h5-font-size);
  font-weight: var(--ig-h5-font-weight);
  font-style: var(--ig-h5-font-style);
  line-height: var(--ig-h5-line-height);
  letter-spacing: var(--ig-h5-letter-spacing);
  text-transform: var(--ig-h5-text-transform);
  margin-top: var(--ig-h5-margin-top);
  margin-bottom: var(--ig-h5-margin-bottom);
}
.ig-typography .ig-typography__h6 {
  font-family: var(--ig-h6-font-family, var(--ig-font-family));
  font-size: var(--ig-h6-font-size);
  font-weight: var(--ig-h6-font-weight);
  font-style: var(--ig-h6-font-style);
  line-height: var(--ig-h6-line-height);
  letter-spacing: var(--ig-h6-letter-spacing);
  text-transform: var(--ig-h6-text-transform);
  margin-top: var(--ig-h6-margin-top);
  margin-bottom: var(--ig-h6-margin-bottom);
}
.ig-typography .ig-typography__subtitle-1 {
  font-family: var(--ig-subtitle-1-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-1-font-size);
  font-weight: var(--ig-subtitle-1-font-weight);
  font-style: var(--ig-subtitle-1-font-style);
  line-height: var(--ig-subtitle-1-line-height);
  letter-spacing: var(--ig-subtitle-1-letter-spacing);
  text-transform: var(--ig-subtitle-1-text-transform);
  margin-top: var(--ig-subtitle-1-margin-top);
  margin-bottom: var(--ig-subtitle-1-margin-bottom);
}
.ig-typography .ig-typography__subtitle-2 {
  font-family: var(--ig-subtitle-2-font-family, var(--ig-font-family));
  font-size: var(--ig-subtitle-2-font-size);
  font-weight: var(--ig-subtitle-2-font-weight);
  font-style: var(--ig-subtitle-2-font-style);
  line-height: var(--ig-subtitle-2-line-height);
  letter-spacing: var(--ig-subtitle-2-letter-spacing);
  text-transform: var(--ig-subtitle-2-text-transform);
  margin-top: var(--ig-subtitle-2-margin-top);
  margin-bottom: var(--ig-subtitle-2-margin-bottom);
}
.ig-typography .ig-typography__body-1 {
  font-family: var(--ig-body-1-font-family, var(--ig-font-family));
  font-size: var(--ig-body-1-font-size);
  font-weight: var(--ig-body-1-font-weight);
  font-style: var(--ig-body-1-font-style);
  line-height: var(--ig-body-1-line-height);
  letter-spacing: var(--ig-body-1-letter-spacing);
  text-transform: var(--ig-body-1-text-transform);
  margin-top: var(--ig-body-1-margin-top);
  margin-bottom: var(--ig-body-1-margin-bottom);
}
.ig-typography .ig-typography__body-2 {
  font-family: var(--ig-body-2-font-family, var(--ig-font-family));
  font-size: var(--ig-body-2-font-size);
  font-weight: var(--ig-body-2-font-weight);
  font-style: var(--ig-body-2-font-style);
  line-height: var(--ig-body-2-line-height);
  letter-spacing: var(--ig-body-2-letter-spacing);
  text-transform: var(--ig-body-2-text-transform);
  margin-top: var(--ig-body-2-margin-top);
  margin-bottom: var(--ig-body-2-margin-bottom);
}
.ig-typography .ig-typography__button {
  font-family: var(--ig-button-font-family, var(--ig-font-family));
  font-size: var(--ig-button-font-size);
  font-weight: var(--ig-button-font-weight);
  font-style: var(--ig-button-font-style);
  line-height: var(--ig-button-line-height);
  letter-spacing: var(--ig-button-letter-spacing);
  text-transform: var(--ig-button-text-transform);
  margin-top: var(--ig-button-margin-top);
  margin-bottom: var(--ig-button-margin-bottom);
}
.ig-typography .ig-typography__caption {
  font-family: var(--ig-caption-font-family, var(--ig-font-family));
  font-size: var(--ig-caption-font-size);
  font-weight: var(--ig-caption-font-weight);
  font-style: var(--ig-caption-font-style);
  line-height: var(--ig-caption-line-height);
  letter-spacing: var(--ig-caption-letter-spacing);
  text-transform: var(--ig-caption-text-transform);
  margin-top: var(--ig-caption-margin-top);
  margin-bottom: var(--ig-caption-margin-bottom);
}
.ig-typography .ig-typography__overline {
  font-family: var(--ig-overline-font-family, var(--ig-font-family));
  font-size: var(--ig-overline-font-size);
  font-weight: var(--ig-overline-font-weight);
  font-style: var(--ig-overline-font-style);
  line-height: var(--ig-overline-line-height);
  letter-spacing: var(--ig-overline-letter-spacing);
  text-transform: var(--ig-overline-text-transform);
  margin-top: var(--ig-overline-margin-top);
  margin-bottom: var(--ig-overline-margin-bottom);
}

:root {
  --ig-size-small: 1;
  --ig-size-medium: 2;
  --ig-size-large: 3;
  --ig-spacing-small: 1;
  --ig-spacing-medium: 1;
  --ig-spacing-large: 1;
  --ig-spacing-inline-small: 1;
  --ig-spacing-inline-medium: 1;
  --ig-spacing-inline-large: 1;
  --ig-spacing-block-small: 1;
  --ig-spacing-block-medium: 1;
  --ig-spacing-block-large: 1;
}

:root {
  --ig-primary-50: hsl(26.89, 100%, 97.03%);
  --ig-primary-50-contrast: black;
  --ig-primary-50: var(--ig-primary-h), calc(var(--ig-primary-s) * 1.23), calc(var(--ig-primary-l) * 1.78);
  --ig-primary-100: hsl(26.89, 73.1%, 90.49%);
  --ig-primary-100-contrast: black;
  --ig-primary-100: var(--ig-primary-h), calc(var(--ig-primary-s) * 0.8), calc(var(--ig-primary-l) * 1.66);
  --ig-primary-200: hsl(26.89, 58.48%, 77.95%);
  --ig-primary-200-contrast: black;
  --ig-primary-200: var(--ig-primary-h), calc(var(--ig-primary-s) * 0.64), calc(var(--ig-primary-l) * 1.43);
  --ig-primary-300: hsl(26.89, 66.71%, 64.87%);
  --ig-primary-300-contrast: black;
  --ig-primary-300: var(--ig-primary-h), calc(var(--ig-primary-s) * 0.73), calc(var(--ig-primary-l) * 1.19);
  --ig-primary-400: hsl(26.89, 79.96%, 58.87%);
  --ig-primary-400-contrast: black;
  --ig-primary-400: var(--ig-primary-h), calc(var(--ig-primary-s) * 0.875), calc(var(--ig-primary-l) * 1.08);
  --ig-primary-h: 27deg;
  --ig-primary-s: 91%;
  --ig-primary-l: 55%;
  --ig-primary-a: 1;
  --ig-primary-500: hsl(26.89, 91.38%, 54.51%);
  --ig-primary-500-contrast: black;
  --ig-primary-500: var(--ig-primary-h), calc(var(--ig-primary-s) * 1), calc(var(--ig-primary-l) * 1);
  --ig-primary-600: hsl(26.89, 100%, 48.51%);
  --ig-primary-600-contrast: black;
  --ig-primary-600: var(--ig-primary-h), calc(var(--ig-primary-s) * 1.26), calc(var(--ig-primary-l) * 0.89);
  --ig-primary-700: hsl(26.89, 100%, 44.15%);
  --ig-primary-700-contrast: black;
  --ig-primary-700: var(--ig-primary-h), calc(var(--ig-primary-s) * 1.52), calc(var(--ig-primary-l) * 0.81);
  --ig-primary-800: hsl(26.89, 100%, 39.79%);
  --ig-primary-800-contrast: black;
  --ig-primary-800: var(--ig-primary-h), calc(var(--ig-primary-s) * 1.5), calc(var(--ig-primary-l) * 0.73);
  --ig-primary-900: hsl(26.89, 100%, 34.89%);
  --ig-primary-900-contrast: white;
  --ig-primary-900: var(--ig-primary-h), calc(var(--ig-primary-s) * 1.34), calc(var(--ig-primary-l) * 0.64);
  --ig-primary-A100: hsl(26.89, 100%, 73.04%);
  --ig-primary-A100-contrast: black;
  --ig-primary-A100: var(--ig-primary-h), calc(var(--ig-primary-s) * 1.23), calc(var(--ig-primary-l) * 1.34);
  --ig-primary-A200: hsl(26.89, 100%, 63.23%);
  --ig-primary-A200-contrast: black;
  --ig-primary-A200: var(--ig-primary-h), calc(var(--ig-primary-s) * 1.22), calc(var(--ig-primary-l) * 1.16);
  --ig-primary-A400: hsl(26.89, 100%, 49.6%);
  --ig-primary-A400-contrast: black;
  --ig-primary-A400: var(--ig-primary-h), calc(var(--ig-primary-s) * 1.23), calc(var(--ig-primary-l) * 0.91);
  --ig-primary-A700: hsl(26.89, 100%, 35.43%);
  --ig-primary-A700-contrast: white;
  --ig-primary-A700: var(--ig-primary-h), calc(var(--ig-primary-s) * 1.23), calc(var(--ig-primary-l) * 0.65);
  --ig-secondary-50: hsl(26.89, 100%, 97.03%);
  --ig-secondary-50-contrast: black;
  --ig-secondary-50: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 1.23), calc(var(--ig-secondary-l) * 1.78);
  --ig-secondary-100: hsl(26.89, 73.1%, 90.49%);
  --ig-secondary-100-contrast: black;
  --ig-secondary-100: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 0.8), calc(var(--ig-secondary-l) * 1.66);
  --ig-secondary-200: hsl(26.89, 58.48%, 77.95%);
  --ig-secondary-200-contrast: black;
  --ig-secondary-200: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 0.64), calc(var(--ig-secondary-l) * 1.43);
  --ig-secondary-300: hsl(26.89, 66.71%, 64.87%);
  --ig-secondary-300-contrast: black;
  --ig-secondary-300: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 0.73), calc(var(--ig-secondary-l) * 1.19);
  --ig-secondary-400: hsl(26.89, 79.96%, 58.87%);
  --ig-secondary-400-contrast: black;
  --ig-secondary-400: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 0.875), calc(var(--ig-secondary-l) * 1.08);
  --ig-secondary-h: 27deg;
  --ig-secondary-s: 91%;
  --ig-secondary-l: 55%;
  --ig-secondary-a: 1;
  --ig-secondary-500: hsl(26.89, 91.38%, 54.51%);
  --ig-secondary-500-contrast: black;
  --ig-secondary-500: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 1), calc(var(--ig-secondary-l) * 1);
  --ig-secondary-600: hsl(26.89, 100%, 48.51%);
  --ig-secondary-600-contrast: black;
  --ig-secondary-600: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 1.26), calc(var(--ig-secondary-l) * 0.89);
  --ig-secondary-700: hsl(26.89, 100%, 44.15%);
  --ig-secondary-700-contrast: black;
  --ig-secondary-700: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 1.52), calc(var(--ig-secondary-l) * 0.81);
  --ig-secondary-800: hsl(26.89, 100%, 39.79%);
  --ig-secondary-800-contrast: black;
  --ig-secondary-800: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 1.5), calc(var(--ig-secondary-l) * 0.73);
  --ig-secondary-900: hsl(26.89, 100%, 34.89%);
  --ig-secondary-900-contrast: white;
  --ig-secondary-900: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 1.34), calc(var(--ig-secondary-l) * 0.64);
  --ig-secondary-A100: hsl(26.89, 100%, 73.04%);
  --ig-secondary-A100-contrast: black;
  --ig-secondary-A100: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 1.23), calc(var(--ig-secondary-l) * 1.34);
  --ig-secondary-A200: hsl(26.89, 100%, 63.23%);
  --ig-secondary-A200-contrast: black;
  --ig-secondary-A200: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 1.22), calc(var(--ig-secondary-l) * 1.16);
  --ig-secondary-A400: hsl(26.89, 100%, 49.6%);
  --ig-secondary-A400-contrast: black;
  --ig-secondary-A400: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 1.23), calc(var(--ig-secondary-l) * 0.91);
  --ig-secondary-A700: hsl(26.89, 100%, 35.43%);
  --ig-secondary-A700-contrast: white;
  --ig-secondary-A700: var(--ig-secondary-h), calc(var(--ig-secondary-s) * 1.23), calc(var(--ig-secondary-l) * 0.65);
  --ig-gray-50: hsl(0, 0%, 98%);
  --ig-gray-50-contrast: black;
  --ig-gray-50: var(--ig-gray-h), var(--ig-gray-s), 98%;
  --ig-gray-100: hsl(0, 0%, 96%);
  --ig-gray-100-contrast: black;
  --ig-gray-100: var(--ig-gray-h), var(--ig-gray-s), 96%;
  --ig-gray-200: hsl(0, 0%, 93%);
  --ig-gray-200-contrast: black;
  --ig-gray-200: var(--ig-gray-h), var(--ig-gray-s), 93%;
  --ig-gray-300: hsl(0, 0%, 88%);
  --ig-gray-300-contrast: black;
  --ig-gray-300: var(--ig-gray-h), var(--ig-gray-s), 88%;
  --ig-gray-400: hsl(0, 0%, 74%);
  --ig-gray-400-contrast: black;
  --ig-gray-400: var(--ig-gray-h), var(--ig-gray-s), 74%;
  --ig-gray-h: 0deg;
  --ig-gray-s: 0%;
  --ig-gray-l: 62%;
  --ig-gray-a: 1;
  --ig-gray-500: hsl(0, 0%, 62%);
  --ig-gray-500-contrast: black;
  --ig-gray-500: var(--ig-gray-h), var(--ig-gray-s), 62%;
  --ig-gray-600: hsl(0, 0%, 54%);
  --ig-gray-600-contrast: black;
  --ig-gray-600: var(--ig-gray-h), var(--ig-gray-s), 54%;
  --ig-gray-700: hsl(0, 0%, 38%);
  --ig-gray-700-contrast: white;
  --ig-gray-700: var(--ig-gray-h), var(--ig-gray-s), 38%;
  --ig-gray-800: hsl(0, 0%, 26%);
  --ig-gray-800-contrast: white;
  --ig-gray-800: var(--ig-gray-h), var(--ig-gray-s), 26%;
  --ig-gray-900: hsl(0, 0%, 13%);
  --ig-gray-900-contrast: white;
  --ig-gray-900: var(--ig-gray-h), var(--ig-gray-s), 13%;
  --ig-surface-50: hsl(0, 0%, 100%);
  --ig-surface-50-contrast: black;
  --ig-surface-50: var(--ig-surface-h), calc(var(--ig-surface-s) * 1.23), calc(var(--ig-surface-l) * 1.78);
  --ig-surface-100: hsl(0, 0%, 100%);
  --ig-surface-100-contrast: black;
  --ig-surface-100: var(--ig-surface-h), calc(var(--ig-surface-s) * 0.8), calc(var(--ig-surface-l) * 1.66);
  --ig-surface-200: hsl(0, 0%, 100%);
  --ig-surface-200-contrast: black;
  --ig-surface-200: var(--ig-surface-h), calc(var(--ig-surface-s) * 0.64), calc(var(--ig-surface-l) * 1.43);
  --ig-surface-300: hsl(0, 0%, 100%);
  --ig-surface-300-contrast: black;
  --ig-surface-300: var(--ig-surface-h), calc(var(--ig-surface-s) * 0.73), calc(var(--ig-surface-l) * 1.19);
  --ig-surface-400: hsl(0, 0%, 100%);
  --ig-surface-400-contrast: black;
  --ig-surface-400: var(--ig-surface-h), calc(var(--ig-surface-s) * 0.875), calc(var(--ig-surface-l) * 1.08);
  --ig-surface-h: 0deg;
  --ig-surface-s: 0%;
  --ig-surface-l: 100%;
  --ig-surface-a: 1;
  --ig-surface-500: hsl(0, 0%, 100%);
  --ig-surface-500-contrast: black;
  --ig-surface-500: var(--ig-surface-h), calc(var(--ig-surface-s) * 1), calc(var(--ig-surface-l) * 1);
  --ig-surface-600: hsl(0, 0%, 89%);
  --ig-surface-600-contrast: black;
  --ig-surface-600: var(--ig-surface-h), calc(var(--ig-surface-s) * 1.26), calc(var(--ig-surface-l) * 0.89);
  --ig-surface-700: hsl(0, 0%, 81%);
  --ig-surface-700-contrast: black;
  --ig-surface-700: var(--ig-surface-h), calc(var(--ig-surface-s) * 1.52), calc(var(--ig-surface-l) * 0.81);
  --ig-surface-800: hsl(0, 0%, 73%);
  --ig-surface-800-contrast: black;
  --ig-surface-800: var(--ig-surface-h), calc(var(--ig-surface-s) * 1.5), calc(var(--ig-surface-l) * 0.73);
  --ig-surface-900: hsl(0, 0%, 64%);
  --ig-surface-900-contrast: black;
  --ig-surface-900: var(--ig-surface-h), calc(var(--ig-surface-s) * 1.34), calc(var(--ig-surface-l) * 0.64);
  --ig-surface-A100: hsl(0, 0%, 100%);
  --ig-surface-A100-contrast: black;
  --ig-surface-A100: var(--ig-surface-h), calc(var(--ig-surface-s) * 1.23), calc(var(--ig-surface-l) * 1.34);
  --ig-surface-A200: hsl(0, 0%, 100%);
  --ig-surface-A200-contrast: black;
  --ig-surface-A200: var(--ig-surface-h), calc(var(--ig-surface-s) * 1.22), calc(var(--ig-surface-l) * 1.16);
  --ig-surface-A400: hsl(0, 0%, 91%);
  --ig-surface-A400-contrast: black;
  --ig-surface-A400: var(--ig-surface-h), calc(var(--ig-surface-s) * 1.23), calc(var(--ig-surface-l) * 0.91);
  --ig-surface-A700: hsl(0, 0%, 65%);
  --ig-surface-A700-contrast: black;
  --ig-surface-A700: var(--ig-surface-h), calc(var(--ig-surface-s) * 1.23), calc(var(--ig-surface-l) * 0.65);
  --ig-info-50: hsl(209.07, 100%, 80.97%);
  --ig-info-50-contrast: black;
  --ig-info-50: var(--ig-info-h), calc(var(--ig-info-s) * 1.23), calc(var(--ig-info-l) * 1.78);
  --ig-info-100: hsl(209.07, 66.9%, 75.51%);
  --ig-info-100-contrast: black;
  --ig-info-100: var(--ig-info-h), calc(var(--ig-info-s) * 0.8), calc(var(--ig-info-l) * 1.66);
  --ig-info-200: hsl(209.07, 53.52%, 65.05%);
  --ig-info-200-contrast: black;
  --ig-info-200: var(--ig-info-h), calc(var(--ig-info-s) * 0.64), calc(var(--ig-info-l) * 1.43);
  --ig-info-300: hsl(209.07, 61.04%, 54.13%);
  --ig-info-300-contrast: black;
  --ig-info-300: var(--ig-info-h), calc(var(--ig-info-s) * 0.73), calc(var(--ig-info-l) * 1.19);
  --ig-info-400: hsl(209.07, 73.17%, 49.13%);
  --ig-info-400-contrast: black;
  --ig-info-400: var(--ig-info-h), calc(var(--ig-info-s) * 0.875), calc(var(--ig-info-l) * 1.08);
  --ig-info-h: 209deg;
  --ig-info-s: 84%;
  --ig-info-l: 45%;
  --ig-info-a: 1;
  --ig-info-500: hsl(209.07, 83.62%, 45.49%);
  --ig-info-500-contrast: black;
  --ig-info-500: var(--ig-info-h), calc(var(--ig-info-s) * 1), calc(var(--ig-info-l) * 1);
  --ig-info-600: hsl(209.07, 100%, 40.49%);
  --ig-info-600-contrast: white;
  --ig-info-600: var(--ig-info-h), calc(var(--ig-info-s) * 1.26), calc(var(--ig-info-l) * 0.89);
  --ig-info-700: hsl(209.07, 100%, 36.85%);
  --ig-info-700-contrast: white;
  --ig-info-700: var(--ig-info-h), calc(var(--ig-info-s) * 1.52), calc(var(--ig-info-l) * 0.81);
  --ig-info-800: hsl(209.07, 100%, 33.21%);
  --ig-info-800-contrast: white;
  --ig-info-800: var(--ig-info-h), calc(var(--ig-info-s) * 1.5), calc(var(--ig-info-l) * 0.73);
  --ig-info-900: hsl(209.07, 100%, 29.11%);
  --ig-info-900-contrast: white;
  --ig-info-900: var(--ig-info-h), calc(var(--ig-info-s) * 1.34), calc(var(--ig-info-l) * 0.64);
  --ig-info-A100: hsl(209.07, 100%, 60.96%);
  --ig-info-A100-contrast: black;
  --ig-info-A100: var(--ig-info-h), calc(var(--ig-info-s) * 1.23), calc(var(--ig-info-l) * 1.34);
  --ig-info-A200: hsl(209.07, 100%, 52.77%);
  --ig-info-A200-contrast: black;
  --ig-info-A200: var(--ig-info-h), calc(var(--ig-info-s) * 1.22), calc(var(--ig-info-l) * 1.16);
  --ig-info-A400: hsl(209.07, 100%, 41.4%);
  --ig-info-A400-contrast: white;
  --ig-info-A400: var(--ig-info-h), calc(var(--ig-info-s) * 1.23), calc(var(--ig-info-l) * 0.91);
  --ig-info-A700: hsl(209.07, 100%, 29.57%);
  --ig-info-A700-contrast: white;
  --ig-info-A700: var(--ig-info-h), calc(var(--ig-info-s) * 1.23), calc(var(--ig-info-l) * 0.65);
  --ig-success-50: hsl(131.32, 52.57%, 91.44%);
  --ig-success-50-contrast: black;
  --ig-success-50: var(--ig-success-h), calc(var(--ig-success-s) * 1.23), calc(var(--ig-success-l) * 1.78);
  --ig-success-100: hsl(131.32, 34.19%, 85.28%);
  --ig-success-100-contrast: black;
  --ig-success-100: var(--ig-success-h), calc(var(--ig-success-s) * 0.8), calc(var(--ig-success-l) * 1.66);
  --ig-success-200: hsl(131.32, 27.35%, 73.46%);
  --ig-success-200-contrast: black;
  --ig-success-200: var(--ig-success-h), calc(var(--ig-success-s) * 0.64), calc(var(--ig-success-l) * 1.43);
  --ig-success-300: hsl(131.32, 31.2%, 61.13%);
  --ig-success-300-contrast: black;
  --ig-success-300: var(--ig-success-h), calc(var(--ig-success-s) * 0.73), calc(var(--ig-success-l) * 1.19);
  --ig-success-400: hsl(131.32, 37.4%, 55.48%);
  --ig-success-400-contrast: black;
  --ig-success-400: var(--ig-success-h), calc(var(--ig-success-s) * 0.875), calc(var(--ig-success-l) * 1.08);
  --ig-success-h: 131deg;
  --ig-success-s: 43%;
  --ig-success-l: 51%;
  --ig-success-a: 1;
  --ig-success-500: hsl(131.32, 42.74%, 51.37%);
  --ig-success-500-contrast: black;
  --ig-success-500: var(--ig-success-h), calc(var(--ig-success-s) * 1), calc(var(--ig-success-l) * 1);
  --ig-success-600: hsl(131.32, 53.85%, 45.72%);
  --ig-success-600-contrast: black;
  --ig-success-600: var(--ig-success-h), calc(var(--ig-success-s) * 1.26), calc(var(--ig-success-l) * 0.89);
  --ig-success-700: hsl(131.32, 64.97%, 41.61%);
  --ig-success-700-contrast: black;
  --ig-success-700: var(--ig-success-h), calc(var(--ig-success-s) * 1.52), calc(var(--ig-success-l) * 0.81);
  --ig-success-800: hsl(131.32, 64.11%, 37.5%);
  --ig-success-800-contrast: black;
  --ig-success-800: var(--ig-success-h), calc(var(--ig-success-s) * 1.5), calc(var(--ig-success-l) * 0.73);
  --ig-success-900: hsl(131.32, 57.27%, 32.88%);
  --ig-success-900-contrast: white;
  --ig-success-900: var(--ig-success-h), calc(var(--ig-success-s) * 1.34), calc(var(--ig-success-l) * 0.64);
  --ig-success-A100: hsl(131.32, 52.57%, 68.84%);
  --ig-success-A100-contrast: black;
  --ig-success-A100: var(--ig-success-h), calc(var(--ig-success-s) * 1.23), calc(var(--ig-success-l) * 1.34);
  --ig-success-A200: hsl(131.32, 52.15%, 59.59%);
  --ig-success-A200-contrast: black;
  --ig-success-A200: var(--ig-success-h), calc(var(--ig-success-s) * 1.22), calc(var(--ig-success-l) * 1.16);
  --ig-success-A400: hsl(131.32, 52.57%, 46.75%);
  --ig-success-A400-contrast: black;
  --ig-success-A400: var(--ig-success-h), calc(var(--ig-success-s) * 1.23), calc(var(--ig-success-l) * 0.91);
  --ig-success-A700: hsl(131.32, 52.57%, 33.39%);
  --ig-success-A700-contrast: white;
  --ig-success-A700: var(--ig-success-h), calc(var(--ig-success-s) * 1.23), calc(var(--ig-success-l) * 0.65);
  --ig-warn-50: hsl(37.07, 100%, 95.98%);
  --ig-warn-50-contrast: black;
  --ig-warn-50: var(--ig-warn-h), calc(var(--ig-warn-s) * 1.23), calc(var(--ig-warn-l) * 1.78);
  --ig-warn-100: hsl(37.07, 76.6%, 89.51%);
  --ig-warn-100-contrast: black;
  --ig-warn-100: var(--ig-warn-h), calc(var(--ig-warn-s) * 0.8), calc(var(--ig-warn-l) * 1.66);
  --ig-warn-200: hsl(37.07, 61.28%, 77.11%);
  --ig-warn-200-contrast: black;
  --ig-warn-200: var(--ig-warn-h), calc(var(--ig-warn-s) * 0.64), calc(var(--ig-warn-l) * 1.43);
  --ig-warn-300: hsl(37.07, 69.89%, 64.17%);
  --ig-warn-300-contrast: black;
  --ig-warn-300: var(--ig-warn-h), calc(var(--ig-warn-s) * 0.73), calc(var(--ig-warn-l) * 1.19);
  --ig-warn-400: hsl(37.07, 83.78%, 58.24%);
  --ig-warn-400-contrast: black;
  --ig-warn-400: var(--ig-warn-h), calc(var(--ig-warn-s) * 0.875), calc(var(--ig-warn-l) * 1.08);
  --ig-warn-h: 37deg;
  --ig-warn-s: 96%;
  --ig-warn-l: 54%;
  --ig-warn-a: 1;
  --ig-warn-500: hsl(37.07, 95.74%, 53.92%);
  --ig-warn-500-contrast: black;
  --ig-warn-500: var(--ig-warn-h), calc(var(--ig-warn-s) * 1), calc(var(--ig-warn-l) * 1);
  --ig-warn-600: hsl(37.07, 100%, 47.99%);
  --ig-warn-600-contrast: black;
  --ig-warn-600: var(--ig-warn-h), calc(var(--ig-warn-s) * 1.26), calc(var(--ig-warn-l) * 0.89);
  --ig-warn-700: hsl(37.07, 100%, 43.68%);
  --ig-warn-700-contrast: black;
  --ig-warn-700: var(--ig-warn-h), calc(var(--ig-warn-s) * 1.52), calc(var(--ig-warn-l) * 0.81);
  --ig-warn-800: hsl(37.07, 100%, 39.36%);
  --ig-warn-800-contrast: black;
  --ig-warn-800: var(--ig-warn-h), calc(var(--ig-warn-s) * 1.5), calc(var(--ig-warn-l) * 0.73);
  --ig-warn-900: hsl(37.07, 100%, 34.51%);
  --ig-warn-900-contrast: black;
  --ig-warn-900: var(--ig-warn-h), calc(var(--ig-warn-s) * 1.34), calc(var(--ig-warn-l) * 0.64);
  --ig-warn-A100: hsl(37.07, 100%, 72.25%);
  --ig-warn-A100-contrast: black;
  --ig-warn-A100: var(--ig-warn-h), calc(var(--ig-warn-s) * 1.23), calc(var(--ig-warn-l) * 1.34);
  --ig-warn-A200: hsl(37.07, 100%, 62.55%);
  --ig-warn-A200-contrast: black;
  --ig-warn-A200: var(--ig-warn-h), calc(var(--ig-warn-s) * 1.22), calc(var(--ig-warn-l) * 1.16);
  --ig-warn-A400: hsl(37.07, 100%, 49.07%);
  --ig-warn-A400-contrast: black;
  --ig-warn-A400: var(--ig-warn-h), calc(var(--ig-warn-s) * 1.23), calc(var(--ig-warn-l) * 0.91);
  --ig-warn-A700: hsl(37.07, 100%, 35.05%);
  --ig-warn-A700-contrast: black;
  --ig-warn-A700: var(--ig-warn-h), calc(var(--ig-warn-s) * 1.23), calc(var(--ig-warn-l) * 0.65);
  --ig-error-50: hsl(346.02, 100%, 95.63%);
  --ig-error-50-contrast: black;
  --ig-error-50: var(--ig-error-h), calc(var(--ig-error-s) * 1.23), calc(var(--ig-error-l) * 1.78);
  --ig-error-100: hsl(346.02, 80%, 89.18%);
  --ig-error-100-contrast: black;
  --ig-error-100: var(--ig-error-h), calc(var(--ig-error-s) * 0.8), calc(var(--ig-error-l) * 1.66);
  --ig-error-200: hsl(346.02, 64%, 76.83%);
  --ig-error-200-contrast: black;
  --ig-error-200: var(--ig-error-h), calc(var(--ig-error-s) * 0.64), calc(var(--ig-error-l) * 1.43);
  --ig-error-300: hsl(346.02, 73%, 63.93%);
  --ig-error-300-contrast: black;
  --ig-error-300: var(--ig-error-h), calc(var(--ig-error-s) * 0.73), calc(var(--ig-error-l) * 1.19);
  --ig-error-400: hsl(346.02, 87.5%, 58.02%);
  --ig-error-400-contrast: black;
  --ig-error-400: var(--ig-error-h), calc(var(--ig-error-s) * 0.875), calc(var(--ig-error-l) * 1.08);
  --ig-error-h: 346deg;
  --ig-error-s: 100%;
  --ig-error-l: 54%;
  --ig-error-a: 1;
  --ig-error-500: hsl(346.02, 100%, 53.73%);
  --ig-error-500-contrast: black;
  --ig-error-500: var(--ig-error-h), calc(var(--ig-error-s) * 1), calc(var(--ig-error-l) * 1);
  --ig-error-600: hsl(346.02, 100%, 47.82%);
  --ig-error-600-contrast: black;
  --ig-error-600: var(--ig-error-h), calc(var(--ig-error-s) * 1.26), calc(var(--ig-error-l) * 0.89);
  --ig-error-700: hsl(346.02, 100%, 43.52%);
  --ig-error-700-contrast: white;
  --ig-error-700: var(--ig-error-h), calc(var(--ig-error-s) * 1.52), calc(var(--ig-error-l) * 0.81);
  --ig-error-800: hsl(346.02, 100%, 39.22%);
  --ig-error-800-contrast: white;
  --ig-error-800: var(--ig-error-h), calc(var(--ig-error-s) * 1.5), calc(var(--ig-error-l) * 0.73);
  --ig-error-900: hsl(346.02, 100%, 34.38%);
  --ig-error-900-contrast: white;
  --ig-error-900: var(--ig-error-h), calc(var(--ig-error-s) * 1.34), calc(var(--ig-error-l) * 0.64);
  --ig-error-A100: hsl(346.02, 100%, 71.99%);
  --ig-error-A100-contrast: black;
  --ig-error-A100: var(--ig-error-h), calc(var(--ig-error-s) * 1.23), calc(var(--ig-error-l) * 1.34);
  --ig-error-A200: hsl(346.02, 100%, 62.32%);
  --ig-error-A200-contrast: black;
  --ig-error-A200: var(--ig-error-h), calc(var(--ig-error-s) * 1.22), calc(var(--ig-error-l) * 1.16);
  --ig-error-A400: hsl(346.02, 100%, 48.89%);
  --ig-error-A400-contrast: black;
  --ig-error-A400: var(--ig-error-h), calc(var(--ig-error-s) * 1.23), calc(var(--ig-error-l) * 0.91);
  --ig-error-A700: hsl(346.02, 100%, 34.92%);
  --ig-error-A700-contrast: white;
  --ig-error-A700: var(--ig-error-h), calc(var(--ig-error-s) * 1.23), calc(var(--ig-error-l) * 0.65);
}

:root {
  --ig-elevation-0: none;
  --ig-elevation-1: 0 calc(var(--ig-elevation-factor, 1) * 1px) calc(var(--ig-elevation-factor, 1) * 3px) 0 rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 1px) calc(var(--ig-elevation-factor, 1) * 1px) 0 rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 2px) calc(var(--ig-elevation-factor, 1) * 1px) calc(var(--ig-elevation-factor, 1) * -1px) rgba(0, 0, 0, 0.08);
  --ig-elevation-2: 0 calc(var(--ig-elevation-factor, 1) * 1px) calc(var(--ig-elevation-factor, 1) * 5px) 0 rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 2px) calc(var(--ig-elevation-factor, 1) * 2px) 0 rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 3px) calc(var(--ig-elevation-factor, 1) * 1px) calc(var(--ig-elevation-factor, 1) * -2px) rgba(0, 0, 0, 0.08);
  --ig-elevation-3: 0 calc(var(--ig-elevation-factor, 1) * 1px) calc(var(--ig-elevation-factor, 1) * 8px) 0 rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 3px) calc(var(--ig-elevation-factor, 1) * 4px) 0 rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 3px) calc(var(--ig-elevation-factor, 1) * 3px) calc(var(--ig-elevation-factor, 1) * -2px) rgba(0, 0, 0, 0.08);
  --ig-elevation-4: 0 calc(var(--ig-elevation-factor, 1) * 2px) calc(var(--ig-elevation-factor, 1) * 4px) calc(var(--ig-elevation-factor, 1) * -1px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 4px) calc(var(--ig-elevation-factor, 1) * 5px) 0 rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 1px) calc(var(--ig-elevation-factor, 1) * 10px) 0 rgba(0, 0, 0, 0.08);
  --ig-elevation-5: 0 calc(var(--ig-elevation-factor, 1) * 3px) calc(var(--ig-elevation-factor, 1) * 5px) calc(var(--ig-elevation-factor, 1) * -1px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 5px) calc(var(--ig-elevation-factor, 1) * 8px) 0 rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 1px) calc(var(--ig-elevation-factor, 1) * 14px) 0 rgba(0, 0, 0, 0.08);
  --ig-elevation-6: 0 calc(var(--ig-elevation-factor, 1) * 3px) calc(var(--ig-elevation-factor, 1) * 5px) calc(var(--ig-elevation-factor, 1) * -1px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 6px) calc(var(--ig-elevation-factor, 1) * 10px) 0 rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 1px) calc(var(--ig-elevation-factor, 1) * 18px) 0 rgba(0, 0, 0, 0.08);
  --ig-elevation-7: 0 calc(var(--ig-elevation-factor, 1) * 4px) calc(var(--ig-elevation-factor, 1) * 5px) calc(var(--ig-elevation-factor, 1) * -2px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 7px) calc(var(--ig-elevation-factor, 1) * 10px) calc(var(--ig-elevation-factor, 1) * 1px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 2px) calc(var(--ig-elevation-factor, 1) * 16px) calc(var(--ig-elevation-factor, 1) * 1px) rgba(0, 0, 0, 0.08);
  --ig-elevation-8: 0 calc(var(--ig-elevation-factor, 1) * 5px) calc(var(--ig-elevation-factor, 1) * 5px) calc(var(--ig-elevation-factor, 1) * -3px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 8px) calc(var(--ig-elevation-factor, 1) * 10px) calc(var(--ig-elevation-factor, 1) * 1px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 3px) calc(var(--ig-elevation-factor, 1) * 14px) calc(var(--ig-elevation-factor, 1) * 2px) rgba(0, 0, 0, 0.08);
  --ig-elevation-9: 0 calc(var(--ig-elevation-factor, 1) * 5px) calc(var(--ig-elevation-factor, 1) * 6px) calc(var(--ig-elevation-factor, 1) * -3px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 9px) calc(var(--ig-elevation-factor, 1) * 12px) calc(var(--ig-elevation-factor, 1) * 1px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 3px) calc(var(--ig-elevation-factor, 1) * 16px) calc(var(--ig-elevation-factor, 1) * 2px) rgba(0, 0, 0, 0.08);
  --ig-elevation-10: 0 calc(var(--ig-elevation-factor, 1) * 6px) calc(var(--ig-elevation-factor, 1) * 6px) calc(var(--ig-elevation-factor, 1) * -3px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 10px) calc(var(--ig-elevation-factor, 1) * 14px) calc(var(--ig-elevation-factor, 1) * 1px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 4px) calc(var(--ig-elevation-factor, 1) * 18px) calc(var(--ig-elevation-factor, 1) * 3px) rgba(0, 0, 0, 0.08);
  --ig-elevation-11: 0 calc(var(--ig-elevation-factor, 1) * 6px) calc(var(--ig-elevation-factor, 1) * 7px) calc(var(--ig-elevation-factor, 1) * -4px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 11px) calc(var(--ig-elevation-factor, 1) * 15px) calc(var(--ig-elevation-factor, 1) * 1px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 4px) calc(var(--ig-elevation-factor, 1) * 20px) calc(var(--ig-elevation-factor, 1) * 3px) rgba(0, 0, 0, 0.08);
  --ig-elevation-12: 0 calc(var(--ig-elevation-factor, 1) * 7px) calc(var(--ig-elevation-factor, 1) * 8px) calc(var(--ig-elevation-factor, 1) * -4px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 12px) calc(var(--ig-elevation-factor, 1) * 17px) calc(var(--ig-elevation-factor, 1) * 2px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 5px) calc(var(--ig-elevation-factor, 1) * 22px) calc(var(--ig-elevation-factor, 1) * 4px) rgba(0, 0, 0, 0.08);
  --ig-elevation-13: 0 calc(var(--ig-elevation-factor, 1) * 7px) calc(var(--ig-elevation-factor, 1) * 8px) calc(var(--ig-elevation-factor, 1) * -4px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 13px) calc(var(--ig-elevation-factor, 1) * 19px) calc(var(--ig-elevation-factor, 1) * 2px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 5px) calc(var(--ig-elevation-factor, 1) * 24px) calc(var(--ig-elevation-factor, 1) * 4px) rgba(0, 0, 0, 0.08);
  --ig-elevation-14: 0 calc(var(--ig-elevation-factor, 1) * 7px) calc(var(--ig-elevation-factor, 1) * 9px) calc(var(--ig-elevation-factor, 1) * -4px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 14px) calc(var(--ig-elevation-factor, 1) * 21px) calc(var(--ig-elevation-factor, 1) * 2px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 5px) calc(var(--ig-elevation-factor, 1) * 26px) calc(var(--ig-elevation-factor, 1) * 4px) rgba(0, 0, 0, 0.08);
  --ig-elevation-15: 0 calc(var(--ig-elevation-factor, 1) * 8px) calc(var(--ig-elevation-factor, 1) * 9px) calc(var(--ig-elevation-factor, 1) * -5px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 15px) calc(var(--ig-elevation-factor, 1) * 22px) calc(var(--ig-elevation-factor, 1) * 2px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 6px) calc(var(--ig-elevation-factor, 1) * 28px) calc(var(--ig-elevation-factor, 1) * 5px) rgba(0, 0, 0, 0.08);
  --ig-elevation-16: 0 calc(var(--ig-elevation-factor, 1) * 8px) calc(var(--ig-elevation-factor, 1) * 10px) calc(var(--ig-elevation-factor, 1) * -5px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 16px) calc(var(--ig-elevation-factor, 1) * 24px) calc(var(--ig-elevation-factor, 1) * 2px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 6px) calc(var(--ig-elevation-factor, 1) * 30px) calc(var(--ig-elevation-factor, 1) * 5px) rgba(0, 0, 0, 0.08);
  --ig-elevation-17: 0 calc(var(--ig-elevation-factor, 1) * 8px) calc(var(--ig-elevation-factor, 1) * 11px) calc(var(--ig-elevation-factor, 1) * -5px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 17px) calc(var(--ig-elevation-factor, 1) * 26px) calc(var(--ig-elevation-factor, 1) * 2px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 6px) calc(var(--ig-elevation-factor, 1) * 32px) calc(var(--ig-elevation-factor, 1) * 5px) rgba(0, 0, 0, 0.08);
  --ig-elevation-18: 0 calc(var(--ig-elevation-factor, 1) * 9px) calc(var(--ig-elevation-factor, 1) * 11px) calc(var(--ig-elevation-factor, 1) * -5px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 18px) calc(var(--ig-elevation-factor, 1) * 28px) calc(var(--ig-elevation-factor, 1) * 2px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 7px) calc(var(--ig-elevation-factor, 1) * 34px) calc(var(--ig-elevation-factor, 1) * 6px) rgba(0, 0, 0, 0.08);
  --ig-elevation-19: 0 calc(var(--ig-elevation-factor, 1) * 9px) calc(var(--ig-elevation-factor, 1) * 12px) calc(var(--ig-elevation-factor, 1) * -6px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 19px) calc(var(--ig-elevation-factor, 1) * 29px) calc(var(--ig-elevation-factor, 1) * 2px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 7px) calc(var(--ig-elevation-factor, 1) * 36px) calc(var(--ig-elevation-factor, 1) * 6px) rgba(0, 0, 0, 0.08);
  --ig-elevation-20: 0 calc(var(--ig-elevation-factor, 1) * 10px) calc(var(--ig-elevation-factor, 1) * 13px) calc(var(--ig-elevation-factor, 1) * -6px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 20px) calc(var(--ig-elevation-factor, 1) * 31px) calc(var(--ig-elevation-factor, 1) * 3px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 8px) calc(var(--ig-elevation-factor, 1) * 38px) calc(var(--ig-elevation-factor, 1) * 7px) rgba(0, 0, 0, 0.08);
  --ig-elevation-21: 0 calc(var(--ig-elevation-factor, 1) * 10px) calc(var(--ig-elevation-factor, 1) * 13px) calc(var(--ig-elevation-factor, 1) * -6px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 21px) calc(var(--ig-elevation-factor, 1) * 33px) calc(var(--ig-elevation-factor, 1) * 3px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 8px) calc(var(--ig-elevation-factor, 1) * 40px) calc(var(--ig-elevation-factor, 1) * 7px) rgba(0, 0, 0, 0.08);
  --ig-elevation-22: 0 calc(var(--ig-elevation-factor, 1) * 10px) calc(var(--ig-elevation-factor, 1) * 14px) calc(var(--ig-elevation-factor, 1) * -6px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 22px) calc(var(--ig-elevation-factor, 1) * 35px) calc(var(--ig-elevation-factor, 1) * 3px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 8px) calc(var(--ig-elevation-factor, 1) * 42px) calc(var(--ig-elevation-factor, 1) * 7px) rgba(0, 0, 0, 0.08);
  --ig-elevation-23: 0 calc(var(--ig-elevation-factor, 1) * 11px) calc(var(--ig-elevation-factor, 1) * 14px) calc(var(--ig-elevation-factor, 1) * -7px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 23px) calc(var(--ig-elevation-factor, 1) * 36px) calc(var(--ig-elevation-factor, 1) * 3px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 9px) calc(var(--ig-elevation-factor, 1) * 44px) calc(var(--ig-elevation-factor, 1) * 8px) rgba(0, 0, 0, 0.08);
  --ig-elevation-24: 0 calc(var(--ig-elevation-factor, 1) * 11px) calc(var(--ig-elevation-factor, 1) * 15px) calc(var(--ig-elevation-factor, 1) * -7px) rgba(0, 0, 0, 0.26), 0 calc(var(--ig-elevation-factor, 1) * 24px) calc(var(--ig-elevation-factor, 1) * 38px) calc(var(--ig-elevation-factor, 1) * 3px) rgba(0, 0, 0, 0.12), 0 calc(var(--ig-elevation-factor, 1) * 9px) calc(var(--ig-elevation-factor, 1) * 46px) calc(var(--ig-elevation-factor, 1) * 8px) rgba(0, 0, 0, 0.08);
}

[igxRipple] {
  --color: var(--igx-ripple-color, hsla(var(--ig-gray-800), 0.7));
}

.igx-ripple__inner {
  display: block;
  position: absolute;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 50%), 50%);
  background: var(--color);
  pointer-events: none;
  transform-origin: center;
  transform: translate3d(0, 0, 0) scale(0);
  will-change: opacity, transform;
  opacity: 0.5;
  margin: 0 !important;
  border: none !important;
}

.igx-ripple {
  overflow: hidden;
}

igx-avatar {
  --background: var(--igx-avatar-background, hsla(var(--ig-gray-400), 0.54));
  --color: var(--igx-avatar-color, hsla(var(--ig-gray-800), 0.96));
  --border-radius: var(--igx-avatar-border-radius, 0rem);
}

.igx-avatar {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: var(--color);
  background: var(--background);
  vertical-align: middle;
  border-radius: var(--border-radius);
  outline-style: none;
  flex-shrink: 0;
}
.igx-avatar::after {
  box-shadow: none;
  transition: box-shadow 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.igx-avatar__image {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.igx-avatar--circle {
  --igx-avatar-border-radius: 2.75rem;
}

.igx-avatar--rounded {
  --igx-avatar-border-radius: 0.5rem;
}

.igx-avatar--initials {
  --size: 2.5rem;
  font-size: calc(var(--size) / 2);
  line-height: calc(var(--size) / 2);
}

.igx-avatar--medium.igx-avatar--initials {
  --size: 4rem;
  font-size: calc(var(--size) / 2);
  line-height: calc(var(--size) / 2);
}

.igx-avatar--large.igx-avatar--initials {
  --size: 5.5rem;
  font-size: calc(var(--size) / 2);
  line-height: calc(var(--size) / 2);
}

.igx-avatar--small {
  --size: 2.5rem;
  width: var(--size);
  height: var(--size);
}

.igx-avatar--medium {
  --size: 4rem;
  width: var(--size);
  height: var(--size);
}

.igx-avatar--large {
  --size: 5.5rem;
  width: var(--size);
  height: var(--size);
}

igx-action-strip {
  --actions-background: var(--igx-action-strip-actions-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --background: var(--igx-action-strip-background, hsla(var(--ig-gray-100), 0.38));
  --icon-color: var(--igx-action-strip-icon-color, 'currentColor');
  --delete-action: var(--igx-action-strip-delete-action, hsla(var(--ig-error-500), var(--ig-error-a)));
  --actions-border-radius: var(--igx-action-strip-actions-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1.5rem), 1.5rem));
}

.igx-action-strip {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  inset-inline-start: 0;
  background: var(--background);
  color: inherit;
  padding: 0 1.5rem;
  z-index: 9999;
}

.igx-action-strip--compact [igxButton=icon], .igx-action-strip--cosy [igxButton=icon] {
  width: 1.75rem;
  height: 1.75rem;
}
.igx-action-strip--compact [igxButton=icon] igx-icon, .igx-action-strip--cosy [igxButton=icon] igx-icon {
  width: var(--igx-icon-size, 0.875rem);
  height: var(--igx-icon-size, 0.875rem);
  font-size: var(--igx-icon-size, 0.875rem);
}

.igx-action-strip--cosy {
  padding: 0 1rem;
}

.igx-action-strip--compact {
  padding: 0 0.75rem;
}

.igx-action-strip__editing-actions,
.igx-action-strip__pinning-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.igx-action-strip__menu-item [igxLabel], .igx-action-strip__menu-item--danger [igxLabel] {
  cursor: pointer;
}
.igx-action-strip__menu-item igx-icon, .igx-action-strip__menu-item--danger igx-icon {
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
}
.igx-action-strip__menu-item igx-icon + [igxLabel], .igx-action-strip__menu-item--danger igx-icon + [igxLabel] {
  margin-inline-start: 0.75rem;
}
.igx-action-strip__menu-item.igx-drop-down__item--cosy igx-icon + [igxLabel], .igx-action-strip__menu-item--danger.igx-drop-down__item--cosy igx-icon + [igxLabel] {
  margin-inline-start: 0.625rem;
}
.igx-action-strip__menu-item.igx-drop-down__item--compact igx-icon + [igxLabel], .igx-action-strip__menu-item--danger.igx-drop-down__item--compact igx-icon + [igxLabel] {
  margin-inline-start: 0.5rem;
}

.igx-action-strip__menu-item--danger {
  color: hsla(var(--ig-error-500), var(--ig-error-a));
}
.igx-action-strip__menu-item--danger:hover {
  color: hsla(var(--ig-error-500), var(--ig-error-a));
}

.igx-action-strip__menu-button {
  display: flex;
  align-items: center;
}

.igx-action-strip__actions {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  position: relative;
  color: var(--icon-color);
  border-radius: var(--actions-border-radius);
  background: var(--actions-background);
  max-height: 2.25rem;
}
.igx-action-strip__actions:last-child {
  margin-inline-end: 0;
}
.igx-action-strip__actions igx-icon {
  color: var(--icon-color);
}
.igx-action-strip__actions [igxButton=icon] igx-icon {
  color: var(--icon-color);
}

.igx-action-strip__editing-actions > [igxButton] {
  margin-inline-start: 0.25rem;
}
.igx-action-strip__editing-actions > [igxButton]:first-of-type {
  margin-inline-start: 0;
}

.igx-action-strip__delete igx-icon {
  color: var(--delete-action);
}

igx-badge {
  --elevation: var(--igx-badge-elevation, var(--ig-elevation-1));
  --icon-color: var(--igx-badge-icon-color, var(--ig-primary-500-contrast));
  --text-color: var(--igx-badge-text-color, var(--ig-primary-500-contrast));
  --border-color: var(--igx-badge-border-color, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --background-color: var(--igx-badge-background-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --border-width: var(--igx-badge-border-width, 0);
  --border-radius: var(--igx-badge-border-radius, 0);
  --shadow: var(--igx-badge-shadow, var(--ig-elevation-1));
}

.igx-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.375rem;
  height: 1.375rem;
  font-size: 0.6875rem;
  font-weight: normal;
  color: var(--text-color);
  line-height: 1;
  background: var(--background-color);
  border-radius: 50%;
  box-shadow: var(--shadow);
  border-width: var(--border-width);
  border-color: var(--border-color);
  border-style: solid;
  overflow: hidden;
}
.igx-badge igx-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--igx-icon-size, 1.25rem);
  height: var(--igx-icon-size, 1.25rem);
  font-size: var(--igx-icon-size, 0.75rem);
  font-weight: normal;
  color: var(--icon-color);
}

.igx-badge--square {
  border-radius: var(--border-radius);
}

.igx-badge__value {
  white-space: nowrap;
  padding: 0.25rem;
}

.igx-badge--success {
  background: hsla(var(--ig-success-500), var(--ig-success-a));
}

.igx-badge--info {
  background: hsla(var(--ig-info-500), var(--ig-info-a));
}

.igx-badge--warning {
  background: hsla(var(--ig-warn-500), var(--ig-warn-a));
}

.igx-badge--error {
  background: hsla(var(--ig-error-500), var(--ig-error-a));
}

.igx-badge--hidden {
  visibility: hidden;
}

igx-bottom-nav {
  --elevation: var(--igx-bottom-nav-elevation, var(--ig-elevation-8));
  --background: var(--igx-bottom-nav-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --idle-item-color: var(--igx-bottom-nav-idle-item-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --active-item-color: var(--igx-bottom-nav-active-item-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --shadow: var(--igx-bottom-nav-shadow, var(--ig-elevation-8));
}

.igx-bottom-nav__panel {
  display: block;
}
.igx-bottom-nav__panel:focus {
  outline-style: none;
}
.igx-bottom-nav__panel:empty {
  display: none;
}

.igx-bottom-nav__menu {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  inset-inline-start: 0;
  inset-inline-end: 0;
  height: 3.5rem;
  background: var(--background);
  overflow: hidden;
  z-index: 8;
}

.igx-bottom-nav__menu--top {
  inset-block-start: 0;
  inset-block-end: inherit;
  box-shadow: var(--shadow);
}

.igx-bottom-nav__menu--bottom {
  inset-block-start: inherit;
  inset-block-end: 0;
  box-shadow: var(--shadow);
}

.igx-bottom-nav__menu-item, .igx-bottom-nav__menu-item--selected, .igx-bottom-nav__menu-item--disabled {
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 5rem;
  max-width: 10.5rem;
  height: 100%;
  color: var(--idle-item-color);
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  padding: 0 0.75rem;
  -webkit-tap-highlight-color: transparent;
  outline-style: none;
}

.igx-bottom-nav__menu-item--disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.igx-bottom-nav__menu-item--selected {
  color: var(--active-item-color);
  transition: color 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.igx-bottom-nav__menu-item [igxBottomNavHeaderLabel], .igx-bottom-nav__menu-item--selected [igxBottomNavHeaderLabel], .igx-bottom-nav__menu-item--disabled [igxBottomNavHeaderLabel] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-block-start: 0.25rem;
  max-width: 100%;
  text-align: center;
  transform: translateZ(0);
  transition: transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  z-index: 1;
}

.igx-bottom-nav__menu-item [igxBottomNavHeaderIcon], .igx-bottom-nav__menu-item--selected [igxBottomNavHeaderIcon], .igx-bottom-nav__menu-item--disabled [igxBottomNavHeaderIcon] {
  display: flex;
  position: relative;
  justify-content: center;
  height: 1.5rem;
  padding: 0 0.5rem;
  transform: translateZ(0);
  transition: transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  z-index: 1;
}

.igx-bottom-nav__menu-item--selected [igxBottomNavHeaderIcon] {
  transform: translateY(-2px);
}

.igx-bottom-nav__menu-item--selected [igxBottomNavHeaderLabel] {
  transform: translateY(-2px) scale(1.166667);
}

[igxButton=flat], .igx-button--flat {
  --shadow-color: var(--igx-button-shadow-color, transparent);
  --border-color: var(--igx-button-border-color, transparent);
  --hover-border-color: var(--igx-button-hover-border-color, transparent);
  --focus-border-color: var(--igx-button-focus-border-color, transparent);
  --focus-visible-border-color: var(--igx-button-focus-visible-border-color, transparent);
  --active-border-color: var(--igx-button-active-border-color, transparent);
  --disabled-border-color: var(--igx-button-disabled-border-color, transparent);
  --disabled-background: var(--igx-button-disabled-background, transparent);
  --disabled-foreground: var(--igx-button-disabled-foreground, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --resting-elevation: var(--igx-button-resting-elevation, var(--ig-elevation-0));
  --hover-elevation: var(--igx-button-hover-elevation, var(--ig-elevation-0));
  --focus-elevation: var(--igx-button-focus-elevation, var(--ig-elevation-0));
  --active-elevation: var(--igx-button-active-elevation, var(--ig-elevation-0));
  --background: var(--igx-button-background, transparent);
  --foreground: var(--igx-button-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --hover-background: var(--igx-button-hover-background, hsla(var(--ig-secondary-500), 0.05));
  --hover-foreground: var(--igx-button-hover-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --focus-background: var(--igx-button-focus-background, hsla(var(--ig-secondary-400), 0.12));
  --focus-foreground: var(--igx-button-focus-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --focus-visible-background: var(--igx-button-focus-visible-background, hsla(var(--ig-secondary-400), 0.12));
  --focus-visible-foreground: var(--igx-button-focus-visible-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --active-background: var(--igx-button-active-background, hsla(var(--ig-secondary-400), 0.12));
  --active-foreground: var(--igx-button-active-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --border-radius: var(--igx-button-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --resting-shadow: var(--igx-button-resting-shadow, var(--ig-elevation-0));
  --hover-shadow: var(--igx-button-hover-shadow, var(--ig-elevation-0));
  --focus-shadow: var(--igx-button-focus-shadow, var(--ig-elevation-0));
  --active-shadow: var(--igx-button-active-shadow, var(--ig-elevation-0));
}

[igxButton=outlined], .igx-button--outlined {
  --shadow-color: var(--igx-button-shadow-color, transparent);
  --border-color: var(--igx-button-border-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --hover-border-color: var(--igx-button-hover-border-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --focus-border-color: var(--igx-button-focus-border-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --focus-visible-border-color: var(--igx-button-focus-visible-border-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --active-border-color: var(--igx-button-active-border-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --disabled-border-color: var(--igx-button-disabled-border-color, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --disabled-background: var(--igx-button-disabled-background, transparent);
  --disabled-foreground: var(--igx-button-disabled-foreground, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --resting-elevation: var(--igx-button-resting-elevation, var(--ig-elevation-0));
  --hover-elevation: var(--igx-button-hover-elevation, var(--ig-elevation-0));
  --focus-elevation: var(--igx-button-focus-elevation, var(--ig-elevation-0));
  --active-elevation: var(--igx-button-active-elevation, var(--ig-elevation-0));
  --background: var(--igx-button-background, transparent);
  --foreground: var(--igx-button-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --hover-background: var(--igx-button-hover-background, hsla(var(--ig-secondary-500), 0.05));
  --hover-foreground: var(--igx-button-hover-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --focus-background: var(--igx-button-focus-background, hsla(var(--ig-secondary-400), 0.12));
  --focus-foreground: var(--igx-button-focus-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --focus-visible-background: var(--igx-button-focus-visible-background, hsla(var(--ig-secondary-400), 0.12));
  --focus-visible-foreground: var(--igx-button-focus-visible-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --active-background: var(--igx-button-active-background, hsla(var(--ig-secondary-400), 0.12));
  --active-foreground: var(--igx-button-active-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --border-radius: var(--igx-button-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --resting-shadow: var(--igx-button-resting-shadow, var(--ig-elevation-0));
  --hover-shadow: var(--igx-button-hover-shadow, var(--ig-elevation-0));
  --focus-shadow: var(--igx-button-focus-shadow, var(--ig-elevation-0));
  --active-shadow: var(--igx-button-active-shadow, var(--ig-elevation-0));
}

[igxButton=raised], .igx-button--raised {
  --shadow-color: var(--igx-button-shadow-color, transparent);
  --border-color: var(--igx-button-border-color, transparent);
  --hover-border-color: var(--igx-button-hover-border-color, transparent);
  --focus-border-color: var(--igx-button-focus-border-color, transparent);
  --focus-visible-border-color: var(--igx-button-focus-visible-border-color, transparent);
  --active-border-color: var(--igx-button-active-border-color, transparent);
  --disabled-border-color: var(--igx-button-disabled-border-color, transparent);
  --disabled-background: var(--igx-button-disabled-background, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --disabled-foreground: var(--igx-button-disabled-foreground, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --resting-elevation: var(--igx-button-resting-elevation, var(--ig-elevation-2));
  --hover-elevation: var(--igx-button-hover-elevation, var(--ig-elevation-4));
  --focus-elevation: var(--igx-button-focus-elevation, var(--ig-elevation-8));
  --active-elevation: var(--igx-button-active-elevation, var(--ig-elevation-8));
  --background: var(--igx-button-background, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --foreground: var(--igx-button-foreground, var(--ig-secondary-500-contrast));
  --hover-background: var(--igx-button-hover-background, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --hover-foreground: var(--igx-button-hover-foreground, var(--ig-secondary-300-contrast));
  --focus-background: var(--igx-button-focus-background, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --focus-foreground: var(--igx-button-focus-foreground, var(--ig-secondary-300-contrast));
  --focus-visible-background: var(--igx-button-focus-visible-background, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --focus-visible-foreground: var(--igx-button-focus-visible-foreground, var(--ig-secondary-300-contrast));
  --active-background: var(--igx-button-active-background, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --active-foreground: var(--igx-button-active-foreground, var(--ig-secondary-300-contrast));
  --border-radius: var(--igx-button-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --resting-shadow: var(--igx-button-resting-shadow, var(--ig-elevation-2));
  --hover-shadow: var(--igx-button-hover-shadow, var(--ig-elevation-4));
  --focus-shadow: var(--igx-button-focus-shadow, var(--ig-elevation-8));
  --active-shadow: var(--igx-button-active-shadow, var(--ig-elevation-8));
}

[igxButton=fab], .igx-button--fab {
  --shadow-color: var(--igx-button-shadow-color, transparent);
  --border-color: var(--igx-button-border-color, transparent);
  --hover-border-color: var(--igx-button-hover-border-color, transparent);
  --focus-border-color: var(--igx-button-focus-border-color, transparent);
  --focus-visible-border-color: var(--igx-button-focus-visible-border-color, transparent);
  --active-border-color: var(--igx-button-active-border-color, transparent);
  --disabled-border-color: var(--igx-button-disabled-border-color, transparent);
  --disabled-background: var(--igx-button-disabled-background, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --disabled-foreground: var(--igx-button-disabled-foreground, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --resting-elevation: var(--igx-button-resting-elevation, var(--ig-elevation-6));
  --hover-elevation: var(--igx-button-hover-elevation, var(--ig-elevation-12));
  --focus-elevation: var(--igx-button-focus-elevation, var(--ig-elevation-12));
  --active-elevation: var(--igx-button-active-elevation, var(--ig-elevation-12));
  --background: var(--igx-button-background, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --foreground: var(--igx-button-foreground, var(--ig-secondary-500-contrast));
  --hover-background: var(--igx-button-hover-background, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --hover-foreground: var(--igx-button-hover-foreground, var(--ig-secondary-300-contrast));
  --focus-background: var(--igx-button-focus-background, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --focus-foreground: var(--igx-button-focus-foreground, var(--ig-secondary-300-contrast));
  --focus-visible-background: var(--igx-button-focus-visible-background, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --focus-visible-foreground: var(--igx-button-focus-visible-foreground, var(--ig-secondary-300-contrast));
  --active-background: var(--igx-button-active-background, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --active-foreground: var(--igx-button-active-foreground, var(--ig-secondary-300-contrast));
  --border-radius: var(--igx-button-border-radius, clamp(0.75rem, calc(var(--ig-radius-factor, 1) * 1.75rem), 1.75rem));
  --resting-shadow: var(--igx-button-resting-shadow, var(--ig-elevation-6));
  --hover-shadow: var(--igx-button-hover-shadow, var(--ig-elevation-12));
  --focus-shadow: var(--igx-button-focus-shadow, var(--ig-elevation-12));
  --active-shadow: var(--igx-button-active-shadow, var(--ig-elevation-12));
}

[igxButton=icon], .igx-button--icon {
  --shadow-color: var(--igx-button-shadow-color, transparent);
  --border-color: var(--igx-button-border-color, transparent);
  --hover-border-color: var(--igx-button-hover-border-color, transparent);
  --focus-border-color: var(--igx-button-focus-border-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --focus-visible-border-color: var(--igx-button-focus-visible-border-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --active-border-color: var(--igx-button-active-border-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --disabled-border-color: var(--igx-button-disabled-border-color, transparent);
  --disabled-background: var(--igx-button-disabled-background, transparent);
  --disabled-foreground: var(--igx-button-disabled-foreground, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --resting-elevation: var(--igx-button-resting-elevation, var(--ig-elevation-0));
  --hover-elevation: var(--igx-button-hover-elevation, var(--ig-elevation-0));
  --focus-elevation: var(--igx-button-focus-elevation, var(--ig-elevation-0));
  --active-elevation: var(--igx-button-active-elevation, var(--ig-elevation-0));
  --background: var(--igx-button-background, transparent);
  --foreground: var(--igx-button-foreground, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --hover-background: var(--igx-button-hover-background, transparent);
  --hover-foreground: var(--igx-button-hover-foreground, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --focus-background: var(--igx-button-focus-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --focus-foreground: var(--igx-button-focus-foreground, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --focus-visible-background: var(--igx-button-focus-visible-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --focus-visible-foreground: var(--igx-button-focus-visible-foreground, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --active-background: var(--igx-button-active-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --active-foreground: var(--igx-button-active-foreground, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --border-radius: var(--igx-button-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1.25rem), 1.25rem));
  --resting-shadow: var(--igx-button-resting-shadow, var(--ig-elevation-0));
  --hover-shadow: var(--igx-button-hover-shadow, var(--ig-elevation-0));
  --focus-shadow: var(--igx-button-focus-shadow, var(--ig-elevation-0));
  --active-shadow: var(--igx-button-active-shadow, var(--ig-elevation-0));
}

.igx-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 5.5rem;
  padding: 0.4375rem 1rem;
  min-height: 2.25rem;
  border: 0.0625rem solid var(--border-color);
  cursor: pointer;
  user-select: none;
  outline-style: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}
.igx-button igx-icon {
  display: flex;
  justify-content: center;
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
}
.igx-button * + * {
  margin-inline-start: 0.75rem;
}

.igx-button--cosy {
  padding: 0.25rem 1rem;
  min-height: 1.875rem;
}
.igx-button--cosy * + * {
  margin-inline-start: 0.5rem;
}

.igx-button--compact {
  padding: 0.0625rem 1rem;
  min-height: 1.5rem;
}
.igx-button--compact * + * {
  margin-inline-start: 0.25rem;
}

.igx-button--flat {
  background: var(--background);
  color: var(--foreground);
  border-radius: var(--border-radius);
}
.igx-button--flat:hover {
  background: var(--hover-background);
  color: var(--hover-foreground);
  border-color: var(--hover-border-color);
}
.igx-button--flat:focus {
  background: var(--focus-background);
  color: var(--focus-foreground);
  box-shadow: 0 0 0 0.1875rem var(--shadow-color);
  border-color: var(--focus-border-color);
}
.igx-button--flat:active {
  background: var(--active-background);
  color: var(--active-foreground);
  box-shadow: 0 0 0 0.1875rem var(--shadow-color);
  border-color: var(--active-border-color);
}
.igx-button--flat:focus-visible {
  background: var(--focus-visible-background);
  color: var(--focus-visible-foreground);
  border-color: var(--focus-visible-border-color);
}

.igx-button--outlined {
  background: var(--background);
  color: var(--foreground);
  border-color: var(--border-color);
  border-radius: var(--border-radius);
  padding: 0.4375rem 0.875rem;
}
.igx-button--outlined:hover {
  background: var(--hover-background);
  color: var(--hover-foreground);
  border-color: var(--hover-border-color);
}
.igx-button--outlined:focus {
  background: var(--focus-background);
  color: var(--focus-foreground);
  border-color: var(--focus-border-color);
}
.igx-button--outlined:active {
  background: var(--active-background);
  color: var(--active-foreground);
  border-color: var(--active-border-color);
  box-shadow: 0 0 0 0.1875rem var(--shadow-color);
}
.igx-button--outlined:focus-visible {
  background: var(--focus-visible-background);
  color: var(--focus-visible-foreground);
  border-color: var(--focus-visible-border-color);
}
.igx-button--outlined:focus-visible:hover {
  background: var(--hover-background);
  color: var(--hover-foreground);
  border-color: var(--hover-border-color);
}
.igx-button--outlined:focus-visible:active {
  background: var(--active-background);
  color: var(--active-foreground);
  border-color: var(--active-border-color);
}

.igx-button--cosy.igx-button--outlined {
  padding: 0.25rem 0.875rem;
  min-height: 1.875rem;
}
.igx-button--cosy.igx-button--outlined * + * {
  margin-inline-start: 0.5rem;
}

.igx-button--compact.igx-button--outlined {
  padding: 0.0625rem 0.875rem;
  min-height: 1.5rem;
}
.igx-button--compact.igx-button--outlined * + * {
  margin-inline-start: 0.25rem;
}

.igx-button--raised {
  color: var(--foreground);
  background: var(--background);
  box-shadow: var(--resting-shadow);
  border-radius: var(--border-radius);
}
.igx-button--raised:hover {
  color: var(--hover-foreground);
  background: var(--hover-background);
  border-color: var(--hover-border-color);
  box-shadow: var(--hover-shadow);
}
.igx-button--raised:focus {
  color: var(--focus-foreground);
  background: var(--focus-background);
  border-color: var(--focus-border-color);
  box-shadow: 0 0 0 0.1875rem var(--shadow-color), var(--focus-shadow);
}
.igx-button--raised:active {
  color: var(--active-foreground);
  background: var(--active-background);
  border-color: var(--active-border-color);
  box-shadow: 0 0 0 0.1875rem var(--shadow-color), var(--active-shadow);
}
.igx-button--raised:focus-visible {
  background: var(--focus-visible-background);
  color: var(--focus-visible-foreground);
  border-color: var(--focus-visible-border-color);
}
.igx-button--raised:focus-visible:hover {
  color: var(--hover-foreground);
  background: var(--hover-background);
  border-color: var(--hover-border-color);
}
.igx-button--raised:focus-visible:active {
  color: var(--active-foreground);
  background: var(--active-background);
  border-color: var(--active-border-color);
}

.igx-button--fab, .igx-button--icon {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  filter: blur(0);
}

.igx-button--fab {
  padding: 0.5rem 0.875rem;
  min-width: 3rem;
  min-height: 3rem;
  line-height: unset;
  white-space: nowrap;
  color: var(--foreground);
  background: var(--background);
  border-color: var(--border-color);
  box-shadow: var(--resting-shadow);
  border-radius: var(--border-radius);
}
.igx-button--fab:hover {
  color: var(--hover-foreground);
  background: var(--hover-background);
  border-color: var(--hover-border-color);
  box-shadow: var(--resting-shadow);
}
.igx-button--fab:focus {
  color: var(--focus-foreground);
  background: var(--focus-background);
  border-color: var(--focus-border-color);
  box-shadow: 0 0 0 0.1875rem var(--shadow-color), var(--focus-shadow);
}
.igx-button--fab:active {
  color: var(--active-foreground);
  background: var(--active-background);
  border-color: var(--active-border-color);
  box-shadow: 0 0 0 0.1875rem var(--shadow-color), var(--active-shadow);
}
.igx-button--fab:focus-visible {
  background: var(--focus-visible-background);
  color: var(--focus-visible-foreground);
  border-color: var(--focus-visible-border-color);
}
.igx-button--fab:focus-visible:hover {
  color: var(--hover-foreground);
  background: var(--hover-background);
  border-color: var(--hover-border-color);
}
.igx-button--fab:focus-visible:active {
  color: var(--active-foreground);
  background: var(--active-background);
  border-color: var(--active-border-color);
}
.igx-button--fab * + * {
  margin-inline-start: 0.75rem;
}

.igx-button--cosy.igx-button--fab {
  padding: 0.25rem 0.625rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
}
.igx-button--cosy.igx-button--fab * + * {
  margin-inline-start: 0.5rem;
}

.igx-button--compact.igx-button--fab {
  padding: 0 0.375rem;
  min-width: 2rem;
  min-height: 2rem;
}
.igx-button--compact.igx-button--fab * + * {
  margin-inline-start: 0.25rem;
}

.igx-button--icon {
  width: 2.25rem;
  height: 2.25rem;
  min-width: unset;
  min-height: unset;
  font-size: 1rem;
  padding: 0;
  color: var(--foreground);
  background: var(--background);
  border-radius: var(--border-radius);
  border-color: var(--border-color);
}
.igx-button--icon:hover {
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  color: var(--hover-foreground);
  background: var(--hover-background);
  border-color: var(--hover-border-color);
}
.igx-button--icon:focus {
  color: var(--focus-foreground);
  background: var(--focus-background);
  border-color: var(--focus-border-color);
}
.igx-button--icon:active {
  color: var(--active-foreground);
  background: var(--active-background);
  border-color: var(--active-border-color);
}
.igx-button--icon:focus-visible {
  color: var(--focus-visible-foreground);
  background: var(--focus-visible-background);
  border-color: var(--focus-visible-border-color);
}
.igx-button--icon:focus-visible:hover {
  color: var(--hover-foreground);
  background: var(--hover-background);
  border-color: var(--focus-visible-border-color);
}
.igx-button--icon:focus-visible:active {
  color: var(--active-foreground);
  background: var(--active-background);
  border-color: var(--focus-visible-border-color);
}
.igx-button--icon:focus, .igx-button--icon:active {
  box-shadow: 0 0 0 0.1875rem var(--shadow-color), var(--focus-shadow);
}

.igx-button--cosy.igx-button--icon {
  width: 1.875rem;
  height: 1.875rem;
}

.igx-button--compact.igx-button--icon {
  width: 1.5rem;
  height: 1.5rem;
}

.igx-button--disabled {
  background: var(--disabled-background);
  color: var(--disabled-foreground);
  border-color: var(--disabled-border-color);
  pointer-events: none;
  box-shadow: none;
}
.igx-button--disabled:focus {
  box-shadow: none;
}

igx-buttongroup {
  --elevation: var(--igx-button-group-elevation, var(--ig-elevation-2));
  --idle-shadow-color: var(--igx-button-group-idle-shadow-color, transparent);
  --item-background: var(--igx-button-group-item-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --item-border-color: var(--igx-button-group-item-border-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --item-text-color: var(--igx-button-group-item-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --item-hover-text-color: var(--igx-button-group-item-hover-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --item-hover-background: var(--igx-button-group-item-hover-background, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --item-selected-text-color: var(--igx-button-group-item-selected-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --item-selected-background: var(--igx-button-group-item-selected-background, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --item-selected-hover-background: var(--igx-button-group-item-selected-hover-background, hsla(var(--ig-gray-500), 0.8));
  --item-selected-border-color: var(--igx-button-group-item-selected-border-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --disabled-text-color: var(--igx-button-group-disabled-text-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --disabled-background-color: var(--igx-button-group-disabled-background-color, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --item-disabled-border: var(--igx-button-group-item-disabled-border, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --border-radius: var(--igx-button-group-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --border: var(--igx-button-group-border, none);
  --shadow: var(--igx-button-group-shadow, var(--ig-elevation-2));
}

.igx-button-group {
  display: flex;
  box-shadow: var(--shadow);
  transition: all 140ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  border-radius: var(--border-radius);
}
.igx-button-group button {
  margin-top: 0;
  margin-inline-end: 0;
  margin-bottom: 0;
}

.igx-button-group__item {
  border: 0.0625rem solid var(--item-border-color);
  color: var(--item-text-color);
  background: var(--item-background);
  min-width: 1.5rem;
  display: flex;
  flex: 1 0 0%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 0;
  transition: all 140ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
[igxButton].igx-button-group__item {
  border-radius: 0;
  border-color: var(--item-border-color);
}
.igx-button-group__item:not(:nth-child(1)) {
  margin-inline-start: -0.0625rem;
}
.igx-button-group__item:first-of-type {
  border-start-start-radius: inherit;
  border-end-start-radius: inherit;
}
.igx-button-group__item:last-of-type {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}
[igxButton][disabled=true].igx-button-group__item {
  color: var(--disabled-text-color);
  background: var(--disabled-background-color);
  border-color: var(--item-disabled-border);
}
.igx-button-group__item:hover, .igx-button-group__item:focus {
  color: var(--item-hover-text-color);
  background: var(--item-hover-background);
}
.igx-button-group--vertical .igx-button-group__item:not(:nth-child(1)) {
  margin-top: -0.0625rem;
  margin-inline-start: 0;
}
.igx-button-group--vertical .igx-button-group__item:first-of-type {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}
.igx-button-group--vertical .igx-button-group__item:last-of-type {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-start-radius: inherit;
  border-end-end-radius: inherit;
}

.igx-button-group__item--selected {
  color: var(--item-selected-text-color);
  background: var(--item-selected-background);
  border-color: var(--item-selected-border-color);
  position: relative;
  z-index: 1;
}
[igxButton].igx-button-group__item--selected {
  border-color: var(--item-selected-border-color);
}
.igx-button-group__item--selected:hover, .igx-button-group__item--selected:focus {
  color: var(--item-selected-text-color);
  background: var(--item-selected-hover-background);
}
.igx-button-group__item--selected:focus {
  z-index: 3;
}
[igxButton][disabled=true].igx-button-group__item--selected {
  position: relative;
}
[igxButton][disabled=true].igx-button-group__item--selected::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  background: hsla(var(--ig-gray-500), 0.12);
}

.igx-button-group--vertical {
  flex-flow: column;
}

.igx-button-group__item-content {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  min-width: 0;
}
.igx-button-group__item-content * ~ * {
  margin-inline-start: 0.625rem;
}

.igx-button-group__button-text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

igx-banner, .igx-banner, .igx-banner--cosy, .igx-banner--compact {
  --banner-background: var(--igx-banner-banner-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --banner-message-color: var(--igx-banner-banner-message-color, var(--ig-surface-500-contrast));
  --banner-border-color: var(--igx-banner-banner-border-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --banner-illustration-color: var(--igx-banner-banner-illustration-color, var(--ig-surface-500-contrast));
}

.igx-banner-host igx-expansion-panel-body {
  padding: 0;
}

.igx-banner__actions,
.igx-banner__actions > igx-banner-actions,
.igx-banner__illustration,
.igx-banner__message {
  display: flex;
}

.igx-banner__illustration,
.igx-banner__message {
  align-items: center;
}

.igx-banner, .igx-banner--cosy, .igx-banner--compact {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  padding-inline-start: calc(1rem);
  padding-inline-end: 0.5rem;
  padding-block-start: 0.5rem;
  padding-block-end: 0.5rem;
  background: var(--banner-background);
}

.igx-banner__illustration {
  justify-content: center;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  color: var(--banner-illustration-color);
}

.igx-banner__border-top {
  border-block-start: 0.0625rem solid var(--banner-border-color);
  inset-block-start: -0.0625rem;
}

.igx-banner__border-bottom {
  border-block-end: 0.0625rem solid var(--banner-border-color);
  inset-block-start: 0;
}

.igx-banner__text {
  color: var(--banner-message-color);
  flex: 1 0 0%;
}
.igx-banner__text > * {
  margin-block-start: 0 !important;
}

.igx-banner--cosy {
  padding-inline-start: calc(1rem);
  padding-inline-end: 0.5rem;
  padding-block-start: 0.5rem;
  padding-block-end: 0.5rem;
}

.igx-banner--compact {
  padding-inline-start: calc(1rem);
  padding-inline-end: 0.5rem;
  padding-block-start: 0.5rem;
  padding-block-end: 0.5rem;
}

.igx-banner__message {
  min-width: 9.375rem;
  flex: 1 0 0%;
  gap: 1rem;
}

.igx-banner__actions > igx-banner-actions {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
  gap: 0.5rem;
  min-height: 2.5rem;
}

igx-calendar, igx-days-view, igx-months-view, igx-years-view, .igx-date-picker {
  --content-background: var(--igx-calendar-content-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --content-text-color: var(--igx-calendar-content-text-color, var(--ig-surface-500-contrast));
  --border-color: var(--igx-calendar-border-color, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --header-background: var(--igx-calendar-header-background, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --header-text-color: var(--igx-calendar-header-text-color, var(--ig-secondary-500-contrast));
  --picker-background-color: var(--igx-calendar-picker-background-color, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --picker-arrow-color: var(--igx-calendar-picker-arrow-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --picker-arrow-hover-color: var(--igx-calendar-picker-arrow-hover-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --picker-text-color: var(--igx-calendar-picker-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --picker-text-hover-color: var(--igx-calendar-picker-text-hover-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --inactive-text-color: var(--igx-calendar-inactive-text-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --label-color: var(--igx-calendar-label-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --weekend-text-color: var(--igx-calendar-weekend-text-color, var(--ig-surface-500-contrast));
  --year-current-text-color: var(--igx-calendar-year-current-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --month-current-text-color: var(--igx-calendar-month-current-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --month-hover-current-text-color: var(--igx-calendar-month-hover-current-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --year-hover-text-color: var(--igx-calendar-year-hover-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --month-hover-background: var(--igx-calendar-month-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --month-hover-text-color: var(--igx-calendar-month-hover-text-color, var(--ig-gray-200-contrast));
  --date-selected-background: var(--igx-calendar-date-selected-background, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --date-selected-hover-background: var(--igx-calendar-date-selected-hover-background, hsla(var(--ig-gray-100), 0.18));
  --date-selected-focus-background: var(--igx-calendar-date-selected-focus-background, hsla(var(--ig-gray-100), 0.18));
  --date-selected-current-background: var(--igx-calendar-date-selected-current-background, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --date-selected-current-hover-background: var(--igx-calendar-date-selected-current-hover-background, hsla(var(--ig-gray-100), 0.18));
  --date-selected-current-focus-background: var(--igx-calendar-date-selected-current-focus-background, hsla(var(--ig-gray-100), 0.18));
  --date-selected-text-color: var(--igx-calendar-date-selected-text-color, var(--ig-secondary-500-contrast));
  --date-selected-hover-foreground: var(--igx-calendar-date-selected-hover-foreground, var(--ig-gray-100-contrast));
  --date-selected-focus-foreground: var(--igx-calendar-date-selected-focus-foreground, var(--ig-gray-100-contrast));
  --date-current-text-color: var(--igx-calendar-date-current-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --date-current-hover-foreground: var(--igx-calendar-date-current-hover-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --date-current-focus-foreground: var(--igx-calendar-date-current-focus-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --date-selected-current-foreground: var(--igx-calendar-date-selected-current-foreground, var(--ig-secondary-500-contrast));
  --date-selected-current-hover-foreground: var(--igx-calendar-date-selected-current-hover-foreground, var(--ig-secondary-500-contrast));
  --date-selected-current-focus-foreground: var(--igx-calendar-date-selected-current-focus-foreground, var(--ig-gray-100-contrast));
  --date-current-bg-color: var(--igx-calendar-date-current-bg-color, transparent);
  --date-current-hover-background: var(--igx-calendar-date-current-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --date-current-focus-background: var(--igx-calendar-date-current-focus-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --date-hover-background: var(--igx-calendar-date-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --date-focus-background: var(--igx-calendar-date-focus-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --date-special-background: var(--igx-calendar-date-special-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --date-special-text-color: var(--igx-calendar-date-special-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --date-disabled-text-color: var(--igx-calendar-date-disabled-text-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --date-disabled-background: var(--igx-calendar-date-disabled-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --week-number-color: var(--igx-calendar-week-number-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --week-number-background: var(--igx-calendar-week-number-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --border-radius: var(--igx-calendar-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --date-border-radius: var(--igx-calendar-date-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1.25rem), 1.25rem));
  --month-border-radius: var(--igx-calendar-month-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1.25rem), 1.25rem));
}

.igx-calendar, .igx-calendar--vertical {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-width: 18.125rem;
  background: var(--content-background);
  outline: none;
  border-radius: var(--border-radius);
  overflow: hidden;
  border: 0 solid var(--border-color);
}
.igx-calendar + .igx-calendar, .igx-calendar--vertical + .igx-calendar, .igx-calendar + .igx-calendar--vertical, .igx-calendar--vertical + .igx-calendar--vertical {
  margin-inline-start: 1rem;
}

.igx-calendar--vertical {
  flex-flow: row nowrap;
}
.igx-calendar--vertical .igx-calendar__header {
  min-width: 10.5rem;
}

.igx-calendar__header {
  background: var(--header-background);
  color: var(--header-text-color);
  padding: 1rem;
}

.igx-calendar__header-year {
  margin: 0;
  color: currentColor;
  opacity: 0.8;
}

.igx-calendar__header-date, .igx-calendar--vertical .igx-calendar__header-date {
  display: flex;
  margin: 0;
}
.igx-calendar__header-date > span, .igx-calendar--vertical .igx-calendar__header-date > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.igx-calendar--vertical .igx-calendar__header-date {
  flex-flow: column nowrap;
}

.igx-calendar-picker {
  display: flex;
  height: 3.5rem;
  align-items: center;
  padding-bottom: 1rem;
  background: var(--picker-background-color);
  position: relative;
}
.igx-calendar-picker div {
  text-align: center;
}
.igx-calendar-picker:focus {
  outline: none;
}

.igx-calendar-picker__dates {
  display: flex;
  justify-content: center;
}

.igx-calendar-picker__prev, .igx-calendar-picker__next {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  color: var(--picker-arrow-color);
  user-select: none;
  outline: none;
  cursor: pointer;
  height: calc(100% - 1rem);
  align-items: center;
  top: 0;
}
.igx-calendar-picker__prev:focus, .igx-calendar-picker__next:focus, .igx-calendar-picker__prev:hover, .igx-calendar-picker__next:hover {
  color: var(--picker-arrow-hover-color);
}
[dir=rtl] .igx-calendar-picker__prev, [dir=rtl] .igx-calendar-picker__next {
  transform: scaleX(-1);
}

.igx-calendar-picker__prev {
  inset-inline-start: 0;
}

.igx-calendar-picker__next {
  inset-inline-end: 0;
}

.igx-calendar-picker__date {
  color: var(--picker-text-color);
  text-align: center;
  outline: none;
  padding: 0 0.125rem;
}
.igx-calendar-picker__date:hover, .igx-calendar-picker__date:focus {
  color: var(--picker-text-hover-color);
  cursor: pointer;
}

.igx-calendar__body {
  flex: 1 1 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
}

igx-years-view,
igx-month-picker,
igx-months-view {
  color: var(--content-text-color);
}
.igx-calendar__label, .igx-calendar__date, .igx-calendar__year {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--content-text-color);
  outline: none;
  min-width: 14.28%;
}
.igx-calendar__label:first-of-type, .igx-calendar__date:first-of-type, .igx-calendar__year:first-of-type {
  padding-inline-start: 0.5rem;
}
.igx-calendar__label:last-of-type, .igx-calendar__date:last-of-type, .igx-calendar__year:last-of-type {
  padding-inline-end: 0.5rem;
}
.igx-calendar__label:nth-last-child(8):first-child, .igx-calendar__date:nth-last-child(8):first-child, .igx-calendar__year:nth-last-child(8):first-child, .igx-calendar__label:nth-last-child(8):first-child ~ *, .igx-calendar__date:nth-last-child(8):first-child ~ *, .igx-calendar__year:nth-last-child(8):first-child ~ * {
  min-width: 12.5% !important;
}

.igx-calendar__body-row {
  display: flex;
  justify-content: space-between;
  margin: 0.125rem 0;
  padding: 0;
}
.igx-calendar__body-row:first-of-type {
  margin-bottom: 0;
}
.igx-calendar__body-row:nth-child(2) {
  margin-top: 0;
}
.igx-calendar__body-row:last-of-type .igx-calendar__date-content--week-number {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.igx-calendar__body-row:last-of-type .igx-calendar__date-content--week-number::before {
  display: none;
}

.igx-calendar__body-row--wrap {
  flex-wrap: wrap;
}
.igx-calendar__body-column {
  padding: 0;
  margin: 0;
}
.igx-calendar__body-column .igx-calendar__year, .igx-calendar__body-column .igx-calendar__year--current {
  flex: 1 0 0;
}

.igx-calendar__date:hover .igx-calendar__date-content::after, .igx-calendar__date--weekend:hover .igx-calendar__date-content::after {
  background: var(--date-hover-background);
}

.igx-calendar__date:focus .igx-calendar__date-content::after, .igx-calendar__date--weekend:focus .igx-calendar__date-content::after {
  background: var(--date-focus-background);
}

.igx-calendar__date--selected:hover .igx-calendar__date-content {
  color: var(--date-selected-hover-foreground);
}
.igx-calendar__date--selected:hover .igx-calendar__date-content::after {
  background: var(--date-selected-hover-background);
}

.igx-calendar__date--current .igx-calendar__date-content {
  color: var(--date-current-text-color);
  font-weight: 600;
  background: var(--date-current-bg-color);
}

.igx-calendar__date--current:hover .igx-calendar__date-content {
  color: var(--date-current-hover-foreground);
  background: var(--date-current-hover-background);
}
.igx-calendar__date--current:hover .igx-calendar__date-content::after {
  background: var(--date-current-hover-background);
}

.igx-calendar__date--current:focus .igx-calendar__date-content {
  color: var(--date-current-focus-foreground);
  background: var(--date-current-focus-background);
}
.igx-calendar__date--current:focus .igx-calendar__date-content::after {
  background: var(--date-current-focus-background);
}

.igx-calendar__date--selected.igx-calendar__date--current .igx-calendar__date-content {
  color: var(--date-selected-current-foreground);
  background: var(--date-selected-current-background);
}

.igx-calendar__date--selected.igx-calendar__date--current:hover .igx-calendar__date-content {
  color: var(--date-selected-current-hover-foreground);
}
.igx-calendar__date--selected.igx-calendar__date--current:hover .igx-calendar__date-content::after {
  background: var(--date-selected-current-hover-background);
}

.igx-calendar__date--selected.igx-calendar__date--current:focus .igx-calendar__date-content {
  color: var(--date-selected-current-focus-foreground);
}
.igx-calendar__date--selected.igx-calendar__date--current:focus .igx-calendar__date-content::after {
  background: var(--date-selected-current-focus-background);
}

.igx-calendar__date--selected:focus .igx-calendar__date-content {
  color: var(--date-selected-focus-foreground);
}
.igx-calendar__date--selected:focus .igx-calendar__date-content::after {
  background: var(--date-selected-focus-background);
}

.igx-calendar__date-content {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  border-radius: var(--date-border-radius);
  z-index: 0;
}
.igx-calendar__date-content::after {
  position: absolute;
  content: "";
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  top: 0.125rem;
  inset-inline-start: 0.125rem;
  border-radius: inherit;
  z-index: -1;
}

.igx-calendar__label {
  height: 2.5rem;
  color: var(--label-color);
  border-radius: 0;
}
.igx-calendar__label:hover, .igx-calendar__label:focus {
  color: var(--label-color);
}

.igx-calendar__date--weekend {
  color: var(--weekend-text-color);
}

.igx-calendar__date--week-number + .igx-calendar__label, .igx-calendar__date--week-number + .igx-calendar__date, .igx-calendar__date--week-number + .igx-calendar__year {
  padding-inline-start: 0 !important;
}

.igx-calendar__label--week-number,
.igx-calendar__date-content--week-number {
  position: relative;
  color: var(--week-number-color) !important;
  border-radius: 0;
  pointer-events: none;
}

.igx-calendar__date--week-number {
  padding-inline-end: 0 !important;
  cursor: default;
}
.igx-calendar__date--week-number:hover .igx-calendar__date-content--week-number, .igx-calendar__date--week-number:focus .igx-calendar__date-content--week-number {
  color: var(--week-number-color) !important;
}

.igx-calendar__date-content--week-number {
  position: relative;
  background: var(--week-number-background) !important;
  min-width: auto;
}
.igx-calendar__date-content--week-number::after {
  display: none;
}
.igx-calendar__date-content--week-number::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.25rem;
  inset-inline-start: 0;
  top: 100%;
  background: var(--week-number-background) !important;
}

.igx-calendar__label--week-number {
  padding-inline-end: 0 !important;
  text-align: center;
}
.igx-calendar__label--week-number span {
  width: 2.5rem;
  position: relative;
  background: var(--week-number-background);
}
.igx-calendar__label--week-number span::before, .igx-calendar__label--week-number span::after {
  content: "";
  position: absolute;
  width: 100%;
  background: var(--week-number-background);
  inset-inline-start: 0;
}
.igx-calendar__label--week-number span::after {
  top: 100%;
  height: 0.375rem;
}
.igx-calendar__label--week-number span::before {
  bottom: 100%;
  height: 0.5rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.igx-calendar__label--week-number + .igx-calendar__label {
  padding-inline-start: 0 !important;
}

.igx-calendar__year, .igx-calendar__year--current {
  margin: 0;
  min-width: 8ch;
  line-height: 3.25rem;
}
.igx-calendar__year:first-of-type, .igx-calendar__year--current:first-of-type {
  padding-inline-start: 0;
}
.igx-calendar__year:last-of-type, .igx-calendar__year--current:last-of-type {
  padding-inline-end: 0;
}

.igx-calendar__year--current {
  color: var(--year-current-text-color);
}

.igx-calendar__year:hover, .igx-calendar__year:focus {
  color: var(--year-hover-text-color);
}

.igx-calendar__month:hover, .igx-calendar__month:focus, .igx-calendar__month--current:hover, .igx-calendar__month--current:focus {
  color: var(--month-hover-text-color);
  outline-width: 0;
}
.igx-calendar__month:hover::after, .igx-calendar__month:focus::after, .igx-calendar__month--current:hover::after, .igx-calendar__month--current:focus::after {
  background: var(--month-hover-background);
}

.igx-calendar__month {
  position: relative;
  display: flex;
  margin: 0 0.75rem;
  flex: 1 0 25%;
  justify-content: center;
  align-items: center;
  height: 5.6875rem;
  z-index: 1;
}
.igx-calendar__month::after {
  position: absolute;
  content: "";
  top: 50%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  transform: translateY(-50%);
  height: 3rem;
  background: transparent;
  border-radius: var(--month-border-radius);
  transition: background-color 0.15s ease-out;
  z-index: -1;
}

.igx-calendar__month--current {
  color: var(--month-current-text-color);
}

.igx-calendar__month--current:hover, .igx-calendar__month--current:focus {
  color: var(--month-hover-current-text-color);
}

.igx-calendar__date--inactive {
  cursor: pointer;
  color: var(--inactive-text-color);
}

.igx-calendar__date--inactive.igx-calendar__date--single:hover {
  color: var(--inactive-text-color);
}

.igx-calendar__date--special .igx-calendar__date-content {
  background: var(--date-special-background);
  color: var(--date-special-text-color);
  font-weight: 900;
}

.igx-calendar__date--selected {
  position: relative;
  background: var(--date-selected-background);
}
.igx-calendar__date--selected .igx-calendar__date-content {
  color: var(--date-selected-text-color);
  background: var(--date-selected-background);
}

.igx-calendar__date--disabled, .igx-calendar__date--disabled.igx-calendar__date--inactive {
  pointer-events: none;
  cursor: not-allowed;
  color: var(--date-disabled-text-color);
}

.igx-calendar__date--single, .igx-calendar__date--selected.igx-calendar__date--first.igx-calendar__date--last {
  background: transparent;
}
.igx-calendar__date--single::before, .igx-calendar__date--selected.igx-calendar__date--first.igx-calendar__date--last::before, .igx-calendar__date--single::after, .igx-calendar__date--selected.igx-calendar__date--first.igx-calendar__date--last::after {
  background: transparent;
}

.igx-calendar__date--disabled.igx-calendar__date--range, .igx-calendar__date--selected.igx-calendar__date--disabled.igx-calendar__date--range {
  background: var(--date-disabled-background) !important;
}
.igx-calendar__date--disabled.igx-calendar__date--range .igx-calendar__date-content {
  color: var(--date-disabled-text-color);
  background: transparent !important;
}

.igx-calendar__date--hidden.igx-calendar__date--disabled.igx-calendar__date--range {
  background: transparent !important;
}
.igx-calendar__date--hidden.igx-calendar__date--disabled.igx-calendar__date--range::after, .igx-calendar__date--hidden.igx-calendar__date--disabled.igx-calendar__date--range::before {
  background: transparent !important;
}

.igx-calendar__date--range.igx-calendar__date--inactive.igx-calendar__date--selected .igx-calendar__date-content {
  color: var(--date-selected-text-color);
}
.igx-calendar__date--range.igx-calendar__date--inactive.igx-calendar__date--selected:focus .igx-calendar__date-content, .igx-calendar__date--range.igx-calendar__date--inactive.igx-calendar__date--selected:hover .igx-calendar__date-content {
  color: var(--date-selected-text-color);
}

.igx-calendar__date--range.igx-calendar__date--inactive.igx-calendar__date--selected.igx-calendar__date--single .igx-calendar__date-content, .igx-calendar__date--range.igx-calendar__date--inactive.igx-calendar__date--selected.igx-calendar__date--first.igx-calendar__date--last .igx-calendar__date-content {
  color: var(--date-selected-text-color);
  background: var(--date-selected-background) !important;
}

.igx-calendar__date--selected.igx-calendar__date--first {
  position: relative;
  background: transparent;
  z-index: 0;
}
.igx-calendar__date--selected.igx-calendar__date--first::after {
  position: absolute;
  content: "";
  width: 50%;
  top: 0;
  inset-inline-start: 50%;
  bottom: 0;
  background: var(--date-selected-background);
  z-index: -1;
}

.igx-calendar__date--selected.igx-calendar__date--last {
  position: relative;
  background: transparent;
  z-index: 0;
}
.igx-calendar__date--selected.igx-calendar__date--last::before {
  position: absolute;
  content: "";
  width: 50%;
  top: 0;
  inset-inline-end: 50%;
  bottom: 0;
  background: var(--date-selected-background);
  z-index: -1;
}

.igx-calendar__date--hidden .igx-calendar__date-content {
  visibility: hidden;
}

.igx-calendar__aria-off-screen {
  position: absolute !important;
  border: none !important;
  height: 1px !important;
  width: 1px !important;
  inset-inline-start: 0 !important;
  top: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
  user-select: none;
  pointer-events: none;
}
.igx-calendar__aria-off-screen:focus {
  outline: none;
}

igx-card {
  --resting-elevation: var(--igx-card-resting-elevation, var(--ig-elevation-2));
  --hover-elevation: var(--igx-card-hover-elevation, var(--ig-elevation-8));
  --background: var(--igx-card-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --header-text-color: var(--igx-card-header-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --subtitle-text-color: var(--igx-card-subtitle-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --content-text-color: var(--igx-card-content-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --actions-text-color: var(--igx-card-actions-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --outline-color: var(--igx-card-outline-color, hsla(var(--ig-gray-400), 0.54));
  --border-radius: var(--igx-card-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.1666666667) * 1.5rem), 1.5rem));
  --resting-shadow: var(--igx-card-resting-shadow, var(--ig-elevation-2));
  --hover-shadow: var(--igx-card-hover-shadow, var(--ig-elevation-8));
}

.igx-card {
  display: flex;
  flex-direction: column;
  box-shadow: var(--resting-shadow);
  overflow: hidden;
  border-radius: var(--border-radius);
  background: var(--background);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  backface-visibility: hidden;
}
.igx-card:hover {
  box-shadow: var(--hover-shadow);
}

.igx-card--outlined {
  box-shadow: none;
  border: 0.0625rem solid var(--outline-color);
}
.igx-card--outlined:hover {
  box-shadow: none;
}

.igx-card--horizontal {
  flex-direction: row;
}

.igx-card-header {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  width: 100%;
  padding: 1rem;
  color: var(--header-text-color);
}
.igx-card-header:empty {
  display: block;
  padding: 0;
}

.igx-card-header--vertical {
  flex-flow: column nowrap;
}
.igx-card-header--vertical .igx-card-header__titles {
  text-align: center;
}
.igx-card-header--vertical .igx-card-header__thumbnail {
  display: flex;
  justify-content: center;
  align-self: unset;
  margin-inline-end: 0;
  margin-bottom: 1rem;
}

.igx-card-header--compact {
  padding: 1rem;
}

.igx-card-header__thumbnail {
  align-self: flex-start;
  margin-inline-end: 1rem;
}
.igx-card-header__thumbnail:empty {
  display: none;
}

.igx-card-header__titles {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  justify-content: center;
}
.igx-card-header__titles:empty {
  display: none;
}

.igx-card-header__subtitle {
  color: var(--subtitle-text-color);
}

.igx-card__tgroup {
  margin: 0 1em;
}

.igx-card-content {
  display: block;
  width: 100%;
  padding: 0.875rem;
  color: var(--content-text-color);
  overflow: auto;
}

.igx-card__media {
  display: block;
  overflow: hidden;
  line-height: 0;
}
.igx-card__media > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.igx-card__media--right {
  width: auto;
  margin-inline-start: auto;
  order: 9999;
}

.igx-card-actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}
.igx-card-actions:first-child {
  margin-block-end: auto;
}
.igx-card-actions:last-child {
  margin-block-start: auto;
}
.igx-card-actions [igxButton] ~ [igxButton] {
  margin-inline-start: 0.5rem;
}

.igx-card-actions--vertical {
  flex-direction: column;
}
.igx-card-actions--vertical:is(:first-child, :last-child) {
  margin-block: initial;
}
[dir=rtl] .igx-card-actions--vertical {
  order: -1;
}

.igx-card-actions__end {
  display: flex;
  align-items: center;
  order: 1;
  color: var(--actions-text-color);
  margin-inline-start: auto;
}
.igx-card-actions__end:empty {
  display: none;
}

.igx-card-actions__start {
  display: flex;
  align-items: center;
  order: 0;
}
.igx-card-actions__start:empty {
  display: none;
}

.igx-card-actions--justify .igx-card-actions__start,
.igx-card-actions--justify .igx-card-actions__end {
  justify-content: space-around;
  flex-grow: 1;
}
.igx-card-actions--justify .igx-card-actions__start:empty,
.igx-card-actions--justify .igx-card-actions__end:empty {
  display: none;
}

.igx-card-actions--vertical .igx-card-actions__end,
.igx-card-actions--vertical .igx-card-actions__start {
  flex-direction: column;
}
.igx-card-actions--vertical .igx-card-actions__end [igxButton] ~ [igxButton],
.igx-card-actions--vertical .igx-card-actions__start [igxButton] ~ [igxButton] {
  margin-inline-start: 0;
  margin-top: 0.5rem;
}

.igx-card-actions--vertical .igx-card-actions__end {
  margin-top: auto;
  margin-inline-start: 0;
}

.igx-card-actions--reverse .igx-card-actions__end {
  order: 0;
  margin-inline-start: 0;
}

.igx-card-actions--reverse .igx-card-actions__start {
  order: 1;
  margin-inline-start: auto;
}

.igx-card-actions--vertical.igx-card-actions--reverse .igx-card-actions__end {
  margin: 0;
  margin-bottom: auto;
}

.igx-card-actions--vertical.igx-card-actions--reverse .igx-card-actions__start {
  margin: 0;
  margin-top: auto;
}

.igx-card-actions__bgroup {
  display: flex;
  flex-flow: row nowrap;
}
.igx-card-actions__bgroup [igxButton] ~ [igxButton] {
  margin-inline-start: 0.5rem;
}

.igx-card-actions__igroup, .igx-card-actions__igroup--start, .igx-card-actions__igroup--end {
  display: flex;
  flex-flow: row nowrap;
}
.igx-card-actions__igroup .igx-button--icon, .igx-card-actions__igroup--start .igx-button--icon, .igx-card-actions__igroup--end .igx-button--icon {
  color: var(--actions-text-color);
}

.igx-card-actions__igroup--start {
  margin-inline-end: auto;
}

.igx-card-actions__igroup--end {
  margin-inline-start: auto;
}

igx-carousel {
  --button-elevation: var(--igx-carousel-button-elevation, var(--ig-elevation-1));
  --slide-background: var(--igx-carousel-slide-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --button-background: var(--igx-carousel-button-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --button-hover-background: var(--igx-carousel-button-hover-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --button-arrow-color: var(--igx-carousel-button-arrow-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --button-hover-arrow-color: var(--igx-carousel-button-hover-arrow-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --button-disabled-background: var(--igx-carousel-button-disabled-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --button-disabled-arrow-color: var(--igx-carousel-button-disabled-arrow-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --indicator-dot-color: var(--igx-carousel-indicator-dot-color, transparent);
  --indicator-border-color: var(--igx-carousel-indicator-border-color, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --indicator-active-dot-color: var(--igx-carousel-indicator-active-dot-color, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --indicator-active-border-color: var(--igx-carousel-indicator-active-border-color, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --border-radius: var(--igx-carousel-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0) * 2.25rem), 2.25rem));
  --button-shadow: var(--igx-carousel-button-shadow, var(--ig-elevation-1));
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 1;
  }
}
.igx-carousel {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-flow: column nowrap;
}

.igx-nav-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.875rem;
  height: 2.875rem;
  cursor: pointer;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 50%), 50%);
  outline-style: none;
  transition: all 0.15s ease-in-out;
  color: var(--button-arrow-color);
  background: var(--button-background);
  box-shadow: var(--button-shadow);
}

.igx-nav-arrow--disabled {
  background: var(--button-disabled-background);
  color: var(--button-disabled-arrow-color);
  pointer-events: none;
  box-shadow: none;
}
.igx-nav-arrow--disabled igx-icon {
  color: currentColor;
}

.igx-nav-arrow:hover {
  color: var(--button-hover-arrow-color);
  background: var(--button-hover-background);
}

.igx-carousel__arrow, .igx-carousel__arrow--prev, .igx-carousel__arrow--next {
  margin: 0 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  outline: none;
  user-select: none;
}

.igx-carousel__arrow--next {
  inset-inline-end: 0;
}

.igx-carousel__arrow--prev {
  inset-inline-start: 0;
}

[dir=rtl] .igx-carousel__arrow--next,
[dir=rtl] .igx-carousel__arrow--prev {
  transform: scaleX(-1);
}

.igx-carousel-indicators, .igx-carousel-indicators--top, .igx-carousel-indicators--bottom {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  padding: 0;
  list-style: none;
  z-index: 10;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}
[dir=rtl] .igx-carousel-indicators, [dir=rtl] .igx-carousel-indicators--top, [dir=rtl] .igx-carousel-indicators--bottom {
  transform: translateX(50%);
}

.igx-carousel-indicators--bottom {
  bottom: 0;
}

.igx-carousel-indicators--top {
  top: 0;
}

.igx-carousel__label {
  align-items: center;
  font-size: 1rem;
}

.igx-carousel-indicators__indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.3125rem;
  cursor: pointer;
}

.igx-nav-dot {
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  text-indent: -624.9375rem;
  border: 0.125rem solid;
  border-color: var(--indicator-border-color);
  box-shadow: var(--ig-elevation-1);
  opacity: 0.7;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 50%), 50%);
  transition: all 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.igx-nav-dot::after {
  animation: "scale-out-center" 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  content: "";
  position: absolute;
  inset: 0.0625rem;
  border-radius: inherit;
  background: var(--indicator-dot-color);
}
.igx-nav-dot:hover {
  opacity: 1;
}

.igx-nav-dot--active {
  border: 0.125rem solid;
  border-color: var(--indicator-active-border-color);
  opacity: 1;
}
.igx-nav-dot--active::after {
  background: var(--indicator-active-dot-color);
  animation: "scale-in-center" 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.igx-carousel__inner {
  position: relative;
  width: 100%;
  height: inherit;
  overflow: hidden;
  outline-style: none;
  border-radius: var(--border-radius);
  min-height: 18.75rem;
  min-width: 18.75rem;
}

.igx-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: -1;
  background: var(--slide-background);
  visibility: hidden;
}

.igx-slide--previous {
  z-index: 1;
  visibility: visible;
}

.igx-slide--current {
  z-index: 2;
  visibility: visible;
}

.igx-slide img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  touch-action: none;
  pointer-events: none;
}

igx-splitter {
  --bar-color: var(--igx-splitter-bar-color, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --focus-color: var(--igx-splitter-focus-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --handle-color: var(--igx-splitter-handle-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --expander-color: var(--igx-splitter-expander-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --size: var(--igx-splitter-size, 4px);
  --border-radius: var(--igx-splitter-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.125rem), 0.125rem));
}

[dir=rtl] [aria-orientation=horizontal].igx-splitter {
  flex-direction: row-reverse !important;
}

.igx-splitter-bar::after, .igx-splitter-bar::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0.25rem;
  background: transparent;
}

.igx-splitter-bar--vertical::after, .igx-splitter-bar--vertical::before {
  width: 0.25rem;
  height: 100%;
}

.igx-splitter-bar {
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background: var(--bar-color);
  border: 0.0625rem solid var(--bar-color);
  z-index: 99;
  opacity: 0.68;
  transition: opacity 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) !important;
}
.igx-splitter-bar::before {
  top: 100%;
}
.igx-splitter-bar::after {
  bottom: 100%;
}
.igx-splitter-bar:hover {
  transition: all 0.25s ease-out;
  opacity: 1;
}

.igx-splitter-bar-host:focus {
  outline: transparent solid 0.0625rem;
  box-shadow: inset 0 0 0 0.0625rem var(--focus-color);
}

.igx-splitter-bar--vertical {
  flex-direction: column;
  height: 100%;
}
.igx-splitter-bar--vertical::before {
  top: 0;
  right: 100%;
}
.igx-splitter-bar--vertical::after {
  top: 0;
  left: 100%;
}

.igx-splitter-bar__handle, .igx-splitter-bar--vertical .igx-splitter-bar__handle {
  background: var(--handle-color);
  border-radius: var(--border-radius);
}

.igx-splitter-bar__handle {
  width: 25%;
  height: var(--size);
  margin: 0 3rem;
}

.igx-splitter-bar--vertical .igx-splitter-bar__handle {
  width: var(--size);
  height: 25%;
  margin: 3rem 0;
}

.igx-splitter-bar--vertical .igx-splitter-bar__expander--end::before, .igx-splitter-bar--vertical .igx-splitter-bar__expander--start::before, .igx-splitter-bar__expander--end::before, .igx-splitter-bar__expander--start::before {
  position: absolute;
  content: "";
  background: transparent;
}

.igx-splitter-bar__expander--start, .igx-splitter-bar__expander--end, .igx-splitter-bar--vertical .igx-splitter-bar__expander--start, .igx-splitter-bar--vertical .igx-splitter-bar__expander--end {
  position: relative;
  width: 0;
  height: 0;
  border-inline-end: var(--size) solid transparent;
  border-inline-start: var(--size) solid transparent;
  cursor: pointer;
  z-index: 1;
}

.igx-splitter-bar__expander--start {
  border-bottom: var(--size) solid var(--expander-color);
}
.igx-splitter-bar__expander--start::before {
  top: calc(100% - 4px);
  left: calc(100% - (4px * 2));
  width: calc(4px * 4);
  height: calc(4px * 3);
}

.igx-splitter-bar__expander--end {
  border-bottom: unset;
  border-top: var(--size) solid var(--expander-color);
}
.igx-splitter-bar__expander--end::before {
  top: calc(100% - (4px * 2));
  left: calc(100% - (4px * 2));
  width: calc(4px * 4);
  height: calc(4px * 3);
}

.igx-splitter-bar--vertical .igx-splitter-bar__expander--start {
  border-top: var(--size) solid transparent;
  border-inline-end: var(--size) solid var(--expander-color);
  border-bottom: var(--size) solid transparent;
  border-inline-start: unset;
}
.igx-splitter-bar--vertical .igx-splitter-bar__expander--start::before {
  top: calc(100% - (4px * 2));
  left: calc(100% - (4px * 2));
  width: calc(4px * 3);
  height: calc(4px * 4);
}

.igx-splitter-bar--vertical .igx-splitter-bar__expander--end {
  border-top: var(--size) solid transparent;
  border-inline-end: unset;
  border-bottom: var(--size) solid transparent;
  border-inline-start: var(--size) solid var(--expander-color);
}
.igx-splitter-bar--vertical .igx-splitter-bar__expander--end::before {
  left: calc(100% - (4px * 2));
  top: calc(100% - (4px * 2));
  height: calc(4px * 4);
  width: calc(4px * 3);
}

igx-data-chart {
  --title-text-color: var(--data-chart-title-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --subtitle-text-color: var(--data-chart-subtitle-text-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --brushes: var(--data-chart-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --marker-brushes: var(--data-chart-marker-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --outlines: var(--data-chart-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --marker-outlines: var(--data-chart-marker-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --plot-area-background: var(--data-chart-plot-area-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
}

igx-doughnut-chart {
  --brushes: var(--ring-series-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --outlines: var(--ring-series-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
}

igx-linear-gauge {
  --backing-brush: var(--linear-gauge-backing-brush, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --backing-outline: var(--linear-gauge-backing-outline, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --font-brush: var(--linear-gauge-font-brush, var(--ig-surface-500-contrast));
  --minor-tick-brush: var(--linear-gauge-minor-tick-brush, var(--ig-surface-500-contrast));
  --needle-brush: var(--linear-gauge-needle-brush, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --needle-outline: var(--linear-gauge-needle-outline, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --range-brushes: var(--linear-gauge-range-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --range-outlines: var(--linear-gauge-range-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --scale-brush: var(--linear-gauge-scale-brush, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --tick-brush: var(--linear-gauge-tick-brush, var(--ig-surface-500-contrast));
  --scale-outline: var(--linear-gauge-scale-outline, hsla(var(--ig-surface-500), var(--ig-surface-a)));
}

igx-radial-gauge {
  --backing-brush: var(--radial-gauge-backing-brush, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --backing-outline: var(--radial-gauge-backing-outline, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --font-brush: var(--radial-gauge-font-brush, var(--ig-surface-500-contrast));
  --minor-tick-brush: var(--radial-gauge-minor-tick-brush, var(--ig-surface-500-contrast));
  --needle-brush: var(--radial-gauge-needle-brush, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --needle-outline: var(--radial-gauge-needle-outline, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --range-brushes: var(--radial-gauge-range-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --range-outlines: var(--radial-gauge-range-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --scale-brush: var(--radial-gauge-scale-brush, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --tick-brush: var(--radial-gauge-tick-brush, var(--ig-surface-500-contrast));
  --needle-pivot-brush: var(--radial-gauge-needle-pivot-brush, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --needle-pivot-outline: var(--radial-gauge-needle-pivot-outline, hsla(var(--ig-surface-500), var(--ig-surface-a)));
}

igx-financial-chart {
  --brushes: var(--financial-chart-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --outlines: var(--financial-chart-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --marker-brushes: var(--financial-chart-marker-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --marker-outlines: var(--financial-chart-marker-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --indicator-brushes: var(--financial-chart-indicator-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --indicator-negative-brushes: var(--financial-chart-indicator-negative-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --negative-brushes: var(--financial-chart-negative-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --negative-outlines: var(--financial-chart-negative-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --overlay-brushes: var(--financial-chart-overlay-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --overlay-outlines: var(--financial-chart-overlay-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --overlay-thickness: var(--financial-chart-overlay-thickness, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --trend-line-brushes: var(--financial-chart-trend-line-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --volume-brushes: var(--financial-chart-volume-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --volume-outlines: var(--financial-chart-volume-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
}

igx-bullet-graph {
  --backing-brush: var(--bullet-graph-backing-brush, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --backing-outline: var(--bullet-graph-backing-outline, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --font-brush: var(--bullet-graph-font-brush, var(--ig-surface-500-contrast));
  --minor-tick-brush: var(--bullet-graph-minor-tick-brush, var(--ig-surface-500-contrast));
  --range-brushes: var(--bullet-graph-range-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --range-outlines: var(--bullet-graph-range-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --scale-background-brush: var(--bullet-graph-scale-background-brush, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --scale-background-outline: var(--bullet-graph-scale-background-outline, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --target-value-brush: var(--bullet-graph-target-value-brush, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --tick-brush: var(--bullet-graph-tick-brush, var(--ig-surface-500-contrast));
  --value-brush: var(--bullet-graph-value-brush, hsla(var(--ig-gray-800), var(--ig-gray-a)));
}

igx-category-chart {
  --brushes: var(--category-chart-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --marker-brushes: var(--category-chart-marker-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --negative-brushes: var(--category-chart-negative-brushes, hsl(347, 82%, 64%));
  --outlines: var(--category-chart-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --marker-outlines: var(--category-chart-marker-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --negative-outlines: var(--category-chart-negative-outlines, hsl(347, 82%, 64%));
  --title-text-color: var(--category-chart-title-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --subtitle-text-color: var(--category-chart-subtitle-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --trend-line-brushes: var(--category-chart-trend-line-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --x-axis-label-text-color: var(--category-chart-x-axis-label-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --x-axis-stroke: var(--category-chart-x-axis-stroke, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --x-axis-tick-stroke: var(--category-chart-x-axis-tick-stroke, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --x-axis-title-text-color: var(--category-chart-x-axis-title-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --y-axis-label-text-color: var(--category-chart-y-axis-label-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --y-axis-major-stroke: var(--category-chart-y-axis-major-stroke, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --y-axis-title-text-color: var(--category-chart-y-axis-title-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --y-axis-tick-stroke: var(--category-chart-y-axis-tick-stroke, hsla(var(--ig-gray-500), var(--ig-gray-a)));
}

igx-geographic-map {
  --plot-area-background: var(--geo-map-plot-area-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
}

igx-pie-chart {
  --brushes: var(--pie-chart-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --outlines: var(--pie-chart-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --label-extent: var(--pie-chart-label-extent, 30);
  --label-outer-color: var(--pie-chart-label-outer-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
}

igx-sparkline {
  --brush: var(--sparkline-brush, hsla(var(--ig-primary-500), var(--ig-primary-a)));
}

igx-funnel-chart {
  --brushes: var(--funnel-chart-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --outlines: var(--funnel-chart-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
}

igx-shape-chart {
  --title-text-color: var(--shape-chart-title-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --subtitle-text-color: var(--shape-chart-subtitle-text-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --brushes: var(--shape-chart-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --marker-brushes: var(--shape-chart-marker-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --outlines: var(--shape-chart-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --marker-outlines: var(--shape-chart-marker-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --trend-line-brushes: var(--shape-chart-trend-line-brushes, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
}

igx-checkbox {
  --tick-color: var(--igx-checkbox-tick-color, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --tick-color-hover: var(--igx-checkbox-tick-color-hover, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --label-color: var(--igx-checkbox-label-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --empty-color: var(--igx-checkbox-empty-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --fill-color: var(--igx-checkbox-fill-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --fill-color-hover: var(--igx-checkbox-fill-color-hover, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --disabled-color: var(--igx-checkbox-disabled-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --disabled-indeterminate-color: var(--igx-checkbox-disabled-indeterminate-color, hsla(var(--ig-secondary-100), var(--ig-secondary-a)));
  --disabled-color-label: var(--igx-checkbox-disabled-color-label, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --error-color: var(--igx-checkbox-error-color, hsla(var(--ig-error-500), var(--ig-error-a)));
  --error-color-hover: var(--igx-checkbox-error-color-hover, hsla(var(--ig-error-500), var(--ig-error-a)));
  --border-radius: var(--igx-checkbox-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 0.625rem), 0.625rem));
  --border-radius-ripple: var(--igx-checkbox-border-radius-ripple, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1.5rem), 1.5rem));
}

@keyframes scale-in-out {
  50% {
    transform: scale(0.9);
    transform-origin: 50% 50%;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 50%;
  }
}
.igx-checkbox {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  outline-style: none;
}

.igx-checkbox--disabled {
  user-select: none;
  pointer-events: none;
  cursor: initial;
}

.igx-checkbox__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: none;
  clip: rect(0, 0, 0, 0);
  outline: 0;
  pointer-events: none;
  overflow: hidden;
  appearance: none;
}

.igx-checkbox__composite-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
}

.igx-checkbox__composite {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--empty-color);
  border-radius: var(--border-radius);
  -webkit-tap-highlight-color: transparent;
  transition: border-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: hidden;
}
.igx-checkbox__composite::after {
  position: absolute;
  content: "";
  inset: 0;
  transition: background 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.igx-checkbox--indeterminate .igx-checkbox__composite, .igx-checkbox--checked .igx-checkbox__composite {
  border-color: var(--fill-color);
}
.igx-checkbox--indeterminate .igx-checkbox__composite::after, .igx-checkbox--checked .igx-checkbox__composite::after {
  background: var(--fill-color);
}

.igx-checkbox--invalid .igx-checkbox__composite {
  border-color: var(--error-color);
}

.igx-checkbox--invalid.igx-checkbox--checked .igx-checkbox__composite {
  border-color: var(--error-color);
}
.igx-checkbox--invalid.igx-checkbox--checked .igx-checkbox__composite::after {
  background: var(--error-color);
}

.igx-checkbox--disabled .igx-checkbox__composite {
  border-color: var(--disabled-color);
  background: transparent;
}

.igx-checkbox--indeterminate.igx-checkbox--disabled .igx-checkbox__composite, .igx-checkbox--checked.igx-checkbox--disabled .igx-checkbox__composite {
  background: var(--disabled-color);
}
.igx-checkbox--indeterminate.igx-checkbox--disabled .igx-checkbox__composite::after, .igx-checkbox--checked.igx-checkbox--disabled .igx-checkbox__composite::after {
  background: var(--disabled-color);
}

.igx-checkbox__composite-mark {
  position: absolute;
  inset: 0;
  stroke: var(--tick-color);
  stroke-linecap: square;
  stroke-width: 3;
  stroke-dasharray: 24;
  stroke-dashoffset: 24;
  fill: none;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.igx-checkbox--indeterminate .igx-checkbox__composite-mark {
  top: 0;
  inset-inline-start: 0;
}
.igx-checkbox--indeterminate.igx-checkbox--disabled .igx-checkbox__composite, .igx-checkbox--indeterminate .igx-checkbox--checked.igx-checkbox--disabled .igx-checkbox__composite, .igx-checkbox--checked.igx-checkbox--disabled .igx-checkbox--indeterminate .igx-checkbox__composite {
  border: var(--disabled-indeterminate-color);
}
.igx-checkbox--indeterminate.igx-checkbox--disabled .igx-checkbox__composite::after, .igx-checkbox--indeterminate .igx-checkbox--checked.igx-checkbox--disabled .igx-checkbox__composite::after, .igx-checkbox--checked.igx-checkbox--disabled .igx-checkbox--indeterminate .igx-checkbox__composite::after {
  background: var(--disabled-indeterminate-color);
}

.igx-checkbox--invalid.igx-checkbox--indeterminate .igx-checkbox__composite::after {
  background: var(--error-color);
}

.igx-checkbox--checked .igx-checkbox__composite-mark {
  stroke-dashoffset: 0;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.igx-checkbox--indeterminate .igx-checkbox__composite-mark {
  stroke-dashoffset: 41; /* length of path - adjacent line length */
  opacity: 1;
  transform: rotate(45deg) translateX(-0.125em);
}

.igx-checkbox__label, .igx-checkbox__label--before {
  display: inline-block;
  color: var(--label-color);
  cursor: pointer;
  user-select: none;
  word-wrap: break-all;
}
.igx-checkbox__label:empty, .igx-checkbox__label--before:empty {
  margin: 0;
}

.igx-checkbox__label--before:empty,
.igx-checkbox__label:empty {
  margin: 0;
}

.igx-checkbox__label {
  margin-inline-start: 0.5rem;
}

.igx-checkbox__label--before {
  margin-inline-end: 0.5rem;
  order: -1;
}

.igx-checkbox--invalid .igx-checkbox__label {
  color: var(--error-color);
}

.igx-checkbox--disabled .igx-checkbox__label, .igx-checkbox--disabled .igx-checkbox__label--before {
  color: var(--disabled-color-label);
}

.igx-checkbox__ripple {
  display: block;
  position: absolute;
  top: calc(50% - 1.25rem);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: var(--border-radius-ripple);
  overflow: hidden;
  pointer-events: none;
  filter: opacity(1);
}
.igx-checkbox__ripple,
.igx-checkbox__ripple [igxRipple] {
  --color: var(--igx-ripple-color, var(--fill-color));
}
.igx-checkbox__ripple .igx-ripple__inner {
  display: block;
  position: absolute;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 50%), 50%);
  background: var(--color);
  pointer-events: none;
  transform-origin: center;
  transform: translate3d(0, 0, 0) scale(0);
  will-change: opacity, transform;
  opacity: 0.5;
  margin: 0 !important;
  border: none !important;
}
.igx-checkbox__ripple .igx-ripple {
  overflow: hidden;
}
.igx-checkbox__ripple,
.igx-checkbox__ripple [igxRipple] {
  --color: var(--igx-ripple-color, var(--fill-color));
}

.igx-checkbox:hover .igx-checkbox__ripple, .igx-checkbox:active .igx-checkbox__ripple, .igx-checkbox--invalid:hover .igx-checkbox__ripple, .igx-checkbox--indeterminate:hover .igx-checkbox__ripple, .igx-checkbox--indeterminate:active .igx-checkbox__ripple, .igx-checkbox--checked:hover .igx-checkbox__ripple, .igx-checkbox--checked:active .igx-checkbox__ripple {
  background: var(--empty-color);
  transition: background 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0.06;
}

.igx-checkbox--indeterminate:hover .igx-checkbox__ripple, .igx-checkbox--indeterminate:active .igx-checkbox__ripple, .igx-checkbox--checked:hover .igx-checkbox__ripple, .igx-checkbox--checked:active .igx-checkbox__ripple {
  background: var(--fill-color);
}

.igx-checkbox--invalid:hover .igx-checkbox__ripple, .igx-checkbox--invalid:active .igx-checkbox__ripple {
  background: var(--error-color);
}

.igx-checkbox--focused .igx-checkbox__ripple, .igx-checkbox--focused:hover .igx-checkbox__ripple, .igx-checkbox--focused.igx-checkbox--checked .igx-checkbox__ripple, .igx-checkbox--focused.igx-checkbox--indeterminate .igx-checkbox__ripple {
  background: var(--empty-color);
  transition: background 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0.12;
}

.igx-checkbox--focused.igx-checkbox--checked .igx-checkbox__ripple, .igx-checkbox--focused.igx-checkbox--indeterminate .igx-checkbox__ripple {
  background: var(--fill-color);
}

.igx-checkbox--focused.igx-checkbox--invalid .igx-checkbox__ripple {
  background: var(--error-color);
}

.igx-checkbox:active .igx-checkbox__ripple, .igx-checkbox--indeterminate:active .igx-checkbox__ripple, .igx-checkbox--checked:active .igx-checkbox__ripple {
  opacity: 0.12;
}

.igx-checkbox--plain .igx-checkbox__composite,
.igx-checkbox--plain .igx-checkbox__composite::after,
.igx-checkbox--plain .igx-checkbox__composite-mark {
  transition: none;
}

igx-chip, .igx-chip__ghost, .igx-chip__ghost--compact, .igx-chip__ghost--cosy {
  --ghost-elevation: var(--igx-chip-ghost-elevation, var(--ig-elevation-8));
  --remove-icon-color: var(--igx-chip-remove-icon-color, 'currentColor');
  --focus-outline-color: var(--igx-chip-focus-outline-color, transparent);
  --focus-selected-outline-color: var(--igx-chip-focus-selected-outline-color, transparent);
  --remove-icon-color-focus: var(--igx-chip-remove-icon-color-focus, hsla(var(--ig-error-500), var(--ig-error-a)));
  --text-color: var(--igx-chip-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --background: var(--igx-chip-background, hsla(var(--ig-gray-400), 0.38));
  --border-color: var(--igx-chip-border-color, transparent);
  --ghost-background: var(--igx-chip-ghost-background, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --hover-text-color: var(--igx-chip-hover-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --hover-background: var(--igx-chip-hover-background, hsla(var(--ig-gray-400), 0.54));
  --hover-border-color: var(--igx-chip-hover-border-color, transparent);
  --focus-text-color: var(--igx-chip-focus-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --focus-background: var(--igx-chip-focus-background, hsla(var(--ig-gray-600), 0.54));
  --focus-border-color: var(--igx-chip-focus-border-color, transparent);
  --selected-text-color: var(--igx-chip-selected-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --selected-background: var(--igx-chip-selected-background, hsla(var(--ig-gray-600), 0.38));
  --selected-border-color: var(--igx-chip-selected-border-color, transparent);
  --hover-selected-text-color: var(--igx-chip-hover-selected-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --hover-selected-background: var(--igx-chip-hover-selected-background, hsla(var(--ig-gray-400), 0.54));
  --hover-selected-border-color: var(--igx-chip-hover-selected-border-color, transparent);
  --focus-selected-text-color: var(--igx-chip-focus-selected-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --focus-selected-background: var(--igx-chip-focus-selected-background, hsla(var(--ig-gray-600), 0.54));
  --focus-selected-border-color: var(--igx-chip-focus-selected-border-color, transparent);
  --border-radius: var(--igx-chip-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1rem), 1rem));
  --ghost-shadow: var(--igx-chip-ghost-shadow, var(--ig-elevation-8));
}

.igx-chip-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.igx-chip-area:empty {
  display: none;
}

.igx-chip {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  transition: all 120ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-property: top, left;
  touch-action: none;
}
[dir=rtl] .igx-chip {
  transition-property: top right;
}
.igx-chip:focus {
  outline-style: none;
}
.igx-chip--disabled .igx-chip__item {
  cursor: default;
  pointer-events: none;
}

.igx-chip igx-icon {
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
  outline-style: none;
}

.igx-chip--cosy igx-icon {
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
  outline-style: none;
}

.igx-chip__ghost--compact igx-icon, .igx-chip--compact igx-icon {
  width: var(--igx-icon-size, 1rem);
  height: var(--igx-icon-size, 1rem);
  font-size: var(--igx-icon-size, 1rem);
  outline-style: none;
}

.igx-chip igx-prefix,
.igx-chip [igxPrefix],
.igx-chip igx-suffix,
.igx-chip [igxSuffix] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  max-width: 32ch;
}
.igx-chip igx-prefix > igx-icon,
.igx-chip [igxPrefix] > igx-icon,
.igx-chip igx-suffix > igx-icon,
.igx-chip [igxSuffix] > igx-icon {
  display: block;
}

.igx-chip igx-suffix + igx-suffix,
.igx-chip [igxSuffix] + igx-suffix,
.igx-chip igx-suffix + [igxSuffix],
.igx-chip [igxSuffix] + [igxSuffix],
.igx-chip igx-suffix + .igx-chip__remove,
.igx-chip [igxSuffix] + .igx-chip__remove {
  margin-inline-start: 0.25rem;
}

.igx-chip igx-prefix + igx-prefix,
.igx-chip [igxPrefix] + igx-prefix,
.igx-chip igx-prefix + [igxPrefix],
.igx-chip [igxPrefix] + [igxPrefix] {
  margin-inline-start: 0.25rem;
}
.igx-chip igx-prefix + igx-prefix igx-avatar,
.igx-chip [igxPrefix] + igx-prefix igx-avatar,
.igx-chip igx-prefix + [igxPrefix] igx-avatar,
.igx-chip [igxPrefix] + [igxPrefix] igx-avatar {
  max-height: 100%;
  max-width: 100%;
  margin-inline-start: 0 !important;
}

.igx-chip__content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 0.5rem;
  max-width: 32ch;
}
.igx-chip__content:empty {
  display: none;
}

.igx-chip--cosy .igx-chip__content {
  padding: 0 0.25rem;
}

.igx-chip--compact .igx-chip__content {
  padding: 0 0.125rem;
}

.igx-chip__remove {
  display: inline-flex;
  margin-inline-end: 0.25rem;
  color: var(--remove-icon-color, currentColor);
}
.igx-chip__remove:empty {
  display: none;
}
.igx-chip__remove:focus {
  outline-style: none;
}
.igx-chip__remove:focus igx-icon {
  outline-style: none;
  color: var(--remove-icon-color-focus);
}
.igx-chip__remove igx-icon:focus {
  outline-style: none;
}

.igx-chip__select, .igx-chip__select--hidden {
  display: inline-flex;
  align-items: center;
  width: 1.125rem;
  opacity: 1;
  z-index: 1;
  transition: opacity 120ms cubic-bezier(0.25, 0.46, 0.45, 0.94), width 120ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.igx-chip__select > *, .igx-chip__select--hidden > * {
  width: inherit !important;
}
.igx-chip__select + igx-prefix, .igx-chip__select--hidden + igx-prefix,
.igx-chip__select + [igxPrefix],
.igx-chip__select--hidden + [igxPrefix] {
  margin-inline-start: 0.25rem;
}

.igx-chip__select--hidden {
  width: 0;
  opacity: 0;
  z-index: -1;
}
.igx-chip__select--hidden + igx-prefix,
.igx-chip__select--hidden + [igxPrefix] {
  margin-inline-start: 0;
}

.igx-chip__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  height: 2rem;
  padding: 0 0.25rem;
  color: var(--text-color);
  background: var(--background);
  border-width: 0.0625rem;
  border-style: solid;
  border-color: var(--border-color);
  border-radius: var(--border-radius);
  user-select: none;
  overflow: hidden;
  cursor: pointer;
  filter: opacity(1);
}

.igx-chip:hover .igx-chip__item {
  color: var(--hover-text-color);
  background: var(--hover-background);
  border-color: var(--hover-border-color);
  transition: all 120ms ease-in;
}

.igx-chip:focus .igx-chip__item {
  color: var(--focus-text-color);
  background: var(--focus-background);
  border-color: var(--focus-border-color);
  outline-style: none;
}

.igx-chip__item--selected {
  color: var(--selected-text-color);
  background: var(--selected-background);
  border-color: var(--selected-border-color);
}

.igx-chip:hover .igx-chip__item--selected {
  color: var(--hover-selected-text-color);
  background: var(--hover-selected-background);
  border-color: var(--hover-selected-border-color);
  transition: all 120ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.igx-chip:focus .igx-chip__item--selected {
  color: var(--focus-selected-text-color);
  background: var(--focus-selected-background);
  border-color: var(--focus-selected-border-color);
  transition: all 120ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.igx-chip--cosy .igx-chip__item {
  height: 1.5rem;
  padding: 0 0.25rem;
}

.igx-chip--compact .igx-chip__item {
  height: 1.125rem;
  padding: 0 0.125rem;
}

.igx-chip__ghost {
  position: absolute;
  box-shadow: var(--ghost-shadow);
  overflow: hidden;
  color: var(--focus-text-color);
  background: var(--ghost-background);
}
.igx-chip__ghost:hover, .igx-chip__ghost:focus {
  background: var(--ghost-background);
}

.igx-chip__ghost--cosy {
  height: 1.5rem;
}
.igx-chip__ghost--cosy .igx-chip__content {
  padding: 0 0.25rem;
}

.igx-chip__ghost--compact {
  height: 1.125rem;
}
.igx-chip__ghost--compact .igx-chip__content {
  padding: 0 0.125rem;
}

.igx-chip__ghost,
.igx-chip__ghost--cosy,
.igx-chip__ghost--compact {
  z-index: 10;
}

igx-column-actions {
  --background-color: var(--igx-column-actions-background-color, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --title-color: var(--igx-column-actions-title-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
}

.igx-column-actions {
  display: flex;
  flex-flow: column nowrap;
  background: var(--background-color);
  box-shadow: var(--ig-elevation-8);
  width: 100%;
  flex: 1 1 auto;
  min-width: 11.25rem;
}

.igx-column-actions__header-title {
  color: var(--title-color);
  margin: 0;
  padding: 1rem 1rem 0.5rem;
}

.igx-column-actions__header-input {
  font-size: 1rem !important;
  margin: -1rem 0 0 !important;
  padding: 0.5rem 1rem;
}

.igx-column-actions__columns {
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  outline-style: none;
}

.igx-column-actions__columns-item {
  padding: 0.25rem 1rem;
  min-height: 2rem;
}

.igx-column-actions__buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
}

igx-combo, igx-simple-combo {
  --toggle-button-background-focus--border: var(--igx-combo-toggle-button-background-focus--border, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --case-icon-color: var(--igx-combo-case-icon-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --case-icon-color--active: var(--igx-combo-case-icon-color--active, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --empty-list-background: var(--igx-combo-empty-list-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --search-separator-border-color: var(--igx-combo-search-separator-border-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --empty-list-placeholder-color: var(--igx-combo-empty-list-placeholder-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --toggle-button-background: var(--igx-combo-toggle-button-background, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --toggle-button-background-focus: var(--igx-combo-toggle-button-background-focus, hsla(var(--ig-gray-400), 0.3));
  --toggle-button-foreground: var(--igx-combo-toggle-button-foreground, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --toggle-button-foreground-focus: var(--igx-combo-toggle-button-foreground-focus, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --toggle-button-foreground-filled: var(--igx-combo-toggle-button-foreground-filled, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --clear-button-background: var(--igx-combo-clear-button-background, transparent);
  --clear-button-background-focus: var(--igx-combo-clear-button-background-focus, transparent);
  --clear-button-foreground: var(--igx-combo-clear-button-foreground, var(--ig-gray-100-contrast));
  --clear-button-foreground-focus: var(--igx-combo-clear-button-foreground-focus, var(--ig-gray-100-contrast));
  --toggle-button-background-disabled: var(--igx-combo-toggle-button-background-disabled, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --toggle-button-foreground-disabled: var(--igx-combo-toggle-button-foreground-disabled, hsla(var(--ig-gray-500), var(--ig-gray-a)));
}

.igx-combo {
  position: relative;
  display: block;
}

.igx-combo__checkbox {
  margin-inline-end: 0.5rem;
}

.igx-combo__drop-down {
  position: absolute;
  width: 100%;
}
.igx-combo__drop-down .igx-drop-down {
  width: 100%;
}

.igx-combo__search {
  padding-inline: max(var(--is-large, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
  padding-block: max(var(--is-large, 1) * max(0.5rem, -1 * 0.5rem) * var(--ig-spacing-block-large, var(--ig-spacing-block, --ig-spacing)), var(--is-medium, 1) * max(0.5rem, -1 * 0.5rem) * var(--ig-spacing-block-medium, var(--ig-spacing-block, --ig-spacing)), var(--is-small, 1) * max(0.5rem, -1 * 0.5rem) * var(--ig-spacing-block-small, var(--ig-spacing-block, --ig-spacing)));
  margin: 0 !important;
  z-index: 26;
  border-bottom: 0.0625rem dashed var(--search-separator-border-color);
}
.igx-combo__search .igx-input-group__bundle {
  padding-block-start: 0;
  height: auto;
}
.igx-combo__search igx-input-group {
  --ig-size: var(--ig-size-small);
}

.igx-combo__case-icon .igx-icon,
.igx-combo__case-icon--active .igx-icon {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

.igx-combo__case-icon .igx-icon {
  color: hsla(var(--ig-gray-600), var(--ig-gray-a)) !important;
}

.igx-combo__case-icon--active .igx-icon {
  color: hsla(var(--ig-primary-500), var(--ig-primary-a));
}

.igx-combo__content {
  position: relative;
  overflow: hidden;
}
.igx-combo__content:focus {
  outline: transparent;
}

.igx-combo__add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem;
  gap: 1rem;
  background: var(--empty-list-background);
}

.igx-combo__add-item {
  height: auto !important;
  background: var(--empty-list-background) !important;
  justify-content: center !important;
}

.igx-combo__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--empty-list-placeholder-color);
  padding: 0 1.5rem;
  font-size: 0.8125rem;
}

.igx-input-group .igx-combo__toggle-button {
  background: var(--toggle-button-background);
  color: var(--toggle-button-foreground);
}
.igx-input-group:not(.igx-input-group--disabled) .igx-combo__clear-button {
  background: var(--clear-button-background);
  color: var(--clear-button-foreground);
}

.igx-input-group--filled .igx-combo__toggle-button {
  color: var(--toggle-button-foreground-filled);
}

.igx-input-group--focused .igx-combo__toggle-button {
  color: var(--toggle-button-foreground-focus);
  background: var(--toggle-button-background-focus);
}
.igx-input-group--focused .igx-combo__clear-button {
  color: var(--clear-button-foreground-focus);
  background: var(--clear-button-background-focus);
}

.igx-input-group.igx-input-group--focused:not(.igx-input-group--box) .igx-combo__toggle-button {
  background: var(--toggle-button-background-focus--border);
}

.igx-input-group--disabled .igx-combo__toggle-button {
  background: var(--toggle-button-background-disabled) !important;
  color: var(--toggle-button-foreground-disabled) !important;
}
igx-select {
  --toggle-button-background-focus--border: var(--igx-select-toggle-button-background-focus--border, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --toggle-button-background: var(--igx-select-toggle-button-background, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --toggle-button-background-focus: var(--igx-select-toggle-button-background-focus, hsla(var(--ig-gray-400), 0.3));
  --toggle-button-foreground: var(--igx-select-toggle-button-foreground, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --toggle-button-foreground-focus: var(--igx-select-toggle-button-foreground-focus, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --toggle-button-foreground-filled: var(--igx-select-toggle-button-foreground-filled, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --toggle-button-background-disabled: var(--igx-select-toggle-button-background-disabled, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --toggle-button-foreground-disabled: var(--igx-select-toggle-button-foreground-disabled, hsla(var(--ig-gray-500), var(--ig-gray-a)));
}

.igx-select {
  position: relative;
  display: block;
}

.igx-input-group .igx-select__toggle-button {
  background: var(--toggle-button-background);
  color: var(--toggle-button-foreground);
}

.igx-input-group--filled .igx-select__toggle-button {
  color: var(--toggle-button-foreground-filled);
}

.igx-input-group--focused .igx-select__toggle-button {
  background: var(--toggle-button-background-focus);
  color: var(--toggle-button-foreground-focus);
}

.igx-input-group.igx-input-group--focused:not(.igx-input-group--box) .igx-select__toggle-button {
  background: var(--toggle-button-background-focus--border);
}

.igx-input-group.igx-input-group--disabled .igx-select__toggle-button {
  background: var(--toggle-button-background-disabled);
  color: var(--toggle-button-foreground-disabled);
}

igx-calendar, igx-days-view, igx-months-view, igx-years-view, .igx-date-picker {
  --content-background: var(--igx-calendar-content-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --content-text-color: var(--igx-calendar-content-text-color, var(--ig-surface-500-contrast));
  --border-color: var(--igx-calendar-border-color, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --header-background: var(--igx-calendar-header-background, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --header-text-color: var(--igx-calendar-header-text-color, var(--ig-secondary-500-contrast));
  --picker-background-color: var(--igx-calendar-picker-background-color, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --picker-arrow-color: var(--igx-calendar-picker-arrow-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --picker-arrow-hover-color: var(--igx-calendar-picker-arrow-hover-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --picker-text-color: var(--igx-calendar-picker-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --picker-text-hover-color: var(--igx-calendar-picker-text-hover-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --inactive-text-color: var(--igx-calendar-inactive-text-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --label-color: var(--igx-calendar-label-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --weekend-text-color: var(--igx-calendar-weekend-text-color, var(--ig-surface-500-contrast));
  --year-current-text-color: var(--igx-calendar-year-current-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --month-current-text-color: var(--igx-calendar-month-current-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --month-hover-current-text-color: var(--igx-calendar-month-hover-current-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --year-hover-text-color: var(--igx-calendar-year-hover-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --month-hover-background: var(--igx-calendar-month-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --month-hover-text-color: var(--igx-calendar-month-hover-text-color, var(--ig-gray-200-contrast));
  --date-selected-background: var(--igx-calendar-date-selected-background, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --date-selected-hover-background: var(--igx-calendar-date-selected-hover-background, hsla(var(--ig-gray-100), 0.18));
  --date-selected-focus-background: var(--igx-calendar-date-selected-focus-background, hsla(var(--ig-gray-100), 0.18));
  --date-selected-current-background: var(--igx-calendar-date-selected-current-background, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --date-selected-current-hover-background: var(--igx-calendar-date-selected-current-hover-background, hsla(var(--ig-gray-100), 0.18));
  --date-selected-current-focus-background: var(--igx-calendar-date-selected-current-focus-background, hsla(var(--ig-gray-100), 0.18));
  --date-selected-text-color: var(--igx-calendar-date-selected-text-color, var(--ig-secondary-500-contrast));
  --date-selected-hover-foreground: var(--igx-calendar-date-selected-hover-foreground, var(--ig-gray-100-contrast));
  --date-selected-focus-foreground: var(--igx-calendar-date-selected-focus-foreground, var(--ig-gray-100-contrast));
  --date-current-text-color: var(--igx-calendar-date-current-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --date-current-hover-foreground: var(--igx-calendar-date-current-hover-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --date-current-focus-foreground: var(--igx-calendar-date-current-focus-foreground, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --date-selected-current-foreground: var(--igx-calendar-date-selected-current-foreground, var(--ig-secondary-500-contrast));
  --date-selected-current-hover-foreground: var(--igx-calendar-date-selected-current-hover-foreground, var(--ig-secondary-500-contrast));
  --date-selected-current-focus-foreground: var(--igx-calendar-date-selected-current-focus-foreground, var(--ig-gray-100-contrast));
  --date-current-bg-color: var(--igx-calendar-date-current-bg-color, transparent);
  --date-current-hover-background: var(--igx-calendar-date-current-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --date-current-focus-background: var(--igx-calendar-date-current-focus-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --date-hover-background: var(--igx-calendar-date-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --date-focus-background: var(--igx-calendar-date-focus-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --date-special-background: var(--igx-calendar-date-special-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --date-special-text-color: var(--igx-calendar-date-special-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --date-disabled-text-color: var(--igx-calendar-date-disabled-text-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --date-disabled-background: var(--igx-calendar-date-disabled-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --week-number-color: var(--igx-calendar-week-number-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --week-number-background: var(--igx-calendar-week-number-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --border-radius: var(--igx-calendar-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --date-border-radius: var(--igx-calendar-date-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1.25rem), 1.25rem));
  --month-border-radius: var(--igx-calendar-month-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1.25rem), 1.25rem));
}

.igx-date-picker {
  box-shadow: var(--ig-elevation-24);
  border-radius: var(--border-radius);
  background: var(--content-background);
  overflow: hidden;
}
.igx-date-picker .igx-calendar, .igx-date-picker .igx-calendar--vertical {
  background: var(--content-background);
}
.igx-date-picker .igx-calendar__header {
  background: var(--header-background);
  color: var(--header-text-color);
}
.igx-date-picker .igx-calendar-picker__prev, .igx-date-picker .igx-calendar-picker__next {
  color: var(--picker-arrow-color);
}
.igx-date-picker .igx-calendar-picker__prev:hover, .igx-date-picker .igx-calendar-picker__next:hover {
  color: var(--picker-arrow-hover-color);
}
.igx-date-picker .igx-calendar-picker__date {
  color: var(--picker-text-color);
}
.igx-date-picker .igx-calendar-picker__date:hover, .igx-date-picker .igx-calendar-picker__date:focus {
  color: var(--picker-text-hover-color);
}
.igx-date-picker .igx-calendar__label, .igx-date-picker .igx-calendar__date, .igx-date-picker .igx-calendar__year {
  color: var(--content-text-color);
  min-width: 14.28%;
}
.igx-date-picker .igx-calendar__label {
  color: var(--label-color);
}
.igx-date-picker .igx-calendar__label:hover, .igx-date-picker .igx-calendar__label:focus {
  color: var(--label-color);
}
.igx-date-picker .igx-calendar__date--weekend {
  color: var(--weekend-text-color);
}
.igx-date-picker .igx-calendar__date--disabled {
  color: var(--date-disabled-text-color);
}
.igx-date-picker .igx-calendar__year--current {
  color: var(--year-current-text-color);
}
.igx-date-picker .igx-calendar__year:hover, .igx-date-picker .igx-calendar__year:focus {
  color: var(--year-hover-text-color);
}
.igx-date-picker .igx-calendar__month:hover, .igx-date-picker .igx-calendar__month:focus, .igx-date-picker .igx-calendar__month--current:hover, .igx-date-picker .igx-calendar__month--current:focus {
  color: var(--month-hover-text-color);
}
.igx-date-picker .igx-calendar__month--current {
  color: var(--month-current-text-color);
}
.igx-date-picker .igx-calendar__date--inactive {
  color: var(--inactive-text-color);
}
.igx-date-picker .igx-calendar__date--inactive:hover {
  color: var(--inactive-text-color);
}
.igx-date-picker .igx-calendar__date--selected {
  color: var(--date-selected-text-color);
}
.igx-date-picker .igx-calendar__date--current {
  color: var(--date-current-text-color);
}

.igx-date-picker--vertical {
  min-width: 23rem; /* 168px for header + 200px for the content */
}

.igx-date-picker--dropdown {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  box-shadow: var(--ig-elevation-3);
}

.igx-date-picker__buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
}

igx-date-range {
  --label-color: var(--igx-date-range-label-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
}

.igx-date-range-picker, .igx-date-range-picker--cosy, .igx-date-range-picker--compact {
  display: flex;
}
.igx-date-range-picker > igx-icon, .igx-date-range-picker--cosy > igx-icon, .igx-date-range-picker--compact > igx-icon {
  cursor: pointer;
}
.igx-date-range-picker igx-input-group, .igx-date-range-picker--cosy igx-input-group, .igx-date-range-picker--compact igx-input-group {
  flex: 1;
}

igx-date-range-start,
igx-date-range-end,
.igx-date-range-picker__start,
.igx-date-range-picker__end {
  flex: 1 0 0%;
}

.igx-date-range-picker__label {
  display: flex;
  align-items: center;
  color: var(--label-color);
  margin: 0 0.5rem;
}

.igx-date-range-picker-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem 1rem 1rem;
}
.igx-date-range-picker-buttons > * {
  margin-inline-end: 0.5rem;
}
.igx-date-range-picker-buttons > *:last-of-type {
  margin-inline-end: 0;
}
.igx-date-range-picker-buttons:empty {
  display: none;
}

.igx-dialog {
  --elevation: var(--igx-dialog-elevation, var(--ig-elevation-24));
  --background: var(--igx-dialog-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --title-color: var(--igx-dialog-title-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --message-color: var(--igx-dialog-message-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --border-color: var(--igx-dialog-border-color, transparent);
  --border-radius: var(--igx-dialog-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.1111111111) * 2.25rem), 2.25rem));
  --shadow: var(--igx-dialog-shadow, var(--ig-elevation-24));
}

.igx-dialog {
  outline-style: none;
}

.igx-dialog--hidden {
  display: none;
}

.igx-dialog__window {
  position: relative;
  min-width: 17.5rem;
  border: 0.0625rem solid var(--border-color);
  border-radius: var(--border-radius);
  background: var(--background);
  box-shadow: var(--shadow);
  overflow: hidden;
}
.igx-dialog__window .igx-calendar {
  min-width: 20rem;
}
.igx-dialog__window .igx-calendar--vertical {
  min-width: 31rem;
}

.igx-dialog__window-title {
  display: flex;
  color: var(--title-color);
  padding: 1.5rem 1.5rem 0.75rem 1.5rem;
}

.igx-dialog__window-content {
  color: var(--message-color);
  padding: 0.75rem 1.5rem;
  position: relative;
  z-index: 0;
}

.igx-dialog__window-message {
  display: inline-block;
  max-width: 40ch;
}
@media all and (-ms-high-contrast: none) {
  .igx-dialog__window-message {
    max-width: 62ch;
  }
}

.igx-dialog__window-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding: 0.5rem;
}
.igx-dialog__window-actions button + button {
  margin-inline-start: 0.5rem;
}

igx-divider {
  --color: var(--igx-divider-color, hsla(var(--ig-gray-300), var(--ig-gray-a)));
}

.igx-divider {
  display: block;
  height: 0.0625rem;
  background: var(--color);
}

.igx-divider--dashed {
  background: linear-gradient(to right, var(--color) 50%, transparent 50%);
  background-size: 0.625rem 0.0625rem; /* dash size */
}

.igx-divider--vertical {
  display: inline-block;
  width: 0.0625rem;
  min-width: 0.0625rem;
  height: auto;
}

.igx-divider--vertical.igx-divider--dashed {
  background: linear-gradient(to bottom, var(--color) 50%, transparent 50%);
  background-size: 0.0625rem 0.625rem; /* dash size */
}

igc-dockmanager {
  --igc-active-color: var(--igc-dockmanager-active-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --igc-background-color: var(--igc-dockmanager-background-color, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --igc-border-color: var(--igc-dockmanager-border-color, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --igc-button-text: var(--igc-dockmanager-button-text, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --igc-context-menu-color-active: var(--igc-dockmanager-context-menu-color-active, var(--ig-surface-500-contrast));
  --igc-dock-background: var(--igc-dockmanager-dock-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --igc-drop-shadow-background: var(--igc-dockmanager-drop-shadow-background, hsla(var(--ig-primary-900), 0.1));
  --igc-floating-pane-border-color: var(--igc-dockmanager-floating-pane-border-color, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --igc-flyout-shadow-color: var(--igc-dockmanager-flyout-shadow-color, hsla(var(--ig-gray-900), 0.1));
  --igc-joystick-border-color: var(--igc-dockmanager-joystick-border-color, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --igc-joystick-icon-color: var(--igc-dockmanager-joystick-icon-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --igc-joystick-icon-color-active: var(--igc-dockmanager-joystick-icon-color-active, var(--ig-surface-500-contrast));
  --igc-pane-content-background: var(--igc-dockmanager-pane-content-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --igc-pane-header-background: var(--igc-dockmanager-pane-header-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --igc-pinned-header-background: var(--igc-dockmanager-pinned-header-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --igc-splitter-background: var(--igc-dockmanager-splitter-background, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --igc-tab-background-active: var(--igc-dockmanager-tab-background-active, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --igc-text-color: var(--igc-dockmanager-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
}

igc-rating {
  --label-color: var(--igc-rating-label-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --symbol-empty-color: var(--igc-rating-symbol-empty-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --symbol-full-color: var(--igc-rating-symbol-full-color, hsla(var(--ig-warn-500), var(--ig-warn-a)));
  --symbol-empty-filter: var(--igc-rating-symbol-empty-filter, grayscale(1));
  --symbol-full-filter: var(--igc-rating-symbol-full-filter, grayscale(0));
  --disabled-label-color: var(--igc-rating-disabled-label-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --disabled-empty-symbol-color: var(--igc-rating-disabled-empty-symbol-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --disabled-full-symbol-color: var(--igc-rating-disabled-full-symbol-color, hsla(var(--ig-warn-500), 0.38));
}

igc-rating::part(label) {
  color: var(--label-color);
}

igc-rating::part(value-label) {
  color: hsla(var(--ig-gray-900), var(--ig-gray-a));
}

igc-rating[disabled]::part(label),
igc-rating[disabled]::part(value-label) {
  color: var(--disabled-label-color);
}

igc-rating[disabled] {
  --symbol-empty-color: var(--disabled-empty-symbol-color);
  --symbol-full-color: var(--disabled-full-symbol-color);
  --symbol-empty-filter: grayscale(100%) opacity(50%);
  --symbol-full-filter: grayscale(50%);
}

.igx-drop-down__list, .igx-grid-toolbar__dd-list {
  --elevation: var(--igx-drop-down-elevation, var(--ig-elevation-8));
  --background-color: var(--igx-drop-down-background-color, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --header-text-color: var(--igx-drop-down-header-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --item-text-color: var(--igx-drop-down-item-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --hover-item-text-color: var(--igx-drop-down-hover-item-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --hover-item-background: var(--igx-drop-down-hover-item-background, hsla(var(--ig-gray-100), 0.8));
  --focused-item-background: var(--igx-drop-down-focused-item-background, hsla(var(--ig-gray-100), 0.8));
  --focused-item-text-color: var(--igx-drop-down-focused-item-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --selected-item-background: var(--igx-drop-down-selected-item-background, hsla(var(--ig-secondary-100), var(--ig-secondary-a)));
  --selected-item-text-color: var(--igx-drop-down-selected-item-text-color, var(--ig-secondary-100-contrast));
  --selected-hover-item-background: var(--igx-drop-down-selected-hover-item-background, hsla(var(--ig-secondary-200), var(--ig-secondary-a)));
  --selected-hover-item-text-color: var(--igx-drop-down-selected-hover-item-text-color, var(--ig-secondary-200-contrast));
  --selected-focus-item-background: var(--igx-drop-down-selected-focus-item-background, hsla(var(--ig-secondary-200), var(--ig-secondary-a)));
  --selected-focus-item-text-color: var(--igx-drop-down-selected-focus-item-text-color, var(--ig-secondary-200-contrast));
  --disabled-item-background: var(--igx-drop-down-disabled-item-background, hsla(var(--ig-gray-50), 0));
  --disabled-item-text-color: var(--igx-drop-down-disabled-item-text-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --border-color: var(--igx-drop-down-border-color, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --border-width: var(--igx-drop-down-border-width, 0);
  --border-radius: var(--igx-drop-down-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --item-border-radius: var(--igx-drop-down-item-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0) * 1.25rem), 1.25rem));
  --shadow: var(--igx-drop-down-shadow, var(--ig-elevation-8));
}

.igx-drop-down {
  position: absolute;
}

.igx-drop-down__list {
  overflow: hidden;
  border-radius: var(--border-radius);
  background: var(--background-color);
  box-shadow: var(--shadow);
  min-width: 8rem;
  border: var(--border-width) solid var(--border-color);
}

.igx-drop-down__list-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.igx-drop-down__group label, .igx-drop-down__header,
.igx-drop-down__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  height: 2.5rem;
  position: relative;
}
.igx-drop-down__group label igx-divider, .igx-drop-down__header igx-divider,
.igx-drop-down__item igx-divider {
  position: absolute;
  width: 100%;
  inset-inline-start: 0;
  bottom: 0;
}

.igx-drop-down__item {
  color: var(--item-text-color);
  cursor: pointer;
  padding: 0 1.5rem;
  border-radius: var(--item-border-radius);
}
.igx-drop-down__item:focus {
  outline: 0;
  outline-color: transparent;
  background: var(--focused-item-background);
  color: var(--focused-item-text-color);
}
.igx-drop-down__item:hover {
  background: var(--hover-item-background);
  color: var(--hover-item-text-color);
}

.igx-drop-down__content {
  display: flex;
  width: 100%;
  align-items: center;
}

.igx-drop-down__inner {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-inline-end: auto;
}

.igx-drop-down__item--cosy {
  height: 2rem;
  padding: 0 1.25rem;
}
.igx-drop-down__item--compact {
  height: 1.75rem;
  padding: 0 1rem;
}
.igx-drop-down__group label igx-prefix, .igx-drop-down__header igx-prefix,
.igx-drop-down__group label [igxPrefix],
.igx-drop-down__header [igxPrefix],
.igx-drop-down__item igx-prefix,
.igx-drop-down__item [igxPrefix] {
  margin-inline-end: 1.25rem;
}

.igx-drop-down__group label igx-suffix + igx-suffix, .igx-drop-down__header igx-suffix + igx-suffix,
.igx-drop-down__group label [igxSuffix] + igx-suffix,
.igx-drop-down__header [igxSuffix] + igx-suffix,
.igx-drop-down__item igx-suffix + igx-suffix,
.igx-drop-down__item [igxSuffix] + igx-suffix,
.igx-drop-down__group label igx-suffix + [igxSuffix],
.igx-drop-down__header igx-suffix + [igxSuffix],
.igx-drop-down__group label [igxSuffix] + [igxSuffix],
.igx-drop-down__header [igxSuffix] + [igxSuffix],
.igx-drop-down__item igx-suffix + [igxSuffix],
.igx-drop-down__item [igxSuffix] + [igxSuffix] {
  margin-inline-start: 0.5rem;
}

.igx-drop-down__header--cosy igx-prefix,
.igx-drop-down__header--cosy [igxPrefix], .igx-drop-down__item--cosy igx-prefix,
.igx-drop-down__item--cosy [igxPrefix] {
  margin-inline-end: 1rem;
}

.igx-drop-down__header--cosy igx-suffix + igx-suffix,
.igx-drop-down__header--cosy [igxSuffix] + igx-suffix, .igx-drop-down__item--cosy igx-suffix + igx-suffix,
.igx-drop-down__item--cosy [igxSuffix] + igx-suffix,
.igx-drop-down__header--cosy igx-suffix + [igxSuffix],
.igx-drop-down__header--cosy [igxSuffix] + [igxSuffix],
.igx-drop-down__item--cosy igx-suffix + [igxSuffix],
.igx-drop-down__item--cosy [igxSuffix] + [igxSuffix] {
  margin-inline-start: 0.375rem;
}

.igx-drop-down__header--compact igx-prefix,
.igx-drop-down__header--compact [igxPrefix], .igx-drop-down__item--compact igx-prefix,
.igx-drop-down__item--compact [igxPrefix] {
  margin-inline-end: 0.75rem;
}

.igx-drop-down__header--compact igx-suffix + igx-suffix,
.igx-drop-down__header--compact [igxSuffix] + igx-suffix, .igx-drop-down__item--compact igx-suffix + igx-suffix,
.igx-drop-down__item--compact [igxSuffix] + igx-suffix,
.igx-drop-down__header--compact igx-suffix + [igxSuffix],
.igx-drop-down__header--compact [igxSuffix] + [igxSuffix],
.igx-drop-down__item--compact igx-suffix + [igxSuffix],
.igx-drop-down__item--compact [igxSuffix] + [igxSuffix] {
  margin-inline-start: 0.25rem;
}

.igx-drop-down__group label, .igx-drop-down__header {
  color: var(--header-text-color);
  pointer-events: none;
  padding: 0 1rem;
}

.igx-drop-down__header--cosy {
  height: 2rem;
  padding: 0 0.75rem;
}
.igx-drop-down__header--compact {
  height: 1.75rem;
  padding: 0 0.5rem;
}
.igx-drop-down__group {
  pointer-events: auto;
}
.igx-drop-down__group .igx-drop-down__item {
  text-indent: 0 1.5rem;
}

.igx-drop-down__item--focused {
  background: var(--focused-item-background);
  color: var(--focused-item-text-color);
}
.igx-drop-down__item--focused:hover {
  color: var(--focused-item-text-color);
  background: var(--focused-item-background);
}

.igx-drop-down__item--selected {
  background: var(--selected-item-background);
  color: var(--selected-item-text-color);
}
.igx-drop-down__item--selected:hover {
  background: var(--selected-hover-item-background);
  color: var(--selected-hover-item-text-color);
}

.igx-drop-down__item--selected.igx-drop-down__item--focused {
  background: var(--selected-focus-item-background);
  color: var(--selected-focus-item-text-color);
}

.igx-drop-down__item--disabled, .igx-drop-down__group--disabled {
  background: var(--disabled-item-background);
  color: var(--disabled-item-text-color);
  cursor: default;
  pointer-events: none;
}
.igx-drop-down__item--disabled:hover, .igx-drop-down__group--disabled:hover {
  background: var(--disabled-item-background);
}

igx-expansion-panel {
  --header-background: var(--igx-expansion-panel-header-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --body-background: var(--igx-expansion-panel-body-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --header-focus-background: var(--igx-expansion-panel-header-focus-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --header-title-color: var(--igx-expansion-panel-header-title-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --header-description-color: var(--igx-expansion-panel-header-description-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --header-icon-color: var(--igx-expansion-panel-header-icon-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --body-color: var(--igx-expansion-panel-body-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --disabled-color: var(--igx-expansion-panel-disabled-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --expanded-margin: var(--igx-expansion-panel-expanded-margin, 0.5rem);
  --border-radius: var(--igx-expansion-panel-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0) * 1rem), 1rem));
}

.igx-expansion-panel {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: margin 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.igx-expansion-panel__header-title {
  color: var(--header-title-color);
  margin-inline-end: 1rem;
}

.igx-expansion-panel__header-description {
  color: var(--header-description-color);
}

.igx-expansion-panel__header-title,
.igx-expansion-panel__header-description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.igx-accordion {
  overflow-y: auto;
}
.igx-accordion .igx-expansion-panel__header-title {
  display: -webkit-inline-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: initial;
  word-wrap: break-word;
}
.igx-accordion .igx-expansion-panel--expanded {
  margin: var(--expanded-margin) 0;
}
.igx-accordion .igx-expansion-panel--expanded:first-of-type {
  margin-top: 0;
}
.igx-accordion .igx-expansion-panel--expanded:last-of-type {
  margin-bottom: 0;
}

.igx-expansion-panel__header-inner {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  cursor: pointer;
  background: var(--header-background);
}
.igx-expansion-panel__header-inner:focus, .igx-expansion-panel__header-inner:active {
  background: var(--header-focus-background);
  outline: transparent;
}

.igx-expansion-panel__title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0%;
  overflow: hidden;
}

.igx-expansion-panel__header-icon--end {
  order: 1;
  margin-inline-start: 1rem;
}

.igx-expansion-panel__header-icon--start {
  order: -1;
  margin-inline-end: 1rem;
}

.igx-expansion-panel__header-icon--none {
  display: none;
}

.igx-expansion-panel__header-icon--end,
.igx-expansion-panel__header-icon--start {
  display: flex;
  align-content: center;
  justify-content: center;
  user-select: none;
  color: var(--header-icon-color);
}
.igx-expansion-panel__header-icon--end igx-icon,
.igx-expansion-panel__header-icon--start igx-icon {
  color: var(--header-icon-color);
}

.igx-expansion-panel__body {
  color: var(--body-color);
  background: var(--body-background);
  overflow: hidden;
  padding: 1rem 1.5rem;
}

.igx-expansion-panel--disabled {
  pointer-events: none;
}
.igx-expansion-panel--disabled .igx-expansion-panel__header-title,
.igx-expansion-panel--disabled .igx-expansion-panel__header-description {
  color: var(--disabled-color);
}
.igx-expansion-panel--disabled .igx-expansion-panel__header-icon--start igx-icon,
.igx-expansion-panel--disabled .igx-expansion-panel__header-icon--end igx-icon {
  color: var(--disabled-color);
}

igx-grid, igx-hierarchical-grid, igx-pivot-grid, igx-tree-grid, igx-advanced-filtering-dialog, igx-grid-excel-style-filtering, .igx-excel-filter__secondary {
  --grid-elevation: var(--igx-grid-grid-elevation, var(--ig-elevation-2));
  --drag-elevation: var(--igx-grid-drag-elevation, var(--ig-elevation-5));
  --grouparea-color: var(--igx-grid-grouparea-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --grouparea-background: var(--igx-grid-grouparea-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --drop-area-text-color: var(--igx-grid-drop-area-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --drop-area-icon-color: var(--igx-grid-drop-area-icon-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --drop-area-background: var(--igx-grid-drop-area-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --drop-area-on-drop-background: var(--igx-grid-drop-area-on-drop-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --header-background: var(--igx-grid-header-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --header-text-color: var(--igx-grid-header-text-color, hsla(var(--ig-gray-800), 0.7));
  --header-selected-background: var(--igx-grid-header-selected-background, hsla(var(--ig-secondary-100), var(--ig-secondary-a)));
  --header-selected-text-color: var(--igx-grid-header-selected-text-color, var(--ig-secondary-100-contrast));
  --sortable-header-icon-hover-color: var(--igx-grid-sortable-header-icon-hover-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --sorted-header-icon-color: var(--igx-grid-sorted-header-icon-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --header-border-width: var(--igx-grid-header-border-width, 1px);
  --header-border-style: var(--igx-grid-header-border-style, solid);
  --header-border-color: var(--igx-grid-header-border-color, hsla(var(--ig-gray-400), 0.38));
  --ghost-header-background: var(--igx-grid-ghost-header-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --ghost-header-text-color: var(--igx-grid-ghost-header-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --ghost-header-icon-color: var(--igx-grid-ghost-header-icon-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --filtering-background-and: var(--igx-grid-filtering-background-and, hsla(var(--ig-primary-300), var(--ig-primary-a)));
  --filtering-background-or: var(--igx-grid-filtering-background-or, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --filtering-background-and--focus: var(--igx-grid-filtering-background-and--focus, hsla(var(--ig-primary-700), var(--ig-primary-a)));
  --filtering-background-or--focus: var(--igx-grid-filtering-background-or--focus, hsla(var(--ig-secondary-700), var(--ig-secondary-a)));
  --grid-border-color: var(--igx-grid-grid-border-color, transparent);
  --content-background: var(--igx-grid-content-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --content-text-color: var(--igx-grid-content-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --row-odd-background: var(--igx-grid-row-odd-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --row-even-background: var(--igx-grid-row-even-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --row-odd-text-color: var(--igx-grid-row-odd-text-color, inherit);
  --row-even-text-color: var(--igx-grid-row-even-text-color, inherit);
  --row-selected-background: var(--igx-grid-row-selected-background, hsla(var(--ig-secondary-50), var(--ig-secondary-a)));
  --tree-selected-filtered-row-text-color: var(--igx-grid-tree-selected-filtered-row-text-color, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --row-selected-text-color: var(--igx-grid-row-selected-text-color, var(--ig-secondary-50-contrast));
  --cell-selected-within-background: var(--igx-grid-cell-selected-within-background, hsla(var(--ig-secondary-200), var(--ig-secondary-a)));
  --cell-selected-within-text-color: var(--igx-grid-cell-selected-within-text-color, var(--ig-secondary-200-contrast));
  --row-selected-hover-background: var(--igx-grid-row-selected-hover-background, hsla(var(--ig-secondary-100), var(--ig-secondary-a)));
  --row-selected-hover-text-color: var(--igx-grid-row-selected-hover-text-color, var(--ig-secondary-100-contrast));
  --row-hover-background: var(--igx-grid-row-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --row-hover-text-color: var(--igx-grid-row-hover-text-color, var(--ig-gray-200-contrast));
  --row-border-color: var(--igx-grid-row-border-color, hsla(var(--ig-gray-300), 0.38));
  --pinned-border-width: var(--igx-grid-pinned-border-width, 2px);
  --pinned-border-style: var(--igx-grid-pinned-border-style, solid);
  --pinned-border-color: var(--igx-grid-pinned-border-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --cell-active-border-color: var(--igx-grid-cell-active-border-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --cell-selected-background: var(--igx-grid-cell-selected-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --tree-selected-filtered-cell-text-color: var(--igx-grid-tree-selected-filtered-cell-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --cell-selected-text-color: var(--igx-grid-cell-selected-text-color, var(--ig-gray-200-contrast));
  --cell-editing-background: var(--igx-grid-cell-editing-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --edit-mode-color: var(--igx-grid-edit-mode-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --edited-row-indicator: var(--igx-grid-edited-row-indicator, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --cell-new-color: var(--igx-grid-cell-new-color, inherit);
  --cell-edited-value-color: var(--igx-grid-cell-edited-value-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --cell-disabled-color: var(--igx-grid-cell-disabled-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --resize-line-color: var(--igx-grid-resize-line-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --drop-indicator-color: var(--igx-grid-drop-indicator-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --group-label-column-name-text: var(--igx-grid-group-label-column-name-text, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --group-label-icon: var(--igx-grid-group-label-icon, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --group-label-text: var(--igx-grid-group-label-text, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --expand-all-icon-color: var(--igx-grid-expand-all-icon-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --expand-all-icon-hover-color: var(--igx-grid-expand-all-icon-hover-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --expand-icon-color: var(--igx-grid-expand-icon-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --expand-icon-hover-color: var(--igx-grid-expand-icon-hover-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --active-expand-icon-color: var(--igx-grid-active-expand-icon-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --active-expand-icon-hover-color: var(--igx-grid-active-expand-icon-hover-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --group-count-background: var(--igx-grid-group-count-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --group-count-text-color: var(--igx-grid-group-count-text-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --group-row-background: var(--igx-grid-group-row-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --group-row-selected-background: var(--igx-grid-group-row-selected-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --filtering-header-background: var(--igx-grid-filtering-header-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --filtering-header-text-color: var(--igx-grid-filtering-header-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --filtering-row-background: var(--igx-grid-filtering-row-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --filtering-row-text-color: var(--igx-grid-filtering-row-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --tree-filtered-text-color: var(--igx-grid-tree-filtered-text-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --body-summaries-background: var(--igx-grid-body-summaries-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --body-summaries-text-color: var(--igx-grid-body-summaries-text-color, var(--ig-gray-100-contrast));
  --root-summaries-background: var(--igx-grid-root-summaries-background, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --root-summaries-text-color: var(--igx-grid-root-summaries-text-color, var(--ig-gray-300-contrast));
  --row-highlight: var(--igx-grid-row-highlight, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --row-ghost-background: var(--igx-grid-row-ghost-background, hsla(var(--ig-primary-50), var(--ig-primary-a)));
  --row-drag-color: var(--igx-grid-row-drag-color, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --drop-area-border-radius: var(--igx-grid-drop-area-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1rem), 1rem));
  --grid-shadow: var(--igx-grid-grid-shadow, var(--ig-elevation-2));
  --drag-shadow: var(--igx-grid-drag-shadow, var(--ig-elevation-5));
}

@keyframes scale-in-ver-center {
  0% {
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}
.igx-grid__tbody-content:focus, .igx-grid__tfoot:focus, .igx-grid-thead__wrapper:focus {
  outline: 0;
}

.igx-grid, .igx-grid--cosy, .igx-grid--compact {
  --igx-tree-indent-size: 1.5rem;
  position: relative;
  display: grid;
  grid-template-rows: auto auto auto 1fr auto auto;
  grid-template-columns: 100%;
  overflow: hidden;
  box-shadow: var(--grid-shadow);
  outline-style: none;
  z-index: 1;
}
.igx-grid .igx-checkbox, .igx-grid--cosy .igx-checkbox, .igx-grid--compact .igx-checkbox {
  min-width: 1.25rem;
}

.igx-grid--cosy {
  --igx-tree-indent-size: 1rem;
}

.igx-grid--compact {
  --igx-tree-indent-size: 0.75rem;
}

.igx-grid__caption {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  line-height: 2rem;
  padding: 1rem 1.5rem;
  grid-row: 1;
}

.igx-grid-thead__wrapper,
.igx-grid__tfoot {
  position: relative;
  display: flex;
  background: var(--header-background);
  color: var(--header-text-color);
  overflow: hidden;
  outline-style: none;
}
.igx-grid-thead__wrapper .igx-grid__tr,
.igx-grid__tfoot .igx-grid__tr {
  position: relative;
  background: inherit;
  color: inherit;
  z-index: 2;
}
.igx-grid-thead__wrapper .igx-grid__tr:hover,
.igx-grid__tfoot .igx-grid__tr:hover {
  background: inherit;
  color: inherit;
}
.igx-grid-thead__wrapper > [aria-activedescendant],
.igx-grid__tfoot > [aria-activedescendant] {
  outline-style: none;
}

.igx-grid-thead__wrapper {
  border-bottom: var(--header-border-width) var(--header-border-style) var(--header-border-color);
  z-index: 2;
}
.igx-grid-thead__wrapper .igx-grid__cbx-selection--push {
  align-items: flex-start;
  padding-top: 0.9375rem;
}
.igx-grid-thead__wrapper .igx-grid__tr {
  border-bottom: none;
}

.igx-grid-thead {
  grid-row: 3;
  display: flex;
  overflow: hidden;
}
.igx-grid-thead .igx-grid__drag-indicator {
  cursor: default;
}
.igx-grid-thead .igx-grid__tr--mrl .igx-grid__hierarchical-expander--header,
.igx-grid-thead .igx-grid__tr--mrl .igx-grid__header-indentation,
.igx-grid-thead .igx-grid__tr--mrl .igx-grid__row-indentation,
.igx-grid-thead .igx-grid__tr--mrl .igx-grid__cbx-selection {
  border-bottom: var(--header-border-width) var(--header-border-style) var(--header-border-color);
}

.igx-grid-thead--cosy .igx-grid-thead__wrapper .igx-grid--cosy .igx-grid__cbx-selection--push, .igx-grid--cosy .igx-grid-thead--cosy .igx-grid-thead__wrapper .igx-grid__cbx-selection--push {
  align-items: flex-start;
  padding-top: 0.625rem;
}

.igx-grid-thead--compact .igx-grid-thead__wrapper .igx-grid--compact .igx-grid__cbx-selection--push, .igx-grid--compact .igx-grid-thead--compact .igx-grid-thead__wrapper .igx-grid__cbx-selection--push {
  align-items: flex-start;
  padding-top: 0.375rem;
}

.igx-grid-thead__title {
  flex-basis: auto !important;
  align-items: center !important;
  border-bottom: var(--header-border-width) var(--header-border-style) var(--header-border-color);
  height: 3.125rem;
}

.igx-grid-thead__title--pinned-last {
  border-inline-end: var(--pinned-border-width) var(--pinned-border-style) var(--pinned-border-color) !important;
}

.igx-grid-thead--cosy .igx-grid-thead__title {
  height: 2.5rem;
  padding: 0 1rem;
}

.igx-grid-thead--compact .igx-grid-thead__title {
  height: 2rem;
  padding: 0 0.75rem;
}

.igx-grid-thead__group {
  display: flex;
  flex-flow: row nowrap;
}

/* We set those with position relative
so that the drop indicators be scoped
to their respective group. The item
being the topmost element, while the
subgroup encapsulates children of each
thead item and group.
*/
.igx-grid-thead__item {
  display: flex;
  flex-flow: column nowrap;
}
.igx-grid-thead__item .igx-grid-thead__group {
  flex: 1 1 auto;
}
.igx-grid-thead__item .igx-grid-thead__title, .igx-grid-thead__item .igx-grid-th {
  flex: 1 1 auto;
}
.igx-grid-thead__item .igx-grid-thead__title {
  flex: 0 0 auto;
}

.igx-grid-thead__item,
.igx-grid-thead__subgroup {
  position: relative;
}

.igx-grid__tfoot {
  grid-row: 5;
  border-top: var(--header-border-width) var(--header-border-style) var(--header-border-color);
  z-index: 10001;
}

.igx-grid__footer {
  grid-row: 7;
}

.igx-grid-thead__wrapper igx-display-container {
  width: 100%;
  overflow: visible;
}

.igx-grid__tr igx-display-container, .igx-grid__summaries igx-display-container, .igx-grid__summaries--body igx-display-container {
  width: 100%;
  overflow: visible;
}

.igx-grid__mrl-block {
  display: grid;
  background: inherit;
  position: relative;
}
.igx-grid__mrl-block .igx-grid-thead__item {
  display: flex;
}
.igx-grid__mrl-block .igx-grid-thead__title, .igx-grid__mrl-block .igx-grid-th {
  align-items: center;
  flex-grow: 1;
  border-bottom: var(--header-border-width) var(--header-border-style) var(--header-border-color);
}
.igx-grid__mrl-block .igx-grid__td, .igx-grid__mrl-block .igx-grid-thead__title, .igx-grid__mrl-block .igx-grid-th {
  border-inline-end: 0.0625rem solid var(--row-border-color);
  border-bottom: 0.0625rem solid var(--row-border-color);
}

.igx-grid__tr--mrl.igx-grid__tr {
  border-bottom-color: transparent;
}
.igx-grid__tr--mrl .igx-grid__cbx-selection,
.igx-grid__tr--mrl .igx-grid__row-indentation,
.igx-grid__tr--mrl .igx-grid__drag-indicator {
  border-bottom: 0.0625rem solid var(--row-border-color);
}

.igx-grid__tbody-content {
  position: relative;
  background: var(--content-background);
  color: var(--content-text-color);
  overflow: hidden;
  z-index: 1;
  outline-style: none;
}

.igx-grid__tbody {
  position: relative;
  display: flex;
  grid-row: 4;
  overflow: hidden;
}

.igx-grid__tbody-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--content-text-color);
  flex-direction: column;
  padding: 1.5rem;
}

.igx-grid__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 6.25rem;
}
.igx-grid__loading > .igx-circular-bar {
  width: 3.125rem;
  height: 3.125rem;
}

.igx-grid__scroll {
  grid-row: 6;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  background: var(--header-background);
  z-index: 10001;
}

.igx-grid-thead__thumb {
  background: var(--header-background);
  border-inline-start: 0.0625rem solid var(--header-border-color);
}

.igx-grid__tfoot-thumb {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  background: var(--header-background);
  border-inline-start: 0.0625rem solid var(--header-border-color);
}

.igx-grid__tbody-scrollbar {
  background: var(--content-background);
  border-inline-start: 0.0625rem solid var(--row-border-color);
  position: relative;
}

.igx-grid__tbody-scrollbar-start {
  background: var(--header-background);
}

.igx-grid__tbody-scrollbar-main {
  position: relative;
}

.igx-grid__tbody-scrollbar-end {
  background: var(--header-background);
}

.igx-grid__scroll-start {
  background: var(--header-background);
}

.igx-grid__scroll-main igx-display-container {
  height: 0;
}
.igx-grid__scroll-main igx-horizontal-virtual-helper {
  height: 100%;
}

.igx-grid__tr {
  display: flex;
  background: var(--content-background);
  border-bottom: 0.0625rem solid var(--row-border-color);
  outline-style: none;
  position: relative;
  background-clip: content-box !important;
}
.igx-grid__tr:hover {
  background: var(--row-hover-background);
  color: var(--row-hover-text-color);
}
.igx-grid__tr:hover .igx-grid__td--column-selected {
  color: var(--row-selected-hover-text-color);
  background: var(--row-selected-hover-background);
}
.igx-grid__tr:hover .igx-grid__td--selected.igx-grid__td--column-selected {
  color: var(--cell-selected-within-text-color);
  background: var(--cell-selected-within-background);
}
.igx-grid__tr.igx-grid__tr--ghost {
  background: var(--row-ghost-background);
  color: var(--row-drag-color);
  z-index: 10002;
}
.igx-grid__tr.igx-grid__tr--ghost,
.igx-grid__tr.igx-grid__tr--ghost igx-grid-row {
  --row-ghost-background: var(--igx-grid-row-row-ghost-background, hsla(var(--ig-primary-50), var(--ig-primary-a)));
  --row-drag-color: var(--igx-grid-row-row-drag-color, );
}

.igx-grid__drag-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  flex: 1 0 auto;
  min-width: calc(1.5rem + 1.5rem);
  background: inherit;
  z-index: 4;
  cursor: move;
  border-inline-end: 0.0625rem solid transparent;
  background-clip: border-box;
}
.igx-grid__drag-indicator igx-icon {
  width: var(--igx-icon-size, 1.5rem);
  height: var(--igx-icon-size, 1.5rem);
  font-size: var(--igx-icon-size, 1.5rem);
}

.igx-grid--cosy .igx-grid__drag-indicator {
  padding: 0 1rem;
  min-width: calc(1.5rem + 1rem);
}

.igx-grid--compact .igx-grid__drag-indicator {
  padding: 0 0.75rem;
  min-width: calc(1.5rem + 0.75rem);
}

.igx-grid__drag-indicator--header {
  border-inline-end: var(--header-border-width) var(--header-border-style) var(--header-border-color);
}

.igx-grid__drag-indicator--off {
  color: var(--row-drag-color);
}

.igx-grid__tr--drag {
  opacity: 0.5;
}

.igx-grid__tr--odd {
  background: var(--row-odd-background);
  color: var(--row-odd-text-color);
}

.igx-grid__tr--even {
  background: var(--row-even-background);
  color: var(--row-even-text-color);
}

.igx-grid__tr--expanded {
  border-bottom: none;
}

.igx-grid__tr--pinned {
  position: relative;
  background: inherit;
  z-index: 10000;
}
.igx-grid__tr--pinned .igx-grid__hierarchical-expander--empty {
  border-inline-end: 0.0625rem solid var(--header-border-color);
}

.igx-grid__tr--pinned-top {
  border-bottom: var(--pinned-border-width) var(--pinned-border-style) var(--pinned-border-color) !important;
}

.igx-grid__tr--pinned-bottom {
  border-top: var(--pinned-border-width) var(--pinned-border-style) var(--pinned-border-color) !important;
  position: absolute;
  bottom: 0;
}

.igx-grid__td--bool, .igx-grid__td--image {
  justify-content: center;
}

.igx-grid__td--bool igx-icon {
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
}
.igx-grid__td--bool .igx-icon--error {
  color: hsla(var(--ig-gray-500), var(--ig-gray-a));
}

.igx-grid__td--bool-true .igx-icon--success {
  color: hsla(var(--ig-gray-700), var(--ig-gray-a));
}

.igx-grid__tr--edit {
  border-bottom: 0.0625rem solid var(--edit-mode-color);
  position: relative;
}
.igx-grid__tr--edit::after {
  content: "";
  position: absolute;
  height: 0.0625rem;
  width: 100%;
  top: -0.0625rem;
  inset-inline-start: 0;
  background: var(--edit-mode-color);
}
.igx-grid__tr--edit.igx-grid__tr {
  border-bottom: 0.0625rem solid var(--edit-mode-color);
}
.igx-grid__tr--edit .igx-grid__td--editing {
  border: none;
}
.igx-grid__tr--edit .igx-grid__td--editing .igx-input-group--focused .igx-input-group__bundle, .igx-input-group--focused .igx-grid__tr--edit .igx-grid__td--editing .igx-input-group__bundle {
  caret-color: var(--edit-mode-color) !important;
}
.igx-grid__tr--edit .igx-grid__td--editing .igx-input-group--fluent .igx-input-group__bundle:focus-within::after, .igx-input-group--fluent .igx-grid__tr--edit .igx-grid__td--editing .igx-input-group__bundle:focus-within::after, .igx-grid__tr--edit .igx-grid__td--editing .igx-input-group--fluent .igx-input-group__bundle:focus::after, .igx-input-group--fluent .igx-grid__tr--edit .igx-grid__td--editing .igx-input-group__bundle:focus::after {
  border: none !important;
}
.igx-grid__tr--edit [aria-readonly=true] {
  color: var(--cell-disabled-color);
}
.igx-grid__tr--edit [aria-readonly=true] igx-icon {
  color: var(--cell-disabled-color);
}

.igx-grid__tr--inner {
  display: flex;
  background: inherit;
}

.igx-grid__tr--header {
  display: flex;
  align-items: center;
}

.igx-grid__tr--add-animate {
  animation: scale-in-ver-center 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.igx-grid__tr--mrl.igx-grid__tr--edit:first-of-type::after {
  top: 0;
  z-index: 5;
}

.igx-grid__tr--edited::before {
  content: "";
  position: absolute;
  width: 0.125rem;
  height: 100%;
  z-index: 10000;
  background: var(--edited-row-indicator);
}

.igx-grid__tr--group {
  position: relative;
  background: var(--header-background) !important;
}

.igx-grid__tr--filtered .igx-grid__td-text {
  color: var(--tree-filtered-text-color);
}
.igx-grid__tr--filtered .igx-grid__tree-grouping-indicator {
  color: var(--tree-filtered-text-color);
}
.igx-grid__tr--filtered .igx-grid__tree-grouping-indicator:hover {
  color: var(--tree-filtered-text-color);
}
.igx-grid__tr--filtered .igx-grid__td--selected .igx-grid__td-text {
  color: var(--tree-selected-filtered-cell-text-color);
}
.igx-grid__tr--filtered .igx-grid__td--selected .igx-grid__tree-grouping-indicator {
  color: var(--tree-selected-filtered-cell-text-color);
}
.igx-grid__tr--filtered .igx-grid__td--selected .igx-grid__tree-grouping-indicator:hover {
  color: var(--tree-selected-filtered-cell-text-color);
}

.igx-grid__tr--selected.igx-grid__tr--filtered .igx-grid__td-text {
  color: var(--tree-selected-filtered-row-text-color);
}
.igx-grid__tr--selected.igx-grid__tr--filtered .igx-grid__tree-grouping-indicator {
  color: var(--tree-selected-filtered-row-text-color);
}
.igx-grid__tr--selected.igx-grid__tr--filtered .igx-grid__tree-grouping-indicator:hover {
  color: var(--tree-selected-filtered-row-text-color);
}
.igx-grid__tr--selected.igx-grid__tr--filtered .igx-grid__td--selected .igx-grid__td-text {
  color: var(--tree-selected-filtered-cell-text-color);
}
.igx-grid__tr--selected.igx-grid__tr--filtered .igx-grid__td--selected .igx-grid__tree-grouping-indicator {
  color: var(--tree-selected-filtered-cell-text-color);
}
.igx-grid__tr--selected.igx-grid__tr--filtered .igx-grid__td--selected .igx-grid__tree-grouping-indicator:hover {
  color: var(--tree-selected-filtered-cell-text-color);
}

.igx-grid__tree-grouping-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  outline-style: none;
  margin-inline-end: 0.5rem;
  cursor: pointer;
  color: var(--expand-icon-color);
}
.igx-grid__tree-grouping-indicator:hover {
  color: var(--expand-icon-hover-color);
}
[dir=rtl] .igx-grid__tree-grouping-indicator {
  transform: scaleX(-1);
}

.igx-grid__tree-loading-indicator {
  width: 1.5rem;
  height: 1.5rem;
  margin-inline-end: 0.5rem;
}
.igx-grid__tree-loading-indicator .igx-circular-bar__outer {
  stroke: var(--expand-icon-color);
}
.igx-grid__tree-loading-indicator > .igx-circular-bar {
  width: 1.5rem;
  height: 1.5rem;
}

.igx-grid__td, .igx-grid-thead__title, .igx-grid-th {
  position: relative;
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  outline-style: none;
  padding: 0 1.5rem;
  font-size: 0.8125rem;
  line-height: 1rem;
  color: inherit;
  text-align: start;
  background-clip: border-box !important;
}

.igx-grid__td-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.igx-grid--cosy .igx-grid__td {
  padding: 0 1rem;
}

.igx-grid--compact .igx-grid__td {
  padding: 0 0.75rem;
}

.igx-grid__td--fw, .igx-grid-th--fw {
  flex-grow: 0;
  outline-style: none;
}

.igx-grid__tr-container--active, .igx-grid__group-row--active, .igx-grid-summary--active, .igx-grid-th--active .igx-grid-th--selected,
.igx-grid-th--active .igx-grid-th--selectable, .igx-grid-th--active, .igx-grid__td--active {
  box-shadow: inset 0 0 0 0.0625rem var(--cell-active-border-color);
}
.igx-grid__tr-container--active .igx-grid__filtering-cell, .igx-grid__group-row--active .igx-grid__filtering-cell, .igx-grid-summary--active .igx-grid__filtering-cell, .igx-grid-th--active .igx-grid__filtering-cell, .igx-grid__td--active .igx-grid__filtering-cell, .igx-grid__tr-container--active .igx-grid__filtering-cell--selected, .igx-grid__group-row--active .igx-grid__filtering-cell--selected, .igx-grid-summary--active .igx-grid__filtering-cell--selected, .igx-grid-th--active .igx-grid__filtering-cell--selected, .igx-grid__td--active .igx-grid__filtering-cell--selected,
.igx-grid__tr-container--active .igx-grid-thead__title,
.igx-grid__group-row--active .igx-grid-thead__title,
.igx-grid-summary--active .igx-grid-thead__title,
.igx-grid-th--active .igx-grid-thead__title,
.igx-grid__td--active .igx-grid-thead__title,
.igx-grid__tr-container--active .igx-grid-th,
.igx-grid__group-row--active .igx-grid-th,
.igx-grid-summary--active .igx-grid-th,
.igx-grid-th--active .igx-grid-th,
.igx-grid__td--active .igx-grid-th {
  border-inline-end-color: var(--cell-active-border-color);
  border-bottom-color: var(--cell-active-border-color);
}

.igx-grid__td--invalid {
  padding-inline-end: 0.25rem !important;
}
.igx-grid__td--invalid > igx-icon {
  margin-inline-start: auto;
  color: hsla(var(--ig-error-500), var(--ig-error-a));
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
}
.igx-grid__td--invalid .igx-grid__td-text {
  width: 100%;
}
.igx-grid__td--invalid .igx-input-group__bundle:focus-within::after {
  border: none !important;
}

.igx-grid__td--valid {
  box-shadow: inset 0 0 0 0.125rem hsla(var(--ig-success-500), var(--ig-success-a)) !important;
}

.igx-grid__td--pinned.igx-grid__td--selected,
.igx-grid__td--selected {
  color: var(--cell-selected-text-color);
  background: var(--cell-selected-background);
}
.igx-grid__td--selected .igx-grid__tree-grouping-indicator:hover {
  color: var(--cell-selected-text-color);
}

.igx-grid__tr--selected {
  color: var(--row-selected-text-color);
  background: var(--row-selected-background);
}
.igx-grid__tr--selected .igx-grid__td--selected {
  color: var(--cell-selected-within-text-color);
  background: var(--cell-selected-within-background);
}
.igx-grid__tr--selected:hover {
  background: var(--row-selected-hover-background);
  color: var(--row-selected-hover-text-color);
}
.igx-grid__tr--selected:hover .igx-grid__td--column-selected {
  color: var(--row-selected-hover-text-color);
  background: var(--row-selected-hover-background);
}
.igx-grid__tr--selected .igx-grid__tree-grouping-indicator {
  color: var(--row-selected-text-color);
}
.igx-grid__tr--selected .igx-grid__tree-grouping-indicator:hover {
  color: var(--row-selected-text-color);
}

.igx-grid__td--column-selected {
  color: var(--row-selected-text-color);
  background: var(--row-selected-background);
}

.igx-grid__td--selected.igx-grid__td--column-selected {
  color: var(--cell-selected-within-text-color);
  background: var(--cell-selected-within-background);
}

.igx-grid__td--new {
  color: var(--cell-new-color);
}

.igx-grid__td--edited .igx-grid__td-text {
  font-style: italic;
  color: var(--cell-edited-value-color);
  padding: 0 0.0625rem;
}

.igx-grid__tr--deleted .igx-grid__td-text {
  font-style: italic;
  color: hsla(var(--ig-error-500), var(--ig-error-a));
  text-decoration: line-through;
}

.igx-grid__tr--disabled .igx-grid__td-text {
  color: var(--cell-disabled-color);
}

.igx-grid__td--editing {
  background: var(--cell-editing-background) !important;
  box-shadow: inset 0 0 0 0.125rem var(--edit-mode-color);
  padding: 0 !important;
}
.igx-grid__td--editing > igx-input-group,
.igx-grid__td--editing igx-combo,
.igx-grid__td--editing igx-select,
.igx-grid__td--editing igx-date-picker,
.igx-grid__td--editing igx-time-picker {
  position: relative;
  height: calc(100% - 0.25rem);
  width: calc(100% - 0.25rem);
  inset-inline-start: 0.125rem;
  overflow: hidden;
}
.igx-grid__td--editing igx-input-group {
  height: 100%;
  --theme: fluent;
}
.igx-grid__td--editing igx-input-group input {
  height: 100%;
}
.igx-grid__td--editing .igx-input-group__bundle {
  height: 100% !important;
  min-height: 100% !important;
  border: none !important;
}
.igx-grid__td--editing .igx-input-group__bundle::before {
  content: none !important;
}
.igx-grid__td--editing .igx-input-group__bundle::after {
  display: none;
}
.igx-grid__td--editing .igx-input-group__bundle-main,
.igx-grid__td--editing .igx-input-group__bundle-start,
.igx-grid__td--editing .igx-input-group__bundle-end {
  height: calc(100% - 0.25rem);
}
.igx-grid__td--invalid.igx-grid__td--editing {
  box-shadow: inset 0 0 0 0.125rem hsla(var(--ig-error-500), var(--ig-error-a)) !important;
  padding-inline-end: 0.25rem !important;
}
.igx-grid__td--editing igx-prefix,
.igx-grid__td--editing igx-suffix {
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.igx-grid__td--editing igx-date-range-picker {
  height: 100%;
}
.igx-grid__td--editing igx-time-picker [igxLabel] {
  display: none;
}
.igx-grid__td--editing input {
  margin: 0 auto;
  max-width: 100%;
}
.igx-grid__td--editing .igx-input-group__input, .igx-grid__td--editing .igx-input-group__textarea {
  font-size: 0.8125rem !important;
  line-height: 1rem !important;
}
.igx-grid__td--editing.igx-grid__td--number, .igx-grid__td--editing.igx-grid-th--number {
  justify-content: flex-start !important;
}

.igx-grid__td--pinned, .igx-grid__td--pinned-last, .igx-grid__td--pinned-first, .igx-grid-th--pinned {
  position: relative;
  background: inherit;
  z-index: 9999;
}

.igx-grid__td--pinned.igx-grid__td--column-selected {
  color: var(--row-selected-text-color);
  background: var(--row-selected-background);
}
.igx-grid__td--pinned.igx-grid__td--column-selected:hover {
  background: var(--row-selected-hover-background);
  color: var(--row-selected-text-color);
}

.igx-grid__td--pinned-last, .igx-grid-th--pinned-last {
  border-inline-end: var(--pinned-border-width) var(--pinned-border-style) var(--pinned-border-color) !important;
}
.igx-grid__td--pinned-last .igx-grid__filtering-cell, .igx-grid-th--pinned-last .igx-grid__filtering-cell, .igx-grid__td--pinned-last .igx-grid__filtering-cell--selected, .igx-grid-th--pinned-last .igx-grid__filtering-cell--selected,
.igx-grid__td--pinned-last .igx-grid-thead__title,
.igx-grid-th--pinned-last .igx-grid-thead__title,
.igx-grid__td--pinned-last .igx-grid-th,
.igx-grid-th--pinned-last .igx-grid-th {
  border-inline-end: none;
}
.igx-grid__td--pinned-first, .igx-grid-th--pinned-first {
  border-inline-start: var(--pinned-border-width) var(--pinned-border-style) var(--pinned-border-color) !important;
}
.igx-grid__td--row-pinned-first {
  overflow: hidden;
}

.igx-grid__td--pinned-chip {
  margin-inline-end: 0.75rem;
}

.igx-grid--cosy .igx-grid__td--pinned-chip {
  margin-inline-end: 0.5rem;
}

.igx-grid--compact .igx-grid__td--pinned-chip {
  margin-inline-end: 0.25rem;
}

.igx-grid-thead__title, .igx-grid-th {
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 0.75rem;
  font-weight: 600;
  min-width: 0;
  padding: 0 1.5rem;
  border-inline-end: var(--header-border-width) var(--header-border-style) var(--header-border-color);
  outline-style: none;
  overflow: hidden;
  transition: color 250ms ease-in-out;
}

.igx-grid-th--filtering {
  background: var(--filtering-header-background);
  color: var(--filtering-header-text-color);
  z-index: 3;
}

.igx-grid-th--cosy {
  padding: 0 1rem;
  min-height: 2.5rem;
}

.igx-grid-th--compact {
  padding: 0 0.75rem;
  min-height: 2rem;
}

.igx-grid-th__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
  min-width: 3ch;
  user-select: none;
  cursor: initial;
  flex-grow: 1; /* hey IE, the text should take most of the space */
  line-height: 4.1666666667;
  transition: color 250ms ease-in-out;
}

.igx-grid-th--cosy .igx-grid-th__title {
  line-height: 3.3333333333;
}

.igx-grid-th--compact .igx-grid-th__title {
  line-height: 2.6666666667;
}

.igx-grid-th__icons {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
  min-width: 1.875rem; /* sort-icon + filter icon width */
  height: 3.125rem;
  align-self: flex-end;
}
.igx-grid-th__icons:empty {
  min-width: 0;
}
.igx-grid-th__icons .sort-icon {
  position: relative;
  display: flex;
}
.igx-grid-th__icons .sort-icon igx-icon {
  width: var(--igx-icon-size, 0.9375rem);
  height: var(--igx-icon-size, 0.9375rem);
  font-size: var(--igx-icon-size, 0.9375rem);
}
.igx-grid-th__icons .sort-icon::after {
  content: attr(data-sortIndex);
  position: absolute;
  top: -0.3125rem;
  inset-inline-end: -0.0625rem;
  font-size: 0.625rem;
  text-align: end;
  font-family: sans-serif;
  line-height: 0.625rem;
}

.igx-grid-th__expander {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 0.5rem;
  cursor: pointer;
}
.igx-grid-th__expander igx-icon {
  color: var(--expand-icon-color);
}
.igx-grid-th__expander:hover igx-icon {
  color: var(--expand-icon-hover-color);
}

.igx-grid-th__group-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.igx-grid-th--collapsible {
  justify-content: normal;
}

.igx-grid-th--selectable {
  color: var(--header-selected-text-color);
  background: var(--header-selected-background);
  opacity: 0.7;
}
.igx-grid-th--selectable.igx-grid__drag-ghost-image {
  opacity: 1;
}
.igx-grid-th--selectable .sort-icon {
  color: var(--header-selected-text-color);
}
.igx-grid-th--selectable .sort-icon ::after {
  background: var(--header-selected-background);
}
.igx-grid-th--selectable.igx-grid-th--sorted .sort-icon {
  color: var(--header-selected-text-color);
}
.igx-grid-th--selectable.igx-grid-th--sorted .sort-icon:focus, .igx-grid-th--selectable.igx-grid-th--sorted .sort-icon:hover {
  color: var(--header-selected-text-color);
}

.igx-grid-th--selected {
  color: var(--header-selected-text-color);
  background: var(--header-selected-background);
}
.igx-grid-th--selected .sort-icon::after {
  background: var(--header-selected-background);
}
.igx-grid-th--selected.igx-grid-th--sorted .sort-icon {
  color: var(--header-selected-text-color);
}
.igx-grid-th--selected.igx-grid-th--sorted .sort-icon:focus, .igx-grid-th--selected.igx-grid-th--sorted .sort-icon:hover {
  color: var(--header-selected-text-color);
}

.igx-grid-th--sortable .sort-icon {
  cursor: pointer;
  opacity: 0.7;
}
.igx-grid-th--sortable .sort-icon:hover {
  opacity: 1;
}

.igx-grid-th--sorted .sort-icon {
  opacity: 1;
  color: var(--sorted-header-icon-color);
}
.igx-grid-th--sorted .sort-icon:hover {
  color: var(--sortable-header-icon-hover-color);
}

.igx-grid-th--filtrable .igx-grid-th__title {
  opacity: 0.7;
}

.igx-grid-th--filtrable.igx-grid-th--sortable .sort-icon {
  cursor: pointer;
  opacity: 0.7;
}
.igx-grid-th--filtrable.igx-grid-th--sortable .sort-icon:hover {
  opacity: 1;
}

.sort-icon {
  transition: all 250ms ease-in-out;
}

.igx-grid-th--cosy .igx-grid-th__icons {
  height: 2.5rem;
}

.igx-grid-th--compact .igx-grid-th__icons {
  height: 2rem;
}

.igx-grid__td--number, .igx-grid-th--number {
  text-align: end;
  justify-content: flex-end;
}
.igx-grid__td--number .igx-grid-th__icons, .igx-grid-th--number .igx-grid-th__icons {
  justify-content: flex-start;
  order: -1;
}
.igx-grid__td--number .igx-grid-th__icons .sort-icon, .igx-grid-th--number .igx-grid-th__icons .sort-icon {
  order: 1;
}

.igx-grid__cbx-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  background: inherit;
  z-index: 4;
  background-clip: border-box;
}

.igx-grid__cbx-padding {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: calc(1.25rem + (1.5rem * 2));
}

.igx-grid--cosy .igx-grid__cbx-padding {
  min-width: calc(1.25rem + (1rem * 2));
}

.igx-grid--compact .igx-grid__cbx-padding {
  min-width: calc(1.25rem + (0.75rem * 2));
}

.igx-grid-th__resize-handle {
  position: absolute;
  width: 0.25rem;
  top: 0;
  inset-inline-end: -0.125rem;
  height: 100%;
  z-index: 2;
}

.igx-grid-th__resize-line {
  position: absolute;
  cursor: col-resize;
  width: 0.25rem;
  background: var(--resize-line-color);
  z-index: 2;
}
.igx-grid-th__resize-line::before, .igx-grid-th__resize-line::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 6rem;
}
.igx-grid-th__resize-line::before {
  inset-inline-end: 100%;
}
.igx-grid-th__resize-line::after {
  inset-inline-start: 100%;
}

.igx-grid__summaries, .igx-grid__summaries--body {
  display: flex;
  overflow: hidden;
  background: var(--root-summaries-background);
  outline-style: none;
}
.igx-grid__summaries .igx-grid-summary__result, .igx-grid__summaries--body .igx-grid-summary__result {
  color: var(--root-summaries-text-color);
}

.igx-grid__summaries--body {
  background: var(--body-summaries-background);
  border-bottom: 0.0625rem dashed var(--row-border-color);
}
.igx-grid__summaries--body:last-of-type {
  border-bottom: none;
}
.igx-grid__summaries--body .igx-grid-summary__result {
  color: var(--body-summaries-text-color);
}

.igx-grid__summaries-patch {
  background: inherit;
  position: relative;
  z-index: 1;
  border-inline-end: 0.0625rem solid var(--header-border-color);
}

.igx-grid-th__drop-indicator-left,
.igx-grid-th__drop-indicator-right {
  position: absolute;
  width: 0.0625rem;
  height: 100%;
  top: 0;
  z-index: 1;
}

.igx-grid-th__drop-indicator-left {
  inset-inline-start: -0.0625rem;
}

.igx-grid-th__drop-indicator-right {
  inset-inline-end: -0.0625rem;
}

.igx-grid-th__drop-indicator--active.igx-grid-th__drop-indicator-left, .igx-grid-th__drop-indicator--active.igx-grid-th__drop-indicator-right {
  border-inline-end: 0.0625rem solid var(--drop-indicator-color);
}
.igx-grid-th__drop-indicator--active::after, .igx-grid-th__drop-indicator--active::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  inset-inline-start: -0.1875rem;
}
.igx-grid-th__drop-indicator--active::before {
  bottom: 0;
  border-width: 0 0.25rem 0.25rem;
  border-color: transparent transparent var(--drop-indicator-color);
}
.igx-grid-th__drop-indicator--active::after {
  top: 0;
  border-width: 0.25rem 0.25rem 0;
  border-color: var(--drop-indicator-color) transparent transparent;
}

.igx-grid__scroll-on-drag-left,
.igx-grid__scroll-on-drag-right {
  position: absolute;
  width: 0.9375rem;
  top: 0;
  height: 100%;
  z-index: 25;
}

.igx-grid__scroll-on-drag-left {
  inset-inline-start: 0;
}

.igx-grid__scroll-on-drag-right {
  inset-inline-end: 0;
}

.igx-grid__scroll-on-drag-pinned {
  position: absolute;
  width: 0.9375rem;
  height: 100%;
  top: 0;
  z-index: 25;
}

.igx-grid__drag-ghost-image {
  position: absolute;
  display: flex;
  align-items: center;
  background: var(--ghost-header-background);
  color: var(--ghost-header-text-color);
  min-width: 10.5rem;
  max-width: 20rem;
  height: 3.125rem;
  min-height: 3.125rem;
  top: -6249.9375rem;
  inset-inline-start: -6249.9375rem;
  border: none;
  box-shadow: var(--drag-shadow);
  overflow: hidden;
  z-index: 20;
}
.igx-grid__drag-ghost-image .igx-grid-th__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 0 0;
  text-align: end;
}
.igx-grid__drag-ghost-image .igx-grid-th__icons {
  display: none;
}
.igx-grid__drag-ghost-image .igx-grid-thead__title {
  border: none;
}

.igx-grid--cosy .igx-grid__drag-ghost-image {
  height: 2.5rem;
  min-height: 2.5rem;
}

.igx-grid--compact .igx-grid__drag-ghost-image {
  height: 2rem;
  min-height: 2rem;
}

.igx-grid__drag-ghost-image-icon {
  color: var(--ghost-header-icon-color);
  margin-inline-end: 0.75rem;
}

.igx-grid__drag-ghost-image-icon-group {
  color: var(--ghost-header-icon-color);
  padding: var(--comfortable);
  padding-inline-end: 0;
  margin-inline-end: 0.5rem;
}

.igx-grid__drag-col-header {
  background: var(--header-background);
}
.igx-grid__drag-col-header .igx-grid-thead__title > *, .igx-grid__drag-col-header .igx-grid-th > * {
  opacity: 0.4;
}

.igx-grid__group-row {
  background: var(--group-row-background);
  display: flex;
  outline-style: none;
  border-bottom: 0.0625rem solid var(--row-border-color);
  min-height: 3.125rem;
}
.igx-grid__group-row .igx-grid__drag-indicator {
  cursor: default;
  flex-grow: 0;
}

.igx-grid__group-row--active {
  background: var(--group-row-selected-background);
}
.igx-grid__group-row--active .igx-grid__grouping-indicator {
  color: var(--expand-icon-color);
}
.igx-grid__group-row--active .igx-grid__drag-indicator {
  border: 0.0625rem solid var(--cell-active-border-color);
  border-inline-start-width: 0;
  border-inline-end-width: 0;
  box-shadow: inset 0.0625rem 0 0 0 var(--cell-active-border-color);
}
.igx-grid__group-row--active:hover {
  background: var(--group-row-selected-background);
}

.igx-grid--cosy .igx-grid__group-row {
  min-height: 2.5rem;
}

.igx-grid--compact .igx-grid__group-row {
  min-height: 2rem;
}

.igx-group-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1rem;
}
.igx-group-label > * {
  margin-inline-end: 0.25rem;
}
.igx-group-label > *:last-child {
  margin-inline-end: 0;
}

.igx-group-label__icon {
  user-select: none;
}
.igx-group-label__icon igx-icon {
  color: var(--group-label-icon);
  width: var(--igx-icon-size, 1rem);
  height: var(--igx-icon-size, 1rem);
  font-size: var(--igx-icon-size, 1rem);
}

.igx-group-label__column-name {
  color: var(--group-label-column-name-text);
  font-weight: 600;
  font-size: 0.75rem;
}

.igx-group-label__count-badge {
  --background-color: var(--group-count-background);
  --text-color: var(--group-count-text-color);
}
.igx-group-label__count-badge > span {
  font-size: 0.75rem;
}

.igx-group-label__text {
  font-size: 0.8125rem;
  color: var(--group-label-text);
}

[dir=rtl] .igx-grid__group-content {
  padding-inline-start: 1.5rem;
}
[dir=rtl] .igx-grid--cosy .igx-grid__group-content, .igx-grid--cosy [dir=rtl] .igx-grid__group-content {
  padding-inline-start: 1rem;
}
[dir=rtl] .igx-grid--compact .igx-grid__group-content, .igx-grid--compact [dir=rtl] .igx-grid__group-content {
  padding-inline-start: 0.75rem;
}
[dir=rtl] .igx-group-label > * {
  margin-inline-start: 0.25rem;
}
[dir=rtl] .igx-group-label > *:last-child {
  margin-inline-start: 0;
}

.igx-grid__group-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  padding-inline-start: 1.5rem;
  min-height: 3.125rem;
}
.igx-grid__group-content:focus {
  outline: transparent;
}

.igx-grid--cosy .igx-grid__group-content {
  padding-inline-start: 1rem;
  min-height: 2.5rem;
}

.igx-grid--compact .igx-grid__group-content {
  padding-inline-start: 0.75rem;
  min-height: 2rem;
}

.igx-grid__row-indentation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  border-inline-end: 0.0625rem solid var(--header-border-color);
  background: inherit;
  z-index: 1;
  background-clip: border-box;
}
.igx-grid__row-indentation::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: -0.0625rem;
  inset-inline-start: 0;
  background: transparent;
}
.igx-grid__row-indentation .igx-button--icon {
  width: 1.75rem;
  height: 1.75rem;
  color: var(--expand-all-icon-color);
}
.igx-grid__row-indentation:focus .igx-button--icon, .igx-grid__row-indentation:hover .igx-button--icon {
  color: var(--expand-all-icon-hover-color);
}

.igx-grid--cosy .igx-grid__row-indentation {
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
}

.igx-grid--compact .igx-grid__row-indentation {
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
}

.igx-grid-grouparea {
  grid-row: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-bottom: var(--header-border-width) var(--header-border-style) var(--header-border-color);
  background: var(--grouparea-background);
  color: var(--grouparea-color);
  min-height: 3.5625rem;
  padding: 0 1.5rem;
  z-index: 2;
  height: 100%;
  overflow: hidden;
}
.igx-grid-grouparea:focus {
  outline-style: none;
}
.igx-grid-grouparea .igx-chip {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.igx-grid-grouparea__connector {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem;
}
.igx-grid-grouparea__connector igx-icon {
  width: var(--igx-icon-size, 1rem);
  height: var(--igx-icon-size, 1rem);
  font-size: var(--igx-icon-size, 1rem);
}
[dir=rtl] .igx-grid-grouparea__connector {
  transform: scaleX(-1);
}

.igx-grid-grouparea--cosy {
  min-height: 3.0625rem;
  padding: 0 1rem;
}
.igx-grid-grouparea--cosy .igx-chip {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.igx-grid-grouparea--compact {
  min-height: 2.5625rem;
  padding: 0 0.75rem;
}
.igx-grid-grouparea--compact .igx-chip {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.igx-drop-area, .igx-drop-area--compact, .igx-drop-area--cosy {
  min-width: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
  border-radius: var(--drop-area-border-radius);
  padding: 0 1.5rem;
  flex: 1 0 0%;
  background: var(--drop-area-background);
}
.igx-drop-area .igx-drop-area__icon, .igx-drop-area--compact .igx-drop-area__icon, .igx-drop-area--cosy .igx-drop-area__icon {
  color: var(--drop-area-icon-color);
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  margin-inline-end: 0.5rem;
}

.igx-drop-area--hover {
  background: var(--drop-area-on-drop-background);
}

.igx-drop-area--compact {
  height: 1.5rem;
  border-radius: var(--drop-area-border-radius);
  padding: 0 1rem;
}

.igx-drop-area--cosy {
  height: 1.5rem;
  border-radius: var(--drop-area-border-radius);
  padding: 0 0.75rem;
}

.igx-drop-area__text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--drop-area-text-color);
  font-size: 0.8125rem;
}

.igx-grid__grouping-indicator {
  position: relative;
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  padding-inline-end: 0.75rem;
  margin-inline-start: 1.5rem;
  min-height: 3.125rem;
}
.igx-grid__grouping-indicator igx-icon {
  color: var(--expand-icon-color);
  width: var(--igx-icon-size, 1.5rem);
}
.igx-grid__grouping-indicator:hover, .igx-grid__grouping-indicator:focus {
  outline-style: none;
}
.igx-grid__grouping-indicator:hover igx-icon, .igx-grid__grouping-indicator:focus igx-icon {
  color: var(--expand-icon-hover-color);
}
[dir=rtl] .igx-grid__grouping-indicator {
  transform: scaleX(-1);
}

.igx-grid--cosy .igx-grid__grouping-indicator {
  min-height: 2.5rem;
  margin-inline-start: 1rem;
}

.igx-grid--compact .igx-grid__grouping-indicator {
  min-height: 2rem;
  margin-inline-start: 0.75rem;
}

.igx-grid__header-indentation {
  position: relative;
  padding-inline-end: 1.5rem;
  border-inline-end: 0.0625rem solid var(--header-border-color);
  background: var(--header-background);
  z-index: 4;
}

.igx-grid--cosy .igx-grid__header-indentation {
  padding-inline-end: 1rem;
}

.igx-grid--compact .igx-grid__header-indentation {
  padding-inline-end: 0.75rem;
}

.igx-grid__group-expand-btn {
  position: absolute;
  cursor: pointer;
  user-select: none;
  top: calc(50% - 12px);
  inset-inline-start: 1.5rem;
}
.igx-grid__group-expand-btn:hover {
  color: var(--expand-icon-hover-color);
}
.igx-grid__group-expand-btn.igx-grid__group-expand-btn--push {
  top: 0.8125rem;
}

.igx-grid--cosy .igx-grid__group-expand-btn {
  inset-inline-start: 1rem;
}
.igx-grid--cosy .igx-grid__group-expand-btn.igx-grid__group-expand-btn--push {
  top: 0.5rem;
}

.igx-grid--compact .igx-grid__group-expand-btn {
  inset-inline-start: 0.75rem;
}
.igx-grid--compact .igx-grid__group-expand-btn.igx-grid__group-expand-btn--push {
  top: 0.25rem;
}

.igx-grid__row-indentation--level-1 {
  padding-inline-start: calc(1.5rem + 1.5rem);
}

.igx-grid__group-row--padding-level-1 .igx-grid__grouping-indicator {
  padding-inline-start: 1.5rem;
}

.igx-grid--cosy .igx-grid__row-indentation--level-1 {
  padding-inline-start: calc(1rem + 1.5rem);
}

.igx-grid--cosy .igx-grid__group-row--padding-level-1 .igx-grid__grouping-indicator {
  padding-inline-start: 1rem;
}

.igx-grid--compact .igx-grid__row-indentation--level-1 {
  padding-inline-start: calc(0.75rem + 1.5rem);
}

.igx-grid--compact .igx-grid__group-row--padding-level-1 .igx-grid__grouping-indicator {
  padding-inline-start: 0.75rem;
}

.igx-grid__row-indentation--level-2 {
  padding-inline-start: calc(3rem + 1.5rem);
}

.igx-grid__group-row--padding-level-2 .igx-grid__grouping-indicator {
  padding-inline-start: 3rem;
}

.igx-grid--cosy .igx-grid__row-indentation--level-2 {
  padding-inline-start: calc(2rem + 1.5rem);
}

.igx-grid--cosy .igx-grid__group-row--padding-level-2 .igx-grid__grouping-indicator {
  padding-inline-start: 2rem;
}

.igx-grid--compact .igx-grid__row-indentation--level-2 {
  padding-inline-start: calc(1.5rem + 1.5rem);
}

.igx-grid--compact .igx-grid__group-row--padding-level-2 .igx-grid__grouping-indicator {
  padding-inline-start: 1.5rem;
}

.igx-grid__row-indentation--level-3 {
  padding-inline-start: calc(4.5rem + 1.5rem);
}

.igx-grid__group-row--padding-level-3 .igx-grid__grouping-indicator {
  padding-inline-start: 4.5rem;
}

.igx-grid--cosy .igx-grid__row-indentation--level-3 {
  padding-inline-start: calc(3rem + 1.5rem);
}

.igx-grid--cosy .igx-grid__group-row--padding-level-3 .igx-grid__grouping-indicator {
  padding-inline-start: 3rem;
}

.igx-grid--compact .igx-grid__row-indentation--level-3 {
  padding-inline-start: calc(2.25rem + 1.5rem);
}

.igx-grid--compact .igx-grid__group-row--padding-level-3 .igx-grid__grouping-indicator {
  padding-inline-start: 2.25rem;
}

.igx-grid__row-indentation--level-4 {
  padding-inline-start: calc(6rem + 1.5rem);
}

.igx-grid__group-row--padding-level-4 .igx-grid__grouping-indicator {
  padding-inline-start: 6rem;
}

.igx-grid--cosy .igx-grid__row-indentation--level-4 {
  padding-inline-start: calc(4rem + 1.5rem);
}

.igx-grid--cosy .igx-grid__group-row--padding-level-4 .igx-grid__grouping-indicator {
  padding-inline-start: 4rem;
}

.igx-grid--compact .igx-grid__row-indentation--level-4 {
  padding-inline-start: calc(3rem + 1.5rem);
}

.igx-grid--compact .igx-grid__group-row--padding-level-4 .igx-grid__grouping-indicator {
  padding-inline-start: 3rem;
}

.igx-grid__row-indentation--level-5 {
  padding-inline-start: calc(7.5rem + 1.5rem);
}

.igx-grid__group-row--padding-level-5 .igx-grid__grouping-indicator {
  padding-inline-start: 7.5rem;
}

.igx-grid--cosy .igx-grid__row-indentation--level-5 {
  padding-inline-start: calc(5rem + 1.5rem);
}

.igx-grid--cosy .igx-grid__group-row--padding-level-5 .igx-grid__grouping-indicator {
  padding-inline-start: 5rem;
}

.igx-grid--compact .igx-grid__row-indentation--level-5 {
  padding-inline-start: calc(3.75rem + 1.5rem);
}

.igx-grid--compact .igx-grid__group-row--padding-level-5 .igx-grid__grouping-indicator {
  padding-inline-start: 3.75rem;
}

.igx-grid__row-indentation--level-6 {
  padding-inline-start: calc(9rem + 1.5rem);
}

.igx-grid__group-row--padding-level-6 .igx-grid__grouping-indicator {
  padding-inline-start: 9rem;
}

.igx-grid--cosy .igx-grid__row-indentation--level-6 {
  padding-inline-start: calc(6rem + 1.5rem);
}

.igx-grid--cosy .igx-grid__group-row--padding-level-6 .igx-grid__grouping-indicator {
  padding-inline-start: 6rem;
}

.igx-grid--compact .igx-grid__row-indentation--level-6 {
  padding-inline-start: calc(4.5rem + 1.5rem);
}

.igx-grid--compact .igx-grid__group-row--padding-level-6 .igx-grid__grouping-indicator {
  padding-inline-start: 4.5rem;
}

.igx-grid__row-indentation--level-7 {
  padding-inline-start: calc(10.5rem + 1.5rem);
}

.igx-grid__group-row--padding-level-7 .igx-grid__grouping-indicator {
  padding-inline-start: 10.5rem;
}

.igx-grid--cosy .igx-grid__row-indentation--level-7 {
  padding-inline-start: calc(7rem + 1.5rem);
}

.igx-grid--cosy .igx-grid__group-row--padding-level-7 .igx-grid__grouping-indicator {
  padding-inline-start: 7rem;
}

.igx-grid--compact .igx-grid__row-indentation--level-7 {
  padding-inline-start: calc(5.25rem + 1.5rem);
}

.igx-grid--compact .igx-grid__group-row--padding-level-7 .igx-grid__grouping-indicator {
  padding-inline-start: 5.25rem;
}

.igx-grid__row-indentation--level-8 {
  padding-inline-start: calc(12rem + 1.5rem);
}

.igx-grid__group-row--padding-level-8 .igx-grid__grouping-indicator {
  padding-inline-start: 12rem;
}

.igx-grid--cosy .igx-grid__row-indentation--level-8 {
  padding-inline-start: calc(8rem + 1.5rem);
}

.igx-grid--cosy .igx-grid__group-row--padding-level-8 .igx-grid__grouping-indicator {
  padding-inline-start: 8rem;
}

.igx-grid--compact .igx-grid__row-indentation--level-8 {
  padding-inline-start: calc(6rem + 1.5rem);
}

.igx-grid--compact .igx-grid__group-row--padding-level-8 .igx-grid__grouping-indicator {
  padding-inline-start: 6rem;
}

.igx-grid__row-indentation--level-9 {
  padding-inline-start: calc(13.5rem + 1.5rem);
}

.igx-grid__group-row--padding-level-9 .igx-grid__grouping-indicator {
  padding-inline-start: 13.5rem;
}

.igx-grid--cosy .igx-grid__row-indentation--level-9 {
  padding-inline-start: calc(9rem + 1.5rem);
}

.igx-grid--cosy .igx-grid__group-row--padding-level-9 .igx-grid__grouping-indicator {
  padding-inline-start: 9rem;
}

.igx-grid--compact .igx-grid__row-indentation--level-9 {
  padding-inline-start: calc(6.75rem + 1.5rem);
}

.igx-grid--compact .igx-grid__group-row--padding-level-9 .igx-grid__grouping-indicator {
  padding-inline-start: 6.75rem;
}

.igx-grid__row-indentation--level-10 {
  padding-inline-start: calc(15rem + 1.5rem);
}

.igx-grid__group-row--padding-level-10 .igx-grid__grouping-indicator {
  padding-inline-start: 15rem;
}

.igx-grid--cosy .igx-grid__row-indentation--level-10 {
  padding-inline-start: calc(10rem + 1.5rem);
}

.igx-grid--cosy .igx-grid__group-row--padding-level-10 .igx-grid__grouping-indicator {
  padding-inline-start: 10rem;
}

.igx-grid--compact .igx-grid__row-indentation--level-10 {
  padding-inline-start: calc(7.5rem + 1.5rem);
}

.igx-grid--compact .igx-grid__group-row--padding-level-10 .igx-grid__grouping-indicator {
  padding-inline-start: 7.5rem;
}

.igx-grid__outlet {
  z-index: 10002;
  position: fixed;
}

.igx-grid__loading-outlet {
  z-index: 10003;
}
.igx-grid__loading-outlet > .igx-overlay__wrapper--modal {
  background: none;
}
.igx-grid__loading-outlet .igx-circular-bar {
  width: 3.125rem;
  height: 3.125rem;
}

.igx-grid__row-editing-outlet {
  z-index: 10000;
  position: absolute;
}
.igx-grid__row-editing-outlet .igx-overlay__wrapper, .igx-grid__row-editing-outlet .igx-overlay__wrapper--modal, .igx-grid__row-editing-outlet .igx-overlay__wrapper--flex, .igx-grid__row-editing-outlet .igx-overlay__wrapper--flex-container {
  /* Change wrapper position from 'fixed' to 'absolute' so that it is hidden when scrolled below the parent grid body content. */
  position: absolute;
}

.igx-grid__addrow-snackbar {
  position: absolute;
  z-index: 5;
  bottom: 1.5rem;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}

.igx-grid__filtering-cell, .igx-grid__filtering-cell--selected {
  display: flex;
  align-items: center;
  border-inline-end: var(--header-border-width) var(--header-border-style) var(--header-border-color);
  border-top: var(--header-border-width) var(--header-border-style) var(--header-border-color);
  height: 3.125rem;
  padding: 0 1.5rem;
  overflow: hidden;
}
.igx-grid__filtering-cell igx-chips-area, .igx-grid__filtering-cell--selected igx-chips-area {
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  flex-wrap: nowrap;
}
.igx-grid__filtering-cell igx-chips-area .igx-filtering-chips__connector, .igx-grid__filtering-cell--selected igx-chips-area .igx-filtering-chips__connector {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 0.5rem;
}

.igx-grid__filtering-cell--selected {
  color: var(--header-selected-text-color);
  background: var(--header-selected-background);
}

.igx-grid__filtering-cell-indicator, .igx-grid__filtering-cell-indicator--hidden {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline-end: 0.5rem;
  margin-inline-start: 0.5rem;
  cursor: pointer;
  visibility: visible;
}
.igx-grid__filtering-cell-indicator igx-icon, .igx-grid__filtering-cell-indicator--hidden igx-icon {
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
}
.igx-grid__filtering-cell-indicator .igx-badge, .igx-grid__filtering-cell-indicator--hidden .igx-badge {
  /* start of IE vertical alignment fix */
  top: 50%;
  transform: translateY(-50%);
  /* end of IE vertical alignment fix */
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  min-width: 0.875rem;
  font-size: 0.75rem;
  text-align: center;
  inset-inline-end: 0;
}

.igx-grid__filtering-cell-indicator--hidden {
  visibility: hidden;
}

.igx-grid__filtering-cell--cosy {
  height: 2.5rem;
  padding: 0 1.5rem;
}

.igx-grid__filtering-cell--compact {
  height: 2rem;
  padding: 0 1.5rem;
}

.igx-grid__filtering-row {
  position: absolute;
  display: flex;
  width: 100%;
  height: 3.125rem;
  padding: 0 1rem;
  align-items: center;
  justify-content: space-between;
  background: var(--filtering-row-background);
  color: var(--filtering-row-text-color);
  inset-inline-start: 0;
  bottom: 0;
  z-index: 3;
}
.igx-grid__filtering-row::after {
  display: block;
  position: absolute;
  content: "";
  background: inherit;
  inset-inline-start: 0;
  inset-inline-end: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0 0.0625rem 0 var(--filtering-row-background), 0 0.25rem 0.625rem rgba(0, 0, 0, 0.12);
  z-index: -1;
}
.igx-grid__filtering-row igx-input-group {
  --theme: fluent;
  width: 100%;
  max-width: 12.5rem;
  min-width: 8.75rem;
}
.igx-grid__filtering-row igx-prefix:focus {
  color: hsla(var(--ig-secondary-500), var(--ig-secondary-a));
}
.igx-grid__filtering-row igx-suffix igx-icon {
  outline-style: none;
}
.igx-grid__filtering-row igx-suffix igx-icon:focus {
  color: hsla(var(--ig-secondary-500), var(--ig-secondary-a));
}
.igx-grid__filtering-row igx-suffix igx-icon + igx-icon {
  margin-inline-start: 0.25rem;
}

.igx-grid__filtering-dropdown-items {
  display: flex;
  align-items: center;
}

.igx-grid__filtering-dropdown-text {
  margin-inline-start: 1rem;
}

.igx-grid__filtering-row--cosy {
  height: 2.5rem;
}

.igx-grid__filtering-row--compact {
  height: 2rem;
}

.igx-grid__filtering-row-main {
  display: flex;
  flex: 1;
  overflow: hidden;
  max-width: calc(100% - 176px);
  min-width: 3.5rem;
}
.igx-grid__filtering-row-main igx-chips-area {
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  flex-wrap: nowrap;
  margin: 0 0.5rem;
}
.igx-grid__filtering-row-main igx-chip {
  margin: 0 0.25rem;
}
.igx-grid__filtering-row-main [igxButton] igx-icon {
  position: absolute;
  inset-inline-start: 0.75rem;
  /* IE fix for vertical alignment*/
  top: 50%;
  transform: translateY(-50%);
}
.igx-grid__filtering-row-main [igxButton] span {
  margin-inline-start: 1rem;
}

.igx-grid__filtering-row-scroll-start {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  overflow: visible;
  margin: 0 0.5rem;
  z-index: 1;
}
.igx-grid__filtering-row-scroll-start::after {
  display: block;
  position: absolute;
  width: 0.625rem;
  content: "";
  inset-block: -0.125rem;
  background: linear-gradient(to right, var(--filtering-row-background), transparent);
  inset-inline-start: calc(100% + 6px);
}
[dir=rtl] .igx-grid__filtering-row-scroll-start {
  transform: scaleX(-1);
}
[dir=rtl] .igx-grid__filtering-row-scroll-start::after {
  content: initial;
}
[dir=rtl] .igx-grid__filtering-row-scroll-start::before {
  display: block;
  position: absolute;
  width: 0.625rem;
  content: "";
  inset-block: -0.125rem;
  background: linear-gradient(to right, var(--filtering-row-background), transparent);
  inset-inline-end: calc(100% + 6px);
}

.igx-grid__filtering-row-scroll-end {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  overflow: visible;
  margin: 0 0.5rem;
  z-index: 1;
}
.igx-grid__filtering-row-scroll-end::before {
  display: block;
  position: absolute;
  width: 0.625rem;
  content: "";
  inset-block: -0.125rem;
  background: linear-gradient(to left, var(--filtering-row-background), transparent);
  inset-inline-end: calc(100% + 6px);
}
[dir=rtl] .igx-grid__filtering-row-scroll-end {
  transform: scaleX(-1);
}
[dir=rtl] .igx-grid__filtering-row-scroll-end::before {
  content: initial;
}
[dir=rtl] .igx-grid__filtering-row-scroll-end::after {
  display: block;
  position: absolute;
  width: 0.625rem;
  content: "";
  inset-block: -0.125rem;
  background: linear-gradient(to left, var(--filtering-row-background), transparent);
  inset-inline-start: calc(100% + 6px);
}

.igx-grid__tr--highlighted {
  position: relative;
}
.igx-grid__tr--highlighted::after {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 0.25rem;
  height: 100%;
  background: var(--row-highlight);
  z-index: 3;
}
.igx-grid__tr--highlighted .igx-grid__tr--edited::before {
  inset-inline-start: 0.25rem;
}
.igx-grid__tr--highlighted::before {
  inset-inline-start: 0.25rem;
}

.igx-grid__tr-container {
  border-bottom: 0.0625rem solid var(--row-border-color);
}

.igx-grid__hierarchical-expander, .igx-grid__hierarchical-expander--empty {
  user-select: none;
  background: inherit;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
  color: var(--expand-icon-color);
  background-clip: border-box;
}
.igx-grid__hierarchical-expander:focus, .igx-grid__hierarchical-expander--empty:focus {
  outline: none;
}
.igx-grid__hierarchical-expander:focus igx-icon, .igx-grid__hierarchical-expander--empty:focus igx-icon {
  color: var(--expand-icon-hover-color);
}
.igx-grid__hierarchical-expander:hover igx-icon, .igx-grid__hierarchical-expander--empty:hover igx-icon {
  color: var(--expand-icon-hover-color);
}
.igx-grid__hierarchical-expander igx-icon, .igx-grid__hierarchical-expander--empty igx-icon {
  color: var(--expand-icon-color);
  max-width: 1.5rem;
  min-width: 1.5rem;
}
[dir=rtl] .igx-grid__hierarchical-expander, [dir=rtl] .igx-grid__hierarchical-expander--empty {
  transform: scaleX(-1);
}
.igx-grid__hierarchical-expander--empty, .igx-grid--cosy .igx-grid__hierarchical-expander--empty, .igx-grid--compact .igx-grid__hierarchical-expander--empty {
  cursor: default;
  pointer-events: none;
}

.igx-grid--cosy .igx-grid__hierarchical-expander, .igx-grid--cosy .igx-grid__hierarchical-expander--empty {
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
}

.igx-grid--compact .igx-grid__hierarchical-expander, .igx-grid--compact .igx-grid__hierarchical-expander--empty {
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
}

.igx-grid__hierarchical-expander--header {
  background: inherit;
  border-inline-end: 0.0625rem solid var(--header-border-color);
  z-index: 3;
  background-clip: border-box;
}
.igx-grid__hierarchical-expander--header igx-icon {
  display: flex;
  align-items: center;
}

.igx-grid__hierarchical-expander--push {
  align-items: flex-start;
}
.igx-grid__hierarchical-expander--push igx-icon {
  min-height: 3.125rem;
  max-height: 3.125rem;
}

.igx-grid--cosy .igx-grid__hierarchical-expander--push igx-icon {
  min-height: 2.5rem;
}

.igx-grid--compact .igx-grid__hierarchical-expander--push igx-icon {
  min-height: 2rem;
}

.igx-grid__header-indentation--no-border {
  border-inline-end: 0.0625rem solid transparent;
}

.igx-grid__hierarchical-indent {
  margin-inline-start: calc(2 * 1.5rem + 1.5rem);
  margin-top: 1.5rem;
  margin-inline-end: 1.5rem;
  margin-bottom: 1.5rem;
}
.igx-grid__hierarchical-indent--scroll {
  margin-inline-end: calc(1.5rem + 18px);
}

.igx-grid--cosy .igx-grid__hierarchical-indent {
  margin-inline-start: calc(2 * 1rem + 1.5rem);
  margin-top: 1rem;
  margin-inline-end: 1rem;
  margin-bottom: 1rem;
}
.igx-grid--cosy .igx-grid__hierarchical-indent--scroll {
  margin-inline-end: calc(1rem + 18px);
}

.igx-grid--compact .igx-grid__hierarchical-indent {
  margin-inline-start: calc(2 * 0.75rem + 1.5rem);
  margin-top: 0.75rem;
  margin-inline-end: 0.75rem;
  margin-bottom: 0.75rem;
}
.igx-grid--compact .igx-grid__hierarchical-indent--scroll {
  margin-inline-end: calc(0.75rem + 18px);
}

.igx-excel-filter {
  display: block;
  width: 20rem;
  height: 100%;
  flex-grow: 1;
  box-shadow: var(--ig-elevation-12);
  overflow: auto;
  min-width: 20rem;
}

.igx-excel-filter--inline {
  box-shadow: none;
  width: 100%;
}

.igx-excel-filter__loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.igx-excel-filter__icon, .igx-excel-filter__icon--filtered {
  display: flex;
  cursor: pointer;
}
.igx-excel-filter__icon igx-icon, .igx-excel-filter__icon--filtered igx-icon {
  width: var(--igx-icon-size, 0.9375rem);
  height: var(--igx-icon-size, 0.9375rem);
  font-size: var(--igx-icon-size, 0.9375rem);
}

.igx-excel-filter__icon--filtered igx-icon {
  color: hsla(var(--ig-secondary-500), var(--ig-secondary-a));
}

.igx-excel-filter__menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--filtering-row-background);
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.25rem), 0.25rem);
}
.igx-excel-filter__menu igx-buttongroup {
  --shadow: none;
  --item-background: var(--filtering-row-background);
  --item-hover-background: hsla(var(--ig-gray-100), var(--ig-gray-a));
  --item-selected-background: hsla(var(--ig-gray-100), var(--ig-gray-a));
  --item-text-color: hsla(var(--ig-gray-700), var(--ig-gray-a));
  --item-hover-text-color: hsla(var(--ig-gray-800), var(--ig-gray-a));
  --item-selected-text-color: hsla(var(--ig-secondary-500), var(--ig-secondary-a));
  --item-border-color: transparent;
  --item-selected-border-color: transparent;
  --item-disabled-border: transparent;
}
.igx-excel-filter__menu,
.igx-excel-filter__menu igx-tree {
  --background: var(--igx-tree-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --foreground: var(--igx-tree-foreground, var(--ig-surface-500-contrast));
  --background-selected: var(--igx-tree-background-selected, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --foreground-selected: var(--igx-tree-foreground-selected, var(--ig-surface-500-contrast));
  --background-active: var(--igx-tree-background-active, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --foreground-active: var(--igx-tree-foreground-active, var(--ig-surface-500-contrast));
  --background-active-selected: var(--igx-tree-background-active-selected, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --foreground-active-selected: var(--igx-tree-foreground-active-selected, var(--ig-surface-500-contrast));
  --border-color: var(--igx-tree-border-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --background-disabled: var(--igx-tree-background-disabled, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --foreground-disabled: var(--igx-tree-foreground-disabled, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --drop-area-color: var(--igx-tree-drop-area-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --hover-color: var(--igx-tree-hover-color, hsla(var(--ig-gray-900), 0.1));
}
.igx-excel-filter__menu .igx-tree {
  display: block;
  z-index: 0;
  overflow-y: auto;
}
.igx-excel-filter__menu .igx-tree-node,
.igx-excel-filter__menu .igx-tree-node__wrapper,
.igx-excel-filter__menu .igx-tree-node__toggle-button,
.igx-excel-filter__menu .igx-tree-node__content,
.igx-excel-filter__menu .igx-tree-node__select {
  display: flex;
}
.igx-excel-filter__menu .igx-tree-node {
  flex-direction: column;
}
.igx-excel-filter__menu .igx-tree-node__wrapper,
.igx-excel-filter__menu .igx-tree-node__toggle-button,
.igx-excel-filter__menu .igx-tree-node__select {
  align-items: center;
}
.igx-excel-filter__menu .igx-tree-node__toggle-button,
.igx-excel-filter__menu .igx-tree-node__select {
  margin-inline-end: 0.5rem;
}
.igx-excel-filter__menu .igx-tree-node__content,
.igx-excel-filter__menu .igx-tree-node__toggle-button,
.igx-excel-filter__menu .igx-tree-node__select {
  z-index: 1;
}
.igx-excel-filter__menu .igx-tree-node__toggle-button--hidden {
  visibility: hidden;
}
.igx-excel-filter__menu .igx-tree-node__wrapper {
  min-height: 3.125rem;
  padding: 0 1.5rem;
  position: relative;
  background: var(--background);
  color: var(--foreground);
}
.igx-excel-filter__menu .igx-tree-node__wrapper igx-icon {
  width: var(--igx-icon-size, 1.5rem);
  height: var(--igx-icon-size, 1.5rem);
  font-size: var(--igx-icon-size, 1.5rem);
}
[dir=rtl] .igx-excel-filter__menu .igx-tree-node__wrapper igx-icon {
  transform: scaleX(-1);
}
.igx-excel-filter__menu .igx-tree-node__wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 0;
}
.igx-excel-filter__menu .igx-tree-node__wrapper:hover::after {
  background: var(--hover-color);
}
.igx-excel-filter__menu .igx-tree-node__wrapper:focus {
  outline-width: 0;
}
.igx-excel-filter__menu .igx-tree-node__wrapper igx-circular-bar {
  width: 1.5rem;
  height: 1.5rem;
}
.igx-excel-filter__menu .igx-tree-node__wrapper--cosy {
  min-height: 2.5rem;
  padding: 0 1rem;
}
.igx-excel-filter__menu .igx-tree-node__wrapper--cosy .igx-tree-node__spacer {
  width: 1rem;
}
.igx-excel-filter__menu .igx-tree-node__wrapper--cosy .igx-tree-node__drop-indicator {
  inset-inline-end: 1rem;
  width: calc(100% - ((1rem * 2) + (1.5rem + 0.5rem)));
}
.igx-excel-filter__menu .igx-tree-node__wrapper--cosy igx-circular-bar {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
}
.igx-excel-filter__menu .igx-tree-node__wrapper--compact {
  min-height: 2rem;
  padding: 0 0.75rem;
}
.igx-excel-filter__menu .igx-tree-node__wrapper--compact .igx-tree-node__spacer {
  width: 0.75rem;
}
.igx-excel-filter__menu .igx-tree-node__wrapper--compact .igx-tree-node__drop-indicator {
  inset-inline-end: 0.75rem;
  width: calc(100% - ((0.75rem * 2) + (1.5rem + 0.5rem)));
}
.igx-excel-filter__menu .igx-tree-node__wrapper--compact igx-circular-bar {
  width: calc(1.5rem - 6px);
  height: calc(1.5rem - 6px);
}
.igx-excel-filter__menu .igx-tree-node__wrapper--selected {
  background: var(--background-selected);
  color: var(--foreground-selected);
}
.igx-excel-filter__menu .igx-tree-node__wrapper--active {
  background: var(--background-active);
  color: var(--foreground-active);
}
.igx-excel-filter__menu .igx-tree-node__wrapper--active.igx-tree-node__wrapper--selected {
  background: var(--background-active-selected);
  color: var(--foreground-active-selected);
}
.igx-excel-filter__menu .igx-tree-node__wrapper--focused {
  box-shadow: inset 0 0 0 0.0625rem var(--border-color);
}
.igx-excel-filter__menu .igx-tree-node__wrapper--disabled {
  background: var(--background-disabled) !important;
  color: var(--foreground-disabled) !important;
  box-shadow: none !important;
  pointer-events: none;
}
.igx-excel-filter__menu .igx-tree-node__wrapper--disabled::after {
  display: none;
}
.igx-excel-filter__menu .igx-tree-node__wrapper--disabled .igx-tree-node__toggle-button {
  color: var(--foreground-disabled) !important;
}
.igx-excel-filter__menu .igx-tree-node__spacer {
  display: inline-block;
  width: 1.5rem;
}
.igx-excel-filter__menu .igx-tree-node__content {
  display: block;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.igx-excel-filter__menu .igx-tree-node__toggle-button {
  justify-content: center;
  cursor: pointer;
  user-select: none;
  min-width: 1.5rem;
}
.igx-excel-filter__menu .igx-tree-node__drop-indicator {
  display: flex;
  visibility: hidden;
  position: absolute;
  inset-inline-end: 1.5rem;
  bottom: 0;
  width: calc(100% - ((1.5rem * 2) + (1.5rem + 0.5rem)));
}
.igx-excel-filter__menu .igx-tree-node__drop-indicator > div {
  flex: 1;
  height: 0.0625rem;
  background: var(--drop-area-color);
}
.igx-excel-filter__menu .igx-tree-node__group {
  overflow: hidden;
}
.igx-excel-filter__menu .igx-tree-node__wrapper {
  padding: 0;
}
.igx-excel-filter__menu igx-chips-area {
  padding: 1rem 1rem 0 1rem;
  gap: 0.5rem;
}

.igx-excel-filter__menu-header, .igx-excel-filter__secondary-header {
  display: flex;
  align-items: center;
  padding: 1rem;
  color: hsla(var(--ig-gray-700), var(--ig-gray-a));
}

.igx-excel-filter__menu-header-actions {
  display: flex;
  margin-inline-start: auto;
}
.igx-excel-filter__menu-header-actions [igxButton] + [igxButton] {
  margin-inline-start: 0.25rem;
}
.igx-excel-filter__menu-header-actions .igx-excel-filter__actions-pin, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-pin--disabled, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-unpin, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-hide, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-select, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-selected, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-filter, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-clear, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-clear--disabled {
  padding: 0 !important;
  margin: 0 !important;
}
.igx-excel-filter__menu-header-actions .igx-excel-filter__actions-pin, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-pin--disabled, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-unpin, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-hide, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-select, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-selected, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-filter, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-clear, .igx-excel-filter__menu-header-actions .igx-excel-filter__actions-clear--disabled {
  justify-content: center;
}

.igx-excel-filter__menu-footer, .igx-excel-filter__secondary-footer {
  display: flex;
  justify-content: space-between;
}
.igx-excel-filter__menu-footer .igx-excel-filter__apply, .igx-excel-filter__secondary-footer .igx-excel-filter__apply,
.igx-excel-filter__menu-footer .igx-excel-filter__cancel,
.igx-excel-filter__secondary-footer .igx-excel-filter__cancel {
  flex-grow: 1;
}
.igx-excel-filter__menu-footer .igx-excel-filter__apply [igxButton], .igx-excel-filter__secondary-footer .igx-excel-filter__apply [igxButton],
.igx-excel-filter__menu-footer .igx-excel-filter__cancel [igxButton],
.igx-excel-filter__secondary-footer .igx-excel-filter__cancel [igxButton] {
  width: 100%;
}

.igx-excel-filter__clear {
  flex-grow: 1;
}

.igx-excel-filter__cancel + .igx-excel-filter__apply {
  margin-inline-start: 1rem;
}

.igx-excel-filter__sort,
.igx-excel-filter__move {
  display: block;
  padding: 0.5rem 1rem;
}
.igx-excel-filter__sort header,
.igx-excel-filter__move header {
  color: hsla(var(--ig-gray-700), var(--ig-gray-a));
  margin-bottom: 0.25rem;
}
.igx-excel-filter__sort igx-icon,
.igx-excel-filter__move igx-icon {
  display: initial;
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
}

.igx-excel-filter__menu--compact .igx-excel-filter__sort,
.igx-excel-filter__menu--compact .igx-excel-filter__move {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.igx-excel-filter__menu--compact .igx-excel-filter__sort header,
.igx-excel-filter__menu--compact .igx-excel-filter__move header {
  color: hsla(var(--ig-gray-700), var(--ig-gray-a));
  margin-inline-end: auto;
}

.igx-excel-filter__actions {
  padding: 0.5rem 1rem;
}

.igx-excel-filter__actions-selected igx-icon {
  color: hsla(var(--ig-secondary-500), var(--ig-secondary-a));
}

[dir=rtl] .igx-excel-filter__move .igx-button-group {
  flex-direction: row-reverse;
}
[dir=rtl] .igx-excel-filter__move .igx-button-group igx-icon,
[dir=rtl] .igx-excel-filter__move .igx-button-group [igxButton] {
  direction: ltr;
}

[dir=rtl] .igx-excel-filter__sort .igx-button-group {
  flex-direction: row-reverse;
}
[dir=rtl] .igx-excel-filter__sort .igx-button-group igx-icon,
[dir=rtl] .igx-excel-filter__sort .igx-button-group [igxButton] {
  direction: ltr;
}

.igx-excel-filter__actions-pin, .igx-excel-filter__actions-pin--disabled, .igx-excel-filter__actions-unpin, .igx-excel-filter__actions-hide, .igx-excel-filter__actions-select, .igx-excel-filter__actions-selected, .igx-excel-filter__actions-filter, .igx-excel-filter__actions-clear, .igx-excel-filter__actions-clear--disabled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: hsla(var(--ig-gray-700), var(--ig-gray-a));
  outline-style: none;
}
.igx-excel-filter__actions-pin:hover, .igx-excel-filter__actions-pin--disabled:hover, .igx-excel-filter__actions-unpin:hover, .igx-excel-filter__actions-hide:hover, .igx-excel-filter__actions-select:hover, .igx-excel-filter__actions-selected:hover, .igx-excel-filter__actions-filter:hover, .igx-excel-filter__actions-clear:hover, .igx-excel-filter__actions-clear--disabled:hover, .igx-excel-filter__actions-pin:focus, .igx-excel-filter__actions-pin--disabled:focus, .igx-excel-filter__actions-unpin:focus, .igx-excel-filter__actions-hide:focus, .igx-excel-filter__actions-select:focus, .igx-excel-filter__actions-selected:focus, .igx-excel-filter__actions-filter:focus, .igx-excel-filter__actions-clear:focus, .igx-excel-filter__actions-clear--disabled:focus {
  background: hsla(var(--ig-gray-100), var(--ig-gray-a));
}
[dir=rtl] .igx-excel-filter__actions-pin igx-icon, [dir=rtl] .igx-excel-filter__actions-pin--disabled igx-icon, [dir=rtl] .igx-excel-filter__actions-unpin igx-icon, [dir=rtl] .igx-excel-filter__actions-hide igx-icon, [dir=rtl] .igx-excel-filter__actions-select igx-icon, [dir=rtl] .igx-excel-filter__actions-selected igx-icon, [dir=rtl] .igx-excel-filter__actions-filter igx-icon, [dir=rtl] .igx-excel-filter__actions-clear igx-icon, [dir=rtl] .igx-excel-filter__actions-clear--disabled igx-icon {
  transform: scaleX(-1);
}

.igx-excel-filter__actions-pin--disabled, .igx-excel-filter__actions-clear--disabled {
  color: hsla(var(--ig-gray-400), var(--ig-gray-a));
  pointer-events: none;
}

.igx-excel-filter__empty {
  display: grid;
  place-items: center;
  height: 100%;
}

.igx-excel-filter__menu-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding: 1rem;
  gap: 1rem;
}
.igx-excel-filter__menu-main igx-list {
  flex-grow: 1;
  overflow: hidden;
  margin-inline: -1rem;
  border: 0;
  border-top: 0.0625rem dashed hsla(var(--ig-gray-300), var(--ig-gray-a));
  border-bottom: 0.0625rem dashed hsla(var(--ig-gray-300), var(--ig-gray-a));
}
.igx-excel-filter__menu-main .igx-excel-filter__tree {
  background: hsla(var(--ig-surface-500), var(--ig-surface-a));
  overflow-y: auto;
  margin: 0 -1rem;
  flex: 1;
  border-top: 0.0625rem dashed hsla(var(--ig-gray-300), var(--ig-gray-a));
  border-bottom: 0.0625rem dashed hsla(var(--ig-gray-300), var(--ig-gray-a));
}
.igx-excel-filter__menu-main .igx-excel-filter__tree igx-icon {
  width: var(--igx-icon-size, 1.25rem);
  height: var(--igx-icon-size, 1.25rem);
  font-size: var(--igx-icon-size, 1.25rem);
}
.igx-excel-filter__menu-main .igx-excel-filter__tree > igx-checkbox,
.igx-excel-filter__menu-main .igx-excel-filter__tree .igx-tree-node__wrapper {
  height: 2.5rem;
  min-height: 2.5rem;
}
.igx-excel-filter__menu-main .igx-excel-filter__tree .igx-tree-node__toggle-button {
  min-width: 1.25rem;
  margin-inline-start: 1rem;
}
.igx-excel-filter__menu-main .igx-excel-filter__tree .igx-tree {
  overflow-y: hidden;
}
.igx-excel-filter__menu-main .igx-excel-filter__tree-alike {
  background: hsla(var(--ig-surface-500), var(--ig-surface-a));
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.igx-excel-filter__menu-main .igx-excel-filter__tree-alike-item {
  display: flex;
  align-items: center;
  height: 2.5rem;
  background: hsla(var(--ig-surface-500), var(--ig-surface-a));
}
.igx-excel-filter__menu-main .igx-excel-filter__tree-alike-item:hover, .igx-excel-filter__menu-main .igx-excel-filter__tree-alike-item:focus {
  background: hsla(var(--ig-gray-200), var(--ig-gray-a));
}
.igx-excel-filter__menu-main .igx-excel-filter__tree-alike-item > igx-checkbox {
  margin-inline-start: calc(1rem + 1.25rem + 0.5rem);
}

.igx-excel-filter__menu--cosy igx-chips-area {
  padding: 0.5rem 0.5rem 0 0.5rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__menu-header, .igx-excel-filter__menu--cosy .igx-excel-filter__secondary-header {
  justify-content: space-between;
  padding: 0.5rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__sort {
  padding: 0.5rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__move {
  padding: 0.5rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__actions {
  padding: 0.5rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__actions-pin, .igx-excel-filter__menu--cosy .igx-excel-filter__actions-pin--disabled, .igx-excel-filter__menu--cosy .igx-excel-filter__actions-unpin, .igx-excel-filter__menu--cosy .igx-excel-filter__actions-hide, .igx-excel-filter__menu--cosy .igx-excel-filter__actions-select, .igx-excel-filter__menu--cosy .igx-excel-filter__actions-selected, .igx-excel-filter__menu--cosy .igx-excel-filter__actions-filter, .igx-excel-filter__menu--cosy .igx-excel-filter__actions-clear, .igx-excel-filter__menu--cosy .igx-excel-filter__actions-clear--disabled {
  padding: 0.5rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__menu-main {
  padding: 0.5rem;
  gap: 0.5rem;
}
.igx-excel-filter__menu--cosy igx-list {
  margin-inline-start: -0.5rem;
  margin-inline-end: -0.5rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__tree {
  margin: 0 -0.5rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__tree > igx-checkbox,
.igx-excel-filter__menu--cosy .igx-excel-filter__tree .igx-tree-node__wrapper {
  height: 2rem;
  min-height: 2rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__tree .igx-tree-node__toggle-button {
  margin-inline-start: 0.5rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__tree-alike-item {
  height: 2rem;
}
.igx-excel-filter__menu--cosy .igx-excel-filter__tree-alike-item > igx-checkbox {
  margin-inline-start: calc((0.5rem * 2) + 1.25rem);
}

.igx-excel-filter__menu--compact igx-chips-area {
  padding: 0.25rem 0.25rem 0 0.25rem;
  gap: 0.25rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__menu-header, .igx-excel-filter__menu--compact .igx-excel-filter__secondary-header {
  justify-content: space-between;
  padding: 0.25rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__sort,
.igx-excel-filter__menu--compact .igx-excel-filter__move {
  padding: 0.25rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__sort igx-buttongroup,
.igx-excel-filter__menu--compact .igx-excel-filter__move igx-buttongroup {
  width: 13rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__move {
  margin-bottom: 0;
}
.igx-excel-filter__menu--compact .igx-excel-filter__actions {
  padding: 0.25rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__actions-pin, .igx-excel-filter__menu--compact .igx-excel-filter__actions-pin--disabled, .igx-excel-filter__menu--compact .igx-excel-filter__actions-unpin, .igx-excel-filter__menu--compact .igx-excel-filter__actions-hide, .igx-excel-filter__menu--compact .igx-excel-filter__actions-select, .igx-excel-filter__menu--compact .igx-excel-filter__actions-selected, .igx-excel-filter__menu--compact .igx-excel-filter__actions-filter, .igx-excel-filter__menu--compact .igx-excel-filter__actions-clear, .igx-excel-filter__menu--compact .igx-excel-filter__actions-clear--disabled {
  padding: 0.25rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__menu-main {
  padding: 0.25rem;
  gap: 0.25rem;
}
.igx-excel-filter__menu--compact igx-list {
  margin-inline-start: -0.25rem;
  margin-inline-end: -0.25rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__tree {
  margin: 0 -0.25rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__tree igx-checkbox,
.igx-excel-filter__menu--compact .igx-excel-filter__tree .igx-tree-node__wrapper {
  height: 1.5rem;
  min-height: 1.5rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__tree .igx-tree-node__toggle-button {
  margin: 0 0.25rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__tree-alike-item {
  height: 1.5rem;
}
.igx-excel-filter__menu--compact .igx-excel-filter__tree-alike-item > igx-checkbox {
  margin-inline-start: calc((0.25rem * 2) + 1.25rem);
}

.igx-excel-filter__secondary {
  width: 32.5rem;
  min-width: 32.5rem;
  background: var(--filtering-row-background);
  box-shadow: var(--ig-elevation-12);
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.25rem), 0.25rem);
}

.igx-excel-filter__secondary--cosy .igx-excel-filter__menu-header, .igx-excel-filter__secondary--cosy .igx-excel-filter__secondary-header {
  padding: 0.5rem;
}
.igx-excel-filter__secondary--cosy .igx-excel-filter__condition {
  padding: 0 0.5rem;
}
.igx-excel-filter__secondary--cosy .igx-excel-filter__add-filter {
  margin: 0 0.5rem 0.5rem;
}
.igx-excel-filter__secondary--cosy .igx-excel-filter__menu-footer, .igx-excel-filter__secondary--cosy .igx-excel-filter__secondary-footer {
  padding: 0.5rem;
}

.igx-excel-filter__secondary--compact .igx-excel-filter__menu-header, .igx-excel-filter__secondary--compact .igx-excel-filter__secondary-header {
  padding: 0.25rem;
}
.igx-excel-filter__secondary--compact .igx-excel-filter__condition {
  padding: 0 0.25rem;
}
.igx-excel-filter__secondary--compact .igx-excel-filter__add-filter {
  margin: 0 0.25rem 0.25rem;
}
.igx-excel-filter__secondary--compact .igx-excel-filter__menu-footer, .igx-excel-filter__secondary--compact .igx-excel-filter__secondary-footer {
  padding: 0.5rem 0.25rem;
}

.igx-excel-filter__secondary-header {
  border-bottom: 0.0625rem solid hsla(var(--ig-gray-300), var(--ig-gray-a));
}

.igx-excel-filter__secondary-main {
  height: 14.5rem;
  overflow: auto;
}

.igx-excel-filter__secondary-footer {
  padding: 0.5rem 1rem;
  border-top: 0.0625rem dashed hsla(var(--ig-gray-300), var(--ig-gray-a));
}
.igx-excel-filter__secondary-footer .igx-excel-filter__apply,
.igx-excel-filter__secondary-footer .igx-excel-filter__cancel {
  flex-grow: 0;
}

.igx-excel-filter__condition {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1rem;
}
.igx-excel-filter__condition igx-select {
  flex-grow: 1;
  flex-basis: 40%;
  margin: 1rem 0;
}
.igx-excel-filter__condition igx-select ~ igx-input-group,
.igx-excel-filter__condition igx-select ~ igx-date-picker {
  margin-inline-start: 1rem;
}
.igx-excel-filter__condition igx-buttongroup {
  min-width: 30%;
}
.igx-excel-filter__condition [igxButton=icon] {
  margin-inline-start: 1rem;
}

.igx-excel-filter__add-filter {
  margin: 0 1rem 1rem;
}
.igx-excel-filter__add-filter igx-icon {
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
}

.igx-excel-filter__filter-results {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

igx-excel-style-filter-operations,
[igxExcelStyleFilterOperations] {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.igx-advanced-filter {
  background: var(--filtering-row-background);
  box-shadow: var(--ig-elevation-12);
}
.igx-advanced-filter igx-query-builder {
  --igx-query-builder-background: var(--filtering-row-background);
  --igx-query-builder-background-and: var(--filtering-background-and);
  --igx-query-builder-background-and--focus: var(--filtering-background-and--focus);
  --igx-query-builder-background-or: var(--filtering-background-or);
  --igx-query-builder-background-or--focus: var(--filtering-background-or--focus);
  box-shadow: none;
}
.igx-advanced-filter igx-query-builder-header {
  cursor: grab;
}

.igx-grid__filtering-row-editing-buttons--small,
.igx-grid__filtering-row-editing-buttons {
  display: flex;
  align-items: center;
}
.igx-grid__filtering-row-editing-buttons--small button,
.igx-grid__filtering-row-editing-buttons button {
  transition: none;
}

.igx-grid__filtering-row-editing-buttons--small button:not([disabled]) igx-icon {
  color: var(--sorted-header-icon-color);
}

.igx-grid__tr-action:last-of-type {
  border-inline-end: var(--header-border-width) var(--header-border-style) var(--header-border-color);
}

.igx-grid__pivot--super-compact .igx-grid__td, .igx-grid__pivot--super-compact .igx-grid-thead__title, .igx-grid__pivot--super-compact .igx-grid-th {
  padding: 0 0.25rem !important;
  min-height: 1.5rem !important;
  height: 1.5rem;
}
.igx-grid__pivot--super-compact .igx-grid-thead__title > *, .igx-grid__pivot--super-compact .igx-grid-th > * {
  line-height: normal;
  align-self: initial;
  max-height: 100%;
}

.igx-grid-thead--pivot {
  display: flex;
}
.igx-grid-thead--pivot .igx-grid-thead--virtualizationWrapper {
  border-inline-start: var(--header-border-width) var(--header-border-style) var(--header-border-color);
}

.igx-grid-thead--virtualizationWrapper {
  height: 100%;
}

.igx-grid-thead--virtualizationContainer {
  overflow: visible;
  height: 100%;
}

.igx-grid__tr-pivot {
  display: flex;
  align-items: center;
  background: inherit;
  overflow: hidden;
  z-index: 3;
  height: 3.125rem;
  padding: 0 1.5rem;
  background-clip: border-box !important;
  border-inline-start: var(--header-border-width) var(--header-border-style) var(--header-border-color);
  border-bottom: var(--header-border-width) var(--header-border-style) var(--header-border-color);
}
.igx-grid--cosy .igx-grid__tr-pivot {
  height: 2.5rem;
  padding: 0 1rem;
}
.igx-grid--compact .igx-grid__tr-pivot {
  height: 2rem;
  padding: 0 0.75rem;
}
.igx-grid__tr-pivot igx-chips-area {
  flex-wrap: nowrap;
  width: auto;
}
.igx-grid__tr-pivot igx-chips-area > * {
  margin-inline-end: 0.25rem;
}
.igx-grid__tr-pivot igx-chips-area:last-child {
  margin-inline-end: 0;
}

.igx-grid__pivot-filter-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.igx-grid__pivot-filter-toggle > igx-badge {
  position: absolute;
  top: -0.25rem;
  inset-inline-start: 60%;
  width: 1.125rem;
  min-width: 1.125rem;
  height: 1.125rem;
  font-size: 0.625rem;
  pointer-events: none;
  user-select: none;
}

.igx-grid__pivot-empty-chip-area {
  line-height: normal;
  font-size: 0.875rem;
  margin-inline-end: 0 !important;
}

.igx-grid__tr-pivot--row-area {
  height: auto !important;
  align-items: flex-end;
  padding-bottom: 1.5rem;
  border-inline-start: 0;
  border-bottom: 0;
}
.igx-grid--cosy .igx-grid__tr-pivot--row-area {
  padding-bottom: 1rem;
}
.igx-grid--compact .igx-grid__tr-pivot--row-area {
  padding-bottom: 0.75rem;
}

.igx-grid__tr-pivot--filter-container {
  display: flex;
  flex-direction: column;
}

.igx-grid__tr-pivot--chip_drop_indicator {
  width: 0.125rem;
  background: var(--resize-line-color);
}

.igx-grid__tr-pivot--drop-row-area {
  flex-grow: 1;
}

.igx-grid__tr-pivot--filter {
  height: 3.125rem;
  border-inline-start: 0;
  border-inline-end: 0;
  border-bottom: var(--header-border-width) var(--header-border-style) var(--header-border-color);
}
.igx-grid--cosy .igx-grid__tr-pivot--filter {
  height: 2.5rem;
}
.igx-grid--compact .igx-grid__tr-pivot--filter {
  height: 2rem;
}

.igx-grid-thead__wrapper--pivot {
  border-bottom: 0;
}

.igx-grid__tr-pivot-group {
  flex: 1;
}

.igx-grid__tr-pivot-toggle-icons {
  display: inline-flex !important;
}

.igx-grid__tr-pivot--columnDimensionLeaf {
  box-shadow: none;
}
.igx-grid__tr-pivot--columnDimensionLeaf igx-grid-header {
  border: none;
}

.igx-grid__tr-pivot--columnMultiRowSpan igx-grid-header > * {
  visibility: hidden;
}

.igx-grid__tr-header-row igx-pivot-row-dimension-header-group igx-pivot-row-dimension-header {
  align-items: center;
}

.igx-grid-summary {
  --background-color: var(--igx-grid-summary-background-color, inherit);
  --focus-background-color: var(--igx-grid-summary-focus-background-color, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --label-color: var(--igx-grid-summary-label-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --label-hover-color: var(--igx-grid-summary-label-hover-color, hsla(var(--ig-primary-700), var(--ig-primary-a)));
  --result-color: var(--igx-grid-summary-result-color, 'currentColor');
  --border-color: var(--igx-grid-summary-border-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --pinned-border-width: var(--igx-grid-summary-pinned-border-width, 2px);
  --pinned-border-style: var(--igx-grid-summary-pinned-border-style, solid);
  --pinned-border-color: var(--igx-grid-summary-pinned-border-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
}

.igx-grid-summary, .igx-grid-summary--cosy, .igx-grid-summary--compact {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 0 1.5rem;
  background: var(--background-color, inherit);
  overflow: hidden;
  outline-style: none;
}
.igx-grid-summary::after, .igx-grid-summary--cosy::after, .igx-grid-summary--compact::after {
  position: absolute;
  inset: 0;
}
.igx-grid-summary:focus::after, .igx-grid-summary--cosy:focus::after, .igx-grid-summary--compact:focus::after {
  background: var(--focus-background-color);
}

.igx-grid-summary--cosy {
  padding: 0 1rem;
}

.igx-grid-summary--compact {
  padding: 0 0.75rem;
}

.igx-grid-summary--pinned {
  position: relative;
  z-index: 1;
}

.igx-grid-summary--fw {
  flex-grow: 0;
}

.igx-grid-summary--pinned-last {
  border-inline-end: var(--pinned-border-width) var(--pinned-border-style) var(--pinned-border-color);
}
@media print {
  .igx-grid-summary--pinned-last {
    border-inline-end: var(--pinned-border-width) var(--pinned-border-style) #999;
  }
}

.igx-grid-summary--pinned-first {
  border-inline-start: var(--pinned-border-width) var(--pinned-border-style) var(--pinned-border-color);
}
@media print {
  .igx-grid-summary--pinned-first {
    border-inline-start: var(--pinned-border-width) var(--pinned-border-style) #999;
  }
}

.igx-grid-summary__item {
  display: flex;
  align-items: center;
  padding: 0.375rem 0;
  font-size: 0.75rem;
  position: relative;
}

.igx-grid-summary--cosy .igx-grid-summary__item {
  padding: 0.125rem 0;
}

.igx-grid-summary--compact .igx-grid-summary__item {
  padding: 0;
}

.igx-grid-summary__label {
  color: var(--label-color);
  min-width: 1.875rem;
  margin-inline-end: 0.1875rem;
}
.igx-grid-summary__label:hover {
  color: var(--label-hover-color);
}

.igx-grid-summary__result {
  color: var(--result-color);
  font-weight: 600;
  flex: 1 1 auto;
  text-align: end;
}

.igx-grid-summary__label,
.igx-grid-summary__result {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

igx-grid-toolbar, .igx-grid-toolbar__dd-list {
  --background-color: var(--igx-grid-toolbar-background-color, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --title-text-color: var(--igx-grid-toolbar-title-text-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --dropdown-background: var(--igx-grid-toolbar-dropdown-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --item-text-color: var(--igx-grid-toolbar-item-text-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --item-hover-background: var(--igx-grid-toolbar-item-hover-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --item-hover-text-color: var(--igx-grid-toolbar-item-hover-text-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --item-focus-background: var(--igx-grid-toolbar-item-focus-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --item-focus-text-color: var(--igx-grid-toolbar-item-focus-text-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
}

.igx-grid-toolbar, .igx-grid-toolbar--cosy, .igx-grid-toolbar--compact {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-row: 1;
  font-size: 1rem;
  border-bottom: 0.0625rem solid hsla(var(--ig-gray-300), var(--ig-gray-a));
  background: var(--background-color);
  height: auto;
  min-height: 3.625rem;
  padding-block: 0;
  padding-inline: 1.5rem 1rem;
}
.igx-grid-toolbar [igxButton], .igx-grid-toolbar--cosy [igxButton], .igx-grid-toolbar--compact [igxButton] {
  margin-inline-start: 1rem;
}
.igx-grid-toolbar [igxButton]:first-of-type, .igx-grid-toolbar--cosy [igxButton]:first-of-type, .igx-grid-toolbar--compact [igxButton]:first-of-type {
  margin-inline-start: 0;
}
.igx-grid-toolbar [igxButton]:last-of-type, .igx-grid-toolbar--cosy [igxButton]:last-of-type, .igx-grid-toolbar--compact [igxButton]:last-of-type {
  margin-inline-end: 0;
}
[dir=rtl].igx-grid-toolbar, [dir=rtl].igx-grid-toolbar--cosy, [dir=rtl].igx-grid-toolbar--compact {
  text-align: end;
}
[dir=rtl].igx-grid-toolbar [igxButton], [dir=rtl].igx-grid-toolbar--cosy [igxButton], [dir=rtl].igx-grid-toolbar--compact [igxButton] {
  margin-inline-start: 0;
  margin-inline-end: 0.5rem;
}
[dir=rtl].igx-grid-toolbar [igxButton]:last-child, [dir=rtl].igx-grid-toolbar--cosy [igxButton]:last-child, [dir=rtl].igx-grid-toolbar--compact [igxButton]:last-child {
  margin-inline-end: 0;
}

.igx-grid-toolbar--cosy {
  min-height: 3.25rem;
  padding-inline: 1rem 0.75rem;
}
.igx-grid-toolbar--cosy [igxButton] {
  margin-inline-start: 0.75rem;
}

.igx-grid-toolbar--compact {
  min-height: 2.75rem;
  padding-inline: 0.75rem 0.5rem;
}
.igx-grid-toolbar--compact [igxButton] {
  margin-inline-start: 0.5rem;
}

.igx-grid-toolbar__title {
  color: var(--title-text-color);
  flex: 1 1 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 40ch;
  margin-inline-end: 1rem;
}

.igx-grid-toolbar__custom-content {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: flex-end;
  margin-inline-end: 1rem;
}

.igx-grid-toolbar__actions {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-inline-start: auto;
}
.igx-grid-toolbar__actions > * {
  display: flex;
  margin-inline-start: 1rem;
}
.igx-grid-toolbar__actions > *:first-child {
  margin-inline-start: 0;
}

.igx-grid-toolbar__actions:empty,
.igx-grid-toolbar__title:empty,
.igx-grid-toolbar__custom-content:empty {
  display: none;
}

.igx-grid-toolbar__title:empty + .igx-grid-toolbar__custom-content:empty + .igx-grid-toolbar__actions {
  width: 100%;
  margin-inline-start: 0;
  justify-content: flex-end;
}

.igx-grid-toolbar__progress-bar {
  position: absolute;
  width: 100%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: -0.0625rem;
  height: 0.125rem;
  overflow: hidden;
  background: var(--background-color);
}
.igx-grid-toolbar__progress-bar igx-linear-bar > * {
  border-radius: 0;
}
.igx-grid-toolbar__progress-bar igx-linear-bar > *:first-child > div {
  background: hsla(var(--ig-secondary-500), var(--ig-secondary-a));
}

.igx-grid-toolbar__adv-filter--filtered {
  border-color: hsla(var(--ig-secondary-500), var(--ig-secondary-a)) !important;
}

.igx-grid-toolbar__dropdown {
  position: relative;
}

.igx-grid-toolbar__dd-list {
  list-style: none;
  background: var(--dropdown-background);
  margin: 0;
  padding: 0;
  box-shadow: var(--ig-elevation-8);
}

.igx-grid-toolbar__dd-list-items {
  cursor: pointer;
  position: relative;
  padding: 0.5rem 1rem;
  color: var(--item-text-color);
  white-space: nowrap;
}
.igx-grid-toolbar__dd-list-items:hover {
  background: var(--item-hover-background);
  color: var(--item-hover-text-color);
}
.igx-grid-toolbar__dd-list-items:focus {
  background: var(--item-focus-background);
  color: var(--item-focus-text-color);
}

igx-pivot-data-selector {
  --background: var(--igx-pivot-data-selector-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --header-color: var(--igx-pivot-data-selector-header-color, hsla(var(--ig-gray-100), var(--ig-gray-a)));
}

.igx-pivot-data-selector {
  display: flex;
  flex-direction: column;
  max-width: 17.5rem;
  background: var(--background);
  z-index: 0;
}
.igx-pivot-data-selector > igx-input-group {
  flex: 0 1 auto;
}
.igx-pivot-data-selector igx-display-container {
  display: flex;
  flex-direction: column;
}
.igx-pivot-data-selector > igx-list igx-display-container {
  padding: 0.25rem;
}
.igx-pivot-data-selector > igx-list igx-list-item {
  display: flex;
  min-height: 1.75rem;
}
.igx-pivot-data-selector > igx-list .igx-checkbox__label, .igx-pivot-data-selector > igx-list .igx-checkbox__label--before {
  font-size: 0.8125rem;
}
.igx-pivot-data-selector .igx-input-group--box .igx-input-group__input, .igx-input-group--box .igx-pivot-data-selector .igx-input-group__input {
  transform: none;
}
.igx-pivot-data-selector .igx-input-group igx-prefix, .igx-input-group .igx-pivot-data-selector igx-prefix, .igx-pivot-data-selector .igx-input-group--focused igx-prefix, .igx-input-group--focused .igx-pivot-data-selector igx-prefix,
.igx-pivot-data-selector .igx-input-group [igxPrefix],
.igx-input-group .igx-pivot-data-selector [igxPrefix],
.igx-pivot-data-selector .igx-input-group--focused [igxPrefix],
.igx-input-group--focused .igx-pivot-data-selector [igxPrefix] {
  padding-inline-end: 1rem !important;
  box-sizing: content-box;
}
.igx-pivot-data-selector .igx-input-group--box .igx-input-group__bundle-main, .igx-input-group--box .igx-pivot-data-selector .igx-input-group__bundle-main {
  padding-top: 0 !important;
}
.igx-pivot-data-selector .igx-expansion-panel__body {
  position: relative;
  height: 8rem;
  font-size: 0.875rem;
  padding: 0.25rem;
  overflow-y: auto;
}
.igx-pivot-data-selector .igx-expansion-panel__body > igx-list {
  height: auto;
}
.igx-pivot-data-selector .igx-expansion-panel__header-icon--start {
  margin-inline-end: 0.5rem;
}
.igx-pivot-data-selector .igx-expansion-panel__header-title {
  display: flex;
}
.igx-pivot-data-selector .igx-expansion-panel__header-title > h6 {
  font-size: 0.75rem;
}
.igx-pivot-data-selector .igx-expansion-panel__header-inner {
  background: var(--header-color);
  padding: 0.25rem;
}
.dragOver .igx-pivot-data-selector .igx-expansion-panel__header-inner {
  background: hsla(var(--ig-gray-300), var(--ig-gray-a));
  box-shadow: inset 0 0 0 0.0625rem hsla(var(--ig-gray-400), var(--ig-gray-a));
}

.igx-pivot-data-selector__filter {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.igx-pivot-data-selector__filter igx-list {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.25rem;
  min-height: 11.625rem;
  max-height: 13rem;
  overflow-y: auto;
}
.igx-pivot-data-selector__filter igx-list-item {
  display: flex;
}
.igx-pivot-data-selector__filter igx-checkbox + span {
  margin-inline-start: 0.5rem;
  line-height: 1.75rem;
}

.igx-pivot-data-selector__header,
.igx-pivot-data-selector__header-extra {
  display: flex;
  align-items: center;
}

.igx-pivot-data-selector__header-extra igx-icon {
  padding: 0 0.5rem;
  box-sizing: content-box;
}
.igx-pivot-data-selector__header-extra .igx-chip__item {
  height: 1.375rem;
}
.igx-pivot-data-selector__header-extra .igx-chip--cosy .igx-chip__item, .igx-chip--cosy .igx-pivot-data-selector__header-extra .igx-chip__item {
  height: 1.25rem;
}
.igx-pivot-data-selector__header-extra .igx-chip--compact .igx-chip__item, .igx-chip--compact .igx-pivot-data-selector__header-extra .igx-chip__item {
  height: 1.125rem;
}
.igx-pivot-data-selector__header-extra .igx-chip__content {
  padding: 0 0.125rem;
}

.igx-pivot-data-selector__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2rem;
  width: 100%;
}
.igx-drag--push .igx-pivot-data-selector__item {
  padding-top: 2rem;
}

.igx-pivot-data-selector__item-ghost {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  background: hsla(var(--ig-surface-500), var(--ig-surface-a));
  min-height: 2rem;
  height: auto;
  padding: 0 0.125rem 0 0.25rem;
  cursor: grabbing;
  box-shadow: var(--ig-elevation-24);
  border: 0.0625rem solid hsla(var(--ig-gray-100), var(--ig-gray-a));
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.125rem), 0.125rem);
  z-index: 10;
}

.igx-pivot-data-selector igx-icon,
.igx-pivot-data-selector__item-ghost igx-icon {
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
}

.igx-pivot-data-selector__item-ghost-text {
  display: flex;
  align-items: center;
}
.igx-pivot-data-selector__item-ghost-text igx-icon {
  margin-inline-end: 0.5rem;
}

.igx-pivot-data-selector__item-ghost--no-drop {
  cursor: no-drop;
}

.igx-pivot-data-selector__item-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 1.125rem + 0.5rem);
}

.igx-pivot-data-selector__item-text,
.igx-pivot-data-selector__action-sort,
.igx-pivot-data-selector__action-filter,
.igx-pivot-data-selector__action-move,
.igx-pivot-data-selector__action-summary {
  user-select: none;
}

.igx-pivot-data-selector__action-sort,
.igx-pivot-data-selector__action-summary,
.igx-pivot-data-selector__action-filter {
  cursor: pointer;
}

.igx-pivot-data-selector__action-move {
  cursor: grab;
}

.igx-pivot-data-selector__item-start {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 100%;
  margin-inline-end: 0.5rem;
  overflow: hidden;
}

.igx-pivot-data-selector__item-end {
  display: flex;
}
.igx-pivot-data-selector__item-end igx-icon + igx-icon {
  margin-inline-start: 0.5rem;
}

.igx-highlight {
  --resting-background: var(--igx-highlight-resting-background, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --resting-color: var(--igx-highlight-resting-color, var(--ig-gray-300-contrast));
  --active-background: var(--igx-highlight-active-background, hsla(var(--ig-secondary-A100), var(--ig-secondary-a)));
  --active-color: var(--igx-highlight-active-color, var(--ig-secondary-A100-contrast));
}

.igx-highlight, .igx-highlight--active {
  color: var(--resting-color);
  background: var(--resting-background);
}

.igx-highlight--active {
  color: var(--active-color);
  background: var(--active-background);
}

igx-icon {
  --color: var(--igx-icon-color, 'currentColor');
  --size: var(--igx-icon-size, 1.5rem);
  --disabled-color: var(--igx-icon-disabled-color, 'currentColor');
}

.igx-icon {
  display: inline-flex;
  width: var(--size);
  height: var(--size);
  font-size: var(--size);
  color: var(--color);
  direction: inherit;
}
.igx-icon div,
.igx-icon svg {
  display: block;
  width: inherit;
  height: inherit;
  fill: currentColor;
}
[igxPrefix].material-icons.igx-icon, [igxSuffix].material-icons.igx-icon {
  font-family: "Material Icons";
}

.igx-icon--success {
  color: hsla(var(--ig-success-500), var(--ig-success-a));
}

.igx-icon--error {
  color: hsla(var(--ig-error-500), var(--ig-error-a));
}

.igx-icon--inactive {
  color: var(--disabled-color) !important;
  opacity: 0.54;
}

igx-input-group, igx-date-range-start, igx-date-range-end {
  --search-resting-elevation: var(--igx-input-group-search-resting-elevation, var(--ig-elevation-1));
  --search-hover-elevation: var(--igx-input-group-search-hover-elevation, var(--ig-elevation-2));
  --search-disabled-elevation: var(--igx-input-group-search-disabled-elevation, var(--ig-elevation-0));
  --helper-text-color: var(--igx-input-group-helper-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --input-prefix-background: var(--igx-input-group-input-prefix-background, transparent);
  --input-prefix-color: var(--igx-input-group-input-prefix-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --input-prefix-background--filled: var(--igx-input-group-input-prefix-background--filled, inherit);
  --input-prefix-color--filled: var(--igx-input-group-input-prefix-color--filled, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --input-prefix-background--focused: var(--igx-input-group-input-prefix-background--focused, inherit);
  --input-prefix-color--focused: var(--igx-input-group-input-prefix-color--focused, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --input-suffix-background: var(--igx-input-group-input-suffix-background, transparent);
  --input-suffix-color: var(--igx-input-group-input-suffix-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --input-suffix-background--filled: var(--igx-input-group-input-suffix-background--filled, inherit);
  --input-suffix-color--filled: var(--igx-input-group-input-suffix-color--filled, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --input-suffix-background--focused: var(--igx-input-group-input-suffix-background--focused, inherit);
  --input-suffix-color--focused: var(--igx-input-group-input-suffix-color--focused, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --idle-text-color: var(--igx-input-group-idle-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --filled-text-color: var(--igx-input-group-filled-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --focused-text-color: var(--igx-input-group-focused-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --disabled-text-color: var(--igx-input-group-disabled-text-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --idle-secondary-color: var(--igx-input-group-idle-secondary-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --filled-secondary-color: var(--igx-input-group-filled-secondary-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --focused-secondary-color: var(--igx-input-group-focused-secondary-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --idle-bottom-line-color: var(--igx-input-group-idle-bottom-line-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --hover-bottom-line-color: var(--igx-input-group-hover-bottom-line-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --focused-bottom-line-color: var(--igx-input-group-focused-bottom-line-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --interim-bottom-line-color: var(--igx-input-group-interim-bottom-line-color, hsla(var(--ig-primary-500), 0.12));
  --disabled-bottom-line-color: var(--igx-input-group-disabled-bottom-line-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --disabled-border-color: var(--igx-input-group-disabled-border-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --border-color: var(--igx-input-group-border-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --hover-border-color: var(--igx-input-group-hover-border-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --focused-border-color: var(--igx-input-group-focused-border-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --border-background: var(--igx-input-group-border-background, transparent);
  --border-disabled-background: var(--igx-input-group-border-disabled-background, transparent);
  --search-background: var(--igx-input-group-search-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --search-disabled-background: var(--igx-input-group-search-disabled-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --success-secondary-color: var(--igx-input-group-success-secondary-color, hsla(var(--ig-success-500), var(--ig-success-a)));
  --warning-secondary-color: var(--igx-input-group-warning-secondary-color, hsla(var(--ig-warn-500), var(--ig-warn-a)));
  --error-secondary-color: var(--igx-input-group-error-secondary-color, hsla(var(--ig-error-500), var(--ig-error-a)));
  --placeholder-color: var(--igx-input-group-placeholder-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --disabled-placeholder-color: var(--igx-input-group-disabled-placeholder-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --box-background: var(--igx-input-group-box-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --box-background-hover: var(--igx-input-group-box-background-hover, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --box-background-focus: var(--igx-input-group-box-background-focus, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --box-disabled-background: var(--igx-input-group-box-disabled-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --box-border-radius: var(--igx-input-group-box-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --border-border-radius: var(--igx-input-group-border-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --search-border-radius: var(--igx-input-group-search-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --success-shadow-color: var(--igx-input-group-success-shadow-color, hsla(var(--ig-success-500), 0.38));
  --error-shadow-color: var(--igx-input-group-error-shadow-color, hsla(var(--ig-error-500), 0.38));
  --search-resting-shadow: var(--igx-input-group-search-resting-shadow, var(--ig-elevation-1));
  --search-hover-shadow: var(--igx-input-group-search-hover-shadow, var(--ig-elevation-2));
  --search-disabled-shadow: var(--igx-input-group-search-disabled-shadow, var(--ig-elevation-0));
}

.igx-input-group igx-prefix, .igx-input-group--focused igx-prefix,
.igx-input-group [igxPrefix],
.igx-input-group--focused [igxPrefix] {
  color: var(--input-prefix-color);
  background: var(--input-prefix-background);
  grid-area: 1/1/auto/auto;
}

.igx-input-group igx-suffix, .igx-input-group--focused igx-suffix,
.igx-input-group [igxSuffix],
.igx-input-group--focused [igxSuffix] {
  color: var(--input-suffix-color);
  background: var(--input-suffix-background);
  grid-area: 1/3/auto/auto;
}

.igx-input-group igx-prefix, .igx-input-group--focused igx-prefix,
.igx-input-group [igxPrefix],
.igx-input-group--focused [igxPrefix],
.igx-input-group igx-suffix,
.igx-input-group--focused igx-suffix,
.igx-input-group [igxSuffix],
.igx-input-group--focused [igxSuffix] {
  position: relative;
  display: inline-flex;
  width: max-content;
  align-items: center;
  height: 100% !important;
  transition: color 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding-inline: max(var(--is-large, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(0.875rem, -1 * 0.875rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0.75rem, -1 * 0.75rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
}
.igx-input-group igx-prefix igx-icon, .igx-input-group--focused igx-prefix igx-icon,
.igx-input-group [igxPrefix] igx-icon,
.igx-input-group--focused [igxPrefix] igx-icon,
.igx-input-group igx-suffix igx-icon,
.igx-input-group--focused igx-suffix igx-icon,
.igx-input-group [igxSuffix] igx-icon,
.igx-input-group--focused [igxSuffix] igx-icon {
  width: max(var(--is-large, 1) * max(1.5rem, -1 * 1.5rem), var(--is-medium, 1) * max(1.25rem, -1 * 1.25rem), var(--is-small, 1) * max(1.125rem, -1 * 1.125rem));
  height: max(var(--is-large, 1) * max(1.5rem, -1 * 1.5rem), var(--is-medium, 1) * max(1.25rem, -1 * 1.25rem), var(--is-small, 1) * max(1.125rem, -1 * 1.125rem));
  font-size: max(var(--is-large, 1) * max(1.5rem, -1 * 1.5rem), var(--is-medium, 1) * max(1.25rem, -1 * 1.25rem), var(--is-small, 1) * max(1.125rem, -1 * 1.125rem)) !important;
}

igx-input-group {
  --theme: material;
}
igx-input-group igx-icon[igxPrefix],
igx-input-group igx-icon[igxSuffix] {
  width: max(var(--is-large, 1) * max(1.5rem, -1 * 1.5rem), var(--is-medium, 1) * max(1.25rem, -1 * 1.25rem), var(--is-small, 1) * max(1.125rem, -1 * 1.125rem));
  height: max(var(--is-large, 1) * max(1.5rem, -1 * 1.5rem), var(--is-medium, 1) * max(1.25rem, -1 * 1.25rem), var(--is-small, 1) * max(1.125rem, -1 * 1.125rem));
  font-size: max(var(--is-large, 1) * max(1.5rem, -1 * 1.5rem), var(--is-medium, 1) * max(1.25rem, -1 * 1.25rem), var(--is-small, 1) * max(1.125rem, -1 * 1.125rem)) !important;
}

.igx-input-group, .igx-input-group--focused {
  --size: max(var(--is-large, 1) * max(3.5rem, -1 * 3.5rem), var(--is-medium, 1) * max(3rem, -1 * 3rem), var(--is-small, 1) * max(2.5rem, -1 * 2.5rem));
  --component-size: var(--ig-size, var(--ig-size-large));
  position: relative;
  display: block;
  color: var(--idle-text-color);
}
.igx-input-group igx-prefix, .igx-input-group--focused igx-prefix,
.igx-input-group [igxPrefix],
.igx-input-group--focused [igxPrefix] {
  outline-style: none;
}
.igx-input-group igx-suffix, .igx-input-group--focused igx-suffix,
.igx-input-group [igxSuffix],
.igx-input-group--focused [igxSuffix] {
  outline-style: none;
}
.igx-input-group input, .igx-input-group--focused input,
.igx-input-group textarea,
.igx-input-group--focused textarea,
.igx-input-group span,
.igx-input-group--focused span {
  font: inherit;
  margin: 0;
}
.igx-input-group input[type=file], .igx-input-group--focused input[type=file] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: none;
  clip: rect(0, 0, 0, 0);
  outline: 0;
  pointer-events: none;
  overflow: hidden;
  appearance: none;
}
.igx-input-group textarea, .igx-input-group--focused textarea {
  overflow: auto;
}
.igx-input-group input[type=number], .igx-input-group--focused input[type=number] {
  -moz-appearance: textfield;
}
.igx-input-group input[type=number]::-webkit-inner-spin-button, .igx-input-group--focused input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  height: auto;
}
.igx-input-group input[type=search]::-webkit-search-cancel-button, .igx-input-group--focused input[type=search]::-webkit-search-cancel-button,
.igx-input-group input[type=search]::-webkit-search-decoration,
.igx-input-group--focused input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.igx-input-group--cosy {
  --component-size: var(--ig-size, var(--ig-size-medium));
}

.igx-input-group--compact {
  --component-size: var(--ig-size, var(--ig-size-small));
}

.igx-input-group--box, .igx-input-group--border, .igx-input-group--search, .igx-input-group--indigo {
  margin-block-start: 0;
}

.igx-input-group--filled {
  color: var(--input-prefix-color--filled);
}
.igx-input-group--filled igx-prefix,
.igx-input-group--filled [igxPrefix] {
  color: var(--input-prefix-color--filled);
  background: var(--input-prefix-background--filled);
}
.igx-input-group--filled igx-suffix,
.igx-input-group--filled [igxSuffix] {
  color: var(--input-suffix-color--filled);
  background: var(--input-suffix-background--filled);
}

.igx-input-group--focused {
  color: var(--input-prefix-color--focused);
}
.igx-input-group--focused igx-prefix,
.igx-input-group--focused [igxPrefix] {
  color: var(--input-prefix-color--focused);
  background: var(--input-prefix-background--focused);
}
.igx-input-group--focused igx-suffix,
.igx-input-group--focused [igxSuffix] {
  color: var(--input-suffix-color--focused);
  background: var(--input-suffix-background--focused);
}

.igx-input-group--disabled, .igx-input-group--disabled.igx-input-group--required {
  user-select: none;
  color: var(--disabled-text-color) !important;
}
.igx-input-group--box .igx-input-group__wrapper, .igx-input-group--indigo .igx-input-group__wrapper {
  border-radius: var(--box-border-radius);
  border-end-start-radius: 0;
  border-end-end-radius: 0;
  overflow: hidden;
}

.igx-input-group__bundle {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-area: 1/2/span 1/span 2;
  height: var(--size);
  position: relative;
  max-width: 100%;
  font-size: 1rem;
}
.igx-input-group__bundle::after {
  content: "";
  position: absolute;
  bottom: 0%;
  width: 100%;
  border-block-end: 0.0625rem solid var(--idle-bottom-line-color);
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.igx-input-group__bundle .igx-input-group__bundle-start {
  border-start-start-radius: calc(var(--box-border-radius) - 0.0625rem);
}
.igx-input-group__bundle .igx-input-group__bundle-end {
  border-start-end-radius: calc(var(--box-border-radius) - 0.0625rem);
}

.igx-input-group--fluent .igx-input-group__bundle::after,
.igx-input-group--bootstrap .igx-input-group__bundle::after {
  display: none;
}

.igx-input-group__bundle:hover {
  cursor: pointer;
}
.igx-input-group__bundle:hover::after {
  border-block-end-width: 0.0625rem;
  border-block-end-color: var(--hover-bottom-line-color);
}

.igx-input-group--focused .igx-input-group__bundle::after {
  border-block-end-width: 0.125rem;
  border-block-end-color: var(--focused-bottom-line-color);
}

.igx-input-group--valid .igx-input-group__bundle::after {
  border-block-end-color: var(--success-secondary-color);
}

.igx-input-group--invalid .igx-input-group__bundle::after {
  border-block-end-color: var(--error-secondary-color);
}

.igx-input-group--disabled .igx-input-group__bundle, .igx-input-group--disabled.igx-input-group--required .igx-input-group__bundle {
  cursor: default;
}
.igx-input-group--disabled .igx-input-group__bundle::after, .igx-input-group--disabled.igx-input-group--required .igx-input-group__bundle::after {
  border-block-end-color: var(--disabled-bottom-line-color);
  border-block-end-style: dashed;
}

.igx-input-group__bundle-start {
  grid-area: 1/1;
}

.igx-input-group__bundle-main {
  grid-area: 1/2/span 1/span 2;
  flex-grow: 1;
  position: relative;
  max-width: inherit;
}

.igx-input-group--box .igx-input-group__bundle {
  padding: 0 !important;
  background: var(--box-background);
}
.igx-input-group--box .igx-input-group__bundle:hover {
  background: var(--box-background-hover);
}

.igx-input-group--box.igx-input-group--focused .igx-input-group__bundle {
  background: var(--box-background-focus);
}

.igx-input-group--box.igx-input-group--disabled .igx-input-group__bundle {
  background-color: var(--box-disabled-background);
}

.igx-input-group__bundle-main,
.igx-input-group__notch {
  padding-inline: 0.25rem;
}

.igx-input-group__notch {
  display: flex;
  align-items: center;
  width: auto;
  min-width: 0;
  height: 100%;
  position: relative;
  grid-area: 1/2;
}

.igx-input-group--border .igx-input-group__notch {
  padding: 0 0.25rem;
}

.igx-input-group__filler {
  grid-area: 1/3;
}

.igx-input-group__bundle-end {
  grid-area: 1/4;
}

.igx-input-group__bundle-start,
.igx-input-group__bundle-end {
  display: flex;
  align-items: center;
  min-width: 0;
  min-height: 100%;
  overflow: hidden;
  min-width: max(var(--is-large, 1) * max(0.875rem, -1 * 0.875rem) * var(--ig-spacing-large, var(--ig-spacing, --ig-spacing)), var(--is-medium, 1) * max(0.75rem, -1 * 0.75rem) * var(--ig-spacing-medium, var(--ig-spacing, --ig-spacing)), var(--is-small, 1) * max(0.625rem, -1 * 0.625rem) * var(--ig-spacing-small, var(--ig-spacing, --ig-spacing)));
}

.igx-input-group--border .igx-input-group__bundle {
  grid-template-columns: auto auto 1fr auto;
  display: grid;
  align-items: initial;
  padding: 0;
  box-shadow: none;
  border-radius: var(--border-border-radius);
  background: var(--border-background);
}
.igx-input-group--border .igx-input-group__bundle .igx-input-group__bundle-start {
  width: auto;
  flex-shrink: 0;
  border-color: var(--border-color);
  border-style: solid;
  border-inline-start-width: 0.0625rem;
  border-inline-end-width: 0;
  border-block-start-width: 0.0625rem;
  border-block-end-width: 0.0625rem;
  border-start-start-radius: var(--border-border-radius);
  border-end-start-radius: var(--border-border-radius);
}
.igx-input-group--border .igx-input-group__bundle .igx-input-group__filler {
  border-width: 0.0625rem;
  border-style: solid;
  border-color: var(--border-color);
  border-left: none;
  border-right: none;
}
.igx-input-group--border .igx-input-group__bundle .igx-input-group__notch {
  border-block-start: 0.0625rem solid var(--border-color);
  border-block-end: 0.0625rem solid var(--border-color);
  overflow: visible;
}
.igx-input-group--border .igx-input-group__bundle .igx-input-group__notch:empty {
  display: none;
}
.igx-input-group--border .igx-input-group__bundle .igx-input-group__bundle-end {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  height: 100%;
  grid-area: 1/4;
  border-color: var(--border-color);
  border-style: solid;
  border-inline-start-width: 0;
  border-inline-end-width: 0.0625rem;
  border-block-start-width: 0.0625rem;
  border-block-end-width: 0.0625rem;
  border-start-end-radius: var(--border-border-radius);
  border-end-end-radius: var(--border-border-radius);
}
.igx-input-group--border .igx-input-group__bundle .igx-input-group igx-prefix, .igx-input-group .igx-input-group--border .igx-input-group__bundle igx-prefix, .igx-input-group--border .igx-input-group__bundle .igx-input-group--focused igx-prefix, .igx-input-group--focused .igx-input-group--border .igx-input-group__bundle igx-prefix,
.igx-input-group--border .igx-input-group__bundle .igx-input-group [igxPrefix],
.igx-input-group .igx-input-group--border .igx-input-group__bundle [igxPrefix],
.igx-input-group--border .igx-input-group__bundle .igx-input-group--focused [igxPrefix],
.igx-input-group--focused .igx-input-group--border .igx-input-group__bundle [igxPrefix],
.igx-input-group--border .igx-input-group__bundle .igx-input-group igx-suffix,
.igx-input-group .igx-input-group--border .igx-input-group__bundle igx-suffix,
.igx-input-group--border .igx-input-group__bundle .igx-input-group--focused igx-suffix,
.igx-input-group--focused .igx-input-group--border .igx-input-group__bundle igx-suffix,
.igx-input-group--border .igx-input-group__bundle .igx-input-group [igxSuffix],
.igx-input-group .igx-input-group--border .igx-input-group__bundle [igxSuffix],
.igx-input-group--border .igx-input-group__bundle .igx-input-group--focused [igxSuffix],
.igx-input-group--focused .igx-input-group--border .igx-input-group__bundle [igxSuffix] {
  height: 100%;
}
.igx-input-group--border .igx-input-group__bundle::after {
  display: none;
}

.igx-input-group--border.igx-input-group--disabled .igx-input-group__bundle {
  background: var(--border-disabled-background);
}

.igx-input-group--border .igx-input-group__input, .igx-input-group--border .igx-input-group__file-input {
  width: 100%;
  height: 100% !important;
  padding-block: 0;
  border: none;
  outline-style: none;
  z-index: 1;
}

/* stylelint-disable */
.igx-input-group--search .igx-input-group__notch,
.igx-input-group--search .igx-input-group__bundle-main {
  padding-inline-start: max(var(--is-large, 1) * max(1.125rem, -1 * 1.125rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0.875rem, -1 * 0.875rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
}

.igx-input-group--search .igx-input-group__notch,
.igx-input-group--search .igx-input-group__bundle-main {
  padding-inline-end: max(var(--is-large, 1) * max(1.125rem, -1 * 1.125rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0.875rem, -1 * 0.875rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
}

/* stylelint-enable */
.igx-input-group--search .igx-input-group__notch,
.igx-input-group--search .igx-input-group__bundle-main {
  padding-inline: 0.25rem;
}

.igx-input-group--search .igx-input-group__bundle {
  background: var(--search-background);
  box-shadow: var(--search-resting-shadow);
  border-radius: var(--search-border-radius);
  overflow: hidden;
}
.igx-input-group--search .igx-input-group__bundle::after {
  display: none;
}

.igx-input-group--search .igx-input-group__bundle:hover {
  box-shadow: var(--search-hover-shadow);
  border-color: var(--hover-border-color);
}

.igx-input-group--search.igx-input-group--focused .igx-input-group__bundle {
  box-shadow: var(--search-hover-shadow);
  border-color: var(--hover-border-color);
}

.igx-input-group--search.igx-input-group--disabled .igx-input-group__bundle, .igx-input-group--fluent.igx-input-group--search.igx-input-group--disabled .igx-input-group__bundle {
  background: var(--search-disabled-background);
  box-shadow: var(--search-disabled-shadow);
  border-color: var(--disabled-border-color);
}

.igx-input-group--border .igx-input-group__bundle-main {
  background: transparent;
  padding: 0 0.25rem;
  font-size: 1rem;
  min-height: var(--size);
}

.igx-input-group__label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  max-width: 100%;
  padding-inline-end: 0.25rem;
  line-height: normal;
  backface-visibility: hidden;
  will-change: transform;
  transform-origin: top left;
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-inline-start: max(var(--is-large, 1) * max(-0.25rem, -1 * -0.25rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(-0.125rem, -1 * -0.125rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0, -1 * 0) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
  color: var(--idle-secondary-color);
}
[dir=rtl] .igx-input-group__label {
  transform-origin: top right;
}

.igx-input-group--border .igx-input-group__label {
  padding-inline-end: 0;
  display: inline-block;
  position: relative;
  background: transparent;
}

.igx-input-group--border .igx-input-group__label,
.igx-input-group--search .igx-input-group__label {
  transform: translateY(0);
}

.igx-input-group--search .igx-input-group__label + .igx-input-group__input {
  transform: translateY(0);
}

.igx-input-group--focused .igx-input-group__label, .igx-input-group--placeholder .igx-input-group__label, .igx-input-group--filled .igx-input-group__label, .igx-input-group--file .igx-input-group__label {
  --floating-label-position: -73%;
  transform: translateY(var(--floating-label-position));
  font-size: 0.75rem;
}

.igx-input-group--border.igx-input-group--focused .igx-input-group__notch,
.igx-input-group--border.igx-input-group--filled .igx-input-group__notch,
.igx-input-group--border.igx-input-group--placeholder .igx-input-group__notch,
.igx-input-group--border.igx-input-group--file .igx-input-group__notch {
  border-block-start-color: transparent !important;
}

.igx-input-group--border.igx-input-group--focused .igx-input-group__bundle-start {
  border-inline-start-width: 0.125rem;
  border-block-start-width: 0.125rem;
  border-block-end-width: 0.125rem;
  border-inline-start-color: var(--focused-border-color);
  border-block-start-color: var(--focused-border-color);
  border-block-end-color: var(--focused-border-color);
}
.igx-input-group--border.igx-input-group--focused .igx-input-group__bundle-end {
  border-inline-end-width: 0.125rem;
  border-block-start-width: 0.125rem;
  border-block-end-width: 0.125rem;
  border-inline-end-color: var(--focused-border-color);
  border-block-start-color: var(--focused-border-color);
  border-block-end-color: var(--focused-border-color);
}
.igx-input-group--border.igx-input-group--focused .igx-input-group__filler,
.igx-input-group--border.igx-input-group--focused .igx-input-group__notch {
  border-block-width: 0.125rem;
}
.igx-input-group--border.igx-input-group--focused .igx-input-group__filler {
  border-block-color: var(--focused-border-color);
}
.igx-input-group--border.igx-input-group--focused .igx-input-group__notch {
  border-block-end-color: var(--focused-border-color);
}
.igx-input-group--border.igx-input-group--focused igx-prefix:first-child,
.igx-input-group--border.igx-input-group--focused [igxPrefix]:first-child {
  margin-inline-start: -0.0625rem;
}
.igx-input-group--border.igx-input-group--focused igx-suffix:last-child,
.igx-input-group--border.igx-input-group--focused [igxSuffix]:last-child {
  margin-inline-end: -0.0625rem;
}

.igx-input-group--border.igx-input-group--filled .igx-input-group__label, .igx-input-group--border.igx-input-group--file .igx-input-group__label, .igx-input-group--border.igx-input-group--focused .igx-input-group__label, .igx-input-group--border.igx-input-group--placeholder .igx-input-group__label {
  --label-position: max(var(--is-large, 1) * max(26px, -1 * 26px), var(--is-medium, 1) * max(22px, -1 * 22px), var(--is-small, 1) * max(18px, -1 * 18px));
  transform: translateY(calc(var(--label-position) * -1));
  font-size: 0.75rem;
  margin-top: 0;
  overflow: hidden;
  will-change: font-size, color, transform;
}

.igx-input-group--textarea-group .igx-input-group__bundle {
  --size: max(var(--is-large, 1) * max(5.125rem, -1 * 5.125rem), var(--is-medium, 1) * max(4.3157894737rem, -1 * 4.3157894737rem), var(--is-small, 1) * max(3.7272727273rem, -1 * 3.7272727273rem));
  min-height: var(--size) !important;
  height: auto !important;
}

.igx-input-group--textarea-group .igx-input-group__label {
  transition-duration: 0ms;
}

.igx-input-group--textarea-group.igx-input-group--focused .igx-input-group__label, .igx-input-group--textarea-group.igx-input-group--filled .igx-input-group__label, .igx-input-group--textarea-group.igx-input-group--placeholder .igx-input-group__label {
  transform: translateY(0) !important;
  top: calc(1.25rem / 4);
}

.igx-input-group--textarea-group.igx-input-group--filled.igx-input-group--border .igx-input-group__label, .igx-input-group--textarea-group.igx-input-group--placeholder.igx-input-group--border .igx-input-group__label,
.igx-input-group--textarea-group.igx-input-group--focused.igx-input-group--border .igx-input-group__label {
  top: -0.375rem;
  margin-block-end: auto !important;
}

.igx-input-group--textarea-group.igx-input-group--filled .igx-input-group__notch, .igx-input-group--textarea-group.igx-input-group--placeholder .igx-input-group__notch, .igx-input-group--textarea-group.igx-input-group--filled.igx-input-group--border .igx-input-group__notch, .igx-input-group--textarea-group.igx-input-group--placeholder.igx-input-group--border .igx-input-group__notch {
  border-block-start-width: 0.125rem;
}

.igx-input-group--focused .igx-input-group__label {
  color: var(--focused-secondary-color);
}

.igx-input-group--valid .igx-input-group__label {
  color: var(--success-secondary-color);
}

.igx-input-group--invalid .igx-input-group__label {
  color: var(--error-secondary-color);
}

.igx-input-group--required .igx-input-group__label::after {
  content: "*";
  font-size: inherit;
  vertical-align: top;
  margin-inline-start: 0.125rem; /* rem(2px) base is 16px */
  display: inline-block;
}

.igx-input-group--disabled .igx-input-group__label {
  color: var(--disabled-text-color) !important;
}

.igx-input-group__input, .igx-input-group__textarea {
  position: relative;
  display: block;
  border: none;
  padding-block-start: 1.25rem;
  padding-block-end: 0.375rem;
  padding-inline: 0;
  height: calc(var(--size) - 2px);
  width: 100%;
  min-width: 0;
  background: transparent;
  color: var(--filled-text-color);
  outline-style: none;
  box-shadow: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.igx-input-group__input:not([type=date]), .igx-input-group__textarea:not([type=date]) {
  line-height: 0 !important; /* Reset typography */
}
.igx-input-group__input::-webkit-input-placeholder, .igx-input-group__textarea::-webkit-input-placeholder {
  line-height: normal;
}
.igx-input-group__input::placeholder, .igx-input-group__textarea::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
  line-height: normal; /* Fix placeholder position in Safari */
}

.igx-input-group__notch:empty + .igx-input-group__bundle-main .igx-input-group__input, .igx-input-group__notch:empty + .igx-input-group__bundle-main .igx-input-group__textarea {
  padding-block: 0;
}

.igx-input-group__file-input {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding-block-start: 1.25rem;
  padding-block-end: 0.375rem;
  height: calc(var(--size) - 2px);
  color: var(--filled-text-color);
}
.igx-input-group__file-input span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: inherit;
  max-width: inherit;
  top: 50%;
  transform: translateY(-50%);
}

.igx-input-group__clear-icon:focus {
  background-color: hsla(var(--ig-primary-500), var(--ig-primary-a));
  color: var(--ig-primary-600-contrast);
  background-color: transparent;
  color: hsla(var(--ig-secondary-500), var(--ig-secondary-a));
}

.igx-input-group__input:-webkit-autofill {
  -webkit-transition-delay: 99999s;
  transition-delay: 99999s;
}

.igx-input-group__input::-ms-clear {
  display: none;
}

.igx-input-group__input:hover, .igx-input-group__file-input:hover, .igx-input-group__textarea:hover {
  cursor: pointer;
}

.igx-input-group__input:focus, .igx-input-group__file-input:focus, .igx-input-group__textarea:focus {
  cursor: text;
  color: var(--focused-text-color);
}

.igx-input-group--disabled .igx-input-group__input, .igx-input-group--disabled .igx-input-group__file-input, .igx-input-group--disabled.igx-input-group--required .igx-input-group__input, .igx-input-group--disabled.igx-input-group--required .igx-input-group__file-input, .igx-input-group--indigo.igx-input-group--disabled .igx-input-group__input, .igx-input-group--indigo.igx-input-group--disabled .igx-input-group__file-input {
  cursor: default;
  color: var(--disabled-text-color) !important;
}
.igx-input-group--disabled .igx-input-group__input::placeholder, .igx-input-group--disabled .igx-input-group__file-input::placeholder, .igx-input-group--disabled.igx-input-group--required .igx-input-group__input::placeholder, .igx-input-group--disabled.igx-input-group--required .igx-input-group__file-input::placeholder, .igx-input-group--indigo.igx-input-group--disabled .igx-input-group__input::placeholder, .igx-input-group--indigo.igx-input-group--disabled .igx-input-group__file-input::placeholder {
  color: var(--disabled-placeholder-color);
}

.igx-input-group__textarea {
  --size: max(var(--is-large, 1) * max(5.125rem, -1 * 5.125rem), var(--is-medium, 1) * max(4.3157894737rem, -1 * 4.3157894737rem), var(--is-small, 1) * max(3.7272727273rem, -1 * 3.7272727273rem));
  min-height: var(--size);
  height: auto;
  resize: vertical;
  overflow: hidden;
  padding: 0;
  line-height: normal !important;
  z-index: 1;
}
.igx-input-group__textarea:not([type="*"]) {
  line-height: normal !important; /* resets typography styles */
}

.igx-input-group--textarea-group .igx-input-group__bundle-main {
  overflow: hidden;
  height: calc(100% - 0.125rem);
  top: 0.0625rem;
  padding-block-start: 1.25rem;
}

.igx-input-group--disabled .igx-input-group__textarea, .igx-input-group--disabled.igx-input-group--required .igx-input-group__textarea {
  color: var(--disabled-text-color) !important;
}
.igx-input-group--disabled .igx-input-group__textarea::placeholder, .igx-input-group--disabled.igx-input-group--required .igx-input-group__textarea::placeholder {
  color: var(--disabled-placeholder-color);
}

.igx-input-group__line {
  position: absolute;
  width: 100%;
  inset-inline-end: 0;
  height: 0.125rem;
  align-self: end;
  transform: scaleX(0);
  transform-origin: center;
  background: var(--focused-secondary-color);
  z-index: 1;
}

.igx-input-group--valid .igx-input-group__line {
  background: var(--success-secondary-color);
}

.igx-input-group--border.igx-input-group--valid .igx-input-group__bundle-start {
  border-inline-start-color: var(--success-secondary-color);
  border-block-start-color: var(--success-secondary-color);
  border-block-end-color: var(--success-secondary-color);
}
.igx-input-group--border.igx-input-group--valid .igx-input-group__bundle-end {
  border-inline-end-color: var(--success-secondary-color);
  border-block-start-color: var(--success-secondary-color);
  border-block-end-color: var(--success-secondary-color);
}
.igx-input-group--border.igx-input-group--valid .igx-input-group__notch,
.igx-input-group--border.igx-input-group--valid .igx-input-group__filler {
  border-block-color: var(--success-secondary-color);
}

.igx-input-group--border.igx-input-group--warning .igx-input-group__bundle-start {
  border-inline-start-color: var(--warning-secondary-color);
  border-block-start-color: var(--warning-secondary-color);
  border-block-end-color: var(--warning-secondary-color);
}
.igx-input-group--border.igx-input-group--warning .igx-input-group__bundle-end {
  border-inline-end-color: var(--warning-secondary-color);
  border-block-start-color: var(--warning-secondary-color);
  border-block-end-color: var(--warning-secondary-color);
}
.igx-input-group--border.igx-input-group--warning .igx-input-group__notch,
.igx-input-group--border.igx-input-group--warning .igx-input-group__filler {
  border-block-color: var(--warning-secondary-color);
}

.igx-input-group--invalid .igx-input-group__line {
  background: var(--error-secondary-color);
}

.igx-input-group--border.igx-input-group--invalid .igx-input-group__bundle-start {
  border-inline-start-color: var(--error-secondary-color);
  border-block-start-color: var(--error-secondary-color);
  border-block-end-color: var(--error-secondary-color);
}
.igx-input-group--border.igx-input-group--invalid .igx-input-group__bundle-end {
  border-inline-end-color: var(--error-secondary-color);
  border-block-start-color: var(--error-secondary-color);
  border-block-end-color: var(--error-secondary-color);
}
.igx-input-group--border.igx-input-group--invalid .igx-input-group__notch,
.igx-input-group--border.igx-input-group--invalid .igx-input-group__filler {
  border-block-color: var(--error-secondary-color);
}

.igx-input-group--border.igx-input-group--disabled .igx-input-group__bundle-start {
  border-inline-start-color: var(--disabled-border-color);
  border-block-start-color: var(--disabled-border-color);
  border-block-end-color: var(--disabled-border-color);
}
.igx-input-group--border.igx-input-group--disabled .igx-input-group__bundle-end {
  border-inline-end-color: var(--disabled-border-color);
  border-block-start-color: var(--disabled-border-color);
  border-block-end-color: var(--disabled-border-color);
}
.igx-input-group--border.igx-input-group--disabled .igx-input-group__notch,
.igx-input-group--border.igx-input-group--disabled .igx-input-group__filler {
  border-block-color: var(--disabled-border-color);
}

.igx-input-group--focused .igx-input-group__line {
  transform: scaleX(1);
  transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.igx-input-group--border .igx-input-group__line, .igx-input-group--search .igx-input-group__line {
  display: none;
}

.igx-input-group__hint {
  color: var(--helper-text-color);
  position: relative;
  display: flex;
  padding-block-start: 0.3125rem;
  padding-inline-start: 1rem;
  justify-content: space-between;
}
.igx-input-group__hint > * {
  margin-inline-end: 0.5rem;
}
.igx-input-group__hint > *:last-child {
  margin-inline-end: 0;
}
.igx-input-group__hint:empty {
  display: none;
}

.igx-input-group--valid .igx-input-group__hint {
  color: var(--success-secondary-color);
}

.igx-input-group--invalid .igx-input-group__hint {
  color: var(--error-secondary-color);
}

.igx-input-group__hint-item--start, .igx-input-group__hint-item--end {
  display: flex;
  align-items: center;
  position: relative;
}

.igx-input-group__hint-item--start {
  justify-content: flex-start;
}

.igx-input-group__hint-item--end {
  justify-content: flex-end;
}

.igx-input-group__hint-item--start,
.igx-input-group__hint-item--end {
  width: 100%;
}

.igx-input-group--disabled igx-prefix, .igx-input-group--disabled.igx-input-group--required igx-prefix,
.igx-input-group--disabled [igxPrefix],
.igx-input-group--disabled igx-suffix,
.igx-input-group--disabled.igx-input-group--required igx-suffix,
.igx-input-group--disabled [igxSuffix] {
  color: var(--disabled-text-color);
  background: inherit;
  pointer-events: none;
}

.igx-input-group--disabled .igx-input-group__hint {
  color: var(--disabled-text-color);
}

.igx-input-group--indigo .igx-input-group__bundle {
  border-radius: var(--box-border-radius) var(--box-border-radius) 0 0;
  transition: background 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding-top: 0;
}
.igx-input-group--indigo .igx-input-group__bundle:hover, .igx-input-group--indigo .igx-input-group__bundle:focus {
  background: var(--box-background-hover);
}

.igx-input-group--indigo.igx-input-group--focused .igx-input-group__label {
  color: var(--focused-secondary-color);
}

.igx-input-group--indigo.igx-input-group--invalid .igx-input-group__label {
  color: var(--error-secondary-color);
}

.igx-input-group--indigo.igx-input-group--valid .igx-input-group__label {
  color: var(--success-secondary-color);
}

.igx-input-group--indigo .igx-input-group__input, .igx-input-group--indigo .igx-input-group__file-input {
  padding-block: 0.5rem;
  padding-inline: max(var(--is-large, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(0.75rem, -1 * 0.75rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0.5rem, -1 * 0.5rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
}

.igx-input-group--indigo.igx-input-group--focused .igx-input-group__bundle {
  background: var(--box-background-hover);
}

.igx-input-group--indigo.igx-input-group--disabled .igx-input-group__bundle {
  background: transparent;
}
.igx-input-group--indigo.igx-input-group--disabled .igx-input-group__bundle:hover, .igx-input-group--indigo.igx-input-group--disabled .igx-input-group__bundle:focus {
  background: transparent;
}
.igx-input-group--indigo.igx-input-group--disabled .igx-input-group__bundle::after {
  border-bottom-style: solid;
}

.igx-input-group--fluent igx-prefix,
.igx-input-group--fluent [igxPrefix],
.igx-input-group--fluent igx-suffix,
.igx-input-group--fluent [igxSuffix] {
  outline-style: none;
}
.igx-input-group--fluent select {
  width: calc(100% + 0.5rem);
  margin-inline-start: -0.5rem !important;
  cursor: pointer !important;
}

.igx-input-group--fluent.igx-input-group--search {
  display: flex;
  flex-direction: column;
}
.igx-input-group--fluent.igx-input-group--search igx-prefix,
.igx-input-group--fluent.igx-input-group--search [igxPrefix] {
  overflow: hidden;
}

.igx-input-group--fluent.igx-input-group--search.igx-input-group--focused igx-prefix,
.igx-input-group--fluent.igx-input-group--search.igx-input-group--focused [igxPrefix] {
  display: none;
}

.igx-input-group--fluent.igx-input-group--required .igx-input-group__bundle::before {
  content: "*";
  position: absolute;
  top: -0.5rem;
  inset-inline-start: calc(100% + 0.25rem);
  color: var(--error-secondary-color);
}

.igx-input-group--bootstrap.igx-input-group--textarea-group .igx-input-group__bundle,
.igx-input-group--fluent.igx-input-group--textarea-group .igx-input-group__bundle {
  display: flex;
}

.igx-input-group--fluent.igx-input-group--required .igx-input-group__label::after {
  color: var(--error-secondary-color);
}

.igx-input-group--fluent.igx-input-group--required.igx-input-group--disabled .igx-input-group__label::after {
  color: var(--disabled-text-color);
}

.igx-input-group--fluent .igx-input-group__bundle, .igx-input-group--fluent.igx-input-group--search .igx-input-group__bundle {
  --size: max(var(--is-large, 1) * max(3rem, -1 * 3rem), var(--is-medium, 1) * max(2.5rem, -1 * 2.5rem), var(--is-small, 1) * max(2rem, -1 * 2rem));
  --min-width: max(var(--is-large, 1) * max(0.5rem, -1 * 0.5rem), var(--is-medium, 1) * max(0.375rem, -1 * 0.375rem), var(--is-small, 1) * max(0.25rem, -1 * 0.25rem));
  min-height: var(--size);
  padding: 0;
  border: 0.0625rem solid var(--border-color);
  background: var(--border-background);
  align-items: stretch;
  overflow: hidden;
}
.igx-input-group--fluent .igx-input-group__bundle:focus .igx-input-group__bundle-start, .igx-input-group--fluent .igx-input-group__bundle:focus-within .igx-input-group__bundle-start {
  margin-block-start: -0.0625rem;
  margin-inline-start: -0.0625rem;
}
.igx-input-group--fluent .igx-input-group__bundle:focus .igx-input-group__bundle-end, .igx-input-group--fluent .igx-input-group__bundle:focus-within .igx-input-group__bundle-end {
  margin-block-start: -0.0625rem;
  margin-inline-end: -0.0625rem;
}
.igx-input-group--fluent .igx-input-group__bundle:focus .igx-input-group__bundle-start:empty + .igx-input-group__bundle-main,
.igx-input-group--fluent .igx-input-group__bundle:focus .igx-input-group__bundle-end:empty + .igx-input-group__bundle-main, .igx-input-group--fluent .igx-input-group__bundle:focus-within .igx-input-group__bundle-start:empty + .igx-input-group__bundle-main,
.igx-input-group--fluent .igx-input-group__bundle:focus-within .igx-input-group__bundle-end:empty + .igx-input-group__bundle-main {
  margin-inline-start: -0.0625rem;
}
.igx-input-group--fluent .igx-input-group__bundle:focus .igx-input-group__bundle-main, .igx-input-group--fluent .igx-input-group__bundle:focus-within .igx-input-group__bundle-main {
  margin-block-start: -0.0625rem;
}

.igx-input-group--fluent .igx-input-group__bundle:hover, .igx-input-group--fluent.igx-input-group--search .igx-input-group__bundle:hover {
  border-color: var(--hover-border-color);
}

.igx-input-group--fluent .igx-input-group__bundle:focus-within, .igx-input-group--fluent .igx-input-group__bundle:focus {
  border-color: var(--focused-border-color);
}

.igx-input-group--fluent.igx-input-group--invalid .igx-input-group__bundle,
.igx-input-group--fluent.igx-input-group--invalid .igx-input-group__bundle:hover,
.igx-input-group--fluent.igx-input-group--invalid .igx-input-group__bundle:focus-within {
  border-color: var(--error-secondary-color);
}

.igx-input-group--fluent.igx-input-group--valid .igx-input-group__bundle,
.igx-input-group--fluent.igx-input-group--valid .igx-input-group__bundle:hover,
.igx-input-group--fluent.igx-input-group--valid .igx-input-group__bundle:focus-within {
  border-color: var(--success-secondary-color);
}

.igx-input-group--fluent.igx-input-group--disabled .igx-input-group__bundle:hover,
.igx-input-group--fluent.igx-input-group--disabled .igx-input-group__bundle {
  border-color: var(--disabled-border-color);
  background: var(--border-disabled-background);
}

.igx-input-group--fluent .igx-input-group__bundle-main, .igx-input-group--fluent.igx-input-group--search .igx-input-group__bundle-main {
  align-self: center;
  cursor: default;
}

.igx-input-group--fluent.igx-input-group--textarea-group .igx-input-group__bundle-start:empty,
.igx-input-group--fluent.igx-input-group--textarea-group .igx-input-group__bundle-end:empty {
  display: none;
}

.igx-input-group--fluent .igx-input-group__input, .igx-input-group--fluent .igx-input-group__file-input, .igx-input-group--fluent.igx-input-group--search .igx-input-group__input {
  font-size: 0.875rem;
  padding-block: 0;
  padding-inline: 0.5rem;
  margin: 0;
  border: none;
}

.igx-input-group--indigo .igx-input-group__textarea {
  padding-block: 0.5rem;
  padding-inline: max(var(--is-large, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(0.75rem, -1 * 0.75rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0.5rem, -1 * 0.5rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
}

.igx-input-group--fluent .igx-input-group__textarea {
  padding: 0.5rem;
}

.igx-input-group--fluent.igx-input-group--disabled .igx-input-group__input, .igx-input-group--fluent.igx-input-group--disabled .igx-input-group__input:hover, .igx-input-group--fluent.igx-input-group--disabled .igx-input-group__input:focus, .igx-input-group--fluent.igx-input-group--disabled .igx-input-group__file-input, .igx-input-group--fluent.igx-input-group--disabled .igx-input-group__file-input:hover, .igx-input-group--fluent.igx-input-group--disabled .igx-input-group__file-input:focus {
  color: var(--disabled-text-color);
}

.igx-input-group--fluent .igx-input-group__label, .igx-input-group--fluent.igx-input-group--search .igx-input-group__label, .igx-input-group--indigo .igx-input-group__label {
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  position: static;
  transform: translateY(0);
  transform-origin: top left;
  margin-top: 0 !important;
  height: auto;
  margin-block-end: 0.25rem;
  color: var(--idle-secondary-color);
}
[dir=rtl] .igx-input-group--fluent .igx-input-group__label, .igx-input-group--fluent [dir=rtl] .igx-input-group__label, [dir=rtl] .igx-input-group--indigo .igx-input-group__label, .igx-input-group--indigo [dir=rtl] .igx-input-group__label {
  transform-origin: top right;
}

.igx-input-group--fluent.igx-input-group--required .igx-input-group__label + .igx-input-group__bundle::before {
  display: none;
}

.igx-input-group--fluent.igx-input-group--valid .igx-input-group__label {
  color: var(--idle-text-color);
}

.igx-input-group--fluent.igx-input-group--invalid .igx-input-group__label {
  color: var(--idle-text-color);
}

.igx-input-group--fluent.igx-input-group--disabled .igx-input-group__label {
  color: var(--disabled-text-color) !important;
}

.igx-input-group--fluent.igx-input-group--filled .igx-input-group__label, .igx-input-group--fluent.igx-input-group--file .igx-input-group__label {
  transform: translateY(0);
}

.igx-input-group--fluent.igx-input-group--focused .igx-input-group__label {
  color: hsla(var(--ig-gray-800), var(--ig-gray-a));
  transform: translateY(0) scale(1);
}

.igx-input-group--fluent.igx-input-group--focused.igx-input-group--textarea-group .igx-input-group__hint {
  margin-block-start: -0.0625rem;
}

.igx-input-group--fluent.igx-input-group--placeholder .igx-input-group__label {
  transform: translateY(0) scale(1);
}

.igx-input-group--fluent igx-prefix .ig-typography [igx-button],
.igx-input-group--fluent [igxPrefix] .ig-typography [igx-button],
.igx-input-group--fluent igx-prefix .ig-typography igx-button,
.igx-input-group--fluent [igxPrefix] .ig-typography igx-button,
.igx-input-group--fluent igx-prefix button,
.igx-input-group--fluent [igxPrefix] button,
.igx-input-group--fluent igx-suffix .ig-typography [igx-button],
.igx-input-group--fluent [igxSuffix] .ig-typography [igx-button],
.igx-input-group--fluent igx-suffix .ig-typography igx-button,
.igx-input-group--fluent [igxSuffix] .ig-typography igx-button,
.igx-input-group--fluent igx-suffix button,
.igx-input-group--fluent [igxSuffix] button {
  border-radius: 0;
  height: 100%;
}

.igx-input-group--fluent [igxPrefix]:first-child [igxButton]::after,
.igx-input-group--fluent [igxPrefix]:first-child button::after,
.igx-input-group--fluent igx-prefix:first-child [igxButton]::after,
.igx-input-group--fluent igx-prefix:first-child button::after {
  border-start-start-radius: var(--border-border-radius);
  border-end-start-radius: var(--border-border-radius);
}

.igx-input-group--fluent igx-prefix,
.igx-input-group--fluent [igxPrefix],
.igx-input-group--fluent igx-suffix,
.igx-input-group--fluent [igxSuffix] {
  padding-inline: max(var(--is-large, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(0.75rem, -1 * 0.75rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0.5rem, -1 * 0.5rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
}

.igx-input-group--fluent .igx-input-group__upload-button {
  padding: 0;
}

.igx-input-group--bootstrap.igx-input-group--prefixed .igx-input-group__file-input,
.igx-input-group--bootstrap.igx-input-group--prefixed .igx-input-group__input,
.igx-input-group--bootstrap.igx-input-group--prefixed .igx-input-group__textarea {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.igx-input-group--bootstrap.igx-input-group--suffixed .igx-input-group__file-input,
.igx-input-group--bootstrap.igx-input-group--suffixed .igx-input-group__input,
.igx-input-group--bootstrap.igx-input-group--suffixed .igx-input-group__textarea {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.igx-input-group--bootstrap.igx-input-group--disabled .igx-input-group__bundle {
  background-image: none;
}
.igx-input-group--bootstrap.igx-input-group--disabled .igx-input-group__bundle .igx-input-group__bundle-start,
.igx-input-group--bootstrap.igx-input-group--disabled .igx-input-group__bundle .igx-input-group__bundle-end {
  border-color: var(--disabled-border-color);
}

.igx-input-group--bootstrap .igx-input-group__bundle {
  --size: max(var(--is-large, 1) * max(3rem, -1 * 3rem), var(--is-medium, 1) * max(2.375rem, -1 * 2.375rem), var(--is-small, 1) * max(1.9375rem, -1 * 1.9375rem));
  padding: 0;
  box-shadow: none;
  z-index: 0;
}

.igx-input-group--bootstrap .igx-input-group__bundle:hover {
  border: none;
  box-shadow: none;
}

.igx-input-group--bootstrap .igx-input-group__bundle-start,
.igx-input-group--bootstrap .igx-input-group__bundle-end {
  flex: none;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: var(--border-color);
  overflow: hidden;
}
.igx-input-group--bootstrap .igx-input-group__bundle-start:empty,
.igx-input-group--bootstrap .igx-input-group__bundle-end:empty {
  display: none;
}

.igx-input-group--bootstrap .igx-input-group__bundle-start {
  grid-area: 1/1;
  border-inline-start-width: 0.0625rem;
  border-inline-end-width: 0;
  border-block-start-width: 0.0625rem;
  border-block-end-width: 0.0625rem;
  border-start-start-radius: var(--box-border-radius);
  border-end-start-radius: var(--box-border-radius);
}

.igx-input-group--bootstrap .igx-input-group__bundle-end {
  grid-area: 1/3;
  border-inline-start-width: 0;
  border-inline-end-width: 0.0625rem;
  border-block-start-width: 0.0625rem;
  border-block-end-width: 0.0625rem;
  border-start-end-radius: var(--box-border-radius);
  border-end-end-radius: var(--box-border-radius);
}

.igx-input-group--bootstrap .igx-input-group__label, .igx-input-group--bootstrap.igx-input-group--focused .igx-input-group__label, .igx-input-group--bootstrap.igx-input-group--valid .igx-input-group__label, .igx-input-group--bootstrap.igx-input-group--invalid .igx-input-group__label {
  position: static;
  display: block;
  color: var(--idle-secondary-color);
  padding: 0;
  line-height: 1.5rem;
  transform: translateY(0);
  transform-origin: top left;
  margin-block-start: 0 !important;
  margin-block-end: 0.25rem;
  height: auto;
  font-size: 1rem;
}
[dir=rtl] .igx-input-group--bootstrap .igx-input-group__label, .igx-input-group--bootstrap [dir=rtl] .igx-input-group__label {
  transform-origin: top right;
}

.igx-input-group--bootstrap .igx-input-group__input, .igx-input-group--bootstrap .igx-input-group__textarea {
  height: auto;
  line-height: 1.5;
  margin: 0;
  font-size: max(var(--is-large, 1) * max(1.25rem, -1 * 1.25rem) * var(--ig-spacing-large, var(--ig-spacing, --ig-spacing)), var(--is-medium, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-medium, var(--ig-spacing, --ig-spacing)), var(--is-small, 1) * max(0.875rem, -1 * 0.875rem) * var(--ig-spacing-small, var(--ig-spacing, --ig-spacing)));
  padding-block: max(var(--is-large, 1) * max(0.5rem, -1 * 0.5rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(0.375rem, -1 * 0.375rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0.25rem, -1 * 0.25rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
  padding-inline: max(var(--is-large, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(0.75rem, -1 * 0.75rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0.5rem, -1 * 0.5rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
  transition: box-shadow 0.15s ease-out, border 0.15s ease-out;
}

.igx-input-group--bootstrap .igx-input-group__input, .igx-input-group--bootstrap .igx-input-group__textarea,
.igx-input-group--bootstrap .igx-input-group__file-input {
  border: 0.0625rem solid var(--border-color);
  padding-block: max(var(--is-large, 1) * max(0.5rem, -1 * 0.5rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(0.375rem, -1 * 0.375rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0.25rem, -1 * 0.25rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
  padding-inline: max(var(--is-large, 1) * max(1rem, -1 * 1rem) * var(--ig-spacing-inline-large, var(--ig-spacing-inline, --ig-spacing)), var(--is-medium, 1) * max(0.75rem, -1 * 0.75rem) * var(--ig-spacing-inline-medium, var(--ig-spacing-inline, --ig-spacing)), var(--is-small, 1) * max(0.5rem, -1 * 0.5rem) * var(--ig-spacing-inline-small, var(--ig-spacing-inline, --ig-spacing)));
  grid-area: 1/2/auto/auto;
  border-radius: var(--box-border-radius);
  z-index: 1;
}

.igx-input-group--bootstrap .igx-input-group__file-input {
  height: inherit;
}

.igx-input-group--bootstrap.igx-input-group--focused .igx-input-group__input, .igx-input-group--bootstrap.igx-input-group--focused .igx-input-group__file-input, .igx-input-group--bootstrap.igx-input-group--focused .igx-input-group__textarea {
  border: 0.0625rem solid var(--focused-border-color);
  box-shadow: 0 0 0 0.25rem var(--focused-secondary-color);
}

.igx-input-group--bootstrap.igx-input-group--valid .igx-input-group__input, .igx-input-group--bootstrap.igx-input-group--valid .igx-input-group__input:hover, .igx-input-group--bootstrap.igx-input-group--valid .igx-input-group__file-input, .igx-input-group--bootstrap.igx-input-group--valid .igx-input-group__file-input:hover, .igx-input-group--bootstrap.igx-input-group--valid .igx-input-group__textarea, .igx-input-group--bootstrap.igx-input-group--valid .igx-input-group__textarea:hover {
  border: 0.0625rem solid var(--success-secondary-color);
  box-shadow: 0 0 0 0.25rem var(--success-shadow-color);
}

.igx-input-group--bootstrap.igx-input-group--invalid .igx-input-group__input, .igx-input-group--bootstrap.igx-input-group--invalid .igx-input-group__input:hover, .igx-input-group--bootstrap.igx-input-group--invalid .igx-input-group__file-input, .igx-input-group--bootstrap.igx-input-group--invalid .igx-input-group__file-input:hover, .igx-input-group--bootstrap.igx-input-group--invalid .igx-input-group__textarea, .igx-input-group--bootstrap.igx-input-group--invalid .igx-input-group__textarea:hover {
  border: 0.0625rem solid var(--error-secondary-color);
  box-shadow: 0 0 0 0.25rem var(--error-shadow-color);
}

.igx-input-group--bootstrap.igx-input-group--disabled .igx-input-group__input, .igx-input-group--bootstrap.igx-input-group--disabled .igx-input-group__file-input, .igx-input-group--bootstrap.igx-input-group--disabled .igx-input-group__textarea {
  background: var(--border-disabled-background);
  border: 0.0625rem solid var(--disabled-border-color);
  box-shadow: none;
}

.igx-input-group--bootstrap.igx-input-group--search .igx-input-group__input {
  transform: translateY(0);
}

.igx-input-group--bootstrap.igx-input-group--disabled .igx-input-group__bundle igx-prefix,
.igx-input-group--bootstrap.igx-input-group--disabled .igx-input-group__bundle [igxPrefix],
.igx-input-group--bootstrap.igx-input-group--disabled .igx-input-group__bundle igx-suffix,
.igx-input-group--bootstrap.igx-input-group--disabled .igx-input-group__bundle [igxSuffix] {
  background: var(--border-disabled-background);
  border-color: var(--disabled-border-color);
  color: var(--disabled-text-color);
}

.igx-input-group--bootstrap .igx-input-group__upload-button {
  padding: 0;
}
.igx-input-group--bootstrap .igx-input-group__upload-button [igxButton] {
  min-height: 0;
  height: calc(100% - 0.5rem);
}

igx-list {
  --background: var(--igx-list-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --header-background: var(--igx-list-header-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --header-text-color: var(--igx-list-header-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --item-background: var(--igx-list-item-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --item-background-hover: var(--igx-list-item-background-hover, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --item-background-active: var(--igx-list-item-background-active, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --item-text-color: var(--igx-list-item-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --item-text-color-hover: var(--igx-list-item-text-color-hover, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --item-text-color-active: var(--igx-list-item-text-color-active, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --item-title-color: var(--igx-list-item-title-color, 'currentColor');
  --item-title-color-hover: var(--igx-list-item-title-color-hover, 'currentColor');
  --item-title-color-active: var(--igx-list-item-title-color-active, 'currentColor');
  --item-action-color: var(--igx-list-item-action-color, 'currentColor');
  --item-action-color-hover: var(--igx-list-item-action-color-hover, 'currentColor');
  --item-action-color-active: var(--igx-list-item-action-color-active, 'currentColor');
  --item-subtitle-color: var(--igx-list-item-subtitle-color, 'currentColor');
  --item-subtitle-color-hover: var(--igx-list-item-subtitle-color-hover, 'currentColor');
  --item-subtitle-color-active: var(--igx-list-item-subtitle-color-active, 'currentColor');
  --item-thumbnail-color: var(--igx-list-item-thumbnail-color, 'currentColor');
  --item-thumbnail-color-hover: var(--igx-list-item-thumbnail-color-hover, 'currentColor');
  --item-thumbnail-color-active: var(--igx-list-item-thumbnail-color-active, 'currentColor');
  --border-color: var(--igx-list-border-color, transparent);
  --border-width: var(--igx-list-border-width, 0);
  --border-radius: var(--igx-list-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0) * 1.5rem), 1.5rem));
  --item-border-radius: var(--igx-list-item-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0) * 1.5rem), 1.5rem));
}

.igx-list, .igx-list--empty, .igx-list--compact, .igx-list--cosy {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  background: var(--background);
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 0;
  border-radius: var(--border-radius);
}
.igx-list:focus-visible, .igx-list--empty:focus-visible, .igx-list--compact:focus-visible, .igx-list--cosy:focus-visible {
  outline-style: none;
}

.igx-list--empty {
  justify-content: center;
  align-items: center;
}

.igx-list--empty .igx-list__message {
  text-align: center;
  color: var(--item-text-color);
  padding: 1rem;
  z-index: 1;
}

.igx-list__header {
  display: flex;
  align-items: center;
  color: var(--header-text-color);
  background: var(--header-background);
  padding: 0.5rem 1rem;
  user-select: none;
}

.igx-list--cosy .igx-list__header {
  padding: 0.25rem 0.5rem;
}

.igx-list--compact .igx-list__header {
  padding: 0.125rem 0.25rem;
}

.igx-list__item-base {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  border-radius: var(--item-border-radius);
  color: var(--item-text-color);
  background: var(--item-background);
}
.igx-list__item-base:hover {
  color: var(--item-text-color-hover);
  background: var(--item-background-hover);
}
.igx-list__item-base:hover .igx-list__item-lines {
  color: currentColor;
}
.igx-list__item-base:hover .igx-list__item-line-title {
  color: var(--item-title-color-hover);
}
.igx-list__item-base:hover .igx-list__item-line-subtitle {
  color: var(--item-subtitle-color-hover);
}
.igx-list__item-base:hover .igx-list__item-actions {
  color: var(--item-action-color-hover);
}
.igx-list__item-base:hover .igx-list__item-actions igx-icon {
  color: var(--item-action-color-hover);
}
.igx-list__item-base:hover .igx-list__item-thumbnail {
  color: var(--item-thumbnail-color-hover);
}
.igx-list__item-base:hover .igx-list__item-thumbnail igx-icon {
  color: var(--item-thumbnail-color-hover);
}

.igx-list__item-right, .igx-list__item-left {
  position: absolute;
  visibility: hidden;
  display: flex;
  z-index: 1;
}

.igx-list__item-lines {
  color: currentColor;
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
}
.igx-list__item-lines:empty {
  display: none;
}

.igx-list__item-line-subtitle {
  color: var(--item-subtitle-color);
  opacity: 0.74;
}

.igx-list__item-line-title {
  color: var(--item-title-color);
}

.igx-list__item-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--item-action-color);
}
.igx-list__item-actions > * {
  margin-inline-start: 0.5rem;
}
.igx-list__item-actions:empty {
  display: none;
}
.igx-list__item-actions igx-icon {
  color: var(--item-action-color);
}
[dir=rtl] .igx-list__item-actions igx-icon {
  transform: scaleX(-1);
}

.igx-list__item-content, .igx-list__item-content:active {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 1rem;
  border-radius: var(--item-border-radius);
  background: inherit;
  z-index: 2;
}

.igx-list--compact .igx-list__item-content {
  padding: 0.125rem 0.25rem;
}

.igx-list--cosy .igx-list__item-content {
  padding: 0.25rem 0.5rem;
}

.igx-list__item-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  padding: 0;
  color: var(--item-thumbnail-color);
}
.igx-list__item-thumbnail > igx-icon {
  margin: 0.5rem 1rem;
  margin-inline-start: 0;
}
.igx-list__item-thumbnail > igx-avatar > igx-icon {
  margin: 0;
}
.igx-list__item-thumbnail:empty {
  display: none;
}

.igx-list__item-thumbnail:not(:empty) + .igx-list__item-lines {
  margin: 0;
  margin-inline-start: 1rem;
}

.igx-list--cosy .igx-list__item-thumbnail:not(:empty) + .igx-list__item-lines {
  margin: 0;
  margin-inline-start: 0.75rem;
}

.igx-list--compact .igx-list__item-thumbnail:not(:empty) + .igx-list__item-lines {
  margin: 0;
  margin-inline-start: 0.5rem;
}

.igx-list--cosy .igx-list__item-thumbnail > igx-icon {
  margin: 0.375rem 0.5rem;
  margin-inline-start: 0;
}

.igx-list--compact .igx-list__item-thumbnail > igx-icon {
  margin: 0.25rem;
  margin-inline-start: 0;
}

.igx-list--cosy .igx-list__item-actions > * {
  margin-inline-start: 0.375rem;
}

.igx-list--compact .igx-list__item-actions > * {
  margin-inline-start: 0.25rem;
}

.igx-list__item-lines + .igx-list__item-actions {
  margin-inline-start: 0.5rem;
}

.igx-list__item-content:active, .igx-list__item-base--active .igx-list__item-content {
  color: var(--item-text-color-active);
  background: var(--item-background-active);
  z-index: 3;
}
.igx-list__item-content:active .igx-list__item-line-title, .igx-list__item-base--active .igx-list__item-content .igx-list__item-line-title {
  color: var(--item-title-color-active);
}
.igx-list__item-content:active .igx-list__item-line-subtitle, .igx-list__item-base--active .igx-list__item-content .igx-list__item-line-subtitle {
  color: var(--item-subtitle-color-active);
}
.igx-list__item-content:active .igx-list__item-actions, .igx-list__item-base--active .igx-list__item-content .igx-list__item-actions {
  color: var(--item-action-color-active);
}
.igx-list__item-content:active .igx-list__item-actions igx-icon, .igx-list__item-base--active .igx-list__item-content .igx-list__item-actions igx-icon {
  color: var(--item-action-color-active);
}
.igx-list__item-content:active .igx-list__item-thumbnail, .igx-list__item-base--active .igx-list__item-content .igx-list__item-thumbnail {
  color: var(--item-thumbnail-color-active);
}
.igx-list__item-content:active .igx-list__item-thumbnail igx-icon, .igx-list__item-base--active .igx-list__item-content .igx-list__item-thumbnail igx-icon {
  color: var(--item-thumbnail-color-active);
}

.igx-list__item-content:not(:active) {
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

igx-navbar {
  --elevation: var(--igx-navbar-elevation, var(--ig-elevation-4));
  --background: var(--igx-navbar-background, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --text-color: var(--igx-navbar-text-color, var(--ig-primary-500-contrast));
  --idle-icon-color: var(--igx-navbar-idle-icon-color, var(--ig-primary-500-contrast));
  --hover-icon-color: var(--igx-navbar-hover-icon-color, var(--ig-primary-500-contrast));
  --shadow: var(--igx-navbar-shadow, var(--ig-elevation-4));
}

.igx-navbar {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 3.5rem;
  max-height: 8rem;
  padding-inline: 1rem;
  background: var(--background);
  color: var(--text-color);
  box-shadow: var(--shadow);
  z-index: 4;
  overflow: hidden;
}

igx-navbar-title,
[igxNavbarTitle], igx-navbar-action,
[igxNavbarAction], .igx-navbar__right, .igx-navbar__left {
  display: flex;
  align-items: center;
}

igx-navbar-title,
[igxNavbarTitle], .igx-navbar__title {
  display: -webkit-inline-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
  flex-grow: 1;
  user-select: text;
  display: flex;
  flex-direction: row;
}

.igx-navbar__right, .igx-navbar__left {
  user-select: none;
}
.igx-navbar__right > * + *, .igx-navbar__left > * + * {
  margin-inline-start: 1rem;
}

.igx-navbar__right > igx-icon, .igx-navbar__left > igx-icon,
.igx-navbar__right [igxButton=icon],
.igx-navbar__left [igxButton=icon] {
  cursor: pointer;
  color: var(--idle-icon-color);
  transition: color 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.igx-navbar__right > igx-icon:hover, .igx-navbar__left > igx-icon:hover,
.igx-navbar__right [igxButton=icon]:hover,
.igx-navbar__left [igxButton=icon]:hover {
  color: var(--hover-icon-color);
}

.igx-navbar__left {
  flex-grow: 1;
}

igx-nav-drawer {
  --elevation: var(--igx-nav-drawer-elevation, var(--ig-elevation-16));
  --background: var(--igx-nav-drawer-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --border-color: var(--igx-nav-drawer-border-color, hsla(var(--ig-gray-900), 0.14));
  --item-header-text-color: var(--igx-nav-drawer-item-header-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --item-text-color: var(--igx-nav-drawer-item-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --item-icon-color: var(--igx-nav-drawer-item-icon-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --item-active-text-color: var(--igx-nav-drawer-item-active-text-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --item-active-background: var(--igx-nav-drawer-item-active-background, hsla(var(--ig-primary-500), 0.12));
  --item-hover-background: var(--igx-nav-drawer-item-hover-background, hsla(var(--ig-gray-200), 0.8));
  --item-hover-text-color: var(--igx-nav-drawer-item-hover-text-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --item-active-icon-color: var(--igx-nav-drawer-item-active-icon-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --item-hover-icon-color: var(--igx-nav-drawer-item-hover-icon-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --border-radius: var(--igx-nav-drawer-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0) * 2.25rem), 2.25rem));
  --item-border-radius: var(--igx-nav-drawer-item-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.1666666667) * 1.5rem), 1.5rem));
  --shadow: var(--igx-nav-drawer-shadow, var(--ig-elevation-16));
}

.igx-nav-drawer {
  transition: flex-basis;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  flex-shrink: 0;
}

.igx-nav-drawer__aside {
  position: fixed;
  height: 100%;
  max-width: 90%;
  min-height: 100%;
  overflow-x: hidden;
  background: var(--background);
  top: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 999;
  transition: width, transform;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94), cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: var(--shadow);
  border-inline-end: 0.0625rem solid var(--border-color);
  border-radius: var(--border-radius);
}

.igx-nav-drawer__aside.panning {
  overflow-x: hidden;
  transition: none;
}

.igx-nav-drawer__aside--pinned {
  position: relative;
  max-width: 100%;
  box-shadow: none;
  z-index: 0;
}

.igx-nav-drawer__aside--collapsed.igx-nav-drawer__aside--right {
  transform: translate3d(300px, 0, 0);
  box-shadow: none;
}
[dir=rtl] .igx-nav-drawer__aside--collapsed.igx-nav-drawer__aside--right {
  transform: translate3d(-300px, 0, 0);
}

.igx-nav-drawer__aside--pinned.igx-nav-drawer__aside--collapsed {
  transform: none;
  width: 0;
  overflow: hidden;
  border: none;
}

.igx-nav-drawer__aside--collapsed {
  transform: translate3d(-300px, 0, 0);
  box-shadow: none;
}
[dir=rtl] .igx-nav-drawer__aside--collapsed {
  transform: translate3d(300px, 0, 0);
}

.igx-nav-drawer__aside--right {
  inset-inline-start: auto;
  inset-inline-end: 0;
  border-inline-end: none;
  border-inline-start: 0.0625rem solid var(--border-color);
}

.igx-nav-drawer__aside--mini {
  width: 3.75rem;
  transition-duration: 0.2s, 0.2s;
}
.igx-nav-drawer__aside--mini .igx-nav-drawer__item, .igx-nav-drawer__aside--mini .igx-nav-drawer__item--active {
  justify-content: center;
}
.igx-nav-drawer__aside--mini .igx-nav-drawer__item igx-icon, .igx-nav-drawer__aside--mini .igx-nav-drawer__item--active igx-icon {
  margin-inline-start: 0;
}

.igx-nav-drawer__aside--normal {
  width: 15.125rem;
  transition-duration: 0.3s, 0.3s;
}

.igx-nav-drawer__overlay {
  opacity: 1;
  background: hsla(var(--ig-gray-500), 0.54);
  transition: opacity, visibility;
  transition-duration: 0.25s, 0.25s;
  transition-timing-function: ease-in, step-start;
  transition-delay: 0s, 0s;
  position: absolute;
  inset-inline-start: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
  z-index: 999;
}

.igx-nav-drawer__overlay.panning {
  transform: translate3d(0, 0, 0);
  transition: none;
}

.igx-nav-drawer__overlay--hidden {
  transition-timing-function: ease-in-out, step-end;
  visibility: hidden;
  opacity: 0;
}

.igx-nav-drawer__overlay.panning.igx-nav-drawer__overlay--hidden {
  /* must be visible during pan.. */
  visibility: visible;
}

.igx-nav-drawer__item, .igx-nav-drawer__item--active {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  color: var(--item-text-color);
  max-height: 3rem;
  min-width: 2rem;
  margin: 0.5rem !important;
  padding: 0.75rem 0.5rem;
  gap: 2rem;
  cursor: pointer;
  user-select: none;
  outline: transparent;
  white-space: nowrap;
  border-radius: var(--item-border-radius);
  text-decoration: none;
  width: calc(100% - 1rem);
  border: none;
  justify-content: flex-start;
}
.igx-nav-drawer__item igx-icon, .igx-nav-drawer__item--active igx-icon {
  color: var(--item-icon-color);
}
[igxButton].igx-nav-drawer__item, [igxButton].igx-nav-drawer__item--active {
  background: transparent;
  border: none;
}
.igx-nav-drawer__item.igx-button--fab, .igx-nav-drawer__item--active.igx-button--fab {
  min-height: auto;
}
.igx-nav-drawer__item.igx-button--icon, .igx-nav-drawer__item--active.igx-button--icon {
  height: auto;
  transition: none;
}
.igx-nav-drawer__item:hover, .igx-nav-drawer__item--active:hover, .igx-nav-drawer__item:focus, .igx-nav-drawer__item--active:focus {
  background: var(--item-hover-background);
  color: var(--item-hover-text-color);
  box-shadow: none;
}
.igx-nav-drawer__item:hover igx-icon, .igx-nav-drawer__item--active:hover igx-icon, .igx-nav-drawer__item:focus igx-icon, .igx-nav-drawer__item--active:focus igx-icon {
  color: var(--item-hover-icon-color);
}

.igx-nav-drawer__item--active {
  color: var(--item-active-text-color);
  background: var(--item-active-background);
}
.igx-nav-drawer__item--active igx-icon {
  color: var(--item-active-icon-color);
}
.igx-nav-drawer__item--active:focus, .igx-nav-drawer__item--active:hover {
  color: var(--item-active-text-color);
  background: var(--item-active-background);
}
.igx-nav-drawer__item--active:focus igx-icon, .igx-nav-drawer__item--active:hover igx-icon {
  color: var(--item-active-icon-color);
}

[igxButton].igx-nav-drawer__item, [igxButton].igx-nav-drawer__item--active {
  box-shadow: none;
}
[igxButton].igx-nav-drawer__item igx-icon, [igxButton].igx-nav-drawer__item--active igx-icon {
  width: var(--igx-icon-size, 1.5rem);
  height: var(--igx-icon-size, 1.5rem);
  font-size: var(--igx-icon-size, 1.5rem);
  margin: 0;
}
[igxButton].igx-nav-drawer__item:hover, [igxButton].igx-nav-drawer__item--active:hover, [igxButton].igx-nav-drawer__item:focus, [igxButton].igx-nav-drawer__item--active:focus {
  box-shadow: none;
  border: none;
}

.igx-nav-drawer__item--header {
  display: block;
  padding: 0.75rem 1rem;
  white-space: nowrap;
  color: var(--item-header-text-color);
}

.igx-nav-drawer__style-dummy {
  height: 0;
  background: none;
  box-shadow: none;
  transition: none;
  visibility: hidden;
}

.igx-nav-drawer--disable-animation {
  transition-duration: 0s;
}

.igx-overlay__wrapper--modal, .igx-overlay__content--modal {
  --background-color: var(--igx-overlay-background-color, hsla(var(--ig-gray-500), 0.54));
}

.igx-overlay {
  width: 0;
  height: 0;
  box-sizing: content-box;
}

.igx-overlay__wrapper, .igx-overlay__wrapper--modal, .igx-overlay__wrapper--flex, .igx-overlay__wrapper--flex-container {
  position: fixed;
  inset: 0;
  background: transparent;
  transition: background 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  pointer-events: none;
  z-index: 10005;
  box-sizing: content-box;
}

.igx-overlay__wrapper--modal {
  background: var(--background-color);
  pointer-events: initial;
}

.igx-overlay__wrapper--flex {
  display: flex;
}

.igx-overlay__wrapper--flex-container {
  display: flex;
  position: absolute;
}

.igx-overlay__content, .igx-overlay__content--modal, .igx-overlay__content--elastic, .igx-overlay__content--relative {
  position: absolute;
  pointer-events: all;
  box-sizing: content-box;
}

.igx-overlay__content--modal {
  pointer-events: initial;
}

.igx-overlay__content--elastic {
  overflow: auto;
}

.igx-overlay__content--relative {
  position: relative;
}

.igx-toggle--hidden {
  display: none !important;
}

igx-paginator {
  --text-color: var(--igx-paginator-text-color, hsla(var(--ig-gray-800), var(--ig-gray-a)));
  --background-color: var(--igx-paginator-background-color, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --border-color: var(--igx-paginator-border-color, hsla(var(--ig-gray-200), var(--ig-gray-a)));
}

.igx-paginator, .igx-paginator--cosy, .igx-paginator--compact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  background: var(--background-color);
  font-size: 0.75rem;
  border-top: 0.0625rem solid var(--border-color);
  z-index: 1;
  padding: 0 1.5rem;
  height: 3.5rem;
  width: 100%;
}
.igx-paginator:empty, .igx-paginator--cosy:empty, .igx-paginator--compact:empty {
  padding: 0;
}

.igx-paginator--cosy {
  padding: 0 1rem;
  height: 3rem;
}

.igx-paginator--compact {
  padding: 0 0.75rem;
  height: 2.75rem;
}

.igx-paginator-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.igx-page-size {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.igx-page-size__label {
  margin-inline-end: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.igx-page-size__select {
  display: flex;
  max-width: 7.125rem;
  min-width: 6.25rem;
}

.igx-page-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.igx-page-nav > * {
  margin-inline-start: 0.5rem;
}
[dir=rtl] .igx-page-nav igx-icon {
  transform: scaleX(-1);
}

.igx-page-nav__text {
  display: flex;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
igx-circular-bar {
  --base-circle-color: var(--igx-circular-bar-base-circle-color, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --progress-circle-color: var(--igx-circular-bar-progress-circle-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --text-color: var(--igx-circular-bar-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --track-border-radius: var(--igx-circular-bar-track-border-radius, 0rem);
  --progress-circle-color-start: var(--igx-circular-bar-progress-circle-color-start, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --progress-circle-color-end: var(--igx-circular-bar-progress-circle-color-end, hsla(var(--ig-primary-500), var(--ig-primary-a)));
}

.igx-circular-bar {
  display: inline-flex;
  flex: 1 1 auto;
  width: 2rem;
  height: 2rem;
  position: relative;
}
.igx-circular-bar svg {
  width: 100%;
  height: 100%;
  min-width: inherit;
  min-height: inherit;
  transform: rotate(-90deg);
}

.igx-circular-bar--indeterminate {
  animation: rotate-center 3s linear normal infinite;
}
[dir=rtl] .igx-circular-bar--indeterminate {
  animation-direction: reverse;
}

.igx-circular-bar__inner {
  stroke-width: 0.25rem;
  fill: transparent;
  stroke: var(--base-circle-color);
}

.igx-circular-bar__outer {
  fill: transparent;
  stroke-width: 0.25rem;
  stroke-dashoffset: 289;
  stroke-dasharray: 289;
}

.igx-circular-bar--indeterminate .igx-circular-bar__outer {
  stroke-dasharray: 289;
  animation: indeterminate-accordion 1.5s cubic-bezier(0, 0.085, 0.68, 0.53) normal infinite;
}
[dir=rtl] .igx-circular-bar--indeterminate .igx-circular-bar__outer, .igx-circular-bar--indeterminate [dir=rtl] .igx-circular-bar__outer {
  animation-direction: reverse;
}

.igx-circular-bar__text {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  font-weight: 600;
  fill: var(--text-color);
}

.igx-circular-bar--indeterminate .igx-circular-bar__text {
  visibility: hidden;
}

.igx-circular-bar__gradient-start {
  stop-color: var(--progress-circle-color-start);
}

.igx-circular-bar__gradient-end {
  stop-color: var(--progress-circle-color-end);
}

@keyframes indeterminate-accordion {
  from {
    stroke-dashoffset: 578;
    stroke-dasharray: 259;
  }
  to {
    stroke-dashoffset: 120;
  }
}
igx-linear-bar {
  --track-color: var(--igx-linear-bar-track-color, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --fill-color-default: var(--igx-linear-bar-fill-color-default, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --fill-color-danger: var(--igx-linear-bar-fill-color-danger, hsla(var(--ig-error-500), var(--ig-error-a)));
  --fill-color-warning: var(--igx-linear-bar-fill-color-warning, hsla(var(--ig-warn-500), var(--ig-warn-a)));
  --fill-color-info: var(--igx-linear-bar-fill-color-info, hsla(var(--ig-info-500), var(--ig-info-a)));
  --fill-color-success: var(--igx-linear-bar-fill-color-success, hsla(var(--ig-success-500), var(--ig-success-a)));
  --stripes-color: var(--igx-linear-bar-stripes-color, hsla(var(--ig-gray-50), 0.7));
  --text-color: var(--igx-linear-bar-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --track-border-radius: var(--igx-linear-bar-track-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0) * 0.5rem), 0.5rem));
}

.igx-linear-bar {
  position: relative;
  display: flex;
  width: 100%;
  flex: 1 1 100%;
  flex-direction: column;
}
[dir=rtl].igx-linear-bar .igx-linear-bar__base, [dir=rtl] .igx-linear-bar .igx-linear-bar__base {
  transform: rotateY(180deg);
}

.igx-linear-bar__base {
  position: relative;
  width: inherit;
  height: 0.25rem;
  background: var(--track-color);
  overflow: hidden;
  border-radius: var(--track-border-radius);
  z-index: 0;
}

.igx-linear-bar__indicator {
  width: 100%;
  position: absolute;
  height: 100%;
}

.igx-linear-bar--striped .igx-linear-bar__indicator {
  background-image: linear-gradient(-45deg, var(--stripes-color) 25%, transparent 25%, transparent 50%, var(--stripes-color) 50%, var(--stripes-color) 75%, transparent 75%, transparent);
  background-size: 2.5rem 2.5rem;
}
[dir=rtl] .igx-linear-bar--striped .igx-linear-bar__indicator, .igx-linear-bar--striped [dir=rtl] .igx-linear-bar__indicator {
  background-image: linear-gradient(45deg, var(--stripes-color) 25%, transparent 25%, transparent 50%, var(--stripes-color) 50%, var(--stripes-color) 75%, transparent 75%, transparent);
}

.igx-linear-bar--indeterminate .igx-linear-bar__indicator-secondary,
.igx-linear-bar--indeterminate .igx-linear-bar__indicator {
  transform-origin: top left;
  width: 100% !important;
  height: inherit;
  position: absolute;
  background: transparent;
}
.igx-linear-bar--indeterminate .igx-linear-bar__indicator-secondary::after,
.igx-linear-bar--indeterminate .igx-linear-bar__indicator::after {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: inherit;
  height: inherit;
  background: var(--fill-color-default);
  backface-visibility: hidden;
}

.igx-linear-bar--indeterminate .igx-linear-bar__indicator {
  transform: scale3d(0, 1, 1);
  animation: indeterminate-primary 2000ms infinite linear;
  left: -145.166611%;
}
.igx-linear-bar--indeterminate .igx-linear-bar__indicator::after {
  animation: indeterminate-primary-scale 2000ms infinite linear;
}

.igx-linear-bar--indeterminate .igx-linear-bar__indicator-secondary {
  animation: indeterminate-secondary 2000ms infinite linear;
  left: -54.888891%;
}
.igx-linear-bar--indeterminate .igx-linear-bar__indicator-secondary::after {
  animation: indeterminate-secondary-scale 2000ms infinite linear;
  width: 100%;
  height: inherit;
}

.igx-linear-bar__indicator {
  background: var(--fill-color-default);
}

.igx-linear-bar--danger .igx-linear-bar__indicator {
  background-color: var(--fill-color-danger);
}

.igx-linear-bar--warning .igx-linear-bar__indicator {
  background-color: var(--fill-color-warning);
}

.igx-linear-bar--info .igx-linear-bar__indicator {
  background-color: var(--fill-color-info);
}

.igx-linear-bar--success .igx-linear-bar__indicator {
  background-color: var(--fill-color-success);
}

.igx-linear-bar__value, .igx-linear-bar__value--start, .igx-linear-bar__value--center, .igx-linear-bar__value--end, .igx-linear-bar__value--top, .igx-linear-bar__value--hidden, .igx-linear-bar--indeterminate .igx-linear-bar__value {
  margin: 0;
  color: var(--text-color);
  font-size: 0.875rem;
  font-weight: 600;
}

.igx-linear-bar__value, .igx-linear-bar__value--start {
  align-self: flex-start;
}

.igx-linear-bar__value--center {
  align-self: center;
}

.igx-linear-bar__value--end {
  align-self: flex-end;
}

.igx-linear-bar__value--top {
  order: -1;
}

.igx-linear-bar__value--hidden, .igx-linear-bar--indeterminate .igx-linear-bar__value {
  display: none;
}

@keyframes indeterminate-primary {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(83.67142%);
  }
  100% {
    transform: translateX(200.611057%);
  }
}
@keyframes indeterminate-primary-scale {
  0% {
    transform: scaleX(0.08);
  }
  36.65% {
    animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    transform: scaleX(0.08);
  }
  69.15% {
    animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    transform: scaleX(0.661479);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes indeterminate-secondary {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(37.651913%);
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(84.386165%);
  }
  100% {
    transform: translateX(160.277782%);
  }
}
@keyframes indeterminate-secondary-scale {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: scaleX(0.08);
  }
  19.15% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: scaleX(0.457104);
  }
  44.15% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: scaleX(0.72796);
  }
  100% {
    transform: scaleX(0.08);
  }
}
igx-radio {
  --hover-color: var(--igx-radio-hover-color, transparent);
  --fill-hover-border-color: var(--igx-radio-fill-hover-border-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --label-color: var(--igx-radio-label-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --empty-color: var(--igx-radio-empty-color, hsla(var(--ig-gray-600), var(--ig-gray-a)));
  --fill-color: var(--igx-radio-fill-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --fill-color-hover: var(--igx-radio-fill-color-hover, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --disabled-color: var(--igx-radio-disabled-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --disabled-label-color: var(--igx-radio-disabled-label-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --error-color: var(--igx-radio-error-color, hsla(var(--ig-error-500), var(--ig-error-a)));
  --error-color-hover: var(--igx-radio-error-color-hover, hsla(var(--ig-error-500), var(--ig-error-a)));
}

.igx-radio {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  color: var(--label-color);
}

.igx-radio__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: none;
  clip: rect(0, 0, 0, 0);
  outline: 0;
  pointer-events: none;
  overflow: hidden;
  appearance: none;
}

.igx-radio--disabled {
  pointer-events: none;
  color: var(--disabled-label-color);
  user-select: none;
}

.igx-radio--invalid .igx-radio__label {
  color: var(--error-color);
}

.igx-radio--disabled .igx-radio__label {
  color: var(--disabled-label-color);
}

.igx-radio__composite {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  line-height: 2.5rem;
  cursor: pointer;
  color: var(--label-color);
  user-select: none;
}
.igx-radio__composite::before, .igx-radio__composite::after {
  position: absolute;
  content: "";
  width: 2.5rem;
  height: 2.5rem;
  inset-inline-start: 0;
  top: 0;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 50%), 50%);
}
.igx-radio__composite::before, .igx-radio__composite::after {
  width: 1.25rem;
  height: 1.25rem;
  top: 25%;
  inset-inline-start: 25%;
}
.igx-radio__composite::before {
  backface-visibility: hidden;
  transform: scale(0);
  transition: all 0.2s ease-in;
  z-index: 1;
}
.igx-radio__composite::after {
  border: 0.125rem solid var(--empty-color);
}

.igx-radio--checked .igx-radio__composite::before {
  border: 0.125rem solid var(--fill-color);
  background: var(--fill-color);
  transform: scale(0.5);
}
.igx-radio--checked .igx-radio__composite::after {
  border: 0.125rem solid var(--fill-color);
}

.igx-radio:hover .igx-radio__composite::before, .igx-radio:active .igx-radio__composite::before {
  background: var(--hover-color);
}

.igx-radio--invalid:hover .igx-radio__composite::before, .igx-radio--invalid:active .igx-radio__composite::before {
  background: var(--error-color);
}

.igx-radio--checked.igx-radio--invalid:hover .igx-radio__composite::before, .igx-radio--checked.igx-radio--invalid:active .igx-radio__composite::before {
  background: var(--error-color-hover);
}
.igx-radio--checked.igx-radio--invalid:hover .igx-radio__composite::after, .igx-radio--checked.igx-radio--invalid:active .igx-radio__composite::after {
  border: 0.125rem solid var(--error-color-hover);
}

.igx-radio--checked:hover .igx-radio__composite::before, .igx-radio--checked:active .igx-radio__composite::before, .igx-radio--focused.igx-radio--checked:hover .igx-radio__composite::before, .igx-radio--focused.igx-radio--checked:active .igx-radio__composite::before {
  background: var(--fill-color-hover);
  border-color: var(--fill-hover-border-color);
}
.igx-radio--checked:hover .igx-radio__composite::after, .igx-radio--checked:active .igx-radio__composite::after, .igx-radio--focused.igx-radio--checked:hover .igx-radio__composite::after, .igx-radio--focused.igx-radio--checked:active .igx-radio__composite::after {
  border-color: var(--fill-hover-border-color);
}

.igx-radio--invalid .igx-radio__composite::after {
  border: 0.125rem solid var(--error-color);
}

.igx-radio--checked.igx-radio--invalid .igx-radio__composite::after {
  border: 0.125rem solid var(--error-color);
}
.igx-radio--checked.igx-radio--invalid .igx-radio__composite::before {
  background: var(--error-color);
  border: 0.125rem solid transparent;
}

.igx-radio--disabled .igx-radio__composite::after {
  border: 0.125rem solid var(--disabled-color);
}

.igx-radio--checked.igx-radio--disabled .igx-radio__composite::after {
  border: 0.125rem solid var(--disabled-color);
}
.igx-radio--checked.igx-radio--disabled .igx-radio__composite::before {
  background: var(--disabled-color);
  border: 0.125rem solid transparent;
}

.igx-radio__label, .igx-radio__label--before {
  color: currentColor;
  cursor: pointer;
  user-select: none;
  word-wrap: break-all;
}
.igx-radio__label:empty, .igx-radio__label--before:empty {
  display: none;
}

.igx-radio__label {
  margin-inline-start: 0.125rem;
}

.igx-radio__label--before {
  order: -1;
  margin-inline-end: 0.125rem;
}

.igx-radio__label--before:empty,
.igx-radio__label:empty {
  margin: 0;
}

.igx-radio__ripple {
  display: block;
  position: absolute;
  top: calc(50% - 1.25rem);
  inset-inline-start: calc(50% - 1.25rem);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 1.25rem), 1.25rem);
  overflow: hidden;
  pointer-events: none;
  filter: opacity(1);
}
.igx-radio__ripple,
.igx-radio__ripple [igxRipple] {
  --color: var(--igx-ripple-color, var(--fill-color));
}
.igx-radio__ripple .igx-ripple__inner {
  display: block;
  position: absolute;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 50%), 50%);
  background: var(--color);
  pointer-events: none;
  transform-origin: center;
  transform: translate3d(0, 0, 0) scale(0);
  will-change: opacity, transform;
  opacity: 0.5;
  margin: 0 !important;
  border: none !important;
}
.igx-radio__ripple .igx-ripple {
  overflow: hidden;
}
.igx-radio__ripple,
.igx-radio__ripple [igxRipple] {
  --color: var(--igx-ripple-color, var(--fill-color));
}

.igx-radio--checked.igx-radio--invalid.igx-radio--focused .igx-radio__composite::after {
  border: 0.125rem solid var(--error-color);
}
.igx-radio--checked.igx-radio--invalid.igx-radio--focused .igx-radio__composite::before {
  background: var(--error-color);
  border-color: var(--error-color);
}

.igx-radio:hover .igx-radio__ripple, .igx-radio:active .igx-radio__ripple, .igx-radio--checked:hover .igx-radio__ripple, .igx-radio--checked:active .igx-radio__ripple, .igx-radio--invalid:hover .igx-radio__ripple, .igx-radio--invalid:active .igx-radio__ripple {
  background: var(--empty-color);
  transition: background 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0.06;
}

.igx-radio--checked:hover .igx-radio__ripple, .igx-radio--checked:active .igx-radio__ripple {
  background: var(--fill-color);
}

.igx-radio--invalid:hover .igx-radio__ripple, .igx-radio--invalid:active .igx-radio__ripple {
  background: var(--error-color);
}

.igx-radio--focused .igx-radio__ripple, .igx-radio--focused:hover .igx-radio__ripple, .igx-radio--focused.igx-radio--invalid .igx-radio__ripple, .igx-radio--focused.igx-radio--checked .igx-radio__ripple {
  background: var(--empty-color);
  transition: background 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0.12;
}

.igx-radio--focused.igx-radio--checked .igx-radio__ripple {
  background: var(--fill-color);
}

.igx-radio--focused.igx-radio--invalid .igx-radio__ripple {
  background: var(--error-color);
}

.igx-radio:active .igx-radio__ripple, .igx-radio--checked:active .igx-radio__ripple, .igx-radio--invalid:active .igx-radio__ripple {
  opacity: 0.12;
}

.igx-radio-group {
  display: block;
}
.igx-radio-group .igx-radio:not(:last-of-type) {
  margin-inline-end: 1rem;
}

.igx-radio-group--vertical {
  display: flex;
  flex-flow: column;
}
.igx-radio-group--vertical .igx-radio:not(:last-of-type) {
  margin-inline-end: 0;
  margin-block-end: 0;
}

igx-query-builder {
  --background: var(--igx-query-builder-background, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --header-background: var(--igx-query-builder-header-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --header-foreground: var(--igx-query-builder-header-foreground, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --background-and: var(--igx-query-builder-background-and, hsla(var(--ig-primary-300), var(--ig-primary-a)));
  --background-and--focus: var(--igx-query-builder-background-and--focus, hsla(var(--ig-primary-700), var(--ig-primary-a)));
  --background-or: var(--igx-query-builder-background-or, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --background-or--focus: var(--igx-query-builder-background-or--focus, hsla(var(--ig-secondary-700), var(--ig-secondary-a)));
}

.igx-query-builder {
  width: auto;
  min-width: 41.25rem;
  background-color: var(--background);
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.25rem), 0.25rem);
  box-shadow: var(--ig-elevation-12);
}

.igx-query-builder__header {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: var(--header-background);
  color: var(--header-foreground);
  user-select: none;
}
.igx-query-builder__header h4,
.igx-query-builder__header .ig-typography__h6 {
  margin-bottom: 0;
}

.igx-query-builder__main {
  display: block;
  padding: 1rem;
  overflow: auto;
  min-height: 13.375rem;
  max-height: 29.25rem;
}
.igx-query-builder__main [igxButton] + [igxButton] {
  margin-inline-start: 0.5rem;
}

.igx-query-builder__outlet igx-select-item > igx-icon {
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
  margin-inline-end: 0.5rem;
}

.igx-filter-tree {
  display: flex;
}
.igx-filter-tree + .igx-filter-tree,
.igx-filter-tree + .igx-filter-tree__expression-item,
.igx-filter-tree + .igx-filter-tree__inputs {
  margin-top: 1rem;
}

.igx-filter-tree__line {
  width: 0.5rem;
  min-width: 0.5rem;
  background-color: white;
  margin-inline-end: 1rem;
  outline-style: none;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.25rem), 0.25rem);
  cursor: pointer;
}

.igx-filter-tree__line--and {
  background: var(--background-and);
}
.igx-filter-tree__line--selected.igx-filter-tree__line--and {
  background: var(--background-and--focus);
}
.igx-filter-tree__line--and:focus {
  box-shadow: inset 0 0 0 0.125rem var(--background-and--focus);
}

.igx-filter-tree__line--or {
  background: var(--background-or);
}
.igx-filter-tree__line--selected.igx-filter-tree__line--or {
  background-color: var(--background-or--focus);
}
.igx-filter-tree__line--or:focus {
  box-shadow: inset 0 0 0 0.125rem var(--background-or--focus);
}

.igx-filter-tree__expression {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.igx-filter-tree__expression-item {
  display: flex;
  align-items: center;
}
.igx-filter-tree__expression-item + .igx-filter-tree,
.igx-filter-tree__expression-item + .igx-filter-tree__inputs,
.igx-filter-tree__expression-item + .igx-filter-tree__expression-item {
  margin-top: 1rem;
}
.igx-filter-tree__expression-item > igx-chip igx-prefix {
  display: flex;
}

.igx-filter-tree__expression-column {
  padding: 0 0.5rem;
}

.igx-filter-tree__expression-actions {
  display: inline-flex;
  margin: 0 0.5rem;
}
.igx-filter-tree__expression-actions igx-icon {
  cursor: pointer;
  color: hsla(var(--ig-gray-500), var(--ig-gray-a));
  outline-style: none;
}
.igx-filter-tree__expression-actions igx-icon:hover, .igx-filter-tree__expression-actions igx-icon:focus {
  color: hsla(var(--ig-gray-800), var(--ig-gray-a));
}
.igx-filter-tree__expression-actions igx-icon + igx-icon {
  margin-inline-start: 0.5rem;
}

.igx-filter-tree__expression-condition {
  opacity: 0.7;
}

.igx-filter-tree__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.igx-filter-tree__buttons + .igx-filter-tree,
.igx-filter-tree__buttons + .igx-filter-tree__expression-item,
.igx-filter-tree__buttons + .igx-filter-tree__inputs {
  margin-top: 1rem;
}

.igx-filter-tree__inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.igx-filter-tree__inputs igx-select + igx-select,
.igx-filter-tree__inputs igx-select + igx-input-group,
.igx-filter-tree__inputs igx-select + igx-date-picker,
.igx-filter-tree__inputs igx-select + igx-time-picker,
.igx-filter-tree__inputs igx-input-group + igx-select {
  margin-inline-start: 0.5rem;
}
.igx-filter-tree__inputs + .igx-filter-tree,
.igx-filter-tree__inputs + .igx-filter-tree__expression-item {
  margin-top: 1rem;
}

.igx-filter-tree__inputs-actions {
  display: flex;
  margin: 0 0.5rem;
  align-items: center;
}
.igx-filter-tree__inputs-actions [igxButton] {
  transition: none;
}
.igx-filter-tree__inputs-actions [igxButton] + [igxButton] {
  margin-inline-start: 0.5rem;
}

.igx-builder-legend {
  display: flex;
  margin-inline-start: auto;
  pointer-events: none;
}

.igx-builder-legend__item, .igx-builder-legend__item--and, .igx-builder-legend__item--or {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
}
.igx-builder-legend__item::before, .igx-builder-legend__item--and::before, .igx-builder-legend__item--or::before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.25rem), 0.25rem);
  margin-inline-end: 0.5rem;
}
.igx-builder-legend__item + .igx-builder-legend__item, .igx-builder-legend__item--and + .igx-builder-legend__item, .igx-builder-legend__item--or + .igx-builder-legend__item, .igx-builder-legend__item + .igx-builder-legend__item--and, .igx-builder-legend__item--and + .igx-builder-legend__item--and, .igx-builder-legend__item--or + .igx-builder-legend__item--and, .igx-builder-legend__item + .igx-builder-legend__item--or, .igx-builder-legend__item--and + .igx-builder-legend__item--or, .igx-builder-legend__item--or + .igx-builder-legend__item--or {
  margin-inline-start: 1.5rem;
}

.igx-builder-legend__item--and::before {
  background: var(--background-and);
}

.igx-builder-legend__item--or::before {
  background: var(--background-or);
}

.igx-filter-contextual-menu {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 12.25rem;
  background-color: var(--background);
  padding: 1rem;
  margin-inline-start: 1rem;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.25rem), 0.25rem);
  border: 0.0625rem solid hsla(var(--ig-gray-200), var(--ig-gray-a));
}
.igx-filter-contextual-menu > [igxButton] + [igxButton],
.igx-filter-contextual-menu igx-buttongroup + [igxButton],
.igx-filter-contextual-menu [igxButton] + igx-buttongroup {
  margin-top: 1rem;
}
.igx-filter-contextual-menu > [igxButton].igx-filter-contextual-menu__close-btn + [igxButton],
.igx-filter-contextual-menu [igxButton].igx-filter-contextual-menu__close-btn + igx-buttongroup {
  margin-top: 0;
}
.igx-filter-contextual-menu [igxButton] > igx-icon + * {
  margin-inline-start: 0.5rem;
}
.igx-filter-contextual-menu igx-buttongroup > * {
  box-shadow: none;
}

.igx-filter-contextual-menu__delete-btn {
  color: hsla(var(--ig-error-500), var(--ig-error-a));
}
.igx-filter-contextual-menu__delete-btn:hover, .igx-filter-contextual-menu__delete-btn:focus {
  color: hsla(var(--ig-error-500), var(--ig-error-a));
}

.igx-filter-contextual-menu__close-btn {
  position: absolute;
  top: -1.125rem;
  inset-inline-end: -1.125rem;
  background-color: var(--background);
  border: 0.0625rem solid hsla(var(--ig-gray-200), var(--ig-gray-a));
}
.igx-filter-contextual-menu__close-btn:hover, .igx-filter-contextual-menu__close-btn:focus {
  background-color: var(--background);
}

.igx-filter-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9rem;
}

.igx-filter-empty__title {
  color: hsla(var(--ig-gray-500), var(--ig-gray-a));
}

.igx-query-builder--cosy .igx-query-builder__main {
  min-height: 10.25rem;
  max-height: 24.625rem;
}
.igx-query-builder--cosy .igx-filter-empty {
  height: 6.6875rem;
}
.igx-query-builder--cosy .igx-query-builder__main,
.igx-query-builder--cosy .igx-query-builder__header {
  padding: 0.75rem 1rem;
}
.igx-query-builder--cosy .igx-filter-tree__line {
  margin-inline-end: 0.75rem;
}
.igx-query-builder--cosy .igx-filter-tree + .igx-filter-tree,
.igx-query-builder--cosy .igx-filter-tree + .igx-filter-tree__expression-item,
.igx-query-builder--cosy .igx-filter-tree + .igx-filter-tree__inputs {
  margin-top: 0.75rem;
}
.igx-query-builder--cosy .igx-filter-tree__expression-item + .igx-filter-tree,
.igx-query-builder--cosy .igx-filter-tree__expression-item + .igx-filter-tree__inputs,
.igx-query-builder--cosy .igx-filter-tree__expression-item + .igx-filter-tree__expression-item {
  margin-top: 0.75rem;
}
.igx-query-builder--cosy .igx-filter-tree__buttons {
  margin-top: 0.75rem;
}
.igx-query-builder--cosy .igx-filter-tree__buttons + .igx-filter-tree,
.igx-query-builder--cosy .igx-filter-tree__buttons + .igx-filter-tree__expression-item,
.igx-query-builder--cosy .igx-filter-tree__buttons + .igx-filter-tree__inputs {
  margin-top: 0.75rem;
}
.igx-query-builder--cosy .igx-filter-tree__inputs + .igx-filter-tree,
.igx-query-builder--cosy .igx-filter-tree__inputs + .igx-filter-tree__expression-item {
  margin-top: 0.75rem;
}

.igx-query-builder--compact .igx-query-builder__main {
  min-height: 8.625rem;
  max-height: 19.0625rem;
}
.igx-query-builder--compact .igx-filter-empty {
  height: 5.75rem;
}
.igx-query-builder--compact .igx-query-builder__main,
.igx-query-builder--compact .igx-query-builder__header {
  padding: 0.5rem 1rem;
}
.igx-query-builder--compact .igx-filter-tree__expression-actions igx-icon {
  width: var(--igx-icon-size, 1.125rem);
  height: var(--igx-icon-size, 1.125rem);
  font-size: var(--igx-icon-size, 1.125rem);
}
.igx-query-builder--compact .igx-filter-tree__line {
  margin-inline-end: 0.5rem;
}
.igx-query-builder--compact .igx-filter-tree + .igx-filter-tree,
.igx-query-builder--compact .igx-filter-tree + .igx-filter-tree__expression-item,
.igx-query-builder--compact .igx-filter-tree + .igx-filter-tree__inputs {
  margin-top: 0.5rem;
}
.igx-query-builder--compact .igx-filter-tree__expression-item + .igx-filter-tree,
.igx-query-builder--compact .igx-filter-tree__expression-item + .igx-filter-tree__inputs,
.igx-query-builder--compact .igx-filter-tree__expression-item + .igx-filter-tree__expression-item {
  margin-top: 0.5rem;
}
.igx-query-builder--compact .igx-filter-tree__buttons {
  margin-top: 0.5rem;
}
.igx-query-builder--compact .igx-filter-tree__buttons + .igx-filter-tree,
.igx-query-builder--compact .igx-filter-tree__buttons + .igx-filter-tree__expression-item,
.igx-query-builder--compact .igx-filter-tree__buttons + .igx-filter-tree__inputs {
  margin-top: 0.5rem;
}
.igx-query-builder--compact .igx-filter-tree__inputs + .igx-filter-tree,
.igx-query-builder--compact .igx-filter-tree__inputs + .igx-filter-tree__expression-item {
  margin-top: 0.5rem;
}

.igx-query-builder--inline {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: inherit;
  max-height: inherit;
  min-width: 30rem;
  box-shadow: none;
}
.igx-query-builder--inline .igx-query-builder__main {
  min-height: initial;
  max-height: initial;
  flex-grow: 1;
}

.igx-filter-contextual-menu--cosy > [igxButton] + [igxButton],
.igx-filter-contextual-menu--cosy igx-buttongroup + [igxButton],
.igx-filter-contextual-menu--cosy [igxButton] + igx-buttongroup {
  margin-top: 0.75rem;
}

.igx-filter-contextual-menu--compact > [igxButton] + [igxButton],
.igx-filter-contextual-menu--compact igx-buttongroup + [igxButton],
.igx-filter-contextual-menu--compact [igxButton] + igx-buttongroup {
  margin-top: 0.5rem;
}

.ig-scrollbar {
  --scrollbar-size: var(--ig-scrollbar-scrollbar-size, 16px);
  --thumb-background: var(--ig-scrollbar-thumb-background, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --track-background: var(--ig-scrollbar-track-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
}

.ig-scrollbar {
  scrollbar-width: var(--scrollbar-size);
  scrollbar-color: var(--thumb-background) var(--track-background);
}
.ig-scrollbar ::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
  background: var(--track-background);
}
.ig-scrollbar ::-webkit-scrollbar-thumb {
  background: var(--thumb-background);
}

@media (hover: none) {
  .ig-scrollbar ::-webkit-scrollbar {
    width: auto;
    height: auto;
  }
}
igx-slider {
  --track-color: var(--igx-slider-track-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --track-step-color: var(--igx-slider-track-step-color, hsla(var(--ig-secondary-200), var(--ig-secondary-a)));
  --track-step-size: var(--igx-slider-track-step-size, 0.1875rem);
  --track-hover-color: var(--igx-slider-track-hover-color, hsla(var(--ig-secondary-400), var(--ig-secondary-a)));
  --thumb-color: var(--igx-slider-thumb-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --thumb-focus-color: var(--igx-slider-thumb-focus-color, transparent);
  --thumb-border-color: var(--igx-slider-thumb-border-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --thumb-border-focus-color: var(--igx-slider-thumb-border-focus-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --thumb-disabled-border-color: var(--igx-slider-thumb-disabled-border-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --disabled-thumb-color: var(--igx-slider-disabled-thumb-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --label-background-color: var(--igx-slider-label-background-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --label-text-color: var(--igx-slider-label-text-color, var(--ig-gray-700-contrast));
  --base-track-color: var(--igx-slider-base-track-color, hsla(var(--ig-secondary-500), 0.24));
  --base-track-hover-color: var(--igx-slider-base-track-hover-color, hsla(var(--ig-secondary-500), 0.24));
  --disabled-base-track-color: var(--igx-slider-disabled-base-track-color, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --disabled-fill-track-color: var(--igx-slider-disabled-fill-track-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --tick-color: var(--igx-slider-tick-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --tick-label-color: var(--igx-slider-tick-label-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
}

.igx-slider {
  display: flex;
  position: relative;
  z-index: 0;
  height: 3rem;
  flex-grow: 1;
  align-items: center;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.igx-slider:hover .igx-slider__track-fill {
  background: var(--track-hover-color);
}
.igx-slider:hover .igx-slider__track-inactive {
  background: var(--base-track-hover-color);
}

.igx-slider--disabled {
  pointer-events: none;
}
.igx-slider--disabled .igx-slider__track-inactive {
  background: var(--disabled-base-track-color);
}

.igx-slider__thumbs {
  position: absolute;
  width: 100%;
  height: 0;
  cursor: default;
  z-index: 1;
  inset-inline-start: 0;
}

.igx-slider__track {
  position: relative;
  width: 100%;
  height: 0.375rem;
  overflow: hidden;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 2rem), 2rem);
}

.igx-slider__track-inactive {
  position: absolute;
  width: 100%;
  height: inherit;
  background: var(--base-track-color);
  transition: background 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-radius: inherit;
  height: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
}

.igx-slider__track-fill {
  position: absolute;
  width: 100%;
  background: var(--track-color);
  transform-origin: left center;
  transform: scaleX(0);
  border-radius: inherit;
  height: inherit;
}
[dir=rtl] .igx-slider__track-fill {
  transform-origin: right center;
}

.igx-slider--disabled .igx-slider__track-fill {
  background: var(--disabled-fill-track-color);
}

.igx-slider__ticks {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: space-between;
}
.igx-slider__ticks.igx-slider__ticks--top {
  bottom: auto;
  top: 0;
  align-items: flex-end;
}

.igx-slider__ticks-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.igx-slider__ticks-group:first-of-type {
  margin-inline-start: -0.0625rem;
}
.igx-slider__ticks-group:last-of-type {
  margin-inline-start: -0.0625rem;
}

.igx-slider__ticks-label {
  color: var(--tick-label-color);
  position: absolute;
  top: 1rem;
  transform: translate(-50%);
  line-height: 0.7;
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
[dir=rtl] .igx-slider__ticks-label {
  left: 100%;
}

.igx-slider__ticks-tick {
  background: var(--tick-color);
  height: 0.5rem;
  width: 0.125rem;
}

.igx-slider__ticks--tall .igx-slider__ticks-label {
  top: calc(1rem + 0.5rem);
}

.igx-slider--disabled .igx-slider__ticks-tick {
  background: var(--disabled-base-track-color) !important;
}

.igx-slider--disabled .igx-slider__ticks-label {
  color: var(--disabled-base-track-color) !important;
}

.igx-slider__ticks-group--tall .igx-slider__ticks-tick {
  height: 1rem;
}
.igx-slider__ticks-group--tall .igx-slider__ticks-label {
  top: calc(1rem + 0.5rem);
}

.igx-slider__ticks--top .igx-slider__ticks-label {
  bottom: calc(0.5rem + 0.5rem);
  top: auto;
}
.igx-slider__ticks--top.igx-slider__ticks--tall .igx-slider__ticks-label {
  bottom: calc(1rem + 0.5rem);
  top: auto;
}

.igx-slider__tick-label--hidden {
  opacity: 0;
}

.igx-slider__track-steps {
  position: absolute;
  display: flex;
  width: 100%;
  height: 0.25rem;
  opacity: 0.85;
  transition: opacity 0.2s ease-out;
  top: 50%;
  transform: translateY(-50%);
  color: var(--track-step-color);
}
.igx-slider__track-steps svg {
  clip-path: inset(0 0.1875rem 0 0.1875rem);
}
.igx-slider__track-steps line {
  stroke: currentColor;
  stroke-width: var(--track-step-size);
  stroke-linecap: round;
}

.igx-slider__tick-labels--top-bottom .igx-slider__ticks-group {
  display: block;
}
.igx-slider__tick-labels--top-bottom .igx-slider__ticks-label {
  writing-mode: vertical-rl;
  transform: translate(-50%) rotate(0deg);
}
.igx-slider__tick-labels--top-bottom .igx-slider__ticks--tall .igx-slider__ticks-label {
  top: calc(1rem + 0.125rem);
}
.igx-slider__tick-labels--top-bottom.igx-slider__ticks--top .igx-slider__ticks-label {
  writing-mode: vertical-rl;
  transform: translate(-50%) rotate(0deg);
}
.igx-slider__tick-labels--top-bottom.igx-slider__ticks--top .igx-slider__ticks--tall .igx-slider__ticks-label {
  bottom: calc(1rem + 0.125rem);
}

.igx-slider__tick-labels--bottom-top .igx-slider__ticks-group {
  display: block;
}
.igx-slider__tick-labels--bottom-top .igx-slider__ticks-label {
  writing-mode: vertical-rl;
  transform: translate(-50%) rotate(180deg);
}
.igx-slider__tick-labels--bottom-top.igx-slider__ticks--top .igx-slider__ticks-label {
  writing-mode: vertical-rl;
  transform: translate(-50%) rotate(180deg);
}
.igx-slider__tick-labels--bottom-top.igx-slider__ticks--top .igx-slider__ticks--tall .igx-slider__ticks-label {
  bottom: calc(1rem + 0.125rem);
}

.igx-slider-thumb-from, .igx-slider-thumb-to {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1.25rem;
  height: 1.25rem;
  outline-style: none;
  top: -0.625rem;
  margin-inline-start: -0.625rem;
}
.igx-slider-thumb-from:hover div::after, .igx-slider-thumb-to:hover div::after {
  opacity: 0.12;
  transform: scale(1);
}
.igx-slider-thumb-from:focus div::after, .igx-slider-thumb-to:focus div::after {
  opacity: 0.18;
  transform: scale(1);
}
.igx-slider-thumb-from:focus div::before, .igx-slider-thumb-to:focus div::before {
  box-shadow: 0 0 0 0rem var(--thumb-focus-color);
}

.igx-slider-thumb-from--disabled:focus div::before, .igx-slider-thumb-to--disabled:focus div::before {
  box-shadow: none;
}
.igx-slider-thumb-from--disabled:focus div::after, .igx-slider-thumb-to--disabled:focus div::after {
  transform: scale(0);
}

.igx-slider-thumb-label-from, .igx-slider-thumb-label-to {
  position: absolute;
  pointer-events: none;
  display: flex;
  top: calc(((1.875rem) + 1.25rem) * -1);
  height: 1.875rem;
}

.igx-slider-thumb-label-from__container, .igx-slider-thumb-label-to__container {
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-inline-start: -50%;
  padding: 0 0.125rem;
  background: var(--label-background-color);
  color: var(--label-text-color);
  min-width: 2.5rem;
  opacity: 0;
}
.igx-slider-thumb-label-from__container::after, .igx-slider-thumb-label-to__container::after {
  content: "";
  position: absolute;
  top: 85%;
  border-inline-start: 0.625rem solid transparent;
  border-inline-end: 0.625rem solid transparent;
  border-top: 0.625rem solid var(--label-background-color);
}

.igx-slider-thumb-from__dot, .igx-slider-thumb-to__dot {
  position: relative;
  inset-inline-start: 0;
  pointer-events: none;
}
.igx-slider-thumb-from__dot::before, .igx-slider-thumb-to__dot::before {
  position: absolute;
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  inset-inline-start: 0rem;
  top: calc((20px - 10px) * -1);
  margin-inline-start: calc((20px - 10px) * -1);
  background: var(--thumb-color);
  border: 0rem solid var(--thumb-border-color);
  transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-radius 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.625rem), 0.625rem);
}
.igx-slider-thumb-from__dot::after, .igx-slider-thumb-to__dot::after {
  position: absolute;
  content: "";
  width: 2.5rem;
  height: 2.5rem;
  background: var(--thumb-color);
  top: calc(50% - 1.25rem);
  inset-inline-start: calc(50% - 1.25rem);
  opacity: 0;
  transform: scale(0);
  transform-origin: center center;
  transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 50%), 50%);
  overflow: hidden;
}

.igx-slider-thumb-from--disabled .igx-slider-thumb-from__dot, .igx-slider-thumb-to--disabled .igx-slider-thumb-to__dot {
  pointer-events: none;
}
.igx-slider-thumb-from--disabled .igx-slider-thumb-from__dot::before, .igx-slider-thumb-to--disabled .igx-slider-thumb-to__dot::before {
  background: var(--disabled-thumb-color);
  border-color: var(--thumb-disabled-border-color);
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.625rem), 0.625rem);
}

.igx-slider-thumb-from--pressed .igx-slider-thumb-from__dot::after, .igx-slider-thumb-to--pressed .igx-slider-thumb-to__dot::after {
  opacity: 0.24 !important;
  transform: scale(1) !important;
}

.igx-slider-thumb-label-from--active .igx-slider-thumb-label-from__container, .igx-slider-thumb-label-to--active .igx-slider-thumb-label-to__container {
  opacity: 1;
}

.igx-slider-thumb-label-from--pressed .igx-slider-thumb-label-from__container, .igx-slider-thumb-label-to--pressed .igx-slider-thumb-label-to__container {
  z-index: 1;
}

igx-snackbar {
  --elevation: var(--igx-snackbar-elevation, var(--ig-elevation-4));
  --background: var(--igx-snackbar-background, hsla(var(--ig-gray-900), 0.9));
  --text-color: var(--igx-snackbar-text-color, var(--ig-gray-900-contrast));
  --button-color: var(--igx-snackbar-button-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --border-radius: var(--igx-snackbar-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.1666666667) * 1.5rem), 1.5rem));
  --shadow: var(--igx-snackbar-shadow, var(--ig-elevation-4));
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.igx-snackbar {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
  padding: 0.4375rem 1.5rem;
  margin: 0.5rem;
  color: var(--text-color);
  background: var(--background);
  backface-visibility: hidden;
  box-shadow: var(--shadow);
  border-radius: var(--border-radius);
  backdrop-filter: blur(8px);
}

.igx-snackbar__message {
  animation: "fade-in" 0.35s ease-out;
}

.igx-snackbar__button {
  background: transparent;
  color: var(--button-color);
  border: 0;
  line-height: 1rem;
  margin-inline-start: 1.5rem;
  text-transform: uppercase;
  user-select: none;
  font-weight: 600;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  transition: color 0.2s ease;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  animation: "fade-in" 0.35s ease-out;
}
.igx-snackbar__button:hover {
  color: var(--button-color);
}

igx-switch {
  --resting-elevation: var(--igx-switch-resting-elevation, var(--ig-elevation-2));
  --hover-elevation: var(--igx-switch-hover-elevation, var(--ig-elevation-3));
  --disabled-elevation: var(--igx-switch-disabled-elevation, var(--ig-elevation-1));
  --border-color: var(--igx-switch-border-color, transparent);
  --border-hover-color: var(--igx-switch-border-hover-color, transparent);
  --border-disabled-color: var(--igx-switch-border-disabled-color, transparent);
  --border-on-color: var(--igx-switch-border-on-color, transparent);
  --border-on-hover-color: var(--igx-switch-border-on-hover-color, transparent);
  --thumb-on-color: var(--igx-switch-thumb-on-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --track-on-color: var(--igx-switch-track-on-color, hsla(var(--ig-secondary-200), var(--ig-secondary-a)));
  --thumb-off-color: var(--igx-switch-thumb-off-color, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --track-disabled-color: var(--igx-switch-track-disabled-color, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --track-on-disabled-color: var(--igx-switch-track-on-disabled-color, hsla(var(--ig-secondary-50), var(--ig-secondary-a)));
  --track-off-color: var(--igx-switch-track-off-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --thumb-disabled-color: var(--igx-switch-thumb-disabled-color, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --thumb-on-disabled-color: var(--igx-switch-thumb-on-disabled-color, hsla(var(--ig-secondary-100), var(--ig-secondary-a)));
  --label-color: var(--igx-switch-label-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --label-disabled-color: var(--igx-switch-label-disabled-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --label-invalid-color: var(--igx-switch-label-invalid-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --track-error-color: var(--igx-switch-track-error-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --thumb-on-error-color: var(--igx-switch-thumb-on-error-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --error-color: var(--igx-switch-error-color, hsla(var(--ig-gray-50), var(--ig-gray-a)));
  --error-color-hover: var(--igx-switch-error-color-hover, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --border-radius-track: var(--igx-switch-border-radius-track, clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.4375rem), 0.4375rem));
  --border-radius-thumb: var(--igx-switch-border-radius-thumb, clamp(0rem, calc(var(--ig-radius-factor, 1) * 0.625rem), 0.625rem));
  --border-radius-ripple: var(--igx-switch-border-radius-ripple, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1.5rem), 1.5rem));
  --resting-shadow: var(--igx-switch-resting-shadow, var(--ig-elevation-2));
  --hover-shadow: var(--igx-switch-hover-shadow, var(--ig-elevation-3));
  --disabled-shadow: var(--igx-switch-disabled-shadow, var(--ig-elevation-1));
}

.igx-switch {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
}

.igx-switch__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: none;
  clip: rect(0, 0, 0, 0);
  outline: 0;
  pointer-events: none;
  overflow: hidden;
  appearance: none;
}

.igx-switch--disabled {
  user-select: none;
  pointer-events: none;
  cursor: initial;
}

.igx-switch__composite {
  display: flex;
  align-items: center;
  width: 2.25rem;
  height: 0.875rem;
  border: 0.0625rem solid var(--border-color);
  border-radius: var(--border-radius-track);
  background: var(--track-off-color);
  cursor: pointer;
  user-select: none;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.igx-switch:hover .igx-switch__composite {
  border-color: var(--border-hover-color);
}

.igx-switch--invalid .igx-switch__composite {
  border-color: var(--error-color);
  border-color: var(--track-error-color);
  background: var(--track-error-color);
}

.igx-switch--checked .igx-switch__composite {
  background: var(--track-on-color);
  border-color: var(--border-on-color);
}

.igx-switch--checked:hover .igx-switch__composite {
  border-color: var(--border-on-hover-color);
}

.igx-switch--disabled .igx-switch__composite {
  background: var(--track-disabled-color);
  border-color: var(--border-disabled-color);
}

.igx-switch__composite-thumb {
  position: relative;
  display: block;
  width: 1.25rem;
  height: 20px;
  min-width: 1.25rem;
  border-radius: var(--border-radius-thumb);
  background: var(--thumb-off-color);
  box-shadow: var(--resting-shadow);
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform: translateX(-0.0625rem);
}
[dir=rtl] .igx-switch__composite-thumb {
  transform: translateX(0.0625rem);
}
.igx-switch__composite-thumb:hover {
  box-shadow: var(--hover-shadow);
}

.igx-switch--checked .igx-switch__composite-thumb {
  background: var(--thumb-on-color);
  transform: translateX(0.9375rem);
}
[dir=rtl] .igx-switch--checked .igx-switch__composite-thumb, .igx-switch--checked [dir=rtl] .igx-switch__composite-thumb {
  transform: translateX(-0.9375rem);
}

.igx-switch--invalid .igx-switch__composite-thumb {
  background: var(--error-color);
}

.igx-switch--invalid.igx-switch--checked .igx-switch__composite-thumb {
  background: var(--thumb-on-error-color);
}

.igx-switch--disabled .igx-switch__composite-thumb, .igx-switch--disabled.igx-switch--invalid .igx-switch__composite-thumb {
  background: var(--thumb-disabled-color);
  box-shadow: var(--disabled-shadow);
}

.igx-switch__ripple {
  display: block;
  position: absolute;
  top: calc(50% - 1.25rem);
  inset-inline-start: calc(50% - 1.25rem);
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  pointer-events: none;
  filter: opacity(1);
  border-radius: var(--border-radius-ripple);
}
.igx-switch__ripple,
.igx-switch__ripple [igxRipple] {
  --color: var(--igx-ripple-color, var(--thumb-on-color));
}
.igx-switch__ripple .igx-ripple__inner {
  display: block;
  position: absolute;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 50%), 50%);
  background: var(--color);
  pointer-events: none;
  transform-origin: center;
  transform: translate3d(0, 0, 0) scale(0);
  will-change: opacity, transform;
  opacity: 0.5;
  margin: 0 !important;
  border: none !important;
}
.igx-switch__ripple .igx-ripple {
  overflow: hidden;
}
.igx-switch__ripple,
.igx-switch__ripple [igxRipple] {
  --color: var(--igx-ripple-color, var(--thumb-on-color));
}

.igx-switch--disabled.igx-switch--checked .igx-switch__composite {
  background: var(--track-on-disabled-color);
}
.igx-switch--disabled.igx-switch--checked .igx-switch__composite-thumb {
  background: var(--thumb-on-disabled-color);
}

.igx-switch--focused .igx-switch__ripple, .igx-switch--focused.igx-switch--checked .igx-switch__ripple, .igx-switch--focused.igx-switch--invalid .igx-switch__ripple {
  background: var(--track-off-color);
  transition: background 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0.12;
}

.igx-switch--focused.igx-switch--checked .igx-switch__ripple {
  background: var(--thumb-on-color);
}

.igx-switch--focused.igx-switch--invalid .igx-switch__ripple {
  background: var(--error-color-hover);
}

.igx-switch__label, .igx-switch__label--before {
  display: inline-block;
  color: var(--label-color);
  cursor: pointer;
  user-select: none;
  word-wrap: break-all;
}
.igx-switch__label:empty, .igx-switch__label--before:empty {
  margin: 0;
}

.igx-switch__label--before:empty,
.igx-switch__label:empty {
  margin: 0;
}

.igx-switch__label {
  margin-inline-start: 0.5rem;
}

.igx-switch__label--before {
  order: -1;
  margin-inline-end: 0.5rem;
}

.igx-switch--invalid .igx-switch__label {
  color: var(--label-invalid-color);
}

.igx-switch--disabled .igx-switch__label, .igx-switch--disabled .igx-switch__label--before {
  color: var(--label-disabled-color);
}

.igx-switch:hover .igx-switch__ripple::after, .igx-switch:active .igx-switch__ripple::after, .igx-switch--checked:hover .igx-switch__ripple::after, .igx-switch--checked:active .igx-switch__ripple::after, .igx-switch--invalid:hover .igx-switch__ripple::after, .igx-switch--invalid:active .igx-switch__ripple::after, .igx-switch--invalid.igx-switch--checked:hover .igx-switch__ripple::after {
  position: absolute;
  content: "";
  opacity: 0.06;
  inset: 0;
}

.igx-switch:hover .igx-switch__ripple::after, .igx-switch:active .igx-switch__ripple::after {
  background: var(--track-off-color);
}

.igx-switch--checked:hover .igx-switch__ripple::after, .igx-switch--checked:active .igx-switch__ripple::after {
  background: var(--thumb-on-color);
}

.igx-switch--invalid:hover .igx-switch__ripple::after, .igx-switch--invalid:active .igx-switch__ripple::after {
  background: var(--error-color-hover);
}

.igx-switch--invalid.igx-switch--checked:hover .igx-switch__ripple::after {
  background: var(--thumb-on-color);
}

.igx-switch:active .igx-switch__ripple::after, .igx-switch--checked:active .igx-switch__ripple::after, .igx-switch--invalid:active .igx-switch__ripple::after {
  opacity: 0.12;
}

igx-tabs {
  --item-text-color: var(--igx-tabs-item-text-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --item-background: var(--igx-tabs-item-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --item-hover-background: var(--igx-tabs-item-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --item-hover-color: var(--igx-tabs-item-hover-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --item-active-color: var(--igx-tabs-item-active-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --item-icon-color: var(--igx-tabs-item-icon-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --item-active-icon-color: var(--igx-tabs-item-active-icon-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --item-hover-icon-color: var(--igx-tabs-item-hover-icon-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --item-disabled-icon-color: var(--igx-tabs-item-disabled-icon-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --item-active-background: var(--igx-tabs-item-active-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --item-disabled-color: var(--igx-tabs-item-disabled-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --indicator-color: var(--igx-tabs-indicator-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --button-color: var(--igx-tabs-button-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --button-background: var(--igx-tabs-button-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --button-hover-background: var(--igx-tabs-button-hover-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --button-hover-color: var(--igx-tabs-button-hover-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --button-disabled-color: var(--igx-tabs-button-disabled-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --tab-ripple-color: var(--igx-tabs-tab-ripple-color, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --button-ripple-color: var(--igx-tabs-button-ripple-color, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --border-color: var(--igx-tabs-border-color, transparent);
  --border-color--hover: var(--igx-tabs-border-color--hover, transparent);
  --border-radius: var(--igx-tabs-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0) * 0.375rem), 0.375rem));
}

.igx-tabs__header,
.igx-tabs__header-button,
.igx-tabs__header-item-inner,
.igx-tabs__header-content {
  display: flex;
}

.igx-tabs {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.igx-tabs__header {
  overflow: hidden;
  flex: 0 0 auto;
  background: var(--item-background);
  min-height: 2.625rem;
  z-index: 1;
}

.igx-tabs__header-content {
  flex: 1 1 auto;
  overflow: hidden;
  scroll-behavior: smooth;
}

.igx-tabs__header-wrapper {
  position: relative;
  flex-grow: 1;
}

.igx-tabs__header-scroll {
  display: flex;
  height: 100%;
}

.igx-tabs__header-button {
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: none;
  padding: 0;
  min-width: 3rem;
  width: 3rem;
  cursor: pointer;
  position: relative;
  background: var(--button-background);
  color: var(--button-color);
  outline: 0;
}
.igx-tabs__header-button:hover {
  background: var(--button-hover-background);
  color: var(--button-hover-color);
}
.igx-tabs__header-button:focus {
  outline: 0;
  background: var(--button-hover-background);
}
.igx-tabs__header-button::-moz-focus-inner {
  border: 0;
}
.igx-tabs__header-button:disabled {
  color: var(--button-disabled-color);
}
.igx-tabs__header-button--none {
  display: none;
}
.igx-tabs__header-button,
.igx-tabs__header-button [igxRipple] {
  --color: var(--igx-ripple-color, var(--button-ripple-color));
}
.igx-tabs__header-button .igx-ripple__inner {
  display: block;
  position: absolute;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 50%), 50%);
  background: var(--color);
  pointer-events: none;
  transform-origin: center;
  transform: translate3d(0, 0, 0) scale(0);
  will-change: opacity, transform;
  opacity: 0.5;
  margin: 0 !important;
  border: none !important;
}
.igx-tabs__header-button .igx-ripple {
  overflow: hidden;
}
.igx-tabs__header-button,
.igx-tabs__header-button [igxRipple] {
  --color: var(--igx-ripple-color, var(--button-ripple-color));
}
[dir=rtl] .igx-tabs__header-button {
  transform: scaleX(-1);
}

.igx-tabs__header-item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 5.625rem;
  max-width: 22.5rem;
  word-wrap: break-word;
  flex-basis: auto;
  flex-shrink: 0;
  padding: 0.6875rem 1rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  user-select: none;
  background: var(--item-background);
  color: var(--item-text-color);
  outline: 0;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  border: 0.0625rem solid var(--border-color);
}
.igx-tabs__header-item > * {
  margin-inline-start: 0.75rem;
}
.igx-tabs__header-item > *:first-child {
  margin-inline-start: 0;
}
.igx-tabs__header-item igx-icon {
  color: var(--item-icon-color);
}
.igx-tabs__header-item::-moz-focus-inner {
  border: 0;
}
.igx-tabs__header-item:focus {
  background: var(--item-active-background);
  color: var(--item-hover-color);
  border-bottom-color: transparent;
}
.igx-tabs__header-item:hover {
  background: var(--item-hover-background);
  color: var(--item-hover-color);
}
.igx-tabs__header-item:hover igx-icon, .igx-tabs__header-item:focus igx-icon {
  color: var(--item-hover-icon-color);
}
.igx-tabs__header-item:hover, .igx-tabs__header-item:focus {
  border: 0.0625rem solid var(--border-color--hover);
}
.igx-tabs__header-item,
.igx-tabs__header-item [igxRipple] {
  --color: var(--igx-ripple-color, var(--tab-ripple-color));
}
.igx-tabs__header-item .igx-ripple__inner {
  display: block;
  position: absolute;
  border-radius: clamp(0rem, calc(var(--ig-radius-factor, 1) * 50%), 50%);
  background: var(--color);
  pointer-events: none;
  transform-origin: center;
  transform: translate3d(0, 0, 0) scale(0);
  will-change: opacity, transform;
  opacity: 0.5;
  margin: 0 !important;
  border: none !important;
}
.igx-tabs__header-item .igx-ripple {
  overflow: hidden;
}
.igx-tabs__header-item,
.igx-tabs__header-item [igxRipple] {
  --color: var(--igx-ripple-color, var(--tab-ripple-color));
}

.igx-tabs__header-item--selected {
  outline: 0;
  color: var(--item-active-color);
}
.igx-tabs__header-item--selected:hover, .igx-tabs__header-item--selected:focus {
  background: var(--item-active-background);
  color: var(--item-active-color);
}
.igx-tabs__header-item--selected:hover igx-icon, .igx-tabs__header-item--selected:focus igx-icon {
  color: var(--item-active-icon-color);
}
.igx-tabs__header-item--selected igx-icon {
  color: var(--item-active-icon-color);
}

.igx-tabs__header-item--disabled {
  outline: 0;
  color: var(--item-disabled-color);
  cursor: default;
  pointer-events: none;
}
.igx-tabs__header-item--disabled igx-icon {
  color: var(--item-disabled-icon-color);
}

.igx-tabs__header-item-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.igx-tabs__header-item-inner > [igxtabheaderlabel] {
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.igx-tabs__header-item-inner > [igxtabheadericon] {
  margin-bottom: 0.5rem;
}
.igx-tabs__header-item-inner > [igxtabheadericon]:last-child {
  margin-bottom: 0;
}

.igx-tabs__header-active-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(0);
  height: 0.125rem;
  min-width: 5.625rem;
  background: var(--indicator-color);
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), width 0.2s cubic-bezier(0.35, 0, 0.25, 1);
}

.igx-tabs__panels {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.igx-tabs__panel {
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1 auto;
}
.igx-tabs__panel::-moz-focus-inner {
  border: 0;
}
.igx-tabs__panel:focus {
  outline-width: 0;
}
[tabindex="0"].igx-tabs__panel {
  position: relative;
}

.igx-tabs__header-scroll--start {
  justify-content: flex-start;
}

.igx-tabs__header-scroll--end {
  justify-content: flex-end;
  min-width: max-content;
}

.igx-tabs__header-scroll--center {
  justify-content: center;
  min-width: max-content;
}

.igx-tabs__header-scroll--justify .igx-tabs__header-item {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

igx-stepper {
  --step-background: var(--igx-stepper-step-background, transparent);
  --step-hover-background: var(--igx-stepper-step-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --step-focus-background: var(--igx-stepper-step-focus-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --indicator-background: var(--igx-stepper-indicator-background, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --indicator-outline: var(--igx-stepper-indicator-outline, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --indicator-color: var(--igx-stepper-indicator-color, var(--ig-gray-400-contrast));
  --title-color: var(--igx-stepper-title-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --title-hover-color: var(--igx-stepper-title-hover-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --title-focus-color: var(--igx-stepper-title-focus-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --subtitle-color: var(--igx-stepper-subtitle-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --subtitle-hover-color: var(--igx-stepper-subtitle-hover-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --subtitle-focus-color: var(--igx-stepper-subtitle-focus-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --complete-step-background: var(--igx-stepper-complete-step-background, transparent);
  --complete-step-hover-background: var(--igx-stepper-complete-step-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --complete-step-focus-background: var(--igx-stepper-complete-step-focus-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --complete-indicator-background: var(--igx-stepper-complete-indicator-background, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --complete-indicator-outline: var(--igx-stepper-complete-indicator-outline, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --complete-indicator-color: var(--igx-stepper-complete-indicator-color, var(--ig-gray-900-contrast));
  --complete-title-color: var(--igx-stepper-complete-title-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --complete-title-hover-color: var(--igx-stepper-complete-title-hover-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --complete-title-focus-color: var(--igx-stepper-complete-title-focus-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --complete-subtitle-color: var(--igx-stepper-complete-subtitle-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --complete-subtitle-hover-color: var(--igx-stepper-complete-subtitle-hover-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --complete-subtitle-focus-color: var(--igx-stepper-complete-subtitle-focus-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --current-step-background: var(--igx-stepper-current-step-background, transparent);
  --current-step-hover-background: var(--igx-stepper-current-step-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --current-step-focus-background: var(--igx-stepper-current-step-focus-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --current-indicator-background: var(--igx-stepper-current-indicator-background, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --current-indicator-outline: var(--igx-stepper-current-indicator-outline, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --current-indicator-color: var(--igx-stepper-current-indicator-color, var(--ig-gray-900-contrast));
  --current-title-color: var(--igx-stepper-current-title-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --current-title-hover-color: var(--igx-stepper-current-title-hover-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --current-title-focus-color: var(--igx-stepper-current-title-focus-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --current-subtitle-color: var(--igx-stepper-current-subtitle-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --current-subtitle-hover-color: var(--igx-stepper-current-subtitle-hover-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --current-subtitle-focus-color: var(--igx-stepper-current-subtitle-focus-color, hsla(var(--ig-gray-700), var(--ig-gray-a)));
  --invalid-step-background: var(--igx-stepper-invalid-step-background, transparent);
  --invalid-step-hover-background: var(--igx-stepper-invalid-step-hover-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --invalid-step-focus-background: var(--igx-stepper-invalid-step-focus-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --invalid-indicator-background: var(--igx-stepper-invalid-indicator-background, hsla(var(--ig-error-500), var(--ig-error-a)));
  --invalid-indicator-outline: var(--igx-stepper-invalid-indicator-outline, hsla(var(--ig-error-500), var(--ig-error-a)));
  --invalid-indicator-color: var(--igx-stepper-invalid-indicator-color, var(--ig-gray-900-contrast));
  --invalid-title-color: var(--igx-stepper-invalid-title-color, hsla(var(--ig-error-500), var(--ig-error-a)));
  --invalid-title-hover-color: var(--igx-stepper-invalid-title-hover-color, hsla(var(--ig-error-500), var(--ig-error-a)));
  --invalid-title-focus-color: var(--igx-stepper-invalid-title-focus-color, hsla(var(--ig-error-500), var(--ig-error-a)));
  --invalid-subtitle-color: var(--igx-stepper-invalid-subtitle-color, hsla(var(--ig-error-500), var(--ig-error-a)));
  --invalid-subtitle-hover-color: var(--igx-stepper-invalid-subtitle-hover-color, hsla(var(--ig-error-500), var(--ig-error-a)));
  --invalid-subtitle-focus-color: var(--igx-stepper-invalid-subtitle-focus-color, hsla(var(--ig-error-500), var(--ig-error-a)));
  --disabled-indicator-color: var(--igx-stepper-disabled-indicator-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --disabled-indicator-background: var(--igx-stepper-disabled-indicator-background, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --disabled-indicator-outline: var(--igx-stepper-disabled-indicator-outline, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --disabled-title-color: var(--igx-stepper-disabled-title-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --disabled-subtitle-color: var(--igx-stepper-disabled-subtitle-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --step-separator-color: var(--igx-stepper-step-separator-color, hsla(var(--ig-gray-300), var(--ig-gray-a)));
  --complete-step-separator-color: var(--igx-stepper-complete-step-separator-color, hsla(var(--ig-gray-900), var(--ig-gray-a)));
  --step-separator-style: var(--igx-stepper-step-separator-style, dashed);
  --complete-step-separator-style: var(--igx-stepper-complete-step-separator-style, solid);
  --border-radius-indicator: var(--igx-stepper-border-radius-indicator, clamp(0rem, calc(var(--ig-radius-factor, 1) * 6.25rem), 6.25rem));
  --border-radius-step-header: var(--igx-stepper-border-radius-step-header, clamp(0rem, calc(var(--ig-radius-factor, 0) * 6.25rem), 6.25rem));
}

.igx-stepper,
.igx-stepper__header,
.igx-stepper__body,
.igx-stepper__step {
  display: flex;
}

.igx-stepper {
  flex-direction: column;
  width: 100%;
}

.igx-stepper__header {
  white-space: nowrap;
  flex-direction: column;
  width: 100%;
  flex: none;
}

.igx-stepper__body {
  position: relative;
  flex-direction: column;
  flex: 1 1 auto;
}

.igx-stepper,
.igx-stepper__body,
.igx-stepper__step-header,
.igx-stepper__step-title-wrapper {
  overflow: hidden;
}

.igx-stepper__step-title {
  color: var(--title-color);
}

.igx-stepper__step-subtitle {
  color: var(--subtitle-color);
}

.igx-stepper__step {
  position: relative;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  min-width: 6.25rem;
}
.igx-stepper__step:focus {
  outline: none;
}
.igx-stepper__step:focus .igx-stepper__step-title {
  color: var(--title-focus-color);
}
.igx-stepper__step:focus .igx-stepper__step-subtitle {
  color: var(--subtitle-focus-color);
}
.igx-stepper__step:focus .igx-stepper__step-header {
  background: var(--step-focus-background);
  color: var(--title-focus-color);
}
.igx-stepper__step:focus .igx-stepper__step-header--current {
  background: var(--current-step-focus-background) !important;
}
.igx-stepper__step:focus .igx-stepper__step-header--current .igx-stepper__step-title {
  color: var(--current-title-focus-color);
}
.igx-stepper__step:focus .igx-stepper__step-header--current .igx-stepper__step-subtitle {
  color: var(--current-subtitle-focus-color);
}
.igx-stepper__step:focus .igx-stepper__step-header--invalid {
  background: var(--invalid-step-focus-background);
}
.igx-stepper__step:focus .igx-stepper__step-header--invalid .igx-stepper__step-title {
  color: var(--invalid-title-focus-color);
}
.igx-stepper__step:focus .igx-stepper__step-header--invalid .igx-stepper__step-subtitle {
  color: var(--invalid-subtitle-focus-color);
}
.igx-stepper__step:first-of-type .igx-stepper__step-header::before {
  visibility: hidden;
}
.igx-stepper__step:last-of-type .igx-stepper__step-content-wrapper::before {
  display: none;
}
.igx-stepper__step:last-of-type .igx-stepper__step-header::after {
  visibility: hidden;
}

.igx-stepper__step-header {
  display: flex;
  padding: 1.5rem;
  position: relative;
  line-height: normal;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  cursor: pointer;
  background: var(--step-background);
  border-radius: var(--border-radius-step-header);
}
.igx-stepper__step-header:hover {
  background: var(--step-hover-background);
  color: var(--title-hover-color);
}

.igx-stepper__step-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 0.75rem;
  height: 1.5rem;
  width: 1.5rem;
  white-space: nowrap;
  border-radius: var(--border-radius-indicator);
  color: var(--indicator-color);
  background: var(--indicator-background);
  box-shadow: 0 0 0 0.0625rem var(--indicator-outline);
}
.igx-stepper__step-indicator > igx-icon {
  width: var(--igx-icon-size, calc(1.5rem - 0.375rem));
  height: var(--igx-icon-size, calc(1.5rem - 0.375rem));
  font-size: var(--igx-icon-size, calc(1.5rem - 0.375rem));
  color: inherit;
}
.igx-stepper__step-indicator div > igx-icon,
.igx-stepper__step-indicator div > igx-avatar,
.igx-stepper__step-indicator div > igx-circular-bar {
  max-height: 1.5rem;
  max-width: 1.5rem;
}

.igx-stepper__step-header--current {
  background: var(--current-step-background) !important;
  color: var(--current-title-color);
}
.igx-stepper__step-header--current .igx-stepper__step-indicator {
  color: var(--current-indicator-color) !important;
  background: var(--current-indicator-background) !important;
  box-shadow: 0 0 0 0.0625rem var(--current-indicator-outline) !important;
}
.igx-stepper__step-header--current .igx-stepper__step-title {
  color: var(--current-title-color);
}
.igx-stepper__step-header--current .igx-stepper__step-subtitle {
  color: var(--current-subtitle-color);
}
.igx-stepper__step-header--current:hover {
  background: var(--current-step-hover-background) !important;
}
.igx-stepper__step-header--current:hover .igx-stepper__step-title {
  color: var(--current-title-hover-color);
}
.igx-stepper__step-header--current:hover .igx-stepper__step-subtitle {
  color: var(--current-subtitle-hover-color);
}

.igx-stepper__step--disabled {
  color: var(--disabled-title-color);
  pointer-events: none;
  cursor: default;
}
.igx-stepper__step--disabled .igx-stepper__step-indicator {
  color: var(--disabled-indicator-color);
  background: var(--disabled-indicator-background);
  box-shadow: 0 0 0 0.0625rem var(--disabled-indicator-outline);
}
.igx-stepper__step--disabled .igx-stepper__step-title {
  color: var(--disabled-title-color);
}
.igx-stepper__step--disabled .igx-stepper__step-subtitle {
  color: var(--disabled-subtitle-color);
}

.igx-stepper__step-header--invalid {
  background: var(--invalid-step-background);
  color: var(--invalid-title-color);
}
.igx-stepper__step-header--invalid .igx-stepper__step-indicator {
  color: var(--invalid-indicator-color);
  background: var(--invalid-indicator-background);
  box-shadow: 0 0 0 0.0625rem var(--invalid-indicator-outline);
}
.igx-stepper__step-header--invalid .igx-stepper__step-title {
  color: var(--invalid-title-color);
}
.igx-stepper__step-header--invalid .igx-stepper__step-subtitle {
  color: var(--invalid-subtitle-color);
}
.igx-stepper__step-header--invalid:hover {
  background: var(--invalid-step-hover-background);
}
.igx-stepper__step-header--invalid:hover .igx-stepper__step-title {
  color: var(--invalid-title-hover-color);
}
.igx-stepper__step-header--invalid:hover .igx-stepper__step-subtitle {
  color: var(--invalid-subtitle-hover-color);
}

.igx-stepper--horizontal .igx-stepper__body-content {
  display: block;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: -1;
}

.igx-stepper__step-content-wrapper,
.igx-stepper--horizontal .igx-stepper__body-content {
  padding: 1rem;
}

.igx-stepper--horizontal .igx-stepper__body-content--active {
  z-index: 1;
  position: relative;
}

.igx-stepper__step-content-wrapper {
  margin-inline-start: calc(1.5rem + (1.5rem / 2));
  position: relative;
  min-height: 2rem;
}
.igx-stepper__step-content-wrapper::before {
  content: "";
  position: absolute;
  inset-inline-start: calc(-0.0625rem / 2);
  top: calc(-1.5rem + 0.5rem);
  bottom: calc(-1.5rem + 0.5rem);
  width: 0.0625rem;
  border-inline-start: 0.0625rem var(--step-separator-style) var(--step-separator-color);
}

.igx-stepper__step-title-wrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 2rem;
}
.igx-stepper__step-title-wrapper:empty {
  display: none;
}
.igx-stepper__step-title-wrapper > * {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.igx-stepper__step--start .igx-stepper__step-header,
.igx-stepper__step--end .igx-stepper__step-header {
  flex-direction: row;
  align-items: center;
}

.igx-stepper__step--start .igx-stepper__step-title-wrapper,
.igx-stepper__step--top .igx-stepper__step-title-wrapper {
  order: -1;
}

.igx-stepper__step--completed .igx-stepper__step-header {
  background: var(--complete-step-background);
}
.igx-stepper__step--completed .igx-stepper__step-header:hover {
  background: var(--complete-step-hover-background);
}
.igx-stepper__step--completed .igx-stepper__step-header:hover .igx-stepper__step-title {
  color: var(--complete-title-hover-color);
}
.igx-stepper__step--completed .igx-stepper__step-header:hover .igx-stepper__step-subtitle {
  color: var(--complete-subtitle-hover-color);
}
.igx-stepper__step--completed .igx-stepper__step-header::after {
  border-top-color: var(--complete-step-separator-color) !important;
  border-top-style: var(--complete-step-separator-style) !important;
}
.igx-stepper__step--completed .igx-stepper__step-indicator {
  color: var(--complete-indicator-color);
  background: var(--complete-indicator-background);
  box-shadow: 0 0 0 0.0625rem var(--complete-indicator-outline);
}
.igx-stepper__step--completed .igx-stepper__step-title {
  color: var(--complete-title-color);
}
.igx-stepper__step--completed .igx-stepper__step-subtitle {
  color: var(--complete-subtitle-color);
}
.igx-stepper__step--completed:focus .igx-stepper__step-header {
  background: var(--complete-step-focus-background);
}
.igx-stepper__step--completed:focus .igx-stepper__step-header .igx-stepper__step-title {
  color: var(--complete-title-focus-color);
}
.igx-stepper__step--completed:focus .igx-stepper__step-header .igx-stepper__step-subtitle {
  color: var(--complete-subtitle-focus-color);
}
.igx-stepper__step--completed .igx-stepper__step-content-wrapper::before {
  border-inline-start-style: var(--complete-step-separator-style);
  border-inline-start-color: var(--complete-step-separator-color);
}

.igx-stepper__step--completed + .igx-stepper__step::before {
  border-top-color: var(--complete-step-separator-color) !important;
  border-top-style: var(--complete-step-separator-style) !important;
}
.igx-stepper__step--completed + .igx-stepper__step .igx-stepper__step-header::before {
  border-top-color: var(--complete-step-separator-color) !important;
  border-top-style: var(--complete-step-separator-style) !important;
}

.igx-stepper__step--simple .igx-stepper__step-indicator {
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: initial;
  height: initial;
}
.igx-stepper__step--simple .igx-stepper__step-indicator div > igx-icon,
.igx-stepper__step--simple .igx-stepper__step-indicator div > igx-avatar,
.igx-stepper__step--simple .igx-stepper__step-indicator div > igx-circular-bar {
  max-width: initial;
  max-height: initial;
}

.igx-stepper--horizontal .igx-stepper__header {
  flex-direction: row;
}
.igx-stepper--horizontal .igx-stepper__step {
  overflow: hidden;
  flex-direction: row;
  flex-grow: 1;
}
.igx-stepper--horizontal .igx-stepper__step::before {
  content: "";
  width: auto;
  min-width: 0.625rem;
  height: 0.0625rem;
  flex: 1;
  position: relative;
  z-index: -1;
  top: calc((1.5rem / 2) + 1.5rem - (0.0625rem / 2));
  border-top: 0.0625rem var(--step-separator-style) var(--step-separator-color);
}
.igx-stepper--horizontal .igx-stepper__step:first-of-type {
  flex-grow: 0;
  min-width: 0;
}
.igx-stepper--horizontal .igx-stepper__step:first-of-type::before {
  display: none;
}
.igx-stepper--horizontal .igx-stepper__step-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.igx-stepper--horizontal .igx-stepper__step-header::before, .igx-stepper--horizontal .igx-stepper__step-header::after {
  content: "";
  position: absolute;
  z-index: -1;
  height: 0.0625rem;
  width: calc(50% - (1.5rem - 0.25rem));
  top: calc((1.5rem / 2) + 1.5rem - (0.0625rem / 2));
  flex: 1;
  border-top: 0.0625rem var(--step-separator-style) var(--step-separator-color);
}
.igx-stepper--horizontal .igx-stepper__step-header::before {
  inset-inline-start: 0;
}
.igx-stepper--horizontal .igx-stepper__step-header::after {
  inset-inline-end: 0;
}
.igx-stepper--horizontal .igx-stepper__step--simple {
  text-align: center;
}
.igx-stepper--horizontal .igx-stepper__step--simple .igx-stepper__step-header {
  align-self: center;
  padding: 0.5rem;
  height: auto;
}
.igx-stepper--horizontal .igx-stepper__step--simple .igx-stepper__step-header::before, .igx-stepper--horizontal .igx-stepper__step--simple .igx-stepper__step-header::after {
  display: none;
}
.igx-stepper--horizontal .igx-stepper__step--simple.igx-stepper__step::before {
  top: calc(50% - (0.0625rem / 2));
}
.igx-stepper--horizontal .igx-stepper__step-title-wrapper {
  width: 100%;
}
.igx-stepper--horizontal .igx-stepper__step--top .igx-stepper__step-header {
  justify-content: flex-end;
}
.igx-stepper--horizontal .igx-stepper__step--top .igx-stepper__step-header::before, .igx-stepper--horizontal .igx-stepper__step--top .igx-stepper__step-header::after {
  top: calc(100% - ((1.5rem / 2) + 1.5rem + (0.0625rem / 2)));
}
.igx-stepper--horizontal .igx-stepper__step--top.igx-stepper__step::before {
  border-top: 0.0625rem var(--step-separator-style) var(--step-separator-color);
  top: calc(100% - ((1.5rem / 2) + 1.5rem + (0.0625rem / 2)));
}
.igx-stepper--horizontal .igx-stepper__step--bottom .igx-stepper__step-header {
  justify-content: flex-start;
}
.igx-stepper--horizontal .igx-stepper__step--top .igx-stepper__step-title-wrapper,
.igx-stepper--horizontal .igx-stepper__step--bottom .igx-stepper__step-title-wrapper {
  text-align: center;
}
.igx-stepper--horizontal .igx-stepper__step--top .igx-stepper__step-header,
.igx-stepper--horizontal .igx-stepper__step--bottom .igx-stepper__step-header {
  flex-direction: column;
}
.igx-stepper--horizontal .igx-stepper__step--start .igx-stepper__step-title-wrapper {
  text-align: end;
}
.igx-stepper--horizontal .igx-stepper__step--start .igx-stepper__step-indicator,
.igx-stepper--horizontal .igx-stepper__step--end .igx-stepper__step-indicator {
  flex: 1 0 auto;
}
.igx-stepper--horizontal .igx-stepper__step--start .igx-stepper__step-header,
.igx-stepper--horizontal .igx-stepper__step--end .igx-stepper__step-header {
  padding: calc(1.5rem / 2);
}
.igx-stepper--horizontal .igx-stepper__step--start .igx-stepper__step-header::before, .igx-stepper--horizontal .igx-stepper__step--start .igx-stepper__step-header::after,
.igx-stepper--horizontal .igx-stepper__step--end .igx-stepper__step-header::before,
.igx-stepper--horizontal .igx-stepper__step--end .igx-stepper__step-header::after {
  display: none;
}
.igx-stepper--horizontal .igx-stepper__step--start.igx-stepper__step::before,
.igx-stepper--horizontal .igx-stepper__step--end.igx-stepper__step::before {
  top: calc(50% - (0.0625rem / 2));
}
.igx-stepper--horizontal .igx-stepper__step-content {
  flex-grow: 1;
}
.igx-stepper--horizontal .igx-stepper__step-content:focus {
  outline: none;
}
.igx-stepper--horizontal .igx-stepper__step-content::before {
  display: none;
}
.igx-stepper--horizontal .igx-stepper__step-content-wrapper {
  text-align: center;
}
.igx-stepper--horizontal .igx-stepper__body-content {
  display: flex;
}

igx-toast {
  --elevation: var(--igx-toast-elevation, var(--ig-elevation-0));
  --background: var(--igx-toast-background, hsla(var(--ig-gray-700), 0.9));
  --text-color: var(--igx-toast-text-color, var(--ig-gray-700-contrast));
  --border-radius: var(--igx-toast-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 1) * 1.625rem), 1.625rem));
  --shadow: var(--igx-toast-shadow, var(--ig-elevation-0));
}

.igx-toast {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 2.625rem auto;
  padding: 1rem 1.5rem;
  min-width: 3.25rem;
  color: var(--text-color);
  background: var(--background);
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--ig-elevation-0);
  backdrop-filter: blur(10px);
}

.igx-tooltip--desktop, .igx-tooptip--mobile {
  --background: var(--igx-tooltip-background, hsla(var(--ig-gray-700), 0.9));
  --text-color: var(--igx-tooltip-text-color, var(--ig-gray-700-contrast));
  --border-radius: var(--igx-tooltip-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.25) * 1rem), 1rem));
}

.igx-tooltip, .igx-tooltip--desktop, .igx-tooltip--mobile {
  display: inline-flex;
  justify-content: center;
  flex-flow: column wrap;
  background: var(--background);
  color: var(--text-color);
  border-radius: var(--border-radius);
  margin: 0 auto;
}

.igx-tooltip--desktop {
  padding: 0 0.5rem;
  min-height: 1.5rem;
}

.igx-tooltip--mobile {
  padding: 0 1rem;
  min-height: 2rem;
}

.igx-time-picker {
  --modal-elevation: var(--igx-time-picker-modal-elevation, var(--ig-elevation-24));
  --dropdown-elevation: var(--igx-time-picker-dropdown-elevation, var(--ig-elevation-8));
  --text-color: var(--igx-time-picker-text-color, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --hover-text-color: var(--igx-time-picker-hover-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --selected-text-color: var(--igx-time-picker-selected-text-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --active-item-background: var(--igx-time-picker-active-item-background, hsla(var(--ig-gray-100), var(--ig-gray-a)));
  --disabled-text-color: var(--igx-time-picker-disabled-text-color, hsla(var(--ig-gray-400), var(--ig-gray-a)));
  --disabled-item-background: var(--igx-time-picker-disabled-item-background, transparent);
  --header-background: var(--igx-time-picker-header-background, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --header-hour-text-color: var(--igx-time-picker-header-hour-text-color, var(--ig-secondary-500-contrast));
  --header-time-period-color: var(--igx-time-picker-header-time-period-color, var(--ig-secondary-500-contrast));
  --background-color: var(--igx-time-picker-background-color, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --border-radius: var(--igx-time-picker-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.2) * 1.25rem), 1.25rem));
  --active-item-border-radius: var(--igx-time-picker-active-item-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0.75) * 1.25rem), 1.25rem));
  --modal-shadow: var(--igx-time-picker-modal-shadow, var(--ig-elevation-24));
  --dropdown-shadow: var(--igx-time-picker-dropdown-shadow, var(--ig-elevation-8));
}

.igx-time-picker {
  display: flex;
  flex-flow: column nowrap;
  max-width: 21.25rem;
  min-width: 20rem;
  border-radius: var(--border-radius);
  box-shadow: var(--modal-shadow);
  overflow: hidden;
}

.igx-time-picker--vertical {
  flex-flow: row nowrap;
  min-width: 33.75rem;
}

.igx-time-picker__main {
  background: var(--background-color);
  flex: 1 1 auto;
}

.igx-time-picker--dropdown {
  min-width: 12.5rem;
  box-shadow: var(--dropdown-shadow);
}

.igx-time-picker__body {
  display: flex;
  padding: 0.625rem 0;
  justify-content: center;
}

.igx-time-picker__hourList {
  text-align: end;
}
[dir=rtl] .igx-time-picker__hourList {
  order: 2;
}

.igx-time-picker__minuteList {
  text-align: center;
}
[dir=rtl] .igx-time-picker__minuteList {
  order: 1;
}

.igx-time-picker__secondsList {
  text-align: center;
}

.igx-time-picker__ampmList {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
[dir=rtl] .igx-time-picker__ampmList {
  order: 3;
}

.igx-time-picker__column {
  max-width: 4rem;
  height: 20.3125rem;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  overflow: hidden;
}
.igx-time-picker__column:focus, .igx-time-picker__column:active {
  outline: none;
}

.igx-time-picker__item {
  width: 3.375rem;
  padding: 0.3125rem 0.625rem;
  border-radius: var(--active-item-border-radius);
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.igx-time-picker__item:focus, .igx-time-picker__item:active {
  outline: none;
}
.igx-time-picker__item:hover {
  color: var(--hover-text-color);
}

.igx-time-picker__item--selected {
  font-size: 1.5rem;
  color: var(--selected-text-color);
}

.igx-time-picker__item--active {
  background: var(--active-item-background);
}

.igx-time-picker__item--disabled {
  color: var(--disabled-text-color);
  background: var(--disabled-item-background);
  pointer-events: none;
}

.igx-time-picker__header {
  background: var(--header-background);
  padding: 1.5rem 1rem;
}

.igx-time-picker__header-ampm {
  color: var(--header-time-period-color);
}

.igx-time-picker--vertical .igx-time-picker__header {
  width: 10.5rem;
}

.igx-time-picker__header-hour {
  display: flex;
  color: var(--header-hour-text-color);
}
[dir=rtl] .igx-time-picker__header-hour {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.igx-time-picker__header-ampm,
.igx-time-picker__header-hour {
  margin: 0;
}

.igx-time-picker__buttons {
  display: flex;
  justify-content: flex-end;
  height: 3.25rem;
  padding: 0.5rem;
}
.igx-time-picker__buttons [igxButton] + [igxButton] {
  margin-inline-start: 0.5rem;
}

igx-tree {
  --background: var(--igx-tree-background, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --foreground: var(--igx-tree-foreground, var(--ig-surface-500-contrast));
  --background-selected: var(--igx-tree-background-selected, hsla(var(--ig-secondary-200), var(--ig-secondary-a)));
  --foreground-selected: var(--igx-tree-foreground-selected, var(--ig-secondary-200-contrast));
  --background-active: var(--igx-tree-background-active, hsla(var(--ig-gray-200), var(--ig-gray-a)));
  --foreground-active: var(--igx-tree-foreground-active, var(--ig-gray-200-contrast));
  --background-active-selected: var(--igx-tree-background-active-selected, hsla(var(--ig-secondary-300), var(--ig-secondary-a)));
  --foreground-active-selected: var(--igx-tree-foreground-active-selected, var(--ig-secondary-300-contrast));
  --border-color: var(--igx-tree-border-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --background-disabled: var(--igx-tree-background-disabled, hsla(var(--ig-surface-500), var(--ig-surface-a)));
  --foreground-disabled: var(--igx-tree-foreground-disabled, hsla(var(--ig-gray-500), var(--ig-gray-a)));
  --drop-area-color: var(--igx-tree-drop-area-color, hsla(var(--ig-secondary-500), var(--ig-secondary-a)));
  --hover-color: var(--igx-tree-hover-color, hsla(var(--ig-gray-900), 0.1));
}

.igx-tree {
  display: block;
  z-index: 0;
  overflow-y: auto;
}

.igx-tree-node,
.igx-tree-node__wrapper,
.igx-tree-node__toggle-button,
.igx-tree-node__content,
.igx-tree-node__select {
  display: flex;
}

.igx-tree-node {
  flex-direction: column;
}

.igx-tree-node__wrapper,
.igx-tree-node__toggle-button,
.igx-tree-node__select {
  align-items: center;
}

.igx-tree-node__toggle-button,
.igx-tree-node__select {
  margin-inline-end: 0.5rem;
}

.igx-tree-node__content,
.igx-tree-node__toggle-button,
.igx-tree-node__select {
  z-index: 1;
}

.igx-tree-node__toggle-button--hidden {
  visibility: hidden;
}

.igx-tree-node__wrapper {
  min-height: 3.125rem;
  padding: 0 1.5rem;
  position: relative;
  background: var(--background);
  color: var(--foreground);
}
.igx-tree-node__wrapper igx-icon {
  width: var(--igx-icon-size, 1.5rem);
  height: var(--igx-icon-size, 1.5rem);
  font-size: var(--igx-icon-size, 1.5rem);
}
[dir=rtl] .igx-tree-node__wrapper igx-icon {
  transform: scaleX(-1);
}
.igx-tree-node__wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 0;
}
.igx-tree-node__wrapper:hover::after {
  background: var(--hover-color);
}
.igx-tree-node__wrapper:focus {
  outline-width: 0;
}
.igx-tree-node__wrapper igx-circular-bar {
  width: 1.5rem;
  height: 1.5rem;
}
.igx-tree-node__wrapper--cosy {
  min-height: 2.5rem;
  padding: 0 1rem;
}
.igx-tree-node__wrapper--cosy .igx-tree-node__spacer {
  width: 1rem;
}
.igx-tree-node__wrapper--cosy .igx-tree-node__drop-indicator {
  inset-inline-end: 1rem;
  width: calc(100% - ((1rem * 2) + (1.5rem + 0.5rem)));
}
.igx-tree-node__wrapper--cosy igx-circular-bar {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
}
.igx-tree-node__wrapper--compact {
  min-height: 2rem;
  padding: 0 0.75rem;
}
.igx-tree-node__wrapper--compact .igx-tree-node__spacer {
  width: 0.75rem;
}
.igx-tree-node__wrapper--compact .igx-tree-node__drop-indicator {
  inset-inline-end: 0.75rem;
  width: calc(100% - ((0.75rem * 2) + (1.5rem + 0.5rem)));
}
.igx-tree-node__wrapper--compact igx-circular-bar {
  width: calc(1.5rem - 6px);
  height: calc(1.5rem - 6px);
}

.igx-tree-node__wrapper--selected {
  background: var(--background-selected);
  color: var(--foreground-selected);
}

.igx-tree-node__wrapper--active {
  background: var(--background-active);
  color: var(--foreground-active);
}

.igx-tree-node__wrapper--active.igx-tree-node__wrapper--selected {
  background: var(--background-active-selected);
  color: var(--foreground-active-selected);
}

.igx-tree-node__wrapper--focused {
  box-shadow: inset 0 0 0 0.0625rem var(--border-color);
}

.igx-tree-node__wrapper--disabled {
  background: var(--background-disabled) !important;
  color: var(--foreground-disabled) !important;
  box-shadow: none !important;
  pointer-events: none;
}
.igx-tree-node__wrapper--disabled::after {
  display: none;
}
.igx-tree-node__wrapper--disabled .igx-tree-node__toggle-button {
  color: var(--foreground-disabled) !important;
}

.igx-tree-node__spacer {
  display: inline-block;
  width: 1.5rem;
}

.igx-tree-node__content {
  display: block;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.igx-tree-node__toggle-button {
  justify-content: center;
  cursor: pointer;
  user-select: none;
  min-width: 1.5rem;
}

.igx-tree-node__drop-indicator {
  display: flex;
  visibility: hidden;
  position: absolute;
  inset-inline-end: 1.5rem;
  bottom: 0;
  width: calc(100% - ((1.5rem * 2) + (1.5rem + 0.5rem)));
}
.igx-tree-node__drop-indicator > div {
  flex: 1;
  height: 0.0625rem;
  background: var(--drop-area-color);
}

.igx-tree-node__group {
  overflow: hidden;
}

igx-watermark {
  --link-background: var(--igx-watermark-link-background, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --background-image: var(--igx-watermark-background-image, url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTg0IiBoZWlnaHQ9IjEwNCIgdmlld0JveD0iMCAwIDE4NCAxMDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuMDUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTYuNyAxMC4xTDEwLjMgOC4zOTk5OUwxMi4xIDEuNjk5OTlMNy4xIDAuMzk5OTk0TDUuMyA3LjA5OTk5TDIuMyA2LjI5OTk5TDEuMSAxMC42TDQuMSAxMS40TDEuMiAyMi4yQzAuMjk5OTk3IDI1LjUgMC4xOTk5OTcgMjcuOSAwLjc5OTk5NyAyOS40QzEuNSAzMC45IDMuMSAzMiA1LjcgMzIuN0M3LjMgMzMuMSA5IDMzLjMgMTAuNiAzMy40TDExLjUgMjkuM0w3LjcgMjguNEM2LjYgMjguMSA1LjkgMjcuNiA1LjggMjYuOUM1LjggMjUuNiA2IDI0LjMgNi40IDIzTDkuMSAxMi44TDE1LjUgMTQuNUwxNi43IDEwLjFaTTE5LjcgMzUuOEwyNCAxOS42TDI1LjEgMTkuNUMyNy41IDE5LjIgMzAgMTkuMSAzMi41IDE5LjNMMzMuOSAxNC4yQzMwLjkgMTQgMjcuOSAxNC40IDI1LjEgMTUuNEwyNS44IDEyLjZMMjAuOCAxMS4zTDE0LjYgMzQuNUwxOS43IDM1LjhaTTM2LjUgNDAuM0w0Mi43IDE3LjFMMzcuNiAxNS43TDMxLjQgMzguOUwzNi41IDQwLjNaTTQzLjggMTMuMkw0NS4yIDcuODk5OTlMNDAuMSA2LjQ5OTk5TDM4LjcgMTEuOEw0My44IDEzLjJaTTYzLjggMjQuMkM2Mi45IDIyLjYgNjEgMjEuNSA1OC4yIDIwLjdDNTUgMTkuOSA1MS44IDE5LjUgNDguNiAxOS40TDQ3LjcgMjNMNDkuMiAyMy4zQzUyLjIgMjMuOCA1NC44IDI0LjQgNTYuNyAyNC45QzU3LjYgMjUuMSA1OC40IDI1LjYgNTguOSAyNi4zQzU5LjMgMjcuMiA1OS40IDI4LjIgNTkgMjkuMUw1OC41IDMwLjhMNTIuNCAyOS43QzUwLjMgMjkuMiA0OC4xIDI5LjMgNDYuMSAzMC4xQzQ0LjYgMzAuOCA0My41IDMyLjMgNDIuOCAzNC43QzQxLjUgMzkuNiA0My4xIDQyLjcgNDcuNyA0My45QzUwLjMgNDQuNiA1MyA0NC43IDU1LjYgNDQuMkM1Ny4xIDQ1LjkgNTkuMSA0Ny4xIDYxLjMgNDcuNkw2Mi41IDQzLjhDNjIgNDMuNiA2MS41IDQzLjMgNjEuMiA0Mi44QzYxIDQyLjMgNjAuOSA0MS43IDYxLjEgNDEuMUw2My45IDMwLjVDNjQuOCAyNy44IDY0LjcgMjUuNyA2My44IDI0LjJaTTU3LjcgMzQuNEw1NiA0MC41TDU1LjEgNDAuNkM1My40IDQwLjYgNTEuNiA0MC41IDUwIDQwQzQ4LjEgMzkuNSA0Ny41IDM4LjIgNDggMzZDNDguMyAzNC4yIDQ5LjkgMzMgNTEuNyAzMy4zQzUxLjkgMzMuMyA1MiAzMy40IDUyLjIgMzMuNEw1Ny43IDM0LjRaTTcxLjUgNDkuNkw4MC4zIDE2LjhMNzUuMiAxNS40TDY2LjQgNDguMkw3MS41IDQ5LjZaIiBmaWxsPSIjMDA5OUZGIi8+Cjwvc3ZnPgo="));
  --color: var(--igx-watermark-color, #fff);
  --border-color: var(--igx-watermark-border-color, hsla(var(--ig-primary-500), var(--ig-primary-a)));
  --border-radius: var(--igx-watermark-border-radius, clamp(0rem, calc(var(--ig-radius-factor, 0) * 1rem), 1rem));
}

.igx-watermark {
  display: block;
  position: absolute;
  bottom: 0;
  inset-inline-end: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  pointer-events: none;
}

.igx-watermark__link {
  display: inline-flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  inset-inline-end: 0;
  font-size: 0.875rem;
  padding: 0.125rem 0.25rem;
  line-height: normal;
  border-radius: var(--border-radius);
  border: 0.0625rem solid var(--border-color);
  color: var(--color);
  z-index: 10002;
  background-color: var(--link-background);
  pointer-events: all;
  text-decoration: none;
}

.igx-watermark__background-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  z-index: 10001;
  pointer-events: none;
  background-image: var(--background-image);
  background-repeat: repeat;
  background-position: 1rem 1rem;
}
@media all and (-ms-high-contrast: none) {
  .igx-watermark__background-img {
    background-image: none;
  }
}