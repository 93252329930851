@use '../themes' as *;
@import '~@ng-select/ng-select/themes/material.theme.css';

.ng-select {
  width: 20rem;
  max-width: 20rem;
  padding-bottom: 0.5rem;
}

.ng-select.small {
  width: 10rem;
  max-width: 10rem;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  bottom: 1.4em;
  padding-left: 0.6em;
}

.ng-select.ng-select-focused .ng-select-container:after {
  border-color: nb-theme(color-primary-500) !important;
}

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after {
  border-color: nb-theme(color-primary-500) !important;
}

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  color: nb-theme(color-primary-500) !important;
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: nb-theme(color-primary-500) !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: nb-theme(color-primary-500) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  color: nb-theme(color-primary-500) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: nb-theme(color-primary-500) !important;
}

.ng-select-hide-down-carat {
  .ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: none !important;
    border-style: none !important;
    border-width: 0 !important;
  }
}
