@use '../themes' as *;

@use '@nebular/theme/styles/globals' as *;

@include nb-install() {
  @include nb-theme-global();
}

/* You can add global styles to this file, and also import other style files */

.header-1 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
}

.header-2 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
}

.clickable {
  cursor: pointer;
}

a {
  color: nb-theme(color-primary-500) !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-error {
  color: nb-theme(color-danger-600);
}

.text-selected {
  color: nb-theme(color-primary-500);
  font-weight: 600;
}

.text-small {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
}

.text-overflow-break-word {
  overflow-wrap: anywhere;
}

.text-center {
  text-align: center;
  margin: auto;
}

.align-items-center {
  align-items: center;
}

.no-display {
  display: none;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-x {
  display: flex;
  flex-direction: row;
}

.flex-y {
  display: flex;
  flex-direction: column;
}

.flex-x-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.grid-2-columns {
  display: grid;
  grid-template-columns: auto auto;
}

.grid-3-columns {
  display: grid;
  grid-template-columns: auto auto auto;
}

.row-gap-1 {
  row-gap: 1rem;
}

.row-gap-2 {
  row-gap: 3rem;
}

.row-gap-3 {
  row-gap: 8rem;
}

.column-gap-1 {
  column-gap: 1rem;
}

.column-gap-2 {
  column-gap: 3rem;
}

.column-gap-3 {
  column-gap: 8rem;
}

.width-full {
  width: 100%;
}

.width-fit {
  width: fit-content;
}

.min-width-5 {
  min-width: 50rem;
}

.width-2 {
  width:3rem;
}

.width-3 {
  width: 8rem;
}

.width-4 {
  width: 20rem;
}

.width-5 {
  width: 50rem;
}

.height-full {
  height: 100%;
}

.min-height-1-5 {
  min-height: 1.5rem !important;
}

.height-1 {
  height: 1rem !important;
}

.height-2 {
  height: 3rem !important;
}

.height-3 {
  height: 8rem !important;
}

.height-4 {
  height: 20rem !important;
}

.height-64vh {
  height: 64vh;
}

.margin-block-auto {
  margin-block: auto;
}

.margin-block-1 {
  margin-block: 1rem;
}

.margin-inline-auto {
  margin-inline: auto;
}

.margin-inline-1 {
  margin-inline: 1rem;
}

.margin-right-auto {
  margin-right: auto;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 3rem;
}

.margin-top-3 {
  margin-top: 8rem;
}

.margin-top-4 {
  margin-top: 20rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-2 {
  margin-bottom: 3rem;
}

.margin-bottom-3 {
  margin-bottom: 8rem;
}

.padding-1 {
  padding: 1rem;
}

.primary-fill {
  background: nb-theme(color-primary-500);
}

.white-fill {
  background: #ffffff;
}

.light-gray-fill {
  background: #D9D9D9;
}

.medium-gray-fill {
  background: #969595;
}

.dark-gray-fill {
  background: #333;
}

.dark-green-fill {
  background-color: #285902;
}

.dark-blue-fill {
  background-color: #000578;
}

.dark-red-fill {
  background-color: #8D1900;
}

.border-none {
  border: none;
}

.border-radius-1 {
  border-radius: 1rem;
}

.border-radius-2 {
  border-radius: 3rem;
}

.border-bottom-black {
  border-bottom: 1px solid #000;
}

.border-light-gray {
  border: 1px solid nb-theme(border-basic-color-3)
}

.margin-left-inv-full {
  margin-left: -100%;
}

.box-shadow {
  box-shadow: 0px 0px 1rem nb-theme(border-basic-color-3);
}